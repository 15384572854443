import { CustomReportGroupVarDict } from "../interfaces-classes/index";
import { utils } from '../exports';
// import { xapiUtils } from '../../xapi/xapi-utils';
import { reportUtils } from "../../reports/report-utils";
import { xapi } from "../../xapi/xapi-base";
export var drilldownGrid = {
    reportStructure: {},
    columnStructure: [],
    mainProject: {},
    userMap: new Map(),
    listType: '',
    groupList: [],
    groupColumnData: [],
    init: function (stmts, mainProject) {
        this.mainProject = Object.assign({}, mainProject);
        utils.sortStmtArrayByTimestamp(stmts);
        this.reportStructure = reportUtils.getReportStructure(this.mainProject);
        this.columnStructure = this.reportStructure.columns || [];
        this.createGroupStructure();
        reportUtils.processStmtData(stmts, this);
        this.processUserData();
        if (this.userMap.size > 1) {
            this.processGroupData();
        }
        var params = new URLSearchParams(location.search);
        var printUserId = params.get('printUserId');
        if (printUserId) {
            var user = this.userMap.get(printUserId.replace('mailto:', ''));
            this.printout.createAndPrintUserGrid(user, 'external');
        }
        else {
            // TODO import and use shared
            this.createDrilldownHtml();
            this.renderGrid();
        }
    },
    applySecondaryFilters: function (standardStmts) {
        return reportUtils.filterStmtsToGroup(standardStmts, this);
    },
    getMatchingStmtsFromGroupResponseStrings: function (groupingVarDict, statements) {
        var groupingDict = {};
        var _loop_1 = function (grpName) {
            var strOpts = groupingVarDict[grpName];
            var textArray = strOpts.textArray, correctIndex = strOpts.correctIndex;
            var matchStmts = statements.filter(function (stmt) {
                return stmt[grpName];
            });
            var groupArray = textArray.map(function (text, idx) {
                var responseMatch = matchStmts.filter(function (stmt) {
                    return stmt.response === text;
                })[0];
                return {
                    text: text,
                    isAnswered: !!responseMatch,
                    isCorrect: idx === correctIndex
                };
            });
            // added prefix string to handle leading numbers in IDs
            groupingDict['grouping' + grpName] = groupArray;
        };
        for (var grpName in groupingVarDict) {
            _loop_1(grpName);
        }
        return groupingDict;
    },
    getMatchingStmtsFromSuccessList: function (successList, statements, successMetric) {
        var successListDict = {};
        successList.forEach(function (item) {
            var question = item.question, variablename = item.variablename, correctAnswer = item.correctAnswer;
            var matchStmt = reportUtils.getMatchingStmtFromProp('variablename', variablename, statements);
            var userScore = null;
            var isCorrect = false;
            if (matchStmt) {
                var userScoreRaw = matchStmt.scoreRaw;
                userScore = userScoreRaw >= 0 ? userScoreRaw : null;
                if (successMetric === 'scoreEval') {
                    var _a = matchStmt.scoreRaw, scoreRaw = _a === void 0 ? 0 : _a, _b = matchStmt.scoreMax, scoreMax = _b === void 0 ? 0 : _b;
                    isCorrect = scoreRaw && scoreMax && scoreRaw === scoreMax;
                }
                else if (successMetric === 'scoreRaw') {
                    isCorrect = userScore;
                }
                else if (successMetric === 'successFlipped') {
                    // SISEP CA is flipped
                    isCorrect = !matchStmt.success;
                }
                else {
                    isCorrect = matchStmt.success;
                }
            }
            else {
                isCorrect = null;
            }
            successListDict[variablename] = {
                question: question,
                isCorrect: isCorrect,
                userScore: userScore,
                correctAnswer: correctAnswer
            };
        });
        return successListDict;
    },
    createGroupStructure: function () {
        this.groupColumnData = this.columnStructure.map(function (column) {
            column = Object.assign({
                drilldownHtml: '',
                trafficLightUserList: [],
                varDict: {},
                varDictUserList: [], // drilldown values for users
            }, column);
            return column;
        });
    },
    processGroupData: function () {
        var _a = this, userMap = _a.userMap, groupColumnData = _a.groupColumnData, reportStructure = _a.reportStructure;
        groupColumnData.forEach(function (column) {
            var parent = column.parent, trafficLightGroupLogic = column.trafficLightGroupLogic, trafficLightUserList = column.trafficLightUserList;
            var groupFns = reportStructure.specialGroupVarDictFns;
            var groupFn = groupFns ? groupFns[parent] : null;
            column.varDict = groupFn ? groupFn(column) : new CustomReportGroupVarDict();
            var trafficCount = utils.calculateStrQtyFromList(trafficLightUserList);
            var trafficTotal = trafficLightUserList.length;
            var greenQty = 0;
            for (var lightName in trafficCount) {
                if (!lightName.includes('green')) {
                    continue;
                }
                greenQty = trafficCount[lightName];
            }
            var greenPercent = (greenQty / trafficTotal) * 100;
            var trafficLight = '';
            if (trafficLightGroupLogic) {
                for (var color in trafficLightGroupLogic) {
                    var colorResults = trafficLightGroupLogic[color];
                    var min = colorResults.min, max = colorResults.max;
                    if (greenPercent >= min && greenPercent <= max) {
                        trafficLight = color;
                    }
                }
            }
            column.trafficLight = trafficLight;
        });
        var groupId = 'Selected Group';
        userMap.set(groupId, {
            type: 'group',
            name: "Group Results",
            email: groupId,
            columnData: groupColumnData
        });
    },
    processUserData: function () {
        var _this = this;
        var _a = this, userMap = _a.userMap, columnStructure = _a.columnStructure;
        userMap.forEach(function (user) {
            var _a = user.statements, statements = _a === void 0 ? [] : _a;
            var lastStmtOverall = statements[statements.length - 1];
            user.lastActivityDate = lastStmtOverall ? lastStmtOverall.date.toLocaleString() : '';
            user.columnData = [];
            columnStructure.forEach(function (column) {
                var parent = column.parent, variablename = column.variablename, drilldown = column.drilldown;
                var _a = drilldown.vars, vars = _a === void 0 ? {} : _a;
                column = Object.assign({}, column);
                var finalDashStmt = reportUtils.getMatchingStmtFromProp('variablename', variablename, statements);
                var _b = (finalDashStmt || {}).response, trafficLight = _b === void 0 ? '' : _b;
                column.trafficLight = trafficLight;
                var _c = vars.opts, opts = _c === void 0 ? {} : _c;
                var _d = opts.prefix, prefix = _d === void 0 ? '' : _d, _e = opts.stringifyVarPrefix, stringifyVarPrefix = _e === void 0 ? '' : _e, successMetric = opts.successMetric;
                var scoreVarList = vars.scoreRaw || [];
                var responseVarList = vars.response || [];
                var successVarList = vars.success || [];
                var successListVarList = vars.successList || [];
                var groupingVarDict = vars.grouping || {};
                var scoreDict = {};
                var responseDict = {};
                var successDict = {};
                function _addToDictUsingVarName(vbl, returnProp, dict) {
                    var matchStmt = reportUtils.getMatchingStmtFromProp('variablename', vbl, statements);
                    var varPrefix = prefix ? returnProp : '';
                    var returnVal;
                    if (matchStmt) {
                        returnVal = matchStmt[returnProp];
                        // if (!returnVal && returnVal !== 0) {
                        // 	returnVal = '';
                        // }
                    }
                    // stringifyVarPrefix will fix variable names with integers
                    varPrefix = varPrefix || stringifyVarPrefix;
                    dict[varPrefix + vbl] = returnVal;
                }
                scoreVarList.forEach(function (vbl) {
                    _addToDictUsingVarName(vbl, 'scoreRaw', scoreDict);
                });
                responseVarList.forEach(function (vbl) {
                    _addToDictUsingVarName(vbl, 'response', responseDict);
                });
                successVarList.forEach(function (vbl) {
                    _addToDictUsingVarName(vbl, 'success', successDict);
                });
                var groupingDict = _this.getMatchingStmtsFromGroupResponseStrings(groupingVarDict, statements);
                var successListDict = _this.getMatchingStmtsFromSuccessList(successListVarList, statements, successMetric);
                var varDict = Object.assign(scoreDict, responseDict, successDict, groupingDict, successListDict);
                column.varDict = varDict;
                var thisGroupColumn = _this.groupColumnData.find(function (col) {
                    return col.parent === parent;
                }) || null;
                if (thisGroupColumn) {
                    thisGroupColumn.varDictUserList.push(varDict);
                    thisGroupColumn.trafficLightUserList.push(trafficLight);
                }
                user.columnData.push(column);
            });
        });
        this.userMap = utils.sortMap(userMap, 'lastActivityDate', 'desc');
    },
    createDrilldownHtml: function (isPrintGrid, user) {
        var singleUserMap = new Map();
        isPrintGrid && user && singleUserMap.set(user.email, user);
        var thisUserMap = isPrintGrid ? singleUserMap : this.userMap;
        thisUserMap.forEach(function (user) {
            var name = user.name, email = user.email;
            user.columnData.forEach(function (column) {
                var parent = column.parent, desc = column.desc, _a = column.opts, opts = _a === void 0 ? {} : _a, drilldown = column.drilldown, varDict = column.varDict;
                var singleColumn = opts.singleColumn;
                var headerClass = isPrintGrid && singleColumn ? 'report-header' : '';
                var template = drilldown.template;
                var headerUserInfo = "\n\t\t\t\t\t<h2>" + name + "</h2>\n\t\t\t\t\t<p style=\"margin: 0;\">" + email + "</p>\n\t\t\t\t";
                var drilldownTitleAndDescHtml = !isPrintGrid && singleColumn ? '' : "\n\t\t\t\t\t<div class=\"title-bar\">\n\t\t\t\t\t\t<h3>" + parent + "</h3>\n\t\t\t\t\t\t<p class=\"desc\">" + desc + "</p>\n\t\t\t\t\t</div>\n\t\t\t\t";
                var drilldownHeader = "\n\t\t\t\t\t<div class=\"drilldown-header " + headerClass + "\">\n\t\t\t\t\t\t<div class=\"" + (!isPrintGrid && 'drilldown-header-user-info') + "\">\n\t\t\t\t\t\t\t" + (isPrintGrid ? '' : headerUserInfo) + "\n\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t" + drilldownTitleAndDescHtml + "\n\t\t\t\t\t</div>\n\t\t\t\t";
                // <hr>
                var drilldownHtml = "\n\t\t\t\t\t<div class=\"drilldown-container\">\n\t\t\t\t\t\t" + (drilldownHeader + (template(varDict) || '(none)')) + "\n\t\t\t\t\t</div>\n\t\t\t\t";
                column.drilldownHtml = drilldownHtml;
            });
        });
    },
    getTrafficInfo: function (trafficLight) {
        if (trafficLight === void 0) { trafficLight = ''; }
        var className = '';
        var text = '';
        ['green', 'yellow', 'red'].forEach(function (color) {
            if (trafficLight.toLowerCase().includes(color)) {
                className = color + '-light';
                text = color + ' light';
            }
        });
        return { className: className, text: text };
    },
    createTrafficLightCells: function (column, email, isPrintGrid) {
        var parent = column.parent, trafficLight = column.trafficLight, drilldown = column.drilldown;
        var icon = (drilldown || {}).icon;
        var hasData = true;
        var elTitle = '';
        var cellContentsHtml = '';
        if (icon) {
            if (isPrintGrid) {
                hasData = false;
                cellContentsHtml = '<em>(Text only - see below)</em>';
            }
            else {
                elTitle = 'Text content';
                cellContentsHtml = '<ion-icon class="drilldown-cell-icon" name="document-text-outline"></ion-icon>';
            }
        }
        else {
            var trafficInfo = drilldownGrid.getTrafficInfo(trafficLight);
            hasData = !!trafficInfo.text;
            var noDataText = '(No data)';
            var noDataClass = !hasData ? 'no-data' : '';
            elTitle = trafficInfo.text || noDataText;
            var trafficLightIcon = "<div class=\"traffic-light " + trafficInfo.className + "\"></div>";
            if (isPrintGrid && !hasData) {
                trafficLightIcon = '';
            }
            var infoText = isPrintGrid ? elTitle : trafficInfo.text;
            cellContentsHtml = "\n\t\t\t\t<div class=\"traffic-light-accessible " + trafficInfo.className + " " + noDataClass + "\">\n\t\t\t\t\t" + trafficLightIcon + "\n\t\t\t\t\t<div class=\"traffic-light-text " + noDataClass + "\">" + infoText + "</div>\n\t\t\t\t</div>\n\t\t\t";
        }
        var html = "\n\t\t\t<td title=\"" + elTitle + "\" class=\"drilldown-cell\" data-drilldown-parent=\"" + parent + "\" data-email=\"" + email + "\">\n\t\t\t\t<div class=\"drilldown-cell-wrapper\">\t\n\t\t\t\t\t" + cellContentsHtml + "\n\t\t\t\t</div>\n\t\t\t</td>\n\t\t";
        return {
            hasData: hasData,
            html: html,
        };
    },
    createGridRowHtml: function (user, isPrintGrid) {
        var reportStructure = this.reportStructure;
        var _a = (reportStructure || {}).printouts, printouts = _a === void 0 ? [] : _a;
        var type = user.type, name = user.name, email = user.email, lastActivityDate = user.lastActivityDate, columnData = user.columnData;
        // const trafficLightHasContentList = [];
        var trafficLightCells = columnData
            .map(function (column) {
            var cell = drilldownGrid.createTrafficLightCells(column, email, isPrintGrid);
            // trafficLightHasContentList.push(cell.hasData);
            return cell.html;
        })
            .join('');
        // if (isPrintGrid) {
        // 	const hasNoTrafficContent = trafficLightHasContentList.every(val => val === false);
        // 	if (hasNoTrafficContent) {
        // 		return '';
        // 	}
        // }
        var additionalPrintColumns = [];
        if (printouts.length) {
            additionalPrintColumns = printouts.map(function (printout) {
                var printoutName = printout.name, profileId = printout.profileId, project = printout.project;
                return "\n\t\t\t\t\t<td>\n\t\t\t\t\t\t<button type=\"button\" class=\"btn btn-default btn-print-grid teal print-grid-custom\" data-user-id=\"" + email + "\" data-profile-id=\"" + profileId + "\" data-project=\"" + project + "\" title=\"" + printoutName + " for " + name + "\">\n\t\t\t\t\t\t\t<i class=\"fa fa-print\"></i> \n\t\t\t\t\t\t\t<span>" + printoutName + "</span>\n\t\t\t\t\t\t</button>\n\t\t\t\t\t</td>\n\t\t\t\t";
            });
        }
        if (type === 'group') {
            var colspan = 4 + additionalPrintColumns.length;
            return "\n\t\t\t\t<tr class=\"group-row\">\n\t\t\t\t\t<td colspan=\"" + colspan + "\">" + name + "</td>\n\t\t\t\t\t" + trafficLightCells + "\n\t\t\t\t</tr>\n\t\t\t";
        }
        else {
            if (isPrintGrid) {
                return trafficLightCells;
            }
            else {
                return "\n\t\t\t\t\t<tr>\n\t\t\t\t\t\t<td>\n\t\t\t\t\t\t\t<button type=\"button\" class=\"btn btn-default btn-print-grid teal print-grid-user\" data-user-id=\"" + email + "\" title=\"Print Summary for " + name + "\">\n\t\t\t\t\t\t\t\t<i class=\"fa fa-print\"></i> \n\t\t\t\t\t\t\t\t<span>Print Learner Summary</span>\n\t\t\t\t\t\t\t</button>\n\t\t\t\t\t\t</td>\n\t\t\t\t\t\t\n\t\t\t\t\t\t" + additionalPrintColumns.join('') + "\n\n\t\t\t\t\t\t<td>" + name + "</td>\n\t\t\t\t\t\t<td>" + (email || '') + "</td>\n\t\t\t\t\t\t<td>" + (lastActivityDate || '') + "</td>\n\t\t\t\t\t\t" + trafficLightCells + "\n\t\t\t\t\t</tr>\n\t\t\t\t";
            }
        }
    },
    renderGrid: function (isPrintGrid, user) {
        var _this = this;
        var _a = this, userMap = _a.userMap, columnStructure = _a.columnStructure, reportStructure = _a.reportStructure;
        var _b = (reportStructure || {}).printouts, printouts = _b === void 0 ? [] : _b;
        var firstHeaderRowHtmlList = columnStructure.map(function (column) {
            return "<th class=\"th-centered\">" + column.parent + "</th>";
        });
        var secondHeaderRowHtmlList = columnStructure.map(function (column) {
            return "<th class=\"th-centered\">" + column.desc + "</th>";
        });
        var firstHeaderRowHtml = firstHeaderRowHtmlList.join('');
        var secondHeaderRowHtml = secondHeaderRowHtmlList.join('');
        if (isPrintGrid) {
            var gridRowHtml = this.createGridRowHtml(user, true);
            var gridRowHtmlEl = document.createElement('div');
            gridRowHtmlEl.innerHTML = gridRowHtml;
            var gridRowText = gridRowHtmlEl.innerText.trim();
            if (!gridRowText) {
                return '';
            }
            return "\n\t\t\t\t<table class=\"drilldown-table\">\n\t\t\t\t\t<thead>\n\t\t\t\t\t\t<tr>" + firstHeaderRowHtml + "</tr>\n\t\t\t\t\t\t<tr>" + secondHeaderRowHtml + "</tr>\n\t\t\t\t\t</thead>\n\n\t\t\t\t\t<tbody>\n\t\t\t\t\t\t" + gridRowHtml + "\n\t\t\t\t\t</tbody>\n\t\t\t\t</table>\n\t\t\t";
        }
        var additionalPrintColumnHeaders = [];
        if (printouts.length) {
            additionalPrintColumnHeaders = printouts.map(function (printout) { return printout.name; });
        }
        window.userMap = userMap;
        console.log('userMap:', window.userMap);
        var outputHtml = "\n\t\t\t<ul style=\"opacity: 0.7;margin: 0 0 1em;padding-left: 2em;\">\n\t\t\t\t<li><em><strong>NOTE:</strong> gray circles represent a lack of activity data for that user</em></li>\n\t\t\t\t<li><em>Select a cell to view details</em></li>\n\t\t\t</ul>\n\t\t\n\t\t\t<table>\n\t\t\t\t<thead>\n\t\t\t\t\t<tr>\n\t\t\t\t\t\t<th>Print Summary</th>\n\t\t\t\t\t\t" + additionalPrintColumnHeaders.map(function (colName) { return "<th>" + colName + "</th>"; }).join('') + "\n\t\t\t\t\t\t<th>Name</th>\n\t\t\t\t\t\t<th>ID</th>\n\t\t\t\t\t\t<th>Date</th>\n\t\t\t\t\t\t" + firstHeaderRowHtml + "\n\t\t\t\t\t</tr>\n\t\t\t\t\t\t\n\t\t\t\t\t<tr>\n\t\t\t\t\t\t<th></th>\n\t\t\t\t\t\t" + additionalPrintColumnHeaders.map(function () { return "<th></th>"; }).join('') + "\n\t\t\t\t\t\t<th></th>\n\t\t\t\t\t\t<th></th>\n\t\t\t\t\t\t<th><em>(Last Activity)</em></th>\n\t\t\t\t\t\t" + secondHeaderRowHtml + "\n\t\t\t\t\t</tr>\n\t\t\t\t</thead>\n\t\t\t\t<tbody>\n\t\t";
        userMap.forEach(function (user) {
            outputHtml += _this.createGridRowHtml(user);
        });
        outputHtml += "\n\t\t\t</tbody>\n\t\t\t</table>\n\t\t";
        $('.grid-container').html(outputHtml);
        this.bindGridEvents();
    },
    printout: {
        onPrintGridUser: function () {
            var _a = this.dataset.userId, userId = _a === void 0 ? '' : _a;
            var user = drilldownGrid.userMap.get(userId);
            drilldownGrid.printout.createAndPrintUserGrid(user, 'internal');
        },
        onGetCustomPrintout: function () {
            var _a = xapi.connection.config, endpoint = _a.endpoint, auth = _a.auth;
            var _b = this.dataset, profileId = _b.profileId, userId = _b.userId, project = _b.project;
            var printUrl = "../printouts/" + project + "/" + profileId + "/";
            var actor = JSON.stringify({ mbox: "mailto:" + userId });
            var printParams = {
                standalone: "true",
                retrieve: "true",
                profileId: profileId,
                endpoint: endpoint,
                auth: encodeURIComponent(auth),
                actor: actor,
            };
            printUrl += '?' + new URLSearchParams(printParams).toString();
            window.open(printUrl, '_blank');
        },
        createPrintHeaderForUser: function (user) {
            var name = user.name, email = user.email, lastActivityDate = user.lastActivityDate;
            var _a = (drilldownGrid.mainProject || {}).report, _b = _a === void 0 ? {} : _a, _c = _b.courseName, courseName = _c === void 0 ? '' : _c;
            // const reportTitle = courseName + ' | Summary';
            var reportTitle = courseName;
            return "\n\t\t\t\t<div class=\"print-grid-page-header\">\n\t\t\t\t\t<div>\n\t\t\t\t\t\t<h1>" + reportTitle + "</h1>\n\t\t\t\t\t\t<h2 class=\"print-grid-page-subheader\">Summary for <strong>" + name + "</strong></h2>\n\t\t\t\t\t</div>\n\t\n\t\t\t\t\t<div>\n\t\t\t\t\t\t<div class=\"print-grid-details\">\n\t\n\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t<div>User ID:</div>\n\t\t\t\t\t\t\t\t<div>Last activity:</div>\n\t\t\t\t\t\t\t\t<div>Report created:</div>\n\t\t\t\t\t\t\t</div>\n\t\n\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t<div>" + email + "</div>\n\t\t\t\t\t\t\t\t<div>" + (lastActivityDate || '') + "</div>\n\t\t\t\t\t\t\t\t<div>" + new Date().toLocaleString() + "</div>\n\t\t\t\t\t\t\t</div>\n\t\n\t\t\t\t\t\t</div>\n\t\t\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t";
        },
        createAndPrintUserGrid: function (user, source) {
            utils.updateUiForPrint('before', source);
            console.log('user:', user);
            drilldownGrid.createDrilldownHtml(true, user);
            var userHtml = drilldownGrid.printout.createPrintHeaderForUser(user);
            var gridHtml = drilldownGrid.renderGrid(true, user);
            var drilldownHtml = user.columnData.map(function (c) { return c.drilldownHtml; }).join('');
            var allHtml = userHtml + gridHtml + drilldownHtml;
            var printContainerEl = document.querySelector('.print-container');
            if (printContainerEl) {
                printContainerEl.innerHTML = '';
                printContainerEl.insertAdjacentHTML('afterbegin', allHtml);
            }
            setTimeout(function () {
                window.print();
            }, 1000);
            setTimeout(function () {
                if (source === "internal") {
                    utils.updateUiForPrint('after', source);
                }
                else if (source === 'external') {
                    window.close();
                }
            }, 2000);
        },
    },
    bindGridEvents: function () {
        $('.grid-loading-container').addClass('hidden');
        var $drilldownEl = $('.drilldown');
        var $drilldownContentEl = $('.drilldown-content');
        $('[data-drilldown-parent]').on('click', function () {
            var _a = this.dataset, email = _a.email, drilldownParent = _a.drilldownParent;
            var user = drilldownGrid.userMap.get(email);
            var thisColumn = user.columnData.filter(function (column) {
                return column.parent === drilldownParent;
            })[0];
            var drilldownData = thisColumn ? thisColumn.drilldownHtml || 'none' : 'none';
            $drilldownContentEl.html(drilldownData);
            $drilldownEl.removeClass('hidden');
            $(this).addClass('selected');
        });
        function closeDrilldown() {
            $('td.selected').removeClass('selected');
            $drilldownContentEl[0].scroll(0, 0);
            $drilldownEl.addClass('hidden');
        }
        $(document).on('click', function (e) {
            var isDrilldown = e.target.closest('.drilldown');
            var isDrilldownBg = e.target.classList.contains('drilldown');
            var isDrilldownClose = e.target.classList.contains('drilldown-close-btn');
            if (isDrilldown && (isDrilldownBg || isDrilldownClose)) {
                closeDrilldown();
            }
        });
        $('.print-grid-user').on('click', drilldownGrid.printout.onPrintGridUser);
        $('.print-grid-custom').on('click', drilldownGrid.printout.onGetCustomPrintout);
        // $('.drilldown-close-btn').on('click', closeDrilldown);
    },
};
