import { DrilldownTable, defaultTrafficLights, } from "../../../../../shared/interfaces-classes/index";
import { reportUtils } from "../../../../report-utils";
var getGenericGroupMsg = reportUtils.getGenericGroupMsg, processSingleVarHtml = reportUtils.processSingleVarHtml, getYesNoFromBoolishVar = reportUtils.getYesNoFromBoolishVar, getCorrectIncorrectByValueComparison = reportUtils.getCorrectIncorrectByValueComparison;
var genericGroupMsg = getGenericGroupMsg();
export var mod2 = {
    'http://NCICMod2': {
        columns: [
            {
                parent: 'LO1',
                variablename: 'LO1dash',
                desc: 'Identify ready agencies',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        response: [
                            'pinecountyselected',
                            'pinecountyyouthselected',
                            'localyouthselected',
                            'childrensfirstselected',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var pinecountyselected = varDict.pinecountyselected, pinecountyyouthselected = varDict.pinecountyyouthselected, localyouthselected = varDict.localyouthselected, childrensfirstselected = varDict.childrensfirstselected;
                        var columns = [
                            {
                                header: '',
                                rows: [
                                    'CORRECT?',
                                    'SELECTED?',
                                    'Level of fitness',
                                    'Level of ability',
                                    'Level of willingness',
                                ],
                            },
                            {
                                header: "Grand County Services",
                                rows: [
                                    'No',
                                    getYesNoFromBoolishVar(pinecountyselected),
                                    2,
                                    3,
                                    4,
                                ],
                                type: getCorrectIncorrectByValueComparison('No', getYesNoFromBoolishVar(pinecountyselected)),
                            },
                            {
                                header: "Grand County Youth Organization",
                                rows: [
                                    'Yes',
                                    getYesNoFromBoolishVar(pinecountyyouthselected),
                                    5,
                                    3,
                                    4,
                                ],
                                type: getCorrectIncorrectByValueComparison('Yes', getYesNoFromBoolishVar(pinecountyyouthselected)),
                            },
                            {
                                header: "Local Youth Activities Association",
                                rows: [
                                    'Yes',
                                    getYesNoFromBoolishVar(localyouthselected),
                                    5,
                                    5,
                                    5,
                                ],
                                type: getCorrectIncorrectByValueComparison('Yes', getYesNoFromBoolishVar(localyouthselected)),
                            },
                            {
                                header: "Children\u2019s First Agency",
                                rows: [
                                    'No',
                                    getYesNoFromBoolishVar(childrensfirstselected),
                                    2,
                                    1,
                                    5,
                                ],
                                type: getCorrectIncorrectByValueComparison('No', getYesNoFromBoolishVar(childrensfirstselected)),
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return "\n\t\t\t\t\t\t<h3 class=\"drilldown-table-header-center\">Agencies</h3>\n\n\t\t\t\t\t\t" + html;
                    }
                }
            },
            {
                parent: 'LO2',
                variablename: 'LO2dash',
                desc: 'Choose the best evaluator',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        response: [
                            'precisionselected',
                            'evalexactchosen',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var precisionselected = varDict.precisionselected, evalexactchosen = varDict.evalexactchosen;
                        var columns = [
                            {
                                header: '',
                                rows: [
                                    'CORRECT?',
                                    'SELECTED?',
                                    'Level of affordability',
                                    'Level of alignment',
                                ],
                            },
                            {
                                header: "Precision Evaluation",
                                rows: [
                                    'No',
                                    getYesNoFromBoolishVar(precisionselected),
                                    0,
                                    5,
                                ],
                                type: getCorrectIncorrectByValueComparison('No', getYesNoFromBoolishVar(precisionselected)),
                            },
                            {
                                header: "EvalEXACT",
                                rows: [
                                    'Yes',
                                    getYesNoFromBoolishVar(evalexactchosen),
                                    5,
                                    5,
                                ],
                                type: getCorrectIncorrectByValueComparison('Yes', getYesNoFromBoolishVar(evalexactchosen)),
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return "\n\t\t\t\t\t\t<h3 class=\"drilldown-table-header-center\">Evaluators</h3>\n\n\t\t\t\t\t\t" + html;
                    }
                }
            },
            {
                parent: 'LO3a',
                variablename: 'LO3adash',
                desc: 'Choose the best funder',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        response: [
                            'abcfundselected',
                            'xyzfundselected',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var abcfundselected = varDict.abcfundselected, xyzfundselected = varDict.xyzfundselected;
                        var columns = [
                            {
                                header: '',
                                rows: [
                                    'CORRECT?',
                                    'SELECTED?',
                                    'Level of availability',
                                    'Level of stability',
                                    'Level of fit',
                                ],
                            },
                            {
                                header: "ABC Foundation",
                                rows: [
                                    'No',
                                    getYesNoFromBoolishVar(abcfundselected),
                                    2,
                                    5,
                                    4,
                                ],
                                type: getCorrectIncorrectByValueComparison('No', getYesNoFromBoolishVar(abcfundselected)),
                            },
                            {
                                header: "XYZ Fund",
                                rows: [
                                    'Yes',
                                    getYesNoFromBoolishVar(xyzfundselected),
                                    5,
                                    2,
                                    5,
                                ],
                                type: getCorrectIncorrectByValueComparison('Yes', getYesNoFromBoolishVar(xyzfundselected)),
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return "\n\t\t\t\t\t\t<h3 class=\"drilldown-table-header-center\">Funders</h3>\n\n\t\t\t\t\t\t" + html;
                    }
                }
            },
            {
                parent: 'LO3b',
                variablename: 'LO3bdash',
                desc: 'Describe appropriate level of funding confidence',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        scoreRaw: [
                            'fundingconfidence',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var fundingconfidence = varDict.fundingconfidence;
                        var columns = [
                            {
                                header: '',
                                rows: [
                                    'Level of funding confidence expressed',
                                    'Appropriate level of funding confidence',
                                ],
                            },
                            {
                                header: "",
                                rows: [
                                    processSingleVarHtml(fundingconfidence),
                                    '1-5',
                                ],
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
        ],
        printouts: [
            {
                name: 'Customized Learner Summary',
                profileId: 'ictp-mod-2',
                project: 'ictp',
            }
        ],
    },
};
