export var colSettings = [
    {
        header: "Name", prop: 'name', width: 120
    },
    {
        header: "ID", prop: 'id', width: 130
    },
    {
        header: "Verb", prop: 'verb', width: 80
    },
    {
        header: "Activity", prop: 'activityName', width: 180
    },
    {
        header: "Description", prop: 'activityDesc', width: 220
    },
    {
        header: "Variable", prop: 'variable', width: 160
    },
    {
        header: "Response", prop: 'response', width: 250
    },
    {
        header: "Score", prop: 'score', width: 60
    },
    {
        header: "Max Score", prop: 'maxscore', width: 75
    },
    {
        header: "Success", prop: 'success', width: 78
    },
    {
        header: "Completion", prop: 'completion', width: 94
    },
    {
        header: "Video watched (%)", prop: 'percentageViewed', width: 110
    },
    {
        header: "Video length (min:sec)", prop: 'duration', width: 150
    },
    {
        header: "Date", prop: 'date', width: 110
    },
];
// const colHeaders = [
// 	"Name",
// 	"ID",
// 	"Verb",
// 	"Activity",
// 	"Description",
// 	"Response",
// 	"Score",
// 	"Max Score",
// 	"Success",
// 	"Completion",
// 	"Video watched (%)",
// 	"Video length (min:sec)",
// 	"Variable",
// 	"Date",
// 	// "Browser",
// 	// "System",
// 	// "JSON"
// ];
// const colWidths = [120, 130, 80, 200, 250, 250, 50, 50, 110, 150, 110, 150, 75, 110, 110, 50];
