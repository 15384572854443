export var choiceTextMap = {
    IDPersA1: 'Community forums for family members',
    IDPersA2: 'Special Education Teachers',
    IDPersA3: 'School counselor',
    IDPersA4: 'Students',
    IDPersB1: 'District trainers',
    IDPersB2: 'School Administrators',
    IDPersB3: 'School staff',
    IDPersB4: 'Regional trainers',
    BarrierA1: 'Funding context',
    BarrierA2: 'Organizational context',
    BarrierA3: 'Community context',
    BarrierA4: 'Political context',
    BarrierA5: 'Learning context',
    BarrierB1: 'Funding, including human capital',
    BarrierB2: 'Community: Values/beliefs of community (& alignment with power dynamics)',
    BarrierB3: 'Policy context',
    BarrierB4: 'Organizational (region within district; rural vs urban—different organizational structure)',
    AssetsA1: 'Funding context',
    AssetsA2: 'Organizational context',
    AssetsA3: 'Community context',
    AssetsA4: 'Political context',
    AssetsA5: 'Learning context',
    AssetsB1: 'Funding, including human capital',
    AssetsB2: 'Community: Values/beliefs of community (& alignment with power dynamics)',
    AssetsB3: 'Policy context',
    AssetsB4: 'Organizational (region with district; rural vs urban—different organizational structures)',
    StrategyA1: 'Communication/Dissemination',
    StrategyA2: 'Powerful Questions',
    StrategyA3: 'Smart Small with Early Adopters',
    StrategyA4: 'Staff-Student Relationships',
    StrategyB1: 'Data & Evaluation',
    StrategyB2: 'Communication Plan',
    StrategyB3: 'Training & Coaching Plan',
    StrategyB4: 'Hold PLC Meetings',
    StrategyB5: 'Administrator makes decisions',
    StrategyB6: 'Email about data system',
};
