import { afirm } from './projects/afirm/index';
import { ictp } from './projects/ictp/index';
import { sisep } from './projects/sisep/index';
import { mimtss } from './projects/mimtss/index';
import { cprt } from './projects/cprt/index';
// import { deep } from './projects/deep';
export var customReports = {
    afirm: afirm,
    ictp: ictp,
    mimtss: mimtss,
    sisep: sisep,
    cprt: cprt
};
