// import "core-js/stable";
// import "regenerator-runtime/runtime";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { xapi } from './xapi/xapi-base';
import { Search } from './xapi/xapi-classes';
import { standardReports } from './reports/standard-reports';
import { customReports } from './reports/custom-reports';
import { polyfills, utils, table, fileExport, messaging } from './shared/exports';
import { reportUtils } from './reports/report-utils';
var app = {
    version: '2.1.0',
    BASE_URL: window.BASE_URL || '',
    config: {},
    query: {},
    settings: {},
    isCustom: false,
    isAutorun: false,
    isCustomAsync: false,
    projectId: '',
    report: {},
    lrsInfo: {},
    init: function () {
        polyfills.init(this.bootstrap.bind(this));
    },
    bootstrap: function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, stmtStartFn;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = app;
                        return [4 /*yield*/, app.getConfig()];
                    case 1:
                        _a.config = _b.sent();
                        app.processQueryString();
                        if (app.query.printUserId) {
                            // app.isAutorun = true;
                            app.isCustom = true;
                            app.isCustomAsync = true;
                        }
                        app.getProjectAndReportInfo();
                        if (!app.projectId) {
                            app.ui.hidePageLoading();
                            alert('Project not found - please check Project or Course ID.');
                            return [2 /*return*/];
                        }
                        xapi.init(app.lrsInfo, app.query);
                        app.ui.init();
                        app.bindEvents();
                        app.updateCourse();
                        if (!app.query.printUserId) {
                            app.ui.hidePageLoading();
                        }
                        stmtStartFn = app.actions.getStmtsStart.bind(app);
                        if (!app.isAutorun) return [3 /*break*/, 3];
                        return [4 /*yield*/, stmtStartFn()];
                    case 2:
                        _b.sent();
                        app.ui.table.show();
                        _b.label = 3;
                    case 3:
                        if (app.isCustomAsync) {
                            customReports[app.projectId].onSubmitCallback = stmtStartFn;
                        }
                        return [2 /*return*/];
                }
            });
        });
    },
    getConfig: function () {
        return __awaiter(this, void 0, void 0, function () {
            var prodFile, devFile, isLocal, jsonFile, rootPath, configPath, response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        prodFile = "config-all-projects.json";
                        devFile = "config-all-projects__DEV.json";
                        isLocal = location.origin.includes("localhost") || location.origin.includes("127.0.0.1");
                        jsonFile = isLocal
                            ? devFile
                            : prodFile;
                        rootPath = location.origin;
                        configPath = rootPath + "/admin/" + jsonFile;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, fetch(configPath)];
                    case 2:
                        response = _a.sent();
                        return [2 /*return*/, response.json()];
                    case 3:
                        error_1 = _a.sent();
                        console.error('error getting config:', error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    },
    checkProjectAliases: function (original) {
        var aliases = {
            'impactcenter': 'ictp',
            'fitt-teach': 'teach-fitt',
        };
        var output = original;
        for (var name_1 in aliases) {
            if (original === name_1) {
                output = aliases[name_1];
            }
        }
        return output;
    },
    checkOtherProjectFormats: function () {
        var _this = this;
        var queryKeyList = Object.keys(this.query);
        var queryKeys = queryKeyList.map(function (queryprop) { return queryprop.toLowerCase(); });
        var projectIdList = app.config.map(function (proj) { return proj.id; });
        var projInQuery = queryKeys.find(function (prop) {
            prop = _this.checkProjectAliases(prop);
            return projectIdList.includes(prop);
        });
        console.log('projInQuery:', projInQuery);
        var thisProject = '';
        if (projInQuery) {
            thisProject = projInQuery.toLowerCase();
        }
        else {
            var pathSplit = location.pathname.split('/') || [];
            thisProject = pathSplit.find(function (segment) {
                return projectIdList.includes(segment);
            });
            console.log('thisProject (in path):', thisProject);
        }
        if (!thisProject) {
            if (this.query.courseId) {
                thisProject = reportUtils.getProjectFromCourseId(this.query.courseId, app.config);
            }
        }
        console.log('thisProject:', thisProject);
        return thisProject;
    },
    getEmbedReport: function (course, reportId, embedStr, embeds) {
        var courseSlug = utils.slugify(course.id);
        var slug = reportId.replace(embedStr, '');
        var matchingCourse = courseSlug === slug;
        if (!matchingCourse) {
            return;
        }
        return {
            name: 'Analytics',
            id: reportId,
            shortId: reportId,
            type: 'embeds',
            embeds: embeds
        };
    },
    guessReportType: function () {
        var isGrid = location.href.includes('grid.html');
        if (isGrid) {
            return 'grid';
        }
    },
    addCustomReportToSettings: function (currentProject) {
        var _a = app.query, _b = _a.reportId, reportId = _b === void 0 ? '' : _b, _c = _a.courseId, courseId = _c === void 0 ? '' : _c;
        var currentCourse = {};
        var currentReport = {};
        var embedStr = '-embeds';
        if (!reportId) {
            if (courseId) {
                reportId = reportUtils.getGridReportIdFromCourseId(courseId, app.config);
                console.log('reportId:', reportId);
            }
            else {
                console.error('No reportId or courseId found:', reportId, courseId);
            }
        }
        currentProject.courseList.forEach(function (course) {
            var _a = course.reports, reports = _a === void 0 ? [] : _a, _b = course.embeds, embeds = _b === void 0 ? [] : _b;
            if (reportId && reportId.includes(embedStr)) {
                var embedReport = app.getEmbedReport(course, reportId, embedStr, embeds);
                if (embedReport) {
                    currentCourse = course;
                    currentReport = embedReport;
                }
            }
            else {
                var matchingReport = reports.find(function (report) { return report.id === reportId; });
                if (matchingReport) {
                    currentCourse = course;
                    currentReport = matchingReport;
                    currentReport.courseName = course.name;
                }
            }
        });
        currentReport.id = currentCourse.id;
        currentReport.shortId = reportId;
        currentReport.type = currentReport.type || app.guessReportType();
        app.settings = __assign(__assign({}, app.query), { course: currentCourse, report: currentReport });
        delete app.settings.reportId;
    },
    processQueryString: function () {
        var query = utils.parseQuery();
        app.query = __assign({}, query);
        app.settings = __assign({}, query);
        this.isCustom = query.type === 'custom';
    },
    getProjectAndReportInfo: function () {
        var newFormat = this.settings.project ? this.settings.project.toLowerCase() : '';
        var thisProjectId = newFormat;
        if (!newFormat) {
            thisProjectId = this.checkOtherProjectFormats();
        }
        thisProjectId = this.checkProjectAliases(thisProjectId);
        this.projectId = thisProjectId;
        var currentProject = app.config.find(function (proj) { return proj.id === thisProjectId; });
        if (currentProject) {
            if (this.isCustom) {
                this.addCustomReportToSettings(currentProject);
            }
            else {
                $('.header-view-link .standard').addClass('hidden');
            }
            var settings = app.settings;
            var _a = settings || {}, report = _a.report, districtCode = _a.districtCode;
            var _b = report || {}, autorun = _b.autorun, async = _b.async;
            this.isAutorun = this.isAutorun || (autorun === null || autorun === void 0 ? void 0 : autorun.toString()) === 'true';
            this.isCustomAsync = this.isCustomAsync || ((async === null || async === void 0 ? void 0 : async.toString()) === 'true') || districtCode;
            var isSandbox = xapi.connection.isSandbox(settings);
            settings.sandbox = isSandbox;
            if (isSandbox) {
                if (currentProject.sandbox) {
                    this.lrsInfo = currentProject.sandbox;
                }
                else {
                    console.error('currentProject does not have Sandbox declared:', currentProject);
                    messaging.show('alert', 'Cannot find LRS Sandbox for this project.');
                }
            }
            else if (currentProject.lrs) {
                this.lrsInfo = currentProject.lrs;
            }
            else {
                console.error('currentProject does not have LRS declared:', currentProject);
                messaging.show('alert', 'Cannot find LRS for this project.');
            }
        }
        else {
            $('.filters, .header-view-link, .grid-report-body').addClass('hidden');
            messaging.show('alert', 'Please add a project name to the URL.');
        }
        this.setLinkHref();
        this.updateTitles(currentProject);
        // this.updateCourse();
    },
    updateCourse: function () {
        var _a, _b;
        var params = new URLSearchParams(location.search);
        var courseIdInQuery = params.get('courseId');
        xapi.search.activity = ((_b = (_a = app.settings) === null || _a === void 0 ? void 0 : _a.course) === null || _b === void 0 ? void 0 : _b.id) || courseIdInQuery || '';
        if (courseIdInQuery) {
            var dom = this.ui.dom;
            dom.$courseSelect.val(courseIdInQuery);
        }
    },
    updateTitles: function (currentProject) {
        var _a;
        var projectName = (currentProject || {}).name;
        var _b = app.settings, _c = _b.report, _d = _c === void 0 ? {} : _c, reportId = _d.id, reportName = _d.name, _e = _b.course, _f = _e === void 0 ? {} : _e, courseName = _f.name;
        var pageTitle = 'xAPI Report';
        var pageTitleHtml = pageTitle;
        var clientName = projectName || '';
        pageTitle += " | " + clientName;
        pageTitleHtml += "<span style=\"font-weight: 100;\"> | " + clientName + "</span>";
        if (reportName) {
            pageTitle = courseName + " | " + reportName;
            pageTitleHtml = pageTitle;
        }
        else if (reportId) {
            pageTitle = courseName;
            pageTitleHtml = pageTitle;
        }
        document.title = pageTitle;
        if ((_a = app.settings) === null || _a === void 0 ? void 0 : _a.sandbox) {
            $('.page-title-eyebrow').removeClass('hidden');
        }
        $('.page-title').html(pageTitleHtml);
    },
    setLinkHref: function () {
        function updateHref(selector) {
            var link = document.querySelector(selector);
            var href = link.href;
            link.href = href + "?project=" + (app.projectId || '');
            // if (xapi.connection.isSandbox(app.settings)) {
            if (app.settings.sandbox) {
                link.href += '&sandbox=true';
            }
        }
        ['.header-view-link .custom', '.header-view-link .standard'].forEach(updateHref);
    },
    updateCustomParams: function () {
        var customProject = customReports[app.projectId];
        if (!customProject) {
            return;
        }
        // let project handle its own params if needed
        var customParamFn = customProject.setCustomParameters;
        if (customParamFn) {
            var projectData = app.config.find(function (proj) { return proj.id === app.projectId; });
            customParamFn(projectData, app.settings);
        }
        var report = app.settings.report;
        if (!customProject.report.id) {
            customProject.report = report;
        }
        customProject.type = customProject.type || report.type;
        if (!report.id) {
            $('.filters').hide();
            messaging.show('alert', 'Please select an option above.');
        }
    },
    ui: {
        dom: {},
        init: function () {
            this.setGlobalDom();
            // this.createProjectSelectorBtns();
            this.createCourseDropdown();
            this.createVerbDropdown();
            if (app.isCustom) {
                app.updateCustomParams();
                this.disableInputsForCustom();
                this.updateFilters();
            }
        },
        setGlobalDom: function () {
            this.dom = {
                $loading: $('.loading-container'),
                $courseSelect: $('#course-select'),
                $activityId: $('#activity-ID'),
                $verbSelect: $('#verb-select'),
                $limitInput: $('#limit'),
                $limitCheckbox: $('#limit-none[type=checkbox]'),
                $dateSince: $('#date-since'),
                $dateUntil: $('#date-until'),
                $timeSince: $('#time-since'),
                $timeUntil: $('#time-until'),
                $email: $('#email'),
                $getBtn: $('.get-stmts'),
                $getMoreBtn: $('.get-more-stmts'),
                // $printBtn: $('.print-cert'),
                $stats: $('.stats'),
                $table: $('table#results'),
                $customContainer: $('.custom-report-container'),
                exportCsv: document.getElementById('export-csv'),
                searchInput: document.getElementById('search_field') || {},
            };
        },
        createProjectSelectorBtns: function () {
            var clientSelectorHtml = app.config.map(function (project) {
                return "\n\t\t\t\t\t<button class=\"project-selection\" title=\"Jump to: " + project.name + "\" data-id=\"" + project.id + "\">" + project.name + "</button>\n\t\t\t\t";
            }).join('');
            $('.project-list').html("<span style=\"margin-right: 1em;\">PROJECTS: </span>" + clientSelectorHtml);
        },
        createCourseDropdown: function () {
            if (!app.projectId) {
                return;
            }
            var thisProject = app.config.find(function (proj) { return proj.id === app.projectId; });
            var courseList = (thisProject || {}).courseList;
            if (!courseList) {
                return;
            }
            var options = [];
            courseList.forEach(function (course, index) {
                var id = course.id, name = course.name, disabled = course.disabled;
                if (disabled) {
                    return;
                }
                var selected = app.isCustom ? (app.report.id === id) : (index === 0);
                var optionCfg = {
                    value: id,
                    text: name,
                    // selected: index === 0,
                    selected: selected,
                };
                var option = $('<option>', optionCfg);
                options.push(option);
            });
            this.dom.$courseSelect.append(options);
        },
        createVerbDropdown: function () {
            var options = [];
            for (var verb in xapi.ids.verbMap) {
                var optionCfg = {
                    value: verb,
                    text: verb,
                };
                var option = $('<option>', optionCfg);
                options.push(option);
            }
            this.dom.$verbSelect.append(options);
        },
        disableInputsForCustom: function () {
            this.dom.$courseSelect.attr('disabled', true);
            this.dom.$activityId.attr('disabled', true);
            this.dom.$verbSelect.attr('disabled', true);
            // this.dom.$limitInput.val(0).attr('disabled', true);
            // this.dom.$limitCheckbox[0].checked = true;
        },
        updateFilters: function () {
            // $('.standard.filters').addClass('hidden');
            // $('.custom.filters').removeClass('hidden');
            // customReports[app.projectId].ui.updateFilters();
            customReports[app.projectId].ui.init(app.settings);
        },
        hidePageLoading: function () {
            var $pageLoading = $('.page-loading');
            setTimeout(function () {
                $pageLoading.addClass('fadeOut');
                setTimeout(function () {
                    $pageLoading.remove();
                    // $pageLoading.removeClass('page-loading');
                }, 350);
            }, 500);
        },
        table: {
            show: function () {
                app.ui.dom.$loading.addClass('hidden');
                app.ui.dom.$table.removeClass('hidden');
                if (!app.isCustom) {
                    app.ui.dom.$stats.removeClass('hidden');
                }
            },
            hide: function () {
                // app.ui.log.display('message', 'hide');
                messaging.hide('alert');
                app.ui.dom.$loading.removeClass('hidden');
                app.ui.dom.$table.addClass('hidden');
            },
        },
    },
    bindEvents: function () {
        $('.project-selection').on('click', function () {
            location.href = 'index.html?project=' + this.dataset.id;
        });
        var dom = this.ui.dom;
        dom.$limitCheckbox.on('click', function () {
            if (this.checked) {
                dom.$limitInput.val(0).attr('disabled', true);
            }
            else {
                dom.$limitInput.val(25).removeAttr('disabled');
            }
        });
        dom.$courseSelect.on('change', function (e) {
            var courseId = e.target.value;
            xapi.search.activity = courseId;
            app.ui.dom.searchInput.value = '';
            var _a = window.location, search = _a.search, origin = _a.origin, pathname = _a.pathname;
            var searchParams = new URLSearchParams(search);
            var rootUrl = origin + pathname;
            searchParams.set('courseId', courseId);
            var newPath = rootUrl + '?' + searchParams.toString();
            window.history.replaceState(null, null, newPath);
        });
        dom.$activityId.on('keyup change', function () {
            xapi.search.activity = this.value;
            if (xapi.search.activity) {
                dom.$courseSelect.attr('disabled', true);
            }
            else {
                dom.$courseSelect.removeAttr('disabled');
            }
        });
        dom.$verbSelect.on('change', function (e) {
            xapi.search.verb = xapi.ids.verbMap[e.target.value] || "";
        });
        dom.$dateSince.on('change', function (e) {
            dom.$timeSince.val('00:00');
        });
        dom.$dateUntil.on('change', function (e) {
            dom.$timeUntil.val('00:00');
        });
        var stmtStartFn = this.actions.getStmtsStart.bind(this);
        dom.$getBtn.on('click', stmtStartFn);
        dom.$getMoreBtn.on('click', stmtStartFn);
        // dom.$printBtn.on('click', this.actions.onPrintCertificateClick.bind(this));
        function addCSVEvent() {
            Handsontable.dom.addEvent(dom.exportCsv, "mouseup", function () {
                fileExport.init(app.projectId);
            });
        }
        if (dom.exportCsv) {
            var debounceDialog = _.debounce(addCSVEvent, 1000, true);
            debounceDialog();
        }
    },
    actions: {
        resetTableData: function (action) {
            if (action !== 'more') {
                xapi.statements = [];
                xapi.moreUrl = '';
                // xapi.skippedCounter = 0;
            }
            var instance = table.instance;
            if (instance && instance.table) {
                instance.destroy();
            }
            app.ui.dom.$customContainer.empty();
            app.ui.dom.searchInput.value = '';
        },
        getxAPISearchValues: function () {
            var dom = app.ui.dom;
            var activityId = dom.$activityId.val();
            if (!app.report.id) {
                if (activityId) {
                    console.warn('no report id?');
                }
                else {
                    dom.$courseSelect.change(); // trigger update
                }
            }
            var _a = xapi.search, activity = _a.activity, verb = _a.verb;
            var search = new Search();
            search.activity = activity;
            if (verb) {
                search.verb = verb;
            }
            var timeDefault = '00:00';
            var sinceDate = dom.$dateSince.val();
            var sinceTime = dom.$timeSince.val() || timeDefault;
            var untilDate = dom.$dateUntil.val();
            var untilTime = dom.$timeUntil.val() || timeDefault;
            if (sinceDate) {
                search.since = utils.getISOString(sinceDate, sinceTime);
            }
            if (untilDate) {
                search.until = utils.getISOString(untilDate, untilTime);
            }
            var userId = dom.$email.val();
            if (userId) {
                var userType = reportUtils.getUserIdType(userId);
                search.agent = xapi.create.agent(userType, userId);
            }
            search.limit = dom.$limitInput.val() || 0;
            if (app.isCustom) {
                search = customReports[app.projectId].getxAPISearchValues(search);
            }
            return search;
        },
        getStmtsStart: function (e) {
            return __awaiter(this, void 0, void 0, function () {
                var action, doContinue, tableData, createStandardReport, useNameAndDescHeaders, customProject, isCustomProject, report, isSummary, isGrid, isGeneric, error_2, _a, addedColumns, removedColumns, error_3;
                var _this = this;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            action = ((e && e.target.dataset) || {}).action;
                            console.log('getting statements....');
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 11, , 12]);
                            return [4 /*yield*/, this.actions.getStatements.bind(this)(action)];
                        case 2:
                            doContinue = _b.sent();
                            if (!doContinue) {
                                return [2 /*return*/];
                            }
                            console.log('get statements done');
                            tableData = [];
                            createStandardReport = true;
                            useNameAndDescHeaders = false;
                            customProject = customReports[this.projectId];
                            isCustomProject = this.isCustom && customProject;
                            if (!isCustomProject) return [3 /*break*/, 7];
                            createStandardReport = false;
                            report = app.settings.report;
                            isSummary = (report === null || report === void 0 ? void 0 : report.type) === 'summary';
                            isGrid = (report === null || report === void 0 ? void 0 : report.type) === 'grid';
                            isGeneric = (report === null || report === void 0 ? void 0 : report.type) === 'generic';
                            if (isSummary || isGrid || isGeneric) {
                                if (this.isAutorun) {
                                    return [2 /*return*/, customProject.init(xapi.statements)];
                                }
                                customProject.init(xapi.statements).then(function () {
                                    _this.ui.table.show();
                                });
                                return [2 /*return*/];
                            }
                            _b.label = 3;
                        case 3:
                            _b.trys.push([3, 5, , 6]);
                            return [4 /*yield*/, customProject.init(xapi.statements)];
                        case 4:
                            tableData = _b.sent();
                            useNameAndDescHeaders = customProject.useNameAndDescHeaders;
                            return [3 /*break*/, 6];
                        case 5:
                            error_2 = _b.sent();
                            if (error_2 === 'invalid') {
                                createStandardReport = true;
                            }
                            return [3 /*break*/, 6];
                        case 6: return [3 /*break*/, 8];
                        case 7:
                            if (this.isCustom) {
                                alert('Custom report not available yet; creating standard report...');
                            }
                            this.isCustom = false;
                            _b.label = 8;
                        case 8:
                            if (!createStandardReport) return [3 /*break*/, 10];
                            _a = customReports[this.projectId] || {}, addedColumns = _a.addedColumns, removedColumns = _a.removedColumns;
                            // console.log('addedColumns, removedColumns:', addedColumns, removedColumns);
                            standardReports.addedColumns = addedColumns || [];
                            standardReports.removedColumns = removedColumns || [];
                            return [4 /*yield*/, standardReports.processData(xapi.statements)];
                        case 9:
                            tableData = _b.sent();
                            // console.log('tableData:', tableData);
                            this.setupAndCreateTable(tableData, action, useNameAndDescHeaders);
                            _b.label = 10;
                        case 10: return [3 /*break*/, 12];
                        case 11:
                            error_3 = _b.sent();
                            console.error('Setup and get statements error:', error_3);
                            return [3 /*break*/, 12];
                        case 12: return [2 /*return*/];
                    }
                });
            });
        },
        getStatements: function (action) {
            return __awaiter(this, void 0, void 0, function () {
                var newStmts, error_4;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            xapi.search = this.actions.getxAPISearchValues();
                            if (!xapi.search) {
                                return [2 /*return*/, false];
                            }
                            this.actions.resetTableData(action);
                            this.ui.table.hide();
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 6, , 7]);
                            return [4 /*yield*/, xapi.get.statements(xapi.moreUrl)];
                        case 2:
                            newStmts = _a.sent();
                            if (action === 'more') {
                                xapi.statements = xapi.statements.concat(newStmts);
                            }
                            else {
                                xapi.statements = newStmts;
                            }
                            if (!!+xapi.search.limit) return [3 /*break*/, 4];
                            return [4 /*yield*/, xapi.get.recursive()];
                        case 3:
                            _a.sent();
                            // console.log('recursive done');
                            return [2 /*return*/, true];
                        case 4:
                            if (xapi.moreUrl) {
                                this.ui.dom.$getMoreBtn.removeClass('hidden');
                            }
                            _a.label = 5;
                        case 5: return [3 /*break*/, 7];
                        case 6:
                            error_4 = _a.sent();
                            console.error('Get Statements error:', error_4);
                            return [3 /*break*/, 7];
                        case 7: return [2 /*return*/, true];
                    }
                });
            });
        },
        getAndDisplayStats: function (tableData) {
            var uniqueUserList = [];
            tableData.forEach(function (row) {
                var _a = __assign({}, row), id = _a.id, name = _a.name;
                var userId = id.toLowerCase();
                var userName = name.toLowerCase();
                var user = { id: userId, name: userName };
                var userInList = uniqueUserList.find(function (u) { return u.id === userId || u.name === userName; });
                if (userInList) {
                    userInList === null || userInList === void 0 ? void 0 : userInList.identities.names.add(name);
                    userInList === null || userInList === void 0 ? void 0 : userInList.identities.ids.add(id);
                    return;
                }
                var identities = {
                    names: new Set(),
                    ids: new Set(),
                };
                identities.names.add(name);
                identities.ids.add(id);
                user.identities = identities;
                uniqueUserList.push(user);
            });
            uniqueUserList.sort(function (a, b) { return a.name > b.name ? 1 : -1; });
            if (uniqueUserList) {
                var userCount = uniqueUserList.length;
                $('span.usercount').html(userCount);
                $('span.quescount').html(tableData.length);
                if (app.isCustom) {
                    $('.stats-container').addClass('hidden');
                }
                else {
                    // $('span.skipped').html(xapi.skippedCounter.toString());
                }
            }
            app.ui.dom.$stats.removeClass('hidden');
        },
    },
    setupAndCreateTable: function (tableData, action, useNameAndDescHeaders) {
        return __awaiter(this, void 0, void 0, function () {
            var error_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!tableData || !tableData.length) {
                            messaging.show('alert', 'No data found');
                            this.ui.table.show();
                            this.ui.dom.$stats.addClass('hidden');
                            return [2 /*return*/];
                        }
                        table.project = this.projectId;
                        table.report = this.settings.report;
                        table.isCustom = this.isCustom;
                        table.useNameAndDescHeaders = useNameAndDescHeaders;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, table.init(tableData, action)];
                    case 2:
                        _a.sent();
                        this.actions.getAndDisplayStats(tableData);
                        return [3 /*break*/, 4];
                    case 3:
                        error_5 = _a.sent();
                        console.error('Table init error:', error_5);
                        return [3 /*break*/, 4];
                    case 4:
                        this.ui.table.show();
                        return [2 /*return*/];
                }
            });
        });
    },
};
document.addEventListener('DOMContentLoaded', function () {
    app.init();
});
