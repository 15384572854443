import { DrilldownTable } from "../../../../../shared/interfaces-classes/index";
import { reportUtils } from "../../../../report-utils";
var processTemplateVarHtml = reportUtils.processTemplateVarHtmlList, getGenericGroupMsg = reportUtils.getGenericGroupMsg;
var genericGroupMsg = getGenericGroupMsg();
export var colearn = {
    'http://SISEPCoLearn': {
        columns: [
            {
                parent: 'LO1',
                variablename: 'SISEPCoLearn_LO1dash',
                desc: 'Identify WHEN to engage in co-learning',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 50
                    },
                    yellow: {
                        min: 51,
                        max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                            // stringifyVarPrefix: 'var',
                        },
                        response: [
                            'SISEPCoLearn_LO1_0102text',
                            'SISEPCoLearn_LO1_0104text',
                            'SISEPCoLearn_LO1_0106text',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var SISEPCoLearn_LO1_0102text = varDict.SISEPCoLearn_LO1_0102text, SISEPCoLearn_LO1_0104text = varDict.SISEPCoLearn_LO1_0104text, SISEPCoLearn_LO1_0106text = varDict.SISEPCoLearn_LO1_0106text;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    "<p>You decide to send an anonymous survey to team members asking questions related to needs, priorities, potential challengers, and facilitators, and review the results with members from the district team.</p>\n                  <p>Which co-learning activity are you engaging in?</p>",
                                    'Which of the following options is most likely to create a safe space?',
                                    "<p>As you are making rounds while groups are generating a final score, you notice several voices are dominating the conversation in two of the groups.</p>\n                  <p>Which co-learning activity is central to consider in your facilitation?</p>",
                                ]
                            },
                            {
                                header: 'Correct Answer',
                                rows: [
                                    'Understand the system and organizational context and culture',
                                    'An activity where individuals anonymously put their score and justification on sticky notes for each indicator of the Hexagon Tool. The team is then divided into 6 small groups (each is assigned a indicator) to come up with the final score.',
                                    'Communicate and listen to create mutual understanding and integrate different perspectives.',
                                ]
                            },
                            {
                                header: 'Answer Provided',
                                rows: processTemplateVarHtml([
                                    SISEPCoLearn_LO1_0102text,
                                    SISEPCoLearn_LO1_0104text,
                                    SISEPCoLearn_LO1_0106text,
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO2b',
                variablename: 'SISEPCoLearn_LO2dash',
                desc: 'Decide HOW to engage in co-learning: Make decisions that result in 3 different outcomes by score ranges',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 50
                    },
                    yellow: {
                        min: 51,
                        max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                            // stringifyVarPrefix: 'var',
                        },
                        response: [
                            'SISEPCoLearn_LO2_0102text',
                            'SISEPCoLearn_LO2_0103text',
                            'SISEPCoLearn_LO2_0104text',
                            'SISEPCoLearn_LO2_0105text',
                            'SISEPCoLearn_LO2_0106text',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        console.log('varDict:', varDict);
                        var SISEPCoLearn_LO2_0102text = varDict.SISEPCoLearn_LO2_0102text, SISEPCoLearn_LO2_0103text = varDict.SISEPCoLearn_LO2_0103text, SISEPCoLearn_LO2_0104text = varDict.SISEPCoLearn_LO2_0104text, SISEPCoLearn_LO2_0105text = varDict.SISEPCoLearn_LO2_0105text, SISEPCoLearn_LO2_0106text = varDict.SISEPCoLearn_LO2_0106text;
                        var columns = [
                            {
                                header: 'Scenario',
                                rows: [
                                    'You want to think of ways to better understand the local context this team is operating under, including their environment and culture. What do you decide to do?',
                                    'Whose feedback do you solicit?',
                                    'Based on the feedback you received, what do you suggest?',
                                    "<p>Administrators and teachers feel that their feedback was not used. What do you do?</p> \n\n                  <ul><li><strong>NOTE:</strong> This question may not have been visited depending on answer to scenario #3</li></ul>\n                  ",
                                    "<p>A coaching service delivery plan is developed and\u2026 What would you do after developing a coaching service delivery plan?</p> \n\n                  <ul><li><strong>NOTE:</strong> This question may not have been visited depending on answer to scenario #3</li></ul>\n                  ",
                                ]
                            },
                            {
                                header: 'Best Answer',
                                rows: [
                                    'You talk individually to the DIT coordinator, director of professional learning, and a district coach. You also review current coaching documents.',
                                    'You administer a survey to teachers, coaches, and administrators related to current coaching needs. You follow up with focus groups.',
                                    'You convene a working group of district staff, administrators, and teachers to evaluate and suggest changes to current coaching efforts.',
                                    'Work with the DIT to create a coaching service delivery plan centering the voices of administrators and teachers.',
                                    'Ask coaches and school staff to review the plan before training is co-created alongside the director of professional learning and the district coaching lead, and rolled out as part of a larger implementation plan.',
                                ]
                            },
                            {
                                header: 'Answer Chosen',
                                rows: processTemplateVarHtml([
                                    SISEPCoLearn_LO2_0102text,
                                    SISEPCoLearn_LO2_0103text,
                                    SISEPCoLearn_LO2_0104text,
                                    SISEPCoLearn_LO2_0105text,
                                    SISEPCoLearn_LO2_0106text,
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO2c',
                variablename: '',
                desc: 'Decide HOW to engage in co-learning: Identify co-learning activities & edit activities after lesson',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 50
                    },
                    yellow: {
                        min: 51,
                        max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    icon: 'text',
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        response: [
                            'SISEPCoLearn_LO2c1',
                            'SISEPCoLearn_LO2c2',
                            'SISEPCoLearn_LO2c3',
                            'SISEPCoLearn_LO2c4',
                            'SISEPCoLearn_LO2c5',
                            'SISEPCoLearn_LO2c6',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var SISEPCoLearn_LO2c1 = varDict.SISEPCoLearn_LO2c1, SISEPCoLearn_LO2c2 = varDict.SISEPCoLearn_LO2c2, SISEPCoLearn_LO2c3 = varDict.SISEPCoLearn_LO2c3, SISEPCoLearn_LO2c4 = varDict.SISEPCoLearn_LO2c4, SISEPCoLearn_LO2c5 = varDict.SISEPCoLearn_LO2c5, SISEPCoLearn_LO2c6 = varDict.SISEPCoLearn_LO2c6;
                        var columns = [
                            {
                                header: 'Activity',
                                rows: [
                                    "<p>Actively seek to learn about the culture, history, and current priorities of the local context.</p>\n                  <p>What specific activities might achieve this?</p>",
                                    "<p>Support learning at the implementing site and recommend strategies based on local context.</p>\n                  <p>What specific activities might achieve this?</p>",
                                    "<p>Engage critical perspectives to effectively apply implementation best practices in the local context.</p>\n                  <p>What specific activities might achieve this?</p>",
                                ]
                            },
                            {
                                header: 'Answer before lesson',
                                rows: processTemplateVarHtml([
                                    SISEPCoLearn_LO2c1,
                                    SISEPCoLearn_LO2c2,
                                    SISEPCoLearn_LO2c3,
                                ])
                            },
                            {
                                header: 'Answer after lesson',
                                rows: processTemplateVarHtml([
                                    SISEPCoLearn_LO2c4,
                                    SISEPCoLearn_LO2c5,
                                    SISEPCoLearn_LO2c6,
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO3',
                variablename: '',
                desc: 'Describe WHY co-learning is important',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 50
                    },
                    yellow: {
                        min: 51,
                        max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    icon: 'text',
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        response: [
                            'SISEPCoLearn_LO3text',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var SISEPCoLearn_LO3text = varDict.SISEPCoLearn_LO3text;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'In your own words, why is co-learning important to successful implementation and scale-up?'
                                ]
                            },
                            {
                                header: 'NIRN\'s answer',
                                rows: [
                                    "<p>Co-learning is important to implementation for at least three reasons:</p>\n                  \n                  <ol>\n                    <li>Our approach to work depends on our experiences and knowledge. If we want to be better facilitators, ask more nuanced questions, navigate sticky situations, and build stronger relationships, we need to learn about the history, culture, and context of our communities.</li> \n\n                    <li>Co-learning involves honoring and lifting the expertise and perspectives of others. We will generate better and more relevant solutions when we create space for and respect all voices.</li>\n\n                    <li>Respecting and protecting voices addresses power differentials to highlight the expertise and experiences of those closest to the program being implemented.</li>\n                  </ol>\n                  "
                                ]
                            },
                            {
                                header: 'Answer provided',
                                rows: processTemplateVarHtml([
                                    SISEPCoLearn_LO3text,
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
        ],
        specialGroupVarDictFns: {},
    }
};
