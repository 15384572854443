import { reportUtils } from '../../../../report-utils';
var getGenericGroupMsg = reportUtils.getGenericGroupMsg;
var genericGroupMsg = getGenericGroupMsg();
export var pdsa = {
    'http://SISEPPDSA': {
        columns: [
            {
                parent: 'LO1',
                variablename: 'LO1Dash',
                desc: 'Employ the PDSA process',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 50
                    },
                    yellow: {
                        min: 51,
                        max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false
                        },
                        success: [
                            'plancorrect',
                        ],
                        scoreRaw: [
                            'planincorrect',
                            'LO1',
                        ]
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var plancorrect = varDict.plancorrect, planincorrect = varDict.planincorrect, LO1 = varDict.LO1;
                        var trafficColor = '';
                        if (plancorrect !== undefined) {
                            var isCorrect = plancorrect.toString().includes('true');
                            var isInCorrect = plancorrect.toString().includes('false');
                            trafficColor = isCorrect ? 'green' : isInCorrect ? 'red' : '';
                        }
                        return "\n\t\t\t\t\t\t\t<table class=\"drilldown-table\">\n\t\t\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t\t\t<td>Got the <strong>correct</strong> answer for the 1st PDSA cycle plan</td>\n\t\t\t\t\t\t\t\t\t<td><div class=\"traffic-light " + trafficColor + "-light\"></div></td>\n\t\t\t\t\t\t\t\t</tr>\n\n\t\t\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t\t\t<td>Number of <strong>incorrect</strong> answers chosen before the correct plan for the 1st PDSA cycle</td>\n\t\t\t\t\t\t\t\t\t<td>" + planincorrect + "</td>\n\t\t\t\t\t\t\t\t</tr>\n\n\t\t\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t\t\t<td>Number of PDSA process questions answered <strong>correctly</strong> <em>(out of 4)</em></td>\n\t\t\t\t\t\t\t\t\t<td>" + LO1 + "</td>\n\t\t\t\t\t\t\t\t</tr>\n\n\t\t\t\t\t\t\t</table>\n\t\t\t\t\t\t";
                    }
                }
            },
            {
                parent: 'LO2',
                variablename: 'LO2Dash',
                desc: 'Document the PDSA process',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 75
                    },
                    yellow: {
                    // min: 51,
                    // max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false
                        },
                        success: [
                            'LO2',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var LO2 = varDict.LO2;
                        var trafficColor = '';
                        if (LO2 !== undefined) {
                            var isCorrect = LO2.toString().includes('true');
                            var isInCorrect = LO2.toString().includes('false');
                            trafficColor = isCorrect ? 'green' : isInCorrect ? 'red' : '';
                        }
                        return "\n\t\t\t\t\t\t\t<table class=\"drilldown-table\">\n\t\t\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t\t\t<td><strong>Correctly</strong> answered the question about documentation in the ACT step of the first PDSA cycle iteration</td>\n\t\t\t\t\t\t\t\t\t<td><div class=\"traffic-light " + trafficColor + "-light\"></div></td>\n\t\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t\t</table>\n\t\t\t\t\t\t";
                    }
                }
            },
            {
                parent: 'LO3',
                variablename: 'LO3Dash',
                desc: 'Utilize PDSA cycles',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 75
                    },
                    yellow: {
                    // min: 51,
                    // max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false
                        },
                        scoreRaw: [
                            'LO3',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var LO3 = varDict.LO3;
                        return "\n\t\t\t\t\t\t\t<table class=\"drilldown-table\">\n\t\t\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t\t\t<td>Number of iterative cycle questions answered <strong>correctly</strong> <em>(out of 2)</em></td>\n\t\t\t\t\t\t\t\t\t<td>" + LO3 + "</td>\n\t\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t\t</table>\n\t\t\t\t\t\t";
                    }
                }
            },
        ],
        specialGroupVarDictFns: {},
    }
};
