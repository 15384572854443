export var choiceTextMap = {
    RCA_Adapt: 'Regional Capacity Assessment',
    Criteria_Adapt: 'Team Member Selection Criteria',
    Interviews_Adapt: 'Interviews',
    Lookfors_Adapt: 'Team Look-fors',
    Readiness_Adapt: 'Context-Specific Readiness Tool',
    RCA_Source: 'Regional Capacity Assessment',
    Criteria_Source: 'Team Member Selection Criteria',
    Interviews_Source: 'Interviews',
    Readiness_Source: 'Context-Specific Readiness Tool',
};
