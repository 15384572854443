export var messaging = {
    types: {
        alert: $('.alert-message'),
        info: $('.on-screen-log'),
    },
    show: function (type, text) {
        var $el = this.types[type];
        if (!$el) {
            console.warn('Message element not found:', type);
            return;
        }
        if (text) {
            $el.html(text);
        }
        $el.show();
    },
    hide: function (type) {
        var $el = this.types[type];
        if (!$el) {
            return;
        }
        $el.hide();
    },
};
