import { DrilldownTable, defaultTrafficLights, } from "../../../../../shared/interfaces-classes/index";
import { reportUtils } from "../../../../report-utils";
var getGenericGroupMsg = reportUtils.getGenericGroupMsg, processSingleVarHtml = reportUtils.processSingleVarHtml, getCorrectIncorrectByValueComparison = reportUtils.getCorrectIncorrectByValueComparison;
var genericGroupMsg = getGenericGroupMsg();
export var mod9_3 = {
    'http://ICTP9micro3': {
        columns: [
            {
                parent: 'LO1',
                variablename: 'LO1dash',
                desc: 'Share fidelity report',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'LO1'
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var LO1 = varDict.LO1;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t<p>Which of these options is the best way to share the community-wide Triple P fidelity report with practitioners?</p>\n\n\t\t\t\t\t\t\t\t\t<p>Select the best option.</p>\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                            {
                                header: 'Correct answers',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t<p><strong>Correct:</strong></p>\n\n\t\t\t\t\t\t\t\t\t<ol>\n\t\t\t\t\t\t\t\t\t\t<li>Andrea and John provide the fidelity report to coaches. They prompt the coaches to have conversations with their practitioners to consider how their individual fidelity data compares to the fidelity data trends as a whole.</li>\n\t\t\t\t\t\t\t\t\t</ol>\n\n\t\t\t\t\t\t\t\t\t<p><strong>Incorrect options:</strong></p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<ol>\n\t\t\t\t\t\t\t\t\t\t<li>Andrea and John email the fidelity report to all practitioners (de-identified overall summary of fidelity across agencies and practitioners).</li>\n\t\t\t\t\t\t\t\t\t\t<li>Andrea and John attend every coaching session to provide the fidelity summary and prompt practitioners to compare their own fidelity data to the community-wide trends.</li> \n\t\t\t\t\t\t\t\t\t\t<li>Andrea and John provide coaches with a list of all practitioners who are deficient in their fidelity based on the Session and Strengths Checklists. They also ask coaches to turn in improvement plans for each practitioner who is below the community-wide average.</li>\n\t\t\t\t\t\t\t\t\t</ol>\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                            {
                                header: 'Number of attempts to answer the question (out of 4)',
                                rows: [
                                    processSingleVarHtml(LO1)
                                ],
                            },
                        ];
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: columns,
                        };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
            {
                parent: 'LO2',
                variablename: 'LO2dash',
                desc: 'Use fidelity data ',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'LO2'
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var LO2 = varDict.LO2;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t<p>What is the best way for coaches to use fidelity data within practitioner coaching sessions?</p>\n\n\t\t\t\t\t\t\t\t\t<p>Select the best option.</p>\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                            {
                                header: 'Correct answers',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t<p><strong>Correct:</strong></p>\n\n\t\t\t\t\t\t\t\t\t<ol>\n\t\t\t\t\t\t\t\t\t\t<li>The coach encourages the practitioner to actively engage with the community-wide fidelity report and their own completed fidelity assessments. The coach prompts for reflection, dialogue and the development of an action plan that includes professional development goals, types of support needed, targeted areas for support, steps for completion, who will complete the steps, and when the steps will be completed. These goals and action plans are collaboratively reviewed in routine coaching sessions.</li>\n\t\t\t\t\t\t\t\t\t</ol>\n\n\t\t\t\t\t\t\t\t\t<p><strong>Incorrect options:</strong></p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<ol>\n\t\t\t\t\t\t\t\t\t\t<li>Andrea and John attend every coaching session to provide the fidelity summary and prompt practitioners to compare their own fidelity data to the community-wide trends.</li>\n\t\t\t\t\t\t\t\t\t\t<li>Andrea and John provide the fidelity report to coaches. They prompt the coaches to have conversations with their practitioners to consider how their individual fidelity data compares to the fidelity data trends as a whole.</li>\n\t\t\t\t\t\t\t\t\t\t<li>Andrea and John provide coaches with a list of all practitioners who are deficient in their fidelity based on the Session and Strengths Checklists. They also ask coaches to turn in improvement plans for each practitioner who is below the community-wide average.</li>\n\t\t\t\t\t\t\t\t\t</ol>\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                            {
                                header: 'Number of attempts to answer the question (out of 4)',
                                rows: [
                                    processSingleVarHtml(LO2)
                                ],
                            },
                        ];
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: columns,
                        };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
        ],
        printouts: [
        // {
        // 	name: 'Customized Learner Summary',
        // 	profileId: 'ictp-mod-9_3',
        // project: 'ictp',
        // }
        ],
    },
};
