import { DrilldownTable, defaultTrafficLights, } from "../../../../../shared/interfaces-classes/index";
import { reportUtils } from "../../../../report-utils";
var getGenericGroupMsg = reportUtils.getGenericGroupMsg, getBoolFromBoolishVar = reportUtils.getBoolFromBoolishVar, processSingleVarHtml = reportUtils.processSingleVarHtml, createDrilldownTableGroup = reportUtils.createDrilldownTableGroup, getCorrectIncorrectByValueComparison = reportUtils.getCorrectIncorrectByValueComparison;
var genericGroupMsg = getGenericGroupMsg();
export var mod6 = {
    'http://ICTPMod6': {
        columns: [
            {
                parent: 'LO3A2a',
                variablename: 'LO3A2a',
                desc: 'Collect data: Understand',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'datatypecorrect',
                            'datatypeincorrect',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var datatypecorrect = varDict.datatypecorrect, datatypeincorrect = varDict.datatypeincorrect;
                        var columns = [
                            {
                                header: 'Essential questions',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t\t<ol>\n\t\t\t\t\t\t\t\t\t\t\t<li>Are we producing intended, socially-important outcomes for families and children across our community?</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Is Triple P producing intended outcomes for participating families? </li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Was Triple P implemented as intended?</li>\n\t\t\t\t\t\t\t\t\t\t</ol>\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                            {
                                header: 'Data options',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t\t\t\t<li>Number of families served</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Parent satisfaction</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Rates of child maltreatment (#1)</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Child behavior improvement (#2)</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Program session adherence (#3)</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Program accessibility</li>\n\t\t\t\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                            {
                                header: 'Number of correct choices',
                                rows: [
                                    datatypecorrect
                                ],
                            },
                            {
                                header: 'Number of incorrect choices',
                                rows: [
                                    datatypeincorrect
                                ],
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
            {
                parent: 'LO3B2',
                variablename: 'LO3B2',
                desc: 'Collect data: Apply',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'dataconsistent',
                            'datacollectintervalcorrect',
                            'incorrectdatamissing',
                            'correctdatamissing',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var dataconsistent = varDict.dataconsistent, datacollectintervalcorrect = varDict.datacollectintervalcorrect, incorrectdatamissing = varDict.incorrectdatamissing, correctdatamissing = varDict.correctdatamissing;
                        // Table 1: Data consistency =======================
                        var columns1 = [
                            {
                                header: 'Options',
                                rows: [
                                    'Custom-designed interviews',
                                    'Caregiver satisfaction questionnaire',
                                ],
                            },
                            {
                                header: 'Correct choice',
                                type: 'checkmark',
                                allowEmpty: true,
                                rows: [
                                    '',
                                    true,
                                ],
                            },
                            {
                                header: 'Learner choice',
                                type: 'checkmark',
                                allowEmpty: true,
                                rows: [
                                    getBoolFromBoolishVar(dataconsistent) === false
                                        ? true
                                        : getBoolFromBoolishVar(dataconsistent) === true
                                            ? ''
                                            : processSingleVarHtml(dataconsistent),
                                    getBoolFromBoolishVar(dataconsistent),
                                ],
                            },
                        ];
                        var drilldownTableOpts1 = {
                            varDict: varDict,
                            columns: columns1,
                            title: 'Data consistency',
                            subtitle: '<strong>Prompt:</strong> Which method is most consistent and measures parent satisfaction?'
                        };
                        var html1 = new DrilldownTable(drilldownTableOpts1).html;
                        // Table 2: Data collection intervals =======================
                        var columns2 = [
                            {
                                header: 'Types of data collection',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t\t<ol>\n\t\t\t\t\t\t\t\t\t\t\t<li>Number of families served</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Parent satisfaction</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Rates of child maltreatment</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Child behavior improvement</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Program session adherence</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Program accessibility</li>\n\t\t\t\t\t\t\t\t\t\t</ol>\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                            {
                                header: 'Potential Frequencies (mix/match)',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t\t\t\t<li>Never (this data is already collected by the state; #3)</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Monthly (#2 & 5)</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Quarterly (#1 & 4)</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Annually (#6)</li>\n\t\t\t\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                            {
                                header: 'Number of correct choices',
                                rows: [
                                    datacollectintervalcorrect
                                ],
                            },
                        ];
                        var drilldownTableOpts2 = {
                            varDict: varDict,
                            columns: columns2,
                            title: 'Data collection intervals',
                            subtitle: '<strong>Prompt:</strong> Match each measure with what you think is the best interval or frequency for data collection.',
                        };
                        var html2 = new DrilldownTable(drilldownTableOpts2).html;
                        // Table 3: Missing data =======================
                        var columns3 = [
                            {
                                header: 'Excerpt',
                                rows: [
                                    'Grand County will collect parent/caregiver satisfaction data using a modified version the Triple P Client Satisfaction Questionnaire. Our CLT decided we will only collect questions 5-10 on the survey based on our community’s needs and goals. Triple P practitioners will collect survey from parents/caregivers within 30 days of the final session being delivered.'
                                ],
                            },
                            {
                                header: 'Choices',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t\t\t\t<li>Data source</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Who\u2019s collecting</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>When</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>How</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Why</li>\n\t\t\t\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                            {
                                header: 'Correct choices',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t\t\t\t<li>How</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Why</li>\n\t\t\t\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                            {
                                header: 'Number of incorrect learner choices ',
                                rows: [
                                    incorrectdatamissing,
                                ],
                            },
                            {
                                header: 'Number of correct learner choices',
                                rows: [
                                    correctdatamissing,
                                ],
                            },
                        ];
                        var drilldownTableOpts3 = {
                            varDict: varDict,
                            columns: columns3,
                            title: 'Missing data',
                            subtitle: '<strong>Prompt:</strong> Review the excerpt and identify which of the 5 pieces are missing. Select more than one.',
                        };
                        var html3 = new DrilldownTable(drilldownTableOpts3).html;
                        return createDrilldownTableGroup([html1, html2, html3]);
                    }
                },
            },
            {
                parent: 'LO#?1',
                variablename: 'LO#?1',
                desc: 'Report Data: Understand',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    icon: 'text',
                    vars: {
                        response: [
                            'Q1step1dataEfficient',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var Q1step1dataEfficient = varDict.Q1step1dataEfficient;
                        var columns = [
                            {
                                header: 'Prompt',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t<p>Remember, our first best-practice for data collection procedures was to try to make data collection <strong>practical and efficient</strong> by integrating them into routine practices. Let\u2019s think about one of our selected data points, child maltreatment, and how we can efficiently collect data for our question about long-term outcomes for Triple P. </p>\n\n\t\t\t\t\t\t\t\t\t<p>At your <strong>Community Leadership Team</strong> meeting, a participant suggests that one way to collect child maltreatment data would be to survey physicians and hospitals in your community to ask them how many cases of child maltreatment they saw. </p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>Another member of the Community Leadership Team asks you what you think about the burden and efficiency of this approach. </p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>How might you respond? Write your answer in the box provided and then click on the button to see a sample response.</p>\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                            {
                                header: 'Answer',
                                rows: [
                                    Q1step1dataEfficient,
                                ],
                            },
                            {
                                header: 'What to look for',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t<p>Crux of answer should be: too burdensome; look for routinely collected data that could be utilized. </p>\n\n\t\t\t\t\t\t\t\t\t<p>Look for question: Does anyone collect these data or similar data already?</p>\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
            {
                parent: 'LO3B3general',
                variablename: 'LO3B3general',
                desc: 'Report data: Apply, general',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'datareportintervalcorrect',
                            'agencydatareturn1',
                            'agencydatareturn2',
                            'agencydatareturn3',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var datareportintervalcorrect = varDict.datareportintervalcorrect, agencydatareturn1 = varDict.agencydatareturn1, agencydatareturn2 = varDict.agencydatareturn2, agencydatareturn3 = varDict.agencydatareturn3;
                        // Table 1: General =======================
                        var columns1 = [
                            {
                                header: 'Types of data reports',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t<ol>\n\t\t\t\t\t\t\t\t\t\t<li>Number of families served</li>\n\t\t\t\t\t\t\t\t\t\t<li>Parent satisfaction</li>\n\t\t\t\t\t\t\t\t\t\t<li>Rates of child maltreatment</li>\n\t\t\t\t\t\t\t\t\t\t<li>Child behavior improvement</li>\n\t\t\t\t\t\t\t\t\t\t<li>Program session adherence</li>\n\t\t\t\t\t\t\t\t\t\t<li>Program accessibility</li>\n\t\t\t\t\t\t\t\t\t</ol>\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                            {
                                header: 'Potential Frequencies (mix/match)',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t\t\t<li>Monthly (#5)</li>\n\t\t\t\t\t\t\t\t\t\t<li>Quarterly (#2 & 4)</li>\n\t\t\t\t\t\t\t\t\t\t<li>Quarterly (or as required by the state; #1)</li>\n\t\t\t\t\t\t\t\t\t\t<li>Annually (#3 & #6)</li>\n\t\t\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                            {
                                header: 'Number of correct choices',
                                rows: [
                                    datareportintervalcorrect,
                                ],
                            },
                        ];
                        var drilldownTableOpts1 = {
                            varDict: varDict,
                            columns: columns1,
                            title: 'General',
                            subtitle: '<strong>Prompt:</strong> Match each measure with what you think is the best interval or frequency for data reporting.',
                        };
                        var html1 = new DrilldownTable(drilldownTableOpts1).html;
                        // Table 2:  =======================
                        var columns2 = [
                            {
                                header: 'Potential answers',
                                rows: [
                                    "Email data reports back to the agencies that turned in data, instructing them to follow up with the LIA (Lead Implementing Agency) if they have any questions.",
                                    'Send data reports back to agencies that turned in data and ask to schedule a follow-up conversation. ',
                                    'Send data reports back to agencies that turned in data and schedule a follow-up conversation with all agencies, including agencies that did not turn in data.',
                                ],
                            },
                            {
                                header: 'Correct',
                                type: 'checkmark',
                                allowEmpty: true,
                                rows: [
                                    '',
                                    '',
                                    true,
                                ],
                            },
                            {
                                header: 'Chosen',
                                type: 'checkmark',
                                allowEmpty: true,
                                rows: [
                                    getBoolFromBoolishVar(agencydatareturn1) || '',
                                    getBoolFromBoolishVar(agencydatareturn2) || '',
                                    getBoolFromBoolishVar(agencydatareturn3) || '',
                                ],
                            },
                        ];
                        var drilldownTableOpts2 = {
                            varDict: varDict,
                            columns: columns2,
                            // title: 'General',
                            subtitle: '<strong>Prompt:</strong> What’s the best way to return the data reports to agencies?',
                        };
                        var html2 = new DrilldownTable(drilldownTableOpts2).html;
                        return createDrilldownTableGroup([html1, html2]);
                    }
                },
            },
            {
                parent: 'LO3B3dataagency',
                variablename: 'LO3B3dataagency',
                desc: 'Report data: Apply, Approach SDAs returning data per plan',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'agencywithdataapproach1',
                            'agencywithdataapproach2',
                            'agencywithdataapproach3',
                            'Q2RespondtoAgencies',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var agencywithdataapproach1 = varDict.agencywithdataapproach1, agencywithdataapproach2 = varDict.agencywithdataapproach2, agencywithdataapproach3 = varDict.agencywithdataapproach3, Q2RespondtoAgencies = varDict.Q2RespondtoAgencies;
                        // Table 1: Approach SDAs returning data per plan =======================
                        var columns1 = [
                            {
                                header: 'Potential answers',
                                rows: [
                                    'Ask them what trends they notice in the data and how they might use/plan to use their data for action planning. ',
                                    'Summarize data.',
                                    'Tell them what implications you see in the data that might impact their action planning/future areas of focus.',
                                ],
                            },
                            {
                                header: 'Correct',
                                type: 'checkmark',
                                allowEmpty: true,
                                rows: [
                                    true,
                                    '',
                                    '',
                                ],
                            },
                            {
                                header: 'Chosen',
                                type: 'checkmark',
                                allowEmpty: true,
                                rows: [
                                    getBoolFromBoolishVar(agencywithdataapproach1) || '',
                                    getBoolFromBoolishVar(agencywithdataapproach2) || '',
                                    getBoolFromBoolishVar(agencywithdataapproach3) || '',
                                ],
                            },
                        ];
                        var drilldownTableOpts1 = {
                            varDict: varDict,
                            columns: columns1,
                            title: 'Approach SDAs returning data per plan',
                            subtitle: '<strong>Prompt:</strong> What’s the best conversational approach?',
                        };
                        var html1 = new DrilldownTable(drilldownTableOpts1).html;
                        // Table 2 =======================
                        var columns2 = [
                            {
                                header: 'Learner response',
                                rows: [
                                    Q2RespondtoAgencies,
                                ],
                            },
                            {
                                header: 'ICTP response',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t<p><strong>Here are some points that you would want to address in some way in your response:</strong></p>\n\n\t\t\t\t\t\t\t\t\t<p>Regarding the 4 agencies that turned in data, you may want to go over their results with them and their thoughts. What reaction do they have to the data? What explanations might they offer for unexpected trends? Since using data is a critical part of a DSDS, you could consider asking how this might impact their action planning or their coaching or other activities with practitioners.</p>\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                        ];
                        var drilldownTableOpts2 = {
                            varDict: varDict,
                            columns: columns2,
                            // title: 'Approach SDAs returning data per plan',
                            subtitle: '<strong>Prompt:</strong> OK: your plan is to ask agency leadership how they might use or plan to use their data. The moment has come! What do you say?',
                        };
                        var html2 = new DrilldownTable(drilldownTableOpts2).html;
                        return createDrilldownTableGroup([html1, html2]);
                    }
                },
            },
            {
                parent: 'LO3B3nodataagency',
                variablename: 'LO3B3nodataagency',
                desc: 'Report data: Apply, Approach SDAs not returning data per plan',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'agencywithoutdataapproach1',
                            'agencywithoutdataapproach2',
                            'agencywithoutdataapproach3',
                            'Q3RespondToNoDataAgency',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var agencywithoutdataapproach1 = varDict.agencywithoutdataapproach1, agencywithoutdataapproach2 = varDict.agencywithoutdataapproach2, agencywithoutdataapproach3 = varDict.agencywithoutdataapproach3, Q3RespondToNoDataAgency = varDict.Q3RespondToNoDataAgency;
                        // Table 1: Approach SDAs returning data per plan =======================
                        var columns1 = [
                            {
                                header: 'Potential answers',
                                rows: [
                                    'Show them the data from the other agencies and tell them you were disappointed there wasn’t any data about their agency; maybe they’ll get the hint!',
                                    'Engage in an open conversation to explore what questions they have about the data, what they’d like to get out of data submission, and any potential barriers to data submission.',
                                    'Remind them about their MOU agree to turn in data in order to motivate compliance.',
                                ],
                            },
                            {
                                header: 'Correct',
                                type: 'checkmark',
                                allowEmpty: true,
                                rows: [
                                    '',
                                    true,
                                    '',
                                ],
                            },
                            {
                                header: 'Chosen',
                                type: 'checkmark',
                                allowEmpty: true,
                                rows: [
                                    getBoolFromBoolishVar(agencywithoutdataapproach1) || '',
                                    getBoolFromBoolishVar(agencywithoutdataapproach2) || '',
                                    getBoolFromBoolishVar(agencywithoutdataapproach3) || '',
                                ],
                            },
                        ];
                        var drilldownTableOpts1 = {
                            varDict: varDict,
                            columns: columns1,
                            title: 'Approach SDAs not returning data per plan',
                            subtitle: '<strong>Prompt:</strong> Next, you are getting ready to begin a conversation with one of the agencies that did not turn in their data. What approach will you take?',
                        };
                        var html1 = new DrilldownTable(drilldownTableOpts1).html;
                        // Table 2 =======================
                        var columns2 = [
                            {
                                header: 'Learner response',
                                rows: [
                                    Q3RespondToNoDataAgency,
                                ],
                            },
                            {
                                header: 'ICTP response',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t<p><strong>Here are some points that you would want to address in some way in your response:</strong></p>\n\n\t\t\t\t\t\t\t\t\t<p>You\u2019d want to start out with some introductory language that frames why you\u2019re there and shows that you are there to support the agency, not just to \u201Ccall them out\u201D for not turning in data. You could achieve this with some statements like \u201Ctoday, I really want to hear from you, your candid thoughts on our data approach - what\u2019s working and what could be improved - so that the LIA can continue to facilitate improving our data strategy for our region.\u201D Connecting back to a common goal may also be a good strategy; you could state something like \u201Cwe\u2019re all here to improve the lives of children and families, and I want to understand from you what else I can do to make that happen\u201D.</p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>After that, you may ask some broad questions that could dig deeper into why the agency is not submitting data and what barriers may be. You could start by asking something like \u201Ctell me your understanding of the data submission process\u201D or \u201CI really want to hear your perspective on how data is working - do you see value in it? What could be improved?\u201D</p>\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                        ];
                        var drilldownTableOpts2 = {
                            varDict: varDict,
                            columns: columns2,
                            // title: 'Approach SDAs returning data per plan',
                            subtitle: '<strong>Prompt:</strong> How would you frame the conversation and what questions might you ask to get the conversation started so you can uncover any potential barriers? ',
                        };
                        var html2 = new DrilldownTable(drilldownTableOpts2).html;
                        return createDrilldownTableGroup([html1, html2]);
                    }
                },
            },
            {
                parent: 'LO#?2',
                variablename: 'LO#?2',
                desc: 'Use data for decision-making',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    icon: 'text',
                    vars: {
                        response: [
                            'Q4DataVision',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var Q4DataVision = varDict.Q4DataVision;
                        var columns = [
                            {
                                header: 'Learner response',
                                rows: [
                                    Q4DataVision
                                ],
                            },
                        ];
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: columns,
                            subtitle: "\n\t\t\t\t\t\t\t\t<div><strong>Prompt:</strong></div>\n\t\t\t\t\t\t\t\t<p>\n\t\t\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t\t\t<li>How will you use data for decision-making?</li>\n\t\t\t\t\t\t\t\t\t\t<li>What is the best way to involve community members at the right time, with the right data, in order to make decisions based on that data?</li>\n\t\t\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t\t\t</p>\n\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t<p>Describe your vision for using data for decision making and improvement of Triple P efforts in Grand County.</p>\n\t\t\t\t\t\t\t"
                        };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
            {
                parent: 'LO3A2d',
                variablename: 'LO3A2d',
                desc: 'Identify roles, responsibilities, resources',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'staffingplan1',
                            'staffingplan2',
                            'staffingplan3',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var staffingplan1 = varDict.staffingplan1, staffingplan2 = varDict.staffingplan2, staffingplan3 = varDict.staffingplan3;
                        var columns = [
                            {
                                header: 'Potential answers',
                                rows: [
                                    "John is in charge because he\u2019s the lead data person.",
                                    "John is responsible for leading the effort with support from the CLT and Andrea as lead coordinator.",
                                    "John, Andrea, and Edward share equal responsibility so that the burden doesn\u2019t fall on any given person entirely.",
                                ],
                            },
                            {
                                header: 'Correct',
                                type: 'checkmark',
                                allowEmpty: true,
                                rows: [
                                    '',
                                    true,
                                    '',
                                ],
                            },
                            {
                                header: 'Chosen',
                                type: 'checkmark',
                                allowEmpty: true,
                                rows: [
                                    getBoolFromBoolishVar(staffingplan1) || '',
                                    getBoolFromBoolishVar(staffingplan2) || '',
                                    getBoolFromBoolishVar(staffingplan3) || '',
                                ],
                            },
                        ];
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: columns,
                            subtitle: '<strong>Prompt:</strong> Which staffing plan seems most appropriate?',
                        };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
            {
                parent: 'LO#?3',
                variablename: 'LO#?3',
                desc: 'Next steps',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    icon: 'text',
                    vars: {
                        response: [
                            'Q5step3whatDidYouLearn',
                            'Q6step3FollowUp',
                            'dsdsSlider',
                            'Q7step3NextSteps',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var Q5step3whatDidYouLearn = varDict.Q5step3whatDidYouLearn, Q6step3FollowUp = varDict.Q6step3FollowUp, dsdsSlider = varDict.dsdsSlider, Q7step3NextSteps = varDict.Q7step3NextSteps;
                        var columns = [
                            {
                                header: 'What did you learn in this module that you might want to share with others? Specifically, who might you want to share it with and what would you want them to know?',
                                rows: [
                                    Q5step3whatDidYouLearn
                                ],
                            },
                            {
                                header: 'What do you still have questions about that you want to follow-up on and how will you follow up on these needs? ',
                                rows: [
                                    Q6step3FollowUp
                                ],
                            },
                            {
                                header: 'How you would rate your current DSDS plan on a scale of 1-10, where 1 is low and 10 is a high score. ',
                                rows: [
                                    dsdsSlider
                                ],
                            },
                            {
                                header: 'Based on your 1-10 rating, write some concrete next steps that you could personally take. ',
                                rows: [
                                    Q7step3NextSteps
                                ],
                            },
                        ];
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: columns
                        };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
        ],
        printouts: [
            {
                name: 'Customized Learner Summary',
                profileId: 'ictp-mod-6',
                project: 'ictp',
            }
        ],
    },
};
// export const mod6 = {
// 	'http://ICTPMod6': {
// 		type: 2,
// 		profileId: 'ictp-mod-6',
// 		hasPrintout: true,
// 		sections: [
// 			{
// 				title: 'Collect Data: <span class="font-light">Understand</span>',
// 				section: 'Collect Data',
// 				activityList: [
// 					{
// 						activityName: 'Collect data: understand',
// 						color: '',
// 						variable: 'LO3A2a',
// 					},
// 				],
// 			},
// 			{
// 				title: 'Collect Data: <span class="font-light">Apply</span>',
// 				section: 'Collect Data',
// 				activityList: [
// 					{
// 						activityName: 'Collect data: apply',
// 						color: '',
// 						variable: 'LO3B2',
// 					}
// 				],
// 			},
// 			{
// 				title: 'Report Data: <span class="font-light">Understand</span>',
// 				note: '<div><strong>Crux of answer should be</strong>: too burdensome; look for routinely collected data that could be utilized.</div><div><strong>Look for question:</strong> Does anyone collect these data or similar data already?</div>',
// 				section: 'Report Data',
// 				activityList: [
// 					{
// 						title: 'Assessment of data burden and efficiency',
// 						activityName: 'Assessment of data burden and efficiency',
// 						response: '',
// 						variable: 'Q1step1dataEfficient',
// 					},
// 				],
// 			},
// 			{
// 				title: 'Report Data: <span class="font-light">Apply | General</span>',
// 				section: 'Report Data',
// 				activityList: [
// 					{
// 						activityName: 'Report data: apply, general',
// 						color: '',
// 						variable: 'LO3B3general',
// 					}
// 				],
// 			},
// 			{
// 				title: 'Report Data: <span class="font-light">Apply | Approach SDAs Returning Data</span>',
// 				section: 'Report Data',
// 				activityList: [
// 					{
// 						activityName: 'Report data: apply, approach SDAs returning data per plan',
// 						color: '',
// 						variable: 'LO3B3dataagency',
// 					},
// 					{
// 						title: 'Asking agency leadership how they might use or plan to use their data',
// 						activityName: 'Agency with data conversation',
// 						response: '',
// 						variable: 'Q2RespondtoAgencies',
// 					},
// 				],
// 			},
// 			{
// 				title: 'Report Data: <span class="font-light">Apply | Approach SDAs Not Returning Data</span>',
// 				section: 'Report Data',
// 				activityList: [
// 					{
// 						activityName: 'Report data: apply, approach SDAs not returning data per plan',
// 						color: '',
// 						variable: 'LO3B3nodataagency',
// 					},
// 					{
// 						title: 'Asking agency leadership how they might use or plan to use their data',
// 						activityName: 'Agency without data conversation',
// 						response: '',
// 						variable: 'Q3RespondToNoDataAgency',
// 					},
// 				],
// 			},
// 			{
// 				title: 'Use Data for decision-making',
// 				section: 'Use Data for decision-making',
// 				activityList: [
// 					{
// 						title: 'Plan for how Grand County will use data for decision-making',
// 						activityName: 'Plan for using data for decision-making',
// 						response: '',
// 						variable: 'Q4DataVision',
// 					},
// 				]
// 			},
// 			{
// 				title: 'Identify roles, responsibilities, resources',
// 				section: 'Identify roles, responsibilities, resources',
// 				activityList: [
// 					{
// 						activityName: 'Identify roles, responsibilities, resources',
// 						color: '',
// 						variable: 'LO3A2d',
// 					},
// 				]
// 			},
// 			{
// 				title: 'Next Steps',
// 				section: 'Next Steps',
// 				activityList: [
// 					{
// 						title: 'Their takeaways',
// 						activityName: 'What did you learn',
// 						response: '',
// 						// variable: 'Q4step3whatDidYouLearn',
// 						variable: 'Q5step3whatDidYouLearn',
// 					},
// 					{
// 						title: 'Their outstanding questions',
// 						activityName: 'Outstanding questions',
// 						response: '',
// 						// variable: 'Q5step3FollowUp',
// 						variable: 'Q6step3FollowUp',
// 					},
// 					{
// 						title: 'How they rate their DSDS on a scale of 1-10',
// 						activityName: 'DSDS rating',
// 						response: '',
// 						variable: 'dsdsSlider',
// 					},
// 					{
// 						title: 'Their next steps',
// 						activityName: 'Next steps they want to take',
// 						response: '',
// 						// variable: 'Q6step3NextSteps',
// 						variable: 'Q7step3NextSteps',
// 					},
// 				]
// 			},
// 		],
// 	},
// };
