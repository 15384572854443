var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { DrilldownTable } from "../../../../../shared/interfaces-classes/index";
import { reportUtils } from "../../../../report-utils";
var getBoolFromBoolishVar = reportUtils.getBoolFromBoolishVar, getCorrectIncorrectByBool = reportUtils.getCorrectIncorrectByBool, processSingleVarHtml = reportUtils.processSingleVarHtml;
function isBoolean(val) {
    return typeof val === 'boolean';
}
function getCheckmarkBoolByAnswer(shouldBeChecked, isCorrect) {
    return !!shouldBeChecked
        ? isCorrect
            ? true : ''
        : isCorrect
            ? '' : true;
}
function getUserCheckmarkRows(rowCompetenceList, rowUserCorrectList) {
    return rowCompetenceList.map(function (competence, idx) {
        var userAnswer = rowUserCorrectList[idx];
        if (!isBoolean(userAnswer)) {
            return userAnswer;
        }
        return {
            type: 'checkmark',
            text: getCheckmarkBoolByAnswer(competence, userAnswer),
            className: getCorrectIncorrectByBool(userAnswer),
        };
    });
}
// Exported methods ========================================
export function lo1DrilldownTable(varDict) {
    var Triplepoverall = varDict.Triplepoverall, changeoverall = varDict.changeoverall, dataoverall = varDict.dataoverall, implementationoverall = varDict.implementationoverall;
    var columns = __spreadArray(__spreadArray(__spreadArray(__spreadArray([
        {
            header: '',
            rows: [
                '<strong>Kristy</strong>',
                '<strong>Edward</strong>',
                '<strong>Andrea</strong>',
                '<strong>Carissa</strong>',
                '<strong>John</strong>',
            ],
        }
    ], __read(getTriplePColumns(varDict))), __read(getChangeMgmtColumns(varDict))), __read(getDataMgmtColumns(varDict))), __read(getImplementationColumns(varDict)));
    var drilldownTableOpts = { varDict: varDict, columns: columns, textCenter: true };
    var html = new DrilldownTable(drilldownTableOpts).html;
    var htmlElement = document.createElement('div');
    htmlElement.innerHTML = html;
    var tableEl = htmlElement.querySelector('table');
    var tableHeadEl = tableEl.querySelector('thead');
    var prependedHeaderRow = "\n    <tr>\n      <th></th>\n      <th colspan=\"2\">Triple P</th>\n      <th colspan=\"2\">Change management</th>\n      <th colspan=\"2\">Data management</th>\n      <th colspan=\"2\">Implementation</th>\n    </tr>\n  ";
    var appendedFooterRow = "\n    <tr style=\"font-weight: bold;\">\n      <td>Overall score</td>\n      <td colspan=\"2\">" + processSingleVarHtml(Triplepoverall) + "</td>\n      <td colspan=\"2\">" + processSingleVarHtml(changeoverall) + "</td>\n      <td colspan=\"2\">" + processSingleVarHtml(dataoverall) + "</td>\n      <td colspan=\"2\">" + processSingleVarHtml(implementationoverall) + "</td>\n    </tr>\n  ";
    tableHeadEl.insertAdjacentHTML('afterbegin', prependedHeaderRow);
    tableEl.insertAdjacentHTML('beforeend', appendedFooterRow);
    return htmlElement.innerHTML;
}
function getTriplePColumns(varDict) {
    var kristytriplep = varDict.kristytriplep, edwardtriplep = varDict.edwardtriplep, andreatriplep = varDict.andreatriplep, carissatriplep = varDict.carissatriplep, johntriplep = varDict.johntriplep;
    var rowCompetenceList = [true, true, true, '', ''];
    var rowUserCorrectList = [kristytriplep, edwardtriplep, andreatriplep, carissatriplep, johntriplep].map(getBoolFromBoolishVar);
    var columns = [
        {
            header: 'Correct?',
            rows: rowCompetenceList,
            type: 'checkmark',
            allowEmpty: true,
        },
        {
            header: 'Chosen?',
            allowEmpty: true,
            rows: getUserCheckmarkRows(rowCompetenceList, rowUserCorrectList),
        },
    ];
    return columns;
}
function getChangeMgmtColumns(varDict) {
    var kristychange = varDict.kristychange, edwardchange = varDict.edwardchange, andreachange = varDict.andreachange, carissachange = varDict.carissachange, johnchange = varDict.johnchange;
    var rowCompetenceList = [true, '', true, true, ''];
    var rowUserCorrectList = [kristychange, edwardchange, andreachange, carissachange, johnchange].map(getBoolFromBoolishVar);
    var columns = [
        {
            header: 'Correct?',
            rows: rowCompetenceList,
            type: 'checkmark',
            allowEmpty: true,
        },
        {
            header: 'Chosen?',
            allowEmpty: true,
            rows: getUserCheckmarkRows(rowCompetenceList, rowUserCorrectList),
        },
    ];
    return columns;
}
function getDataMgmtColumns(varDict) {
    var kristydata = varDict.kristydata, edwarddata = varDict.edwarddata, andreadata = varDict.andreadata, carissadata = varDict.carissadata, johndata = varDict.johndata;
    var rowCompetenceList = ['', '', '', '', true];
    var rowUserCorrectList = [kristydata, edwarddata, andreadata, carissadata, johndata].map(getBoolFromBoolishVar);
    var columns = [
        {
            header: 'Correct?',
            rows: rowCompetenceList,
            type: 'checkmark',
            allowEmpty: true,
        },
        {
            header: 'Chosen?',
            allowEmpty: true,
            rows: getUserCheckmarkRows(rowCompetenceList, rowUserCorrectList),
        },
    ];
    return columns;
}
function getImplementationColumns(varDict) {
    var kristyimplementation = varDict.kristyimplementation, edwardimplementation = varDict.edwardimplementation, andreaimplementation = varDict.andreaimplementation, carissaimplementation = varDict.carissaimplementation, johnimplementation = varDict.johnimplementation;
    var rowCompetenceList = ['', '', true, true, ''];
    var rowUserCorrectList = [kristyimplementation, edwardimplementation, andreaimplementation, carissaimplementation, johnimplementation].map(getBoolFromBoolishVar);
    var columns = [
        {
            header: 'Correct?',
            rows: rowCompetenceList,
            type: 'checkmark',
            allowEmpty: true,
        },
        {
            header: 'Chosen?',
            allowEmpty: true,
            rows: getUserCheckmarkRows(rowCompetenceList, rowUserCorrectList),
        },
    ];
    return columns;
}
