import { DrilldownTable } from "../../../../../shared/interfaces-classes/index";
import { reportUtils } from "../../../../report-utils";
var processTemplateVarHtml = reportUtils.processTemplateVarHtmlList, getGenericGroupMsg = reportUtils.getGenericGroupMsg;
var genericGroupMsg = getGenericGroupMsg();
export var stages = {
    'http://SISEPStages': {
        columns: [
            {
                parent: 'LO1a',
                variablename: 'LO1adash',
                desc: 'Find activities and outcomes with Implementation Stages Planning Tool',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 50
                    },
                    yellow: {
                        min: 51,
                        max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                            // stringifyVarPrefix: 'var',
                        },
                        response: [
                            'text0202',
                            'text0203',
                            'text0204',
                            'text0205',
                            'text0206',
                            'text0207',
                            'tries0202',
                            'tries0203',
                            'tries0204',
                            'tries0205',
                            'tries0206',
                            'tries0207',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var text0202 = varDict.text0202, text0203 = varDict.text0203, text0204 = varDict.text0204, text0205 = varDict.text0205, text0206 = varDict.text0206, text0207 = varDict.text0207, tries0202 = varDict.tries0202, tries0203 = varDict.tries0203, tries0204 = varDict.tries0204, tries0205 = varDict.tries0205, tries0206 = varDict.tries0206, tries0207 = varDict.tries0207;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    "<p>Within which stage does this activity occur?</p>\n\t\t\t\t\t\t\t\t\t<p><strong>Activity:</strong> Begin to implement coaching supports</p>\n\t\t\t\t\t\t\t\t\t",
                                    "<p>Within which stage does this activity occur?</p>\n\t\t\t\t\t\t\t\t\t<p><strong>Activity:</strong> Assess fit and feasibility of practice options to address the change needed</p>\n\t\t\t\t\t\t\t\t\t",
                                    "<p>Within which stage does this outcome occur? </p>\n\t\t\t\t\t\t\t\t\t<p><strong>Outcome:</strong> Sustained use of the program/practice with all practitioners delivering with fidelity and ease</p>\n\t\t\t\t\t\t\t\t\t",
                                    "<p>Within which stage does this activity occur?</p>\n\t\t\t\t\t\t\t\t\t<p><strong>Activity:</strong> Develop data systems <em>(what data, how data will be collected, used, and shared)</em></p>\n\t\t\t\t\t\t\t\t\t",
                                    "<p>Within which stage does this outcome occur? </p>\n\t\t\t\t\t\t\t\t\t<p><strong>Outcome:</strong> Majority of practitioners are using the program/practice</p>\n\t\t\t\t\t\t\t\t\t",
                                    "<p>Within which stage does this outcome occur? </p>\n\t\t\t\t\t\t\t\t\t<p><strong>Outcome:</strong> Demonstrated need for program/practice</p>\n\t\t\t\t\t\t\t\t\t",
                                ]
                            },
                            {
                                header: 'Correct answer',
                                rows: [
                                    'Initial implementation',
                                    'Exploration',
                                    'Full implementation',
                                    'Installation',
                                    'Full implementation',
                                    'Exploration',
                                ]
                            },
                            {
                                header: 'First answer provided',
                                rows: processTemplateVarHtml([
                                    text0202,
                                    text0203,
                                    text0204,
                                    text0205,
                                    text0206,
                                    text0207,
                                ])
                            },
                            {
                                header: 'Number of attempts',
                                rows: processTemplateVarHtml([
                                    tries0202,
                                    tries0203,
                                    tries0204,
                                    tries0205,
                                    tries0206,
                                    tries0207,
                                ])
                            }
                        ];
                        // ===========================
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO1b',
                variablename: 'LO1bdash',
                desc: 'Bigger picture understanding of stages',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 50
                    },
                    yellow: {
                        min: 51,
                        max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                            // stringifyVarPrefix: 'var',
                        },
                        response: [
                            'text0208',
                            'text0209',
                            'text0210',
                            'text0211',
                            'tries0208',
                            'tries0209',
                            'tries0210',
                            'tries0211',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var text0208 = varDict.text0208, text0209 = varDict.text0209, text0210 = varDict.text0210, text0211 = varDict.text0211, tries0208 = varDict.tries0208, tries0209 = varDict.tries0209, tries0210 = varDict.tries0210, tries0211 = varDict.tries0211;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    "<p>The newly developed Swift County Schools implementation team meets to complete a root cause analysis.  By completing the five whys protocol and reviewing disaggregated data and feedback from staff and parents, they hope to learn what the specific need is in the area of mathematics for their students.</p>\n\n\t\t\t\t\t\t\t\t\t<p>As Swift County Schools will learn, early investment pays off later.</p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>What is Swift County\u2019s current implementation stage?</p>\n\t\t\t\t\t\t\t\t\t",
                                    "<p>Three months later, the Swift County Schools team meets to develop their plan for implementing a new mathematics program.  One team member asks the question. \u201CDo we have the right people at the table for these decisions?\u201D  The team decides to revisit their membership and roles and responsibilities to ensure a diverse group and that all voices are heard.</p>\n\n\n\t\t\t\t\t\t\t\t\t<p>As Swift County Schools will learn, implementation stages are nonlinear.</p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>What is Swift County\u2019s current implementation stage?</p>\n\t\t\t\t\t\t\t\t\t",
                                    "<p>One year later, the Swift County Schools are implementing a new mathematics program and the team is only meeting every three months to review their implementation plan.</p>\n\n\t\t\t\t\t\t\t\t\t<p>The team meets to discuss the survey results from a recent training. After looking at staff survey data, they decided they needed to obtain more feedback before moving forward. There was also a building that did not even receive the survey.</p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>As Swift County Schools will learn, work can progress in more than one stage at a time.</p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>What is Swift County\u2019s current implementation stage?</p>\n\t\t\t\t\t\t\t\t\t",
                                    "<p>Four months later, the Swift County Schools team has created a schedule allowing them to meet monthly with all members. The team recently met to begin discussing fidelity data as well as complete their capacity assessment for their mathematics program.  They discover that while communication is better, there is still a need to improve feedback loops with staff and stakeholders before scaling-up the program.</p>\n\n\t\t\t\t\t\t\t\t\t<p>As Swift County Schools will learn, implementation work is continuous.</p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>What is Swift County\u2019s current implementation stage?</p>\n\t\t\t\t\t\t\t\t\t",
                                ]
                            },
                            {
                                header: 'Correct answer',
                                rows: [
                                    'Exploration',
                                    'Installation',
                                    'Initial implementation',
                                    'Full implementation',
                                ]
                            },
                            {
                                header: 'First answer provided',
                                rows: processTemplateVarHtml([
                                    text0208,
                                    text0209,
                                    text0210,
                                    text0211,
                                ])
                            },
                            {
                                header: 'Number of attempts',
                                rows: processTemplateVarHtml([
                                    tries0208,
                                    tries0209,
                                    tries0210,
                                    tries0211,
                                ])
                            }
                        ];
                        // ===========================
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO2',
                variablename: 'LO2dash',
                desc: 'Create implementation plan that reflects understanding of stages',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 50
                    },
                    yellow: {
                        min: 51,
                        max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                            // stringifyVarPrefix: 'var',
                        },
                        response: [
                            'text0302',
                            'text0303',
                            'text0306',
                            'tries0302',
                            'tries0303',
                            'tries0306',
                            'text0309a',
                            'text0309b',
                            'text0309c',
                            'text0309d',
                            'text0312a',
                            'text0312b',
                            'text0312c',
                            'text0312d',
                            'tries0309',
                            'tries0312',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var text0302 = varDict.text0302, text0303 = varDict.text0303, text0306 = varDict.text0306, text0309a = varDict.text0309a, text0309b = varDict.text0309b, text0309c = varDict.text0309c, text0309d = varDict.text0309d, text0312a = varDict.text0312a, text0312b = varDict.text0312b, text0312c = varDict.text0312c, text0312d = varDict.text0312d, tries0302 = varDict.tries0302, tries0303 = varDict.tries0303, tries0306 = varDict.tries0306, tries0309 = varDict.tries0309, tries0312 = varDict.tries0312;
                        var andJoin = '<p>AND</p>';
                        var text0309 = function () {
                            return [
                                text0309a,
                                text0309b,
                                text0309c,
                                text0309d,
                            ].filter(function (val) { return val; })
                                .join(andJoin);
                        };
                        var text0312 = function () {
                            return [
                                text0312a,
                                text0312b,
                                text0312c,
                                text0312d,
                            ].filter(function (val) { return val; })
                                .join(andJoin);
                        };
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'Which of the exploration activities should Friendly County skip for now?',
                                    'Which of these activities should Boundless County focus on at this stage?',
                                    'Which of these activities should Mountain Middle School include in their implementation plan at this stage?',
                                    "<p>Which of these activities should East District High School focus on at this stage?</p> \n\t\t\t\t\t\t\t\t\t<p><em>Select all that apply</em></p>",
                                    "<p>Which of these activities should East District High School focus on at this stage?</p> \n\t\t\t\t\t\t\t\t\t<p><em>Select all that apply</em></p>",
                                ]
                            },
                            {
                                header: 'Correct answer',
                                rows: [
                                    'None of these: they should spend the time and money in spite of the pressure.',
                                    'Assess fit and feasibility of options to address the change needed.',
                                    'Secure and develop infrastructure resources and supports needed for the program/practice.',
                                    "Evaluate for expected outcomes of the academic practice.\n\t\t\t\t\t\t\t\t\t<p>AND</p>\n\t\t\t\t\t\t\t\t\tScan or assess what current social-emotional supports are in place.\n\t\t\t\t\t\t\t\t\t<p>AND</p>\n\t\t\t\t\t\t\t\t\tRevisit implementation team membership to ensure needed perspectives.",
                                    "Evaluate for expected outcomes.\n\t\t\t\t\t\t\t\t\t<p>AND</p>\n\t\t\t\t\t\t\t\t\tContinue the training and coaching supports to maintain skillful use of the program or practice.\n\t\t\t\t\t\t\t\t\t<p>AND</p>\n\t\t\t\t\t\t\t\t\tContinue to monitor and improve implementation supports and resources needed to sustain implementation.",
                                ]
                            },
                            {
                                header: 'First answer provided',
                                rows: processTemplateVarHtml([
                                    text0302,
                                    text0303,
                                    text0306,
                                    text0309(),
                                    text0312(),
                                ])
                            },
                            {
                                header: 'Number of attempts',
                                rows: processTemplateVarHtml([
                                    tries0302,
                                    tries0303,
                                    tries0306,
                                    tries0309,
                                    tries0312,
                                ])
                            }
                        ];
                        // ===========================
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
        ],
        specialGroupVarDictFns: {},
    }
};
// import { CustomReportLights } from '../../../../../shared/report-classes';
// export const stages = {
// 	// Mod2
// 	'http://SISEPStages': <CustomReportLights>{
// 		sections: [
// 			{
// 				title: 'Identify an organization’s current implementation stage',
// 				section: 'LO1',
// 				activityList: [
// 					{
// 						name: 'LO1',
// 						desc: 'LO1: green/yellow/red light',
// 						color: '',
// 						variable: 'LO2',
// 					},
// 				],
// 			},
// 			{
// 				title: 'Create implementation plan that reflects understanding of stages',
// 				section: 'LO2',
// 				activityList: [
// 					{
// 						name: 'LO2',
// 						desc: 'LO2: green/yellow/red light',
// 						color: '',
// 						variable: 'LO2',
// 					},
// 				],
// 			}
// 		]
// 	},
// };
