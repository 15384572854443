import { messaging, utils } from "../shared/exports";
import { xapiUtils } from "../xapi/xapi-utils";
var User = /** @class */ (function () {
    function User() {
        this.name = '';
        this.id = '';
    }
    return User;
}());
export var reportUtils = {
    getUser: function (actor) {
        if (!actor) {
            console.error('Actor not found');
            return new User();
        }
        var name = actor.name, mbox = actor.mbox, account = actor.account;
        var id = '';
        if (account) {
            name = name || account.name || '';
            id = account.name || name || '';
        }
        else {
            id = (mbox || '').replace('mailto:', '');
            ;
        }
        return { name: name, id: id };
    },
    getUserIdType: function (userId) {
        if (userId === void 0) { userId = ''; }
        if (userId.includes('mailto:') || userId.includes('@')) {
            return 'mbox';
        }
        else {
            return 'account';
        }
    },
    createUserData: function (thisUser, courseStructureType) {
        if (!thisUser) {
            console.error('User not found');
            return {};
        }
        var _a = this, type = _a.type, colDataStructure = _a.colDataStructure;
        var UserData = colDataStructure[courseStructureType][type];
        if (!UserData) {
            console.error('UserData not found');
            return {};
        }
        var user = new UserData();
        user.uid = thisUser.id;
        return user;
    },
    encodeID: function (text) {
        // taken from xapi_functions.js, so should be the same
        text = text || "";
        return encodeURIComponent(text.replace(/\s/g, '-').toLowerCase()).replace(/[!'()*]/g, "");
    },
    isDesiredVerb: function (display) {
        var displayValues = Object.values(display);
        var isScored = displayValues.includes('scored');
        var isComplete = displayValues.includes('completed');
        return isScored || isComplete;
    },
    /**
     * Currently used for SISEP Grid drilldowns
     * @param option Object containing text, isAnswered, isCorrect
     * @returns HTML string list item
     */
    createScoredListItem: function (option) {
        var text = option.text, isAnswered = option.isAnswered, isCorrect = option.isCorrect;
        var suffix = isCorrect ? ' (<strong>correct</strong>)' : '';
        var answeredCorrectly = isAnswered && isCorrect;
        var iconType = answeredCorrectly ? 'checkmark-circle' : 'close';
        var checkTypeClass = answeredCorrectly ? 'correct' : isAnswered ? 'incorrect' : 'none';
        return "\n\t\t\t<li>\n\t\t\t\t<ion-icon name=\"" + iconType + "\" class=\"" + checkTypeClass + "\"></ion-icon>\n\t\t\t\t<span>" + text + suffix + "</span>\n\t\t\t</li>";
    },
    getPrintUserId: function () {
        var params = new URLSearchParams(location.search);
        return params.get('printUserId');
    },
    getProjectIdAndCourseConfigFromCourseId: function (courseId, appConfig) {
        var projectId = '';
        var course = null;
        appConfig.forEach(function (client) {
            var courseList = client.courseList;
            var matchedCourse = courseList.find(function (course) { return course.id === courseId; });
            if (matchedCourse) {
                projectId = client.id;
                course = matchedCourse;
            }
        });
        return {
            projectId: projectId,
            course: course,
        };
    },
    getGridReportIdFromCourseId: function (courseId, appConfig) {
        var courseConfig = this.getProjectIdAndCourseConfigFromCourseId(courseId, appConfig);
        var course = courseConfig.course;
        var _a = (course || {}).reports, reports = _a === void 0 ? [] : _a;
        var matchedReport = reports.find(function (report) { return report.type === 'grid'; }) || {};
        return matchedReport.id;
    },
    getProjectFromCourseId: function (courseId, appConfig) {
        var courseConfig = this.getProjectIdAndCourseConfigFromCourseId(courseId, appConfig);
        var projectId = courseConfig.projectId;
        return projectId;
    },
    submitAutoPrint: function (printUserId, projectData, reportProcessor) {
        utils.updateUiForPrint('before', 'external');
        reportProcessor.listType = 'email';
        projectData.searchUserId = printUserId.replace('mailto:', '');
        projectData.searchUserType = reportUtils.getUserIdType(printUserId);
        // hack to ensure all other setup is done...
        setTimeout(function () {
            projectData.onSubmitCallback();
        }, 500);
    },
    /**
     * Bind Name and Email form submission
     * @param projectData core project object
     * @param reportProcessor main reportProcessor object (drilldownGrid, etc.)
     */
    bindActorFormEvents: function (projectData, reportProcessor) {
        var printUserId = reportUtils.getPrintUserId();
        if (printUserId) {
            reportUtils.submitAutoPrint(printUserId, projectData, reportProcessor);
            return;
        }
        function submitForm(e, type) {
            e.preventDefault();
            reportUtils.onSubmitActorForm.bind(this)(type, projectData, reportProcessor);
        }
        $('form#name-inputs').submit(function (e) {
            submitForm.bind(this)(e, 'name');
        });
        $('form#email-inputs').submit(function (e) {
            submitForm.bind(this)(e, 'email');
        });
    },
    onSubmitActorForm: function (type, projectData, reportProcessor) {
        reportProcessor.listType = type;
        var reportType = projectData.type;
        $("." + reportType + "-loading-container").removeClass('hidden');
        $("." + reportType + "-container").html('');
        var rawFormData = $(this).serializeArray();
        var formData = utils.getDictFromSerializedForm(rawFormData);
        var names = formData.names, emails = formData.emails;
        var formDataValue = type === 'name' ? names : emails;
        var groupList = utils.getGroupListFromTextarea(formDataValue);
        reportProcessor.groupList = groupList;
        if (type === 'email') {
            if (groupList.length === 1) {
                var userId = groupList[0];
                projectData.searchUserId = userId;
                projectData.searchUserType = reportUtils.getUserIdType(userId);
            }
        }
        // runs app.actions.getStmtsStart() which then hits this project report's init()
        projectData.onSubmitCallback();
    },
    /**
     * Filter statements to actors in group list
     * @param standardStmts
     * @param reportProcessor main reportProcessor object (drilldownGrid, etc.)
     * @returns
     */
    filterStmtsToGroup: function (standardStmts, reportProcessor) {
        var listType = reportProcessor.listType, groupList = reportProcessor.groupList;
        if (!groupList.length) {
            return standardStmts;
        }
        return standardStmts.filter(function (stmt) {
            // const { actor: { name = '', mbox = '' } } = stmt;
            var _a = stmt.actor, _b = _a.name, name = _b === void 0 ? '' : _b, _c = _a.mbox, mbox = _c === void 0 ? '' : _c, _d = _a.account, _e = _d === void 0 ? {} : _d, _f = _e.name, accountName = _f === void 0 ? '' : _f;
            var listValue = '';
            if (listType === 'name') {
                listValue = name;
            }
            else {
                var userId = mbox.replace('mailto:', '') || accountName;
                listValue = userId;
            }
            var matchStmts = groupList.filter(function (valueInGroup) {
                return listValue === valueInGroup;
            });
            return matchStmts.length;
        });
    },
    /**
     * Flatten and filter statement data, and create User Map
     * @param stmts raw TinCan formatted statements
     * @param reportProcessor main reportProcessor object (drilldownGrid, etc.)
     * @returns
     */
    processStmtData: function (stmts, reportProcessor) {
        var _a, _b;
        var standardStmts = xapiUtils.getStandardStmtsFromTincan(stmts);
        var filteredStmts = reportProcessor.applySecondaryFilters(standardStmts);
        if (!filteredStmts || !filteredStmts.length) {
            messaging.show('alert', 'No data found');
            return;
        }
        var exts = ['variableName', 'variablename', 'variable']; // camel case to cover both types
        var simplifiedStmts = xapiUtils.getSimplifiedStmtsFromStandard((_b = (_a = reportProcessor === null || reportProcessor === void 0 ? void 0 : reportProcessor.mainProject) === null || _a === void 0 ? void 0 : _a.report) === null || _b === void 0 ? void 0 : _b.id, filteredStmts, exts);
        reportProcessor.userMap = xapiUtils.getUserMapFromSimplifiedStmts(simplifiedStmts);
    },
    getReportStructure: function (projectData) {
        var _a = projectData || {}, report = _a.report, structures = _a.structures;
        if (!report) {
            console.error('report not available in projectData:', projectData);
        }
        var _b = report || {}, id = _b.id, subtype = _b.subtype;
        var reportId = subtype ? id + "/" + subtype : id;
        return (structures === null || structures === void 0 ? void 0 : structures[reportId]) || {};
    },
    getMatchingStmtFromProp: function (prop, varValue, statements) {
        var matchStmts = statements.filter(function (stmt) {
            return stmt[prop] === varValue;
        });
        utils.sortStmtArrayByTimestamp(matchStmts, 'desc');
        return matchStmts[0]; // last stmt
    },
    getGenericGroupMsg: function () {
        return '<p>Select individual results for more details, or view <strong>Analytics</strong> report.</p>';
    },
    getCorrectIncorrectByValueComparison: function (val1, val2) {
        return val1 === val2 ? 'correct' : 'incorrect';
    },
    getCorrectIncorrectByBool: function (val) {
        return val ? 'correct' : 'incorrect';
    },
    /**
     * If falsy, but not false, return "No data"
     *
     * Otherwise, return a boolean
     * @param val bool-ish value (string, number, boolean etc.)
     * @returns boolean or "(No data)"
     */
    getBoolFromBoolishVar: function (val) {
        if (val === undefined || val === null || val === '') {
            return reportUtils.processSingleVarHtml().toString();
        }
        var bool = val;
        if (typeof val === 'string') {
            bool = val === 'true';
            var num = parseInt(val);
            if (!isNaN(num)) {
                bool = num !== 0;
            }
        }
        else if (typeof val === 'number') {
            bool = val !== 0;
        }
        return !!bool;
    },
    /**
     * Get a checkmark, red X, or "No data" from a bool-ish variable
     * @param boolishVar bool-ish value (string, number, boolean etc.)
     * @returns checkmark, red X, or "No data"
     */
    getCheckOrXOrNoDataFromBoolishVar: function (boolishVar) {
        var bool = reportUtils.getBoolFromBoolishVar(boolishVar);
        var isBool = typeof bool === 'boolean';
        var isTrue = isBool && bool;
        var isFalse = isBool && !bool;
        return isTrue
            ? true // checkmark
            : (isFalse
                ? false // red X
                : bool); // no data
    },
    getYesNoFromBoolishVar: function (val) {
        if (val === undefined || val === null || val === '') {
            return reportUtils.processSingleVarHtml().toString();
        }
        var bool = reportUtils.getBoolFromBoolishVar(val);
        return bool ? 'Yes' : 'No';
        // const boolString = (bool ? bool.toString() : '').toLowerCase();
        // const boolString = bool.toString().toLowerCase();
        // let textResponse = boolString ? boolString === 'true' ? 'Yes' : 'No' : '';
        // return reportUtils.processSingleVarHtml(textResponse).toString();
    },
    getHtmlFromBoolVars: function (varDict, choiceTextMap, varList, type) {
        var rawHtmlList = varList
            .map(function (varName) {
            var varValString = (varDict[varName] ? varDict[varName].toString() : '').toLowerCase();
            if (varValString == 'true') {
                var itemOutput = choiceTextMap[varName];
                if (type === 'list') {
                    itemOutput = "<li>" + itemOutput + "</li>";
                }
                return itemOutput;
            }
        })
            .filter(function (val) { return val; });
        var output = rawHtmlList.join('<br>');
        if (type === 'list') {
            output = "\n\t\t\t\t<ul>\n\t\t\t\t\t" + rawHtmlList.join('') + "\n\t\t\t\t</ul>\n\t\t\t";
        }
        return output;
    },
    /**
     * Process a single variable's HTML output to display "No data" if undefined.
     *
     * It also handles staticAndDynamic objects.
     * @param vblValue
     * @param opts
     * @param opts.returnBlankIfEmpty - If true, return an empty string if the value is empty (otherwise No Data message).
     * @returns CellType
     */
    processSingleVarHtml: function (vblValue, opts) {
        var noDataText = (opts === null || opts === void 0 ? void 0 : opts.returnBlankIfEmpty) ? '' : '<em>(No data)</em>';
        var output = '';
        if (typeof vblValue === 'object' && vblValue !== null) {
            var type = vblValue.type, title = vblValue.title, text = vblValue.text;
            if (type === 'staticAndDynamic') {
                output = "\n\t\t\t\t\t<div class=\"static-dynamic-container\">\n\t\t\t\t\t\t<div class=\"static-dynamic-title\">" + title + "</div>\n\t\t\t\t\t\t<div class=\"static-dynamic-text\">" + (text || noDataText) + "</div>\n\t\t\t\t\t</div>\n\t\t\t\t";
            }
            else {
                output = vblValue;
            }
        }
        else if (vblValue === 0) {
            output = vblValue;
        }
        else {
            output = vblValue === undefined ? noDataText : vblValue;
        }
        return output;
    },
    // ChatGPT ("Code Formatter" GPTs) version:
    // ------------------------------------------------
    /**
     * Processes a single variable and returns formatted HTML based on the type.
     * @param {CellType} vblValue - The value to be processed.
     * @returns {CellType} - The processed value as HTML or as original value.
     */
    GPTprocessSingleVarHtml: function (vblValue) {
        var noDataText = '<em>(No data)</em>';
        var output;
        if (typeof vblValue === 'object') {
            var type = vblValue.type, title = vblValue.title, _a = vblValue.text, text = _a === void 0 ? noDataText : _a;
            if (type === 'staticAndDynamic') {
                output = "\n\t\t\t\t\t\t\t<div class=\"static-dynamic-container\">\n\t\t\t\t\t\t\t\t\t<div class=\"static-dynamic-title\">" + title + "</div>\n\t\t\t\t\t\t\t\t\t<div class=\"static-dynamic-text\">" + text + "</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t";
                return output;
            }
            return vblValue;
        }
        if (vblValue === 0) {
            return vblValue;
        }
        return vblValue !== null && vblValue !== void 0 ? vblValue : noDataText;
    },
    processTemplateVarHtmlList: function (varList) {
        return varList.map(function (v) { return reportUtils.processSingleVarHtml(v); });
    },
    // createCellWithAdditionalText(title: string, contents: string) {
    // 	const output: DrilldownTableRowCell = {
    // 		type: 'staticAndDynamic',
    // 		title,
    // 		text: contents,
    // 	};
    // 	return output;
    // },
    tableDividerHtml: '<hr class="drilldown-table-divider"/>',
    cellDividerHtml: '<hr class="drilldown-table-cell-divider"/>',
    createDrilldownTableGroup: function (tableList) {
        return tableList.join(reportUtils.tableDividerHtml);
    },
};
