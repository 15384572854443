import { DrilldownTable, defaultTrafficLights, } from "../../../../../shared/interfaces-classes/index";
import { reportUtils } from "../../../../report-utils";
var getGenericGroupMsg = reportUtils.getGenericGroupMsg, processSingleVarHtml = reportUtils.processSingleVarHtml, getCorrectIncorrectByValueComparison = reportUtils.getCorrectIncorrectByValueComparison;
var genericGroupMsg = getGenericGroupMsg();
export var mod4 = {
    'http://ICTPMod4': {
        columns: [
            {
                parent: 'LO1',
                variablename: 'LO1dash',
                desc: 'Assign appropriate tasks to your new coalition implementation team',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'a',
                            'b',
                            'c',
                            'd',
                            'e',
                            'f',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var a = varDict.a, b = varDict.b, c = varDict.c, d = varDict.d, e = varDict.e, f = varDict.f;
                        var rowCorrectList = [
                            '',
                            '',
                            true,
                            true,
                            true,
                            '',
                        ];
                        var rowSelectedVarList = [
                            a,
                            b,
                            c,
                            d,
                            e,
                            f,
                        ];
                        var rowSelectedDataList = rowCorrectList.map(function (rowCorrectItem, idx) { return ({
                            type: 'checkmark',
                            text: processSingleVarHtml(rowSelectedVarList[idx], { returnBlankIfEmpty: true }),
                            className: getCorrectIncorrectByValueComparison(!!rowCorrectItem, !!rowSelectedVarList[idx]),
                        }); });
                        var columns = [
                            {
                                header: 'Coalition implementation team potential tasks',
                                rows: [
                                    'Selecting Triple P interventions to respond to identified community parenting support needs and preferences',
                                    'Setting the community’s shared vision for parenting and family support',
                                    'Generating readiness and buy-in among potential new partners for the community coalition',
                                    'Brokering new partnerships and roles within the coalition for community members',
                                    'Facilitating regular communication between and across individual Triple P service agencies and partners.',
                                    'Provide Triple P training to newly selected practitioners in the coalition',
                                ]
                            },
                            {
                                header: 'Correct tasks',
                                rows: rowCorrectList,
                                allowEmpty: true,
                                type: 'checkmark',
                            },
                            {
                                header: 'Tasks chosen',
                                rows: rowSelectedDataList
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO2',
                variablename: 'LO2dash',
                desc: 'Establish a coalition leadership team ',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'approachA',
                            'approachB',
                            'approachC',
                            'CLTinvitation',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var approachA = varDict.approachA, approachB = varDict.approachB, approachC = varDict.approachC, CLTinvitation = varDict.CLTinvitation;
                        // Table 01 ======================
                        var rowCorrectList = [
                            '',
                            '',
                            true,
                        ];
                        var rowSelectedVarList = [
                            approachA,
                            approachB,
                            approachC,
                        ];
                        var rowSelectedDataList = rowCorrectList.map(function (rowCorrectItem, idx) { return ({
                            type: 'checkmark',
                            text: processSingleVarHtml(rowSelectedVarList[idx], { returnBlankIfEmpty: true }),
                            className: getCorrectIncorrectByValueComparison(!!rowCorrectItem, !!rowSelectedVarList[idx]),
                        }); });
                        var columns01 = [
                            {
                                header: 'Potential approaches to building a coalition leadership team',
                                rows: [
                                    'Go out into the community and gather those who are interested in Triple P. They may or may not be currently involved in your community Triple P coalition, however they display a genuine interest in learning more.',
                                    'Gather all of the high flyers and champions of Triple P in your coalition. They have a great interest in Triple P decision making and display overall success connecting with new partners.',
                                    'Leverage your existing  relationships with leaders in the initial cohort of agencies that invested in the Grand County Triple P coalition.',
                                ]
                            },
                            {
                                header: 'Correct approach',
                                rows: rowCorrectList,
                                allowEmpty: true,
                                type: 'checkmark',
                            },
                            {
                                header: 'Approaches chosen (learners try again if incorrect)',
                                rows: rowSelectedDataList
                            },
                        ];
                        var drilldownTableOpts01 = { varDict: varDict, columns: columns01 };
                        var html01 = new DrilldownTable(drilldownTableOpts01).html;
                        // Table 02 ======================
                        var columns02 = [
                            {
                                header: 'Learner invitation:',
                                rows: [
                                    CLTinvitation
                                ]
                            },
                            {
                                header: 'ICTP suggestion:',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t<p>We have been pleased to partner with your agency to provide Triple P interventions across Grand County and we\u2019re excited about the progress we\u2019ve made together so far.</p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>The next steps for our coalition include the development of a collaborative vision and the establishment of community-wide Triple P strategic plans that address identified community needs. We also need to develop strategies for continuous communication across coalition members and shared measurement systems for data collection, reporting, and quality improvement.</p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>To move these steps forward, we recognize that our (lead) agency and team cannot lead the coalition alone. We need leadership from you and other partners already participating in the Grand County Triple P Coalition.</p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>As an initial investor in Grand County\u2019s Triple P coalition, we would like to request that you and the key Triple P implementation support staff in your service agency join our newly forming coalition leadership team. We hope to get this team and our next steps off the ground soon, so we\u2019d be pleased to connect to discuss more details and answer any questions you might have about your involvement.</p>\n\n\t\t\t\t\t\t\t\t\t<p>\n\t\t\t\t\t\t\t\t\t\tThank You,<br>\n\t\t\t\t\t\t\t\t\t\tGrand County Implementation Team\n\t\t\t\t\t\t\t\t\t</p>\n",
                                ]
                            },
                        ];
                        var drilldownTableOpts02 = { varDict: varDict, columns: columns02 };
                        var html02 = new DrilldownTable(drilldownTableOpts02).html;
                        // ==================================
                        return "\n\t\t\t\t\t\t<h3>Approach/es chosen</h3>\n\t\t\t\t\t\t" + html01 + "\n\t\t\t\t\t\t\n\t\t\t\t\t\t<hr class=\"drilldown-table-divider\" />\n\n\t\t\t\t\t\t<h3>Invitation constructed</h3>\n\t\t\t\t\t\t" + html02 + "\n\t\t\t\t\t\t";
                    }
                }
            },
            {
                parent: 'LO3',
                variablename: 'LO3dash',
                desc: 'Get coalition leadership team started on key tasks',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'nextstepA',
                            'nextstepB',
                            'nextstepC',
                            'challenge1A',
                            'challenge1B',
                            'challenge1C',
                            'challenge2A',
                            'challenge2B',
                            'challenge2C',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var nextstepA = varDict.nextstepA, nextstepB = varDict.nextstepB, nextstepC = varDict.nextstepC, challenge1A = varDict.challenge1A, challenge1B = varDict.challenge1B, challenge1C = varDict.challenge1C, challenge2A = varDict.challenge2A, challenge2B = varDict.challenge2B, challenge2C = varDict.challenge2C;
                        // Table 01 ======================
                        var rowCorrectList01 = [
                            '',
                            true,
                            '',
                        ];
                        var rowSelectedVarList01 = [
                            nextstepA,
                            nextstepB,
                            nextstepC,
                        ];
                        var rowSelectedDataList01 = rowCorrectList01.map(function (rowCorrectItem, idx) { return ({
                            type: 'checkmark',
                            text: processSingleVarHtml(rowSelectedVarList01[idx], { returnBlankIfEmpty: true }),
                            className: getCorrectIncorrectByValueComparison(!!rowCorrectItem, !!rowSelectedVarList01[idx]),
                        }); });
                        var columns01 = [
                            {
                                header: 'What is the next right step?',
                                rows: [
                                    'Send each leadership team member an email, explaining all the responsibilities of the coalition leadership team as well as the vision for parenting and family support across Grand County.',
                                    'Schedule an in-person meeting, bringing together all coalition leadership team members, including Carissa and your coalition implementation team.',
                                    'Send a survey to each leader allowing them to provide input on what the vision of Triple P will look like for Grand County and select Triple P interventions that align with needs previously identified in the community’s needs assessment.',
                                ],
                            },
                            {
                                header: 'Correct approach',
                                rows: rowCorrectList01,
                                allowEmpty: true,
                                type: 'checkmark',
                            },
                            {
                                header: 'Approaches chosen (learners try again if incorrect)',
                                rows: rowSelectedDataList01,
                                allowEmpty: true,
                            }
                        ];
                        var drilldownTableOpts01 = { varDict: varDict, columns: columns01 };
                        var html01 = new DrilldownTable(drilldownTableOpts01).html;
                        // Table 02 ======================
                        var rowCorrectList02 = [
                            true,
                            true,
                            '',
                        ];
                        var rowSelectedVarList02 = [
                            challenge1A,
                            challenge1B,
                            challenge1C,
                        ];
                        var rowSelectedDataList02 = rowCorrectList02.map(function (rowCorrectItem, idx) { return ({
                            type: 'checkmark',
                            text: processSingleVarHtml(rowSelectedVarList02[idx], { returnBlankIfEmpty: true }),
                            // className: getCorrectIncorrectByValueComparison(!!rowCorrectItem, !!rowSelectedVarList02[idx]),
                        }); });
                        var columns02 = [
                            {
                                header: 'Which ideas might be most effective for facilitating the development of a shared vision?',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t<p><strong>Andrea and Kristy will:</strong></p>\n\n\t\t\t\t\t\t\t\t\t<ol>\n\t\t\t\t\t\t\t\t\t\t<li>Define what a shared vision may look like and the importance of creating a such a vision for parenting and family support across Grand County</li>\n\n\t\t\t\t\t\t\t\t\t\t<li>Facilitate team members by asking pairs of team members to discuss their thoughts about key vision components and then work across pairs to identify common themes</li>\n\n\t\t\t\t\t\t\t\t\t\t<li>Use chart paper to capture the common themes from small group discussions</li>\n\n\t\t\t\t\t\t\t\t\t\t<li>Draft a concise but inclusive vision statement</li>\n\n\t\t\t\t\t\t\t\t\t\t<li>Share the draft back to all team members for feedback</li>\n\t\t\t\t\t\t\t\t\t</ol>\n\n\t\t\t\t\t\t\t\t\t",
                                    "\n\t\t\t\t\t\t\t\t\t<p><strong>Andrea and Kristy will:</strong></p>\n\n\t\t\t\t\t\t\t\t\t<ol>\n\t\t\t\t\t\t\t\t\t\t<li>Provide leadership team members with a set of five post-it notes</li>\n\n\t\t\t\t\t\t\t\t\t\t<li>Define what a shared vision may look like and the importance of creating a such a vision for parenting and family support across Grand County</li>\n\n\t\t\t\t\t\t\t\t\t\t<li>Ask team members time to write three-to-five key elements for the Grand County\u2019s vision on separate post-it notes and place their post-it notes on a large sheet of chart paper</li>\n\n\t\t\t\t\t\t\t\t\t\t<li>During a break, Andrea and Kristy group the notes based on common themes and then report the groupings back to the larger group. Summary phrases are created for each group of notes</li>\n\n\t\t\t\t\t\t\t\t\t\t<li>The chart paper is then brought back to each follow up meeting to ground leadership team members in their shared vision</li>\n\t\t\t\t\t\t\t\t\t</ol>\n\n\t\t\t\t\t\t\t\t\t",
                                    "\n\t\t\t\t\t\t\t\t\t<p><strong>To help the leadership team be the most efficient with their meeting time, Andrea and Kristy will:</strong></p>\n\n\t\t\t\t\t\t\t\t\t<ol>\n\t\t\t\t\t\t\t\t\t\t<li>Draft a shared vision based on the earlier exploration and readiness activities covered during Modules 1 and 2</li>\n\n\t\t\t\t\t\t\t\t\t\t<li>Email this draft vision out before the meeting and ask leaders to read over it so they can be prepared to provide feedback during the meeting</li>\n\n\t\t\t\t\t\t\t\t\t\t<li>Review the draft vision statement aloud during the meeting and open a conversation for team members to</li>\n\n\t\t\t\t\t\t\t\t\t\t<li>Modify the draft vision statement as the conversation unfolds</li>\n\n\t\t\t\t\t\t\t\t\t\t<li>Report the final version for approval once the conversation is complete</li>\n\t\t\t\t\t\t\t\t\t</ol>\n\t\t\t\t\t\t\t\t\t",
                                ],
                            },
                            {
                                header: 'Correct approach',
                                rows: rowCorrectList02,
                                allowEmpty: true,
                                type: 'checkmark',
                            },
                            {
                                header: 'Approaches chosen (learners only choose one)',
                                rows: rowSelectedDataList02,
                                allowEmpty: true,
                            }
                        ];
                        var drilldownTableOpts02 = { varDict: varDict, columns: columns02 };
                        var html02 = new DrilldownTable(drilldownTableOpts02).html;
                        // Table 03 ======================
                        var rowCorrectList03 = [
                            true,
                            '',
                            true,
                        ];
                        var rowSelectedVarList03 = [
                            challenge2A,
                            challenge2B,
                            challenge2C,
                        ];
                        var rowSelectedDataList03 = rowCorrectList03.map(function (rowCorrectItem, idx) { return ({
                            type: 'checkmark',
                            text: processSingleVarHtml(rowSelectedVarList03[idx], { returnBlankIfEmpty: true }),
                            // className: getCorrectIncorrectByValueComparison(!!rowCorrectItem, !!rowSelectedVarList03[idx]),
                        }); });
                        var columns03 = [
                            {
                                header: 'How might Andrea, John, and Kristy go about facilitating this decision-making process?',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t<p><strong>Andrea and Kristy will:</strong></p>\n\n\t\t\t\t\t\t\t\t\t<ol>\n\t\t\t\t\t\t\t\t\t\t<li>Prior to the leadership team meeting, John will send out a copy of the results from the Grand County needs assessment he helped carry out in Module 1.</li>\n\n\t\t\t\t\t\t\t\t\t\t<li>At the beginning of the meeting, John will briefly review these results and answer key questions from leadership team members. Andrea and Kristy will next review the Triple P system and make sure leadership team members are clear about intended targets, formats, and intensities across the range of interventions.</li>\n\n\t\t\t\t\t\t\t\t\t\t<li>Coalition leadership team members will then participate in an interactive nomination process to identify and then decide on which initial Triple P interventions to install to start addressing Grand County\u2019s identified needs.</li>\n\t\t\t\t\t\t\t\t\t</ol>\n\t\t\t\t\t\t\t\t\t",
                                    "\n\t\t\t\t\t\t\t\t\t<p><strong>Andrea and Kristy will:</strong></p>\n\n\t\t\t\t\t\t\t\t\t<ol>\n\t\t\t\t\t\t\t\t\t\t<li>Prior to the leadership team meeting, Andrea, John, and Kristy will send out materials reviewing the Triple P system</li>\n\n\t\t\t\t\t\t\t\t\t\t<li>At the beginning of the meeting, Andrea and Kristy will ensure leadership team members are clear about intended targets, formats, and intensities across the range of Triple P interventions</li>\n\n\t\t\t\t\t\t\t\t\t\t<li>Facilitate an interactive nomination process to help leadership team members identify and then decide on which initial Triple P interventions to install to best meet the team\u2019s collective preferences</li>\n\t\t\t\t\t\t\t\t\t</ol>\n\t\t\t\t\t\t\t\t\t",
                                    "\n\t\t\t\t\t\t\t\t\t<p><strong>To help the leadership team be the most efficient with their meeting time, Andrea and Kristy will:</strong></p>\n\n\t\t\t\t\t\t\t\t\t<ol>\n\t\t\t\t\t\t\t\t\t\t<li>Prior to the meeting, Andrea will check in with your coalition\u2019s funders and statewide partners to determine if there are any Triple P interventions that the coalition is required to adopt before moving to other community needs and preferences</li>\n\n\t\t\t\t\t\t\t\t\t\t<li>Andrea will report out this information at the beginning of the leadership team meeting, providing clear rationale from the state about why these Triple P interventions are required</li>\n\n\t\t\t\t\t\t\t\t\t\t<li>Next, Andrea, John, and Kristy will facilitate leadership team members through an interactive nomination and decision-making process using data about community needs to choose additional Triple P interventions to wrap around these required interventions</li>\n\t\t\t\t\t\t\t\t\t</ol>\n\n\t\t\t\t\t\t\t\t\t",
                                ],
                            },
                            {
                                header: 'Correct approach',
                                rows: rowCorrectList03,
                                allowEmpty: true,
                                type: 'checkmark',
                            },
                            {
                                header: 'Approaches chosen (learners only choose one)',
                                rows: rowSelectedDataList03,
                                allowEmpty: true,
                            }
                        ];
                        var drilldownTableOpts03 = { varDict: varDict, columns: columns03 };
                        var html03 = new DrilldownTable(drilldownTableOpts03).html;
                        // ==================================
                        return "\n\t\t\t\t\t\t<h3>CLT First steps</h3>\n\t\t\t\t\t\t" + html01 + "\n\n\t\t\t\t\t\t<hr class=\"drilldown-table-divider\" />\n\n\t\t\t\t\t\t<h3>Managing challenges</h3>\n\t\t\t\t\t\t" + html02 + "\n\n\t\t\t\t\t\t<hr class=\"drilldown-table-divider\" />\n\n\t\t\t\t\t\t" + html03 + "\n\t\t\t\t\t\t\n\t\t\t\t\t\t";
                    }
                },
            },
        ],
        printouts: [
            {
                name: 'Customized Learner Summary',
                profileId: 'ictp-mod-4',
                project: 'ictp',
            }
        ],
    },
};
