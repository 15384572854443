var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { inventory } from './inventory';
import { stages } from './stages';
import { ca } from './ca';
import { pdsa } from './pdsa';
import { cl } from './cl';
import { bt } from './bt';
import { hex } from './hex';
import { colearn } from './colearn';
import { growSustain } from './grow-sustain';
import { uc } from './uc';
import { bc } from './bc';
import { brokering } from './brokering';
import { power } from './power';
import { codesign } from './codesign';
import { assetsNeeds } from './assets-needs';
import { tailoring } from './tailoring';
export var structures = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, inventory), stages), ca), pdsa), cl), bt), hex), colearn), growSustain), uc), bc), brokering), power), codesign), assetsNeeds), tailoring);
