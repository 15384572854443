import { DrilldownTable, defaultTrafficLights, } from "../../../../../shared/interfaces-classes/index";
import { reportUtils } from "../../../../report-utils";
import { lo1DrilldownTable } from "../processing/mod3";
var getGenericGroupMsg = reportUtils.getGenericGroupMsg, getYesNoFromBoolishVar = reportUtils.getYesNoFromBoolishVar;
var genericGroupMsg = getGenericGroupMsg();
export var mod3 = {
    'http://ICTPMod3': {
        columns: [
            {
                parent: 'LO1',
                variablename: 'LO1dash',
                desc: 'Develop a competent coalition implementation team',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'kristytriplep',
                            'kristychange',
                            'kristydata',
                            'kristyimplementation',
                            'edwardtriplep',
                            'edwardchange',
                            'edwarddata',
                            'edwardimplementation',
                            'andreatriplep',
                            'andreachange',
                            'andreadata',
                            'andreaimplementation',
                            'carissatriplep',
                            'carissachange',
                            'carissadata',
                            'carissaimplementation',
                            'johntriplep',
                            'johnchange',
                            'johndata',
                            'johnimplementation',
                            'Triplepoverall',
                            'changeoverall',
                            'dataoverall',
                            'implementationoverall',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        return lo1DrilldownTable(varDict);
                    }
                }
            },
            {
                parent: 'LO2',
                variablename: 'LO2dash',
                desc: 'Manage the day to day operations of the coalition',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'CITcoordinatorincorrect',
                            'CITmembersincorrect',
                            'FTEincorrect',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var CITcoordinatorincorrect = varDict.CITcoordinatorincorrect, CITmembersincorrect = varDict.CITmembersincorrect, FTEincorrect = varDict.FTEincorrect;
                        var columns = [
                            {
                                rows: [
                                    'Number of incorrect tries to identify appropriate CIT coordinator',
                                    'Number of incorrect tries to identify all others as CIT members',
                                    'FTE incorrectly set at below 3 on the first try?',
                                ],
                            },
                            {
                                rows: [
                                    CITcoordinatorincorrect,
                                    CITmembersincorrect,
                                    getYesNoFromBoolishVar(FTEincorrect),
                                ],
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns, hideHeaderRow: true };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO3',
                variablename: 'LO3dash',
                desc: 'Gain leadership involvement',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        return 'See <strong>Customized Learner Summary</strong> for details.';
                    }
                }
            },
        ],
        printouts: [
            {
                name: 'Customized Learner Summary',
                profileId: 'ictp-mod-3',
                project: 'ictp',
            }
        ]
    },
};
