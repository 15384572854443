var MiMTSSFrequenciesPerRank = /** @class */ (function () {
    function MiMTSSFrequenciesPerRank() {
        // The stmt flattener uses the first 2 words of the context activity name for the property key
        // this is where "preTest" and "postTest" derive from here and in the flattened stmts
        this.preTest = 0;
        this.postTest = 0;
    }
    return MiMTSSFrequenciesPerRank;
}());
export { MiMTSSFrequenciesPerRank };
var MiMTSSChartActivity = /** @class */ (function () {
    function MiMTSSChartActivity(variablename) {
        this.id = '';
        this.name = '';
        this.description = '';
        this.variablename = '';
        this.frequencyList = [];
        this.frequencyMap = new Map();
        this.records = [];
        this.id = variablename;
        this.variablename = variablename;
    }
    return MiMTSSChartActivity;
}());
export { MiMTSSChartActivity };
var UserStmtActivityInfo = /** @class */ (function () {
    function UserStmtActivityInfo() {
        this.id = null;
        this.name = null;
        this.description = null;
    }
    return UserStmtActivityInfo;
}());
var UserStmts = /** @class */ (function () {
    function UserStmts() {
        // mbox = null;
        this.records = [];
        this.diffRecords = [];
        this.activityInfo = {
            responsedifference: new UserStmtActivityInfo(),
        };
        this.isComplete = true;
        this.outOfRange = false;
        this.partialResults = false;
        // FIX: TEMP for West Branch
        this.hasOldScores = false;
        this.oldScores = {
            pre: {
                controlpositive: {
                    1: null,
                    2: null,
                },
                controlpunitive: {
                    1: null,
                    2: null,
                },
                nocontrol: {
                    1: null,
                    2: null,
                },
            },
            post: {
                controlpositive: {
                    1: null,
                    2: null,
                },
                controlpunitive: {
                    1: null,
                    2: null,
                },
                nocontrol: {
                    1: null,
                    2: null,
                },
            }
        };
        this.scores = {
            pre: {
                controlpositive: null,
                controlpunitive: null,
                nocontrol: null,
            },
            post: {
                controlpositive: null,
                controlpunitive: null,
                nocontrol: null,
            },
            responsedifference: null,
        };
    }
    return UserStmts;
}());
export { UserStmts };
