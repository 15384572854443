import { defaultTrafficLights, DrilldownTable } from "../../../../../shared/interfaces-classes/index";
import { choiceTextMap } from "../processing/uc-choice-map";
import { reportUtils } from "../../../../report-utils";
var getHtmlFromBoolVars = reportUtils.getHtmlFromBoolVars, getGenericGroupMsg = reportUtils.getGenericGroupMsg, processTemplateVarHtml = reportUtils.processTemplateVarHtmlList;
var genericGroupMsg = getGenericGroupMsg();
export var uc = {
    'http://SISEPUC': {
        columns: [
            {
                parent: 'LO1',
                variablename: 'LO1',
                desc: 'Explain why context is important',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    icon: 'text',
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        response: [
                            'ContextImportance',
                        ]
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var ContextImportance = varDict.ContextImportance;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'Why is it important for implementation support providers to understand the context surrounding the teams they support?',
                                ]
                            },
                            {
                                header: 'NIRN’s answer',
                                rows: [
                                    'Understanding context allows implementation support providers the opportunity to deeply understand the nuances of culture, community values, financial assets and opportunities, political trends and tolerance for learning and change within the interactions with the partners they support. This understanding increases the likelihood that the implementation specialist can employ the most effective strategies to leverage those contexts that might be supportive of implementation and lessen the impact of those that might hinder implementation.'
                                ]
                            },
                            {
                                header: 'Answer provided',
                                rows: processTemplateVarHtml([
                                    ContextImportance
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO2',
                variablename: 'LO2dash',
                desc: 'Solicit information from a variety of stakeholders and perspectives',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        response: [
                            'IDPersA1',
                            'IDPersA2',
                            'IDPersA3',
                            'IDPersA4',
                            'IDPersB1',
                            'IDPersB2',
                            'IDPersB3',
                            'IDPersB4',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var columns = [
                            {
                                header: 'Context',
                                rows: [
                                    "\n                  <p><strong>(Story 1)</strong></p>\n\n                  <p>You are supporting a district implementation team. This team is made up of members who have been on the team for years without turnover and are a good representation of district perspectives. The exception is the new superintendent who is from a district which has been open to new ideas and trying new programs. Bringing that mindset to the new district, the superintendent has asked the team to be part of a state pilot program focused on aligning restorative justice practices within a multi-tiered system of supports (or MTSS).</p> \n                  \n                  <p>As part of this program the district receives funding from the state. In initial attempts to implement MTSS and PBIS, the results were mixed. There are still too many office referrals and suspensions. The team believes adding restorative justice will boost their PBIS implementation and reduce referrals.</p> ",
                                    "\n                  <p><strong>(Story 2)</strong></p>\n\n                  <p>You are supporting a state implementation team. The team is implementing a new data system. Training the state\u2019s schools will start with four state-level trainers who then train district-level trainers. The district-level trainers will train all staff at each of their district schools to use the system, including assigning and providing levels of information access.</p> \n                  \n                  <p>The state has 100 school districts (which vary in size), so each state-level trainer will teach the system to 25 school district-level trainers. Funding for training efforts will be based on district population with the assumption of a class size of 20. The selection criteria for district-level trainers will be identified by each district.</p>",
                                ]
                            },
                            {
                                header: 'Correct answer',
                                rows: [
                                    "\n                    <ul>\n                      <li>Community forums for family members</li>\n                      <li>Students</li>\n                    </ul>\n                  ",
                                    "\n                    <ul>\n                      <li>District trainers</li>\n                      <li>Regional trainers</li>\n                    </ul>\n                  ",
                                ]
                            },
                            {
                                header: 'Answer provided',
                                rows: processTemplateVarHtml([
                                    getHtmlFromBoolVars(varDict, choiceTextMap, [
                                        'IDPersA1',
                                        'IDPersA2',
                                        'IDPersA3',
                                        'IDPersA4',
                                    ], 'list'),
                                    getHtmlFromBoolVars(varDict, choiceTextMap, [
                                        'IDPersB1',
                                        'IDPersB2',
                                        'IDPersB3',
                                        'IDPersB4',
                                    ], 'list'),
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO3a',
                variablename: 'LO3adash',
                desc: 'Identify contextual barriers to implementation',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        response: [
                            'BarrierA1',
                            'BarrierA2',
                            'BarrierA3',
                            'BarrierA4',
                            'BarrierA5',
                            'BarrierB1',
                            'BarrierB2',
                            'BarrierB3',
                            'BarrierB4',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var columns = [
                            {
                                header: 'Context',
                                rows: [
                                    "\n                  <p><strong>(Story 1)</strong></p>\n\n                  <p>You are supporting a district implementation team. These team members have worked together for without turnover and are a good representation of district perspectives. The exception is the new superintendent, who is from a district that has been open to new ideas and trying new programs. Bringing that mindset to this new district, the superintendent asked the team to be part of a state pilot program focused on aligning restorative justice practices within a multi-tiered system of supports (or MTSS).</p>\n                  \n                  </p>As part of this program, the district receives funding from the state. In initial attempts to implement MTSS and PBIS, the results were mixed. There are still too many office referrals and suspensions. The team believes that adding restorative justice will boost their PBIS implementation and reduce referrals.</p>",
                                    "\n                  <p><strong>(Story 2)</strong></p>\n\n                  <p>You are supporting a state implementation team. The team is implementing a new data system. Training the state\u2019s schools will start with four state-level trainers who then train district-level trainers. The district-level trainers will train all staff at each of their district schools to use the system, including assigning and providing levels of information access. </p>\n                  \n                  <p>The state has 100 school districts (which vary in size), so each state-level trainer will teach the system to 25 school district-level trainers. Funding for training efforts will be based on district population with the assumption of a class size of 20. The selection criteria for district-level trainers will be identified by each district.</p>",
                                ]
                            },
                            {
                                header: 'Correct answer',
                                rows: [
                                    "\n                    <ul>\n                      <li>Community context</li>\n                      <li>Learning context</li>\n                    </ul>\n                  ",
                                    "\n                    <ul>\n                      <li>Funding, including human capital</li>\n                      <li>Organizational (region within district; rural vs urban\u2014different organizational structures)</li>\n                    </ul>\n                  ",
                                ]
                            },
                            {
                                header: 'Answer provided',
                                rows: processTemplateVarHtml([
                                    getHtmlFromBoolVars(varDict, choiceTextMap, [
                                        'BarrierA1',
                                        'BarrierA2',
                                        'BarrierA3',
                                        'BarrierA4',
                                        'BarrierA5',
                                    ], 'list'),
                                    getHtmlFromBoolVars(varDict, choiceTextMap, [
                                        'BarrierB1',
                                        'BarrierB2',
                                        'BarrierB3',
                                        'BarrierB4',
                                        'BarrierB5',
                                    ], 'list'),
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO3b',
                variablename: 'LO3bdash',
                desc: 'Identify contextual assets to implementation',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        response: [
                            'AssetsA1',
                            'AssetsA2',
                            'AssetsA3',
                            'AssetsA4',
                            'AssetsA5',
                            'AssetsB1',
                            'AssetsB2',
                            'AssetsB3',
                            'AssetsB4',
                            'AssetsB5',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var columns = [
                            {
                                header: 'Context',
                                rows: [
                                    "\n                  <p><strong>(Story 1)</strong></p>\n\n                  <p>You are supporting a district implementation team. These team members have worked together for without turnover and are a good representation of district perspectives. The exception is the new superintendent, who is from a district that has been open to new ideas and trying new programs. Bringing that mindset to this new district, the superintendent asked the team to be part of a state pilot program focused on aligning restorative justice practices within a multi-tiered system of supports (or MTSS).</p> \n                  \n                  <p>As part of this program, the district receives funding from the state. In initial attempts to implement MTSS and PBIS, the results were mixed. There are still too many office referrals and suspensions. The team believes that adding restorative justice will boost their PBIS implementation and reduce referrals.</p> ",
                                    "\n                  <p><strong>(Story 2)</strong></p>\n\n                  <p>You are supporting a state implementation team. The team is implementing a new data system. Training the state\u2019s schools will start with four state-level trainers who then train district-level trainers. The district-level trainers will train all staff at each of their district schools to use the system, including assigning and providing levels of information access. </p>\n                  \n                  <p>The state has 100 school districts (which vary in size), so each state-level trainer will teach the system to 25 school district-level trainers. Funding for training efforts will be based on district population with the assumption of a class size of 20. The selection criteria for district-level trainers will be identified by each district.</p>\n                  ",
                                ]
                            },
                            {
                                header: 'Correct answer',
                                rows: [
                                    "\n                    <ul>\n                      <li>Funding context</li>\n                      <li>Organizational context</li>\n                      <li>Political context</li>\n                    </ul>\n                  ",
                                    "\n                  <ul>\n                    <li>Funding, including human capital</li>\n                    <li>Community: Values/beliefs of community (& alignment with power dynamics)</li>\n                    <li>Policy context</li>\n                    <li>Organizational (region with district; rural vs urban\u2014different organizational structures)</li>\n                  </ul>\n                  ",
                                ]
                            },
                            {
                                header: 'Answer provided',
                                rows: processTemplateVarHtml([
                                    getHtmlFromBoolVars(varDict, choiceTextMap, [
                                        'AssetsA1',
                                        'AssetsA2',
                                        'AssetsA3',
                                        'AssetsA4',
                                        'AssetsA5',
                                    ], 'list'),
                                    getHtmlFromBoolVars(varDict, choiceTextMap, [
                                        'AssetsB1',
                                        'AssetsB2',
                                        'AssetsB3',
                                        'AssetsB4',
                                    ], 'list'),
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO4',
                variablename: 'LO4dash',
                desc: 'Engage in continuous problem-solving efforts/mitigating strategies',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        // response: [
                        //   'StrategyA',
                        // ],
                        response: [
                            'StrategyA1',
                            'StrategyA2',
                            'StrategyA3',
                            'StrategyA4',
                            'StrategyB1',
                            'StrategyB2',
                            'StrategyB3',
                            'StrategyB4',
                            'StrategyB5',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var StrategyA = varDict.StrategyA;
                        var columns = [
                            {
                                header: 'Context',
                                rows: [
                                    "\n                  <p><strong>(Story 1)</strong></p>\n                  \n                  <p>You are supporting a district implementation team. These team members have worked together for without turnover and are a good representation of district perspectives. The exception is the new superintendent, who is from a district that has been open to new ideas and trying new programs. Bringing that mindset to this new district, the superintendent asked the team to be part of a state pilot program focused on aligning restorative justice practices within a multi-tiered system of supports (or MTSS).</p>\n                  \n                  <p>As part of this program, the district receives funding from the state. In initial attempts to implement MTSS and PBIS, the results were mixed. There are still too many office referrals and suspensions. The team believes that adding restorative justice will boost their PBIS implementation and reduce referrals.</p>",
                                    "\n                  <p><strong>(Story 2)</strong></p>\n\n                  <p>You are supporting a state implementation team. The team is implementing a new data system. Training the state\u2019s schools will start with four state-level trainers who then train district-level trainers. The district-level trainers will train all staff at each of their district schools to use the system, including assigning and providing levels of information access.</p> \n                  \n                  <p>The state has 100 school districts (which vary in size), so each state-level trainer will teach the system to 25 school district-level trainers. Funding for training efforts will be based on district population with the assumption of a class size of 20. The selection criteria for district-level trainers will be identified by each district.</p>\n                  ",
                                ]
                            },
                            {
                                header: 'Correct answer',
                                rows: [
                                    // `<p>You decided to meet with the Superintendent and the School Counselor, as well as a few teachers who are both for and against the new initiative, to discuss the feasibility of moving forward. You plan for this meeting by jotting down a number of powerful questions to determine what is driving their thoughts and feelings about RP. The hope is that the team will agree to share the responses and develop a communication plan that will not only disseminate this information, but solicit feedback from a variety of partners. </p>
                                    // <p>From the data gathered, you facilitate the development of a team representative of critical perspectives (i.e. district staff, teachers, students, family members). Over the next several meetings, you guide the team through the Hexagon Discussion and Analysis Tool to determine if RP is indeed the best fit to meet the needs identified.</p>`,
                                    "\n                  <p><strong>Most accurate to least accurate:</strong></p>\n\n                  <ul>\n                    <li>Powerful Questions</li>\n                    <li>Staff-Student Relationships</li>\n                    <li>Smart Small with Early Adopters</li>\n                    <li>Communication/Dissemination</li>\n                  </ul>\n                  ",
                                    "\n                  <p><strong>Most important:</strong></p>\n\n                  <ul>\n                    <li>Training & Coaching Plan</li>\n                  </ul>\n\n                  <p><strong>Also requires:</strong></p>\n\n                  <ul>\n                    <li>Data & Evaluation</li>\n                    <li>Communication Plan</li>\n                  </ul>\n                  ",
                                ]
                            },
                            {
                                header: 'Answer provided',
                                rows: processTemplateVarHtml([
                                    getHtmlFromBoolVars(varDict, choiceTextMap, [
                                        'StrategyA1',
                                        'StrategyA2',
                                        'StrategyA3',
                                        'StrategyA4',
                                    ], 'list'),
                                    getHtmlFromBoolVars(varDict, choiceTextMap, [
                                        'StrategyB1',
                                        'StrategyB2',
                                        'StrategyB3',
                                        'StrategyB4',
                                        'StrategyB5',
                                        'StrategyB6',
                                    ], 'list'),
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
        ],
    }
};
