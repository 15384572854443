var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { reportUtils } from "../../reports/report-utils";
import { utils } from "../utils";
/////////////////////////////////////////////////////////////
export var defaultTrafficLights = {
    red: {
        min: 0,
        max: 50
    },
    yellow: {
        min: 51,
        max: 75
    },
    green: {
        min: 76,
        max: 100
    },
};
function getCheckmarkValue(cellStr) {
    var correct = utils.getBooleanFromString(cellStr);
    return utils.createCheckmarkIconHtml(correct);
}
export function createCheckmarkCellHtml(cellRaw) {
    if (cellRaw === void 0) { cellRaw = ''; }
    var cellStr = cellRaw.toString();
    var checkVal = getCheckmarkValue(cellStr);
    return "<div class=\"cell-flex icon-only\">" + checkVal + "</div>";
}
/**
 * Get cell value
 * @param cell - Cell value (string, number, boolean, or DrilldownTableRowCell)
 * @param varDict - Variable dictionary for structure
 * @param columnType - Column type (one of RowTypes)
 * @returns cellValue - Cell value
 * @returns cellClass - Cell class
 *
 */
function getCellValue(_a) {
    var cell = _a.cell, varDict = _a.varDict, columnType = _a.columnType, allowEmpty = _a.allowEmpty;
    var cellRaw = cell;
    var cellValue = '';
    var cellClass = columnType;
    var cellIsObject = typeof cell === 'object';
    var cellData = cellIsObject && cell;
    if (cellData && cellData.type === 'checkmark') {
        cellRaw = cellData.text;
    }
    var cellIsString = typeof cellRaw === 'string';
    var cellIsNumber = typeof cellRaw === 'number';
    var cellIsStringOrNum = (cellIsString || cellIsNumber) && !cellIsObject;
    var cellIsBoolean = typeof cellRaw === 'boolean';
    var rowType = (cellIsObject
        ? cellData === null || cellData === void 0 ? void 0 : cellData.type
        : columnType) || 'text';
    if (cellIsBoolean && columnType === 'checkmark') {
        rowType = 'checkmark';
    }
    if (cellIsStringOrNum && !columnType) {
        cellValue = cellRaw;
    }
    cellClass = cellData && cellData.className
        ? cellData.className
        : rowType || columnType;
    switch (rowType) {
        case 'text':
            cellValue = cellIsString ? cellRaw.trim() : cellRaw;
            break;
        case 'list':
            var listItemHtml = cellData.listItems
                .map(function (listItem) { return "<li>" + listItem + "</li>"; })
                .join('');
            cellValue = "<ul>" + listItemHtml + "</ul>";
            break;
        case 'variable':
            var processedVal = reportUtils.processSingleVarHtml(varDict[cellData.variable]);
            varDict && (cellValue = processedVal);
            break;
        case 'checkmark':
            if (cellIsString || cellIsBoolean) {
                // assume boolean in text?
                var cellStr = cellRaw.toString();
                if (cellStr.toLowerCase().includes('no data')) {
                    cellValue = cellRaw;
                }
                else {
                    cellValue = cellStr === '' && allowEmpty
                        ? ''
                        : createCheckmarkCellHtml(cellStr);
                }
            }
            else if (cellIsNumber) {
                cellValue = createCheckmarkCellHtml(!!cellRaw);
            }
            else {
                console.warn('Unhandled checkmark cell type', cellRaw);
            }
            break;
        // These will drive the cellRaw class "column-correct" or "column-incorrect"
        case 'correct':
        case 'incorrect':
            cellValue = cellRaw;
            break;
        default:
            cellValue = cellRaw;
            break;
    }
    return { cellValue: cellValue, cellClass: cellClass };
}
function getCellIdxListToAddRowspans(rowspanList) {
    if (rowspanList === void 0) { rowspanList = []; }
    var cellIdxListToAddRowspans = [];
    rowspanList.forEach(function (spanLength, cellIdx) {
        if (cellIdx === 0) {
            cellIdxListToAddRowspans.push({
                cellIdx: cellIdx,
                spanLength: spanLength,
            });
        }
        else {
            var prevIdx = cellIdx - 1;
            var newVal = cellIdxListToAddRowspans[prevIdx].cellIdx + rowspanList[prevIdx];
            cellIdxListToAddRowspans.push({
                cellIdx: newVal,
                spanLength: spanLength,
            });
        }
    });
    return cellIdxListToAddRowspans;
}
/**
 * Drilldown Table
 * @description Creates a drilldown table
 * @param varDict - Variable dictionary
 * @param columns - Array of columns
 * @returns html - HTML string
 */
var DrilldownTable = /** @class */ (function () {
    function DrilldownTable(_a) {
        var _b = _a.varDict, varDict = _b === void 0 ? {} : _b, columns = _a.columns, title = _a.title, subtitle = _a.subtitle, hideHeaderRow = _a.hideHeaderRow, textCenter = _a.textCenter;
        this.html = '';
        var tHeadHtml = '';
        var tBodyHtml = '';
        var tBodyRowMap = new Map();
        columns.forEach(function (_a) {
            var header = _a.header, rowspanList = _a.rowspanList, rows = _a.rows, _b = _a.type, columnType = _b === void 0 ? 'text' : _b, allowEmpty = _a.allowEmpty;
            !hideHeaderRow && (tHeadHtml += "<th>" + header + "</th>");
            var hasRowSpan = rowspanList && rowspanList.length > 0;
            var cellIdxListToAddRowspans = hasRowSpan ? getCellIdxListToAddRowspans(rowspanList) : [];
            rows.forEach(function (cell, cellIdx) {
                var cellProcessed = allowEmpty ? cell : reportUtils.processSingleVarHtml(cell);
                var _a = getCellValue({
                    cell: cellProcessed,
                    varDict: varDict,
                    columnType: columnType,
                    allowEmpty: allowEmpty,
                }), cellValue = _a.cellValue, cellClass = _a.cellClass;
                var tbodyRow = tBodyRowMap.get(cellIdx) || [];
                if (hasRowSpan) {
                    var addRowspan = cellIdxListToAddRowspans.find(function (_a) {
                        var idx = _a.cellIdx;
                        return idx === cellIdx;
                    });
                    if (addRowspan) {
                        var rowspanAttr = hasRowSpan && "rowspan=\"" + addRowspan.spanLength + "\"";
                        tbodyRow.push("\n\t\t\t\t\t\t\t<td class=\"column-" + (cellClass || columnType) + "\" " + (rowspanAttr || '') + ">\n\t\t\t\t\t\t\t\t<p>" + cellValue + "</p>\n\t\t\t\t\t\t\t</td>\n\t\t\t\t\t\t");
                        tBodyRowMap.set(cellIdx, tbodyRow);
                    }
                    return;
                }
                tbodyRow.push("\n\t\t\t\t\t<td class=\"column-" + (cellClass || columnType) + "\">\n\t\t\t\t\t\t<p>" + cellValue + "</p>\n\t\t\t\t\t</td>\n\t\t\t\t");
                tBodyRowMap.set(cellIdx, tbodyRow);
            });
        });
        __spreadArray([], __read(tBodyRowMap.entries())).sort().forEach(function (_a) {
            var _b = __read(_a, 2), _cellIdx = _b[0], cellList = _b[1];
            tBodyHtml += "<tr>" + cellList.join('') + "</tr>";
        });
        var headerRow = hideHeaderRow ? '' : "<thead><tr>" + tHeadHtml + "</tr></thead>";
        var titleHtml = title ? "<h3>" + title + "</h3>" : '';
        var subtitleHtml = subtitle ? "<p>" + subtitle + "</p>" : '';
        var tableClassList = ['drilldown-table'];
        textCenter && tableClassList.push('table-center');
        this.html = ("\n\t\t\t" + titleHtml + "\n\t\t\t" + subtitleHtml + "\n\t\t\t<table class=\"" + tableClassList.join(' ') + "\">\n\t\t\t\t" + headerRow + "\n\t\t\t\n\t\t\t\t<tbody>" + tBodyHtml.trim() + "</tbody>\n\t\t\t</table>\n\t\t").trim();
    }
    return DrilldownTable;
}());
export { DrilldownTable };
