import { DrilldownTable, defaultTrafficLights, } from "../../../../../shared/interfaces-classes/index";
import { choiceTextMap } from "../processing/bc-choice-map";
import { reportUtils } from "../../../../report-utils";
var getHtmlFromBoolVars = reportUtils.getHtmlFromBoolVars, processTemplateVarHtml = reportUtils.processTemplateVarHtmlList, getGenericGroupMsg = reportUtils.getGenericGroupMsg, processSingleVarHtml = reportUtils.processSingleVarHtml;
var genericGroupMsg = getGenericGroupMsg();
export var bc = {
    'http://SISEPBuildingCapacity': {
        columns: [
            {
                parent: 'LO1',
                variablename: 'LO1dash',
                desc: 'Assessed each of the four types of capacities',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        response: [
                            'InnovationChoice',
                            'RCA_Adapt',
                            'Criteria_Adapt',
                            'Interviews_Adapt',
                            'Lookfors_Adapt',
                            'Readiness_Adapt',
                            'EfficientChoice',
                            'InnovationText',
                            'AnalyticText',
                            'AdaptiveText',
                            'GeneralText',
                            'NextStepLO1',
                            'RCA_Source',
                            'Criteria_Source',
                            'Interviews_Source',
                            'Readiness_Source',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var InnovationChoice = varDict.InnovationChoice, EfficientChoice = varDict.EfficientChoice, InnovationText = varDict.InnovationText, AnalyticText = varDict.AnalyticText, AdaptiveText = varDict.AdaptiveText, GeneralText = varDict.GeneralText, NextStepLO1 = varDict.NextStepLO1;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'Which tools assess innovation-specific capacity?',
                                    'Which tools assess adaptive capacity?',
                                    'Which tool is the most efficient way to assess all 4 types of capacity?',
                                    'Assess the team’s capacity',
                                    'After you have reviewed the Team Look-fors to address the adaptive capacity, what is your next step to support the team?',
                                    'What data sources would you look at next to assist you in designing your supports?',
                                ]
                            },
                            {
                                header: 'NIRN\'s answer',
                                // <li>Regional Capacity Assessment</li>
                                rows: [
                                    "<ul>\n                    <li>Interviews</li>\n                  </ul>\n                  ",
                                    "<ul>\n                    <li>Interviews</li>\n                    <li>Team Look-fors</li>\n                    <li>Context-Specific Readiness Tool</li>\n                  </ul>\n                  ",
                                    "<ul>\n                    <li>Team Look-fors</li>\n                  </ul>\n                    ",
                                    // <strong>NIRN’s answers:</strong>
                                    "\n                  <p><strong>Innovation-specific:</strong> During the last Implementation Team Meeting, it is clear that while the focus of equity is identified, the team has not yet selected an innovation to address the need.</p>\n\n                  <p><strong>Analytic:</strong> The Team Look-fors indicated a need to address teaming structures and improvement cycle protocols.</p>  \n\n                  <p><strong>Adaptive:</strong> Per section \u201CManaging adaptive Challenges\u201D on the Team Look-fors, the implementation team needs to address engaging all voices, communication, and their way of work.</p>\n\n                  <p><strong>General:</strong> After the last implementation team meeting, it is clear that while the team is newly forming and addressing their analytic capacity, there is a need to select an innovation and address adaptive capacity</p>\n                  ",
                                    "Cross-check the results with other capacity assessment methods",
                                    "<ul>\n                    <li>Team Member Selection Criteria</li>\n                    <li>Context-Specific Readiness Tool</li>\n                  </ul>\n                  ",
                                ]
                            },
                            {
                                header: 'Answer provided',
                                rows: processTemplateVarHtml([
                                    // InnovationChoice,
                                    "<ul>\n                    <li>" + processSingleVarHtml(InnovationChoice) + "</li>\n                  </ul>\n                  ",
                                    getHtmlFromBoolVars(varDict, choiceTextMap, [
                                        'RCA_Adapt',
                                        'Criteria_Adapt',
                                        'Interviews_Adapt',
                                        'Lookfors_Adapt',
                                        'Readiness_Adapt',
                                    ], 'list'),
                                    "<ul>\n                    <li>" + processSingleVarHtml(EfficientChoice) + "</li>\n                  </ul>\n                  ",
                                    "\n                  <p><strong>Innovation-specific:</strong> " + InnovationText + "</p>\n                  <p><strong>Analytic:</strong> " + AnalyticText + "</p>\n                  <p><strong>Adaptive:</strong> " + AdaptiveText + "</p>\n                  <p><strong>General:</strong> " + GeneralText + "</p>\n                  ",
                                    NextStepLO1,
                                    getHtmlFromBoolVars(varDict, choiceTextMap, [
                                        'RCA_Source',
                                        'Criteria_Source',
                                        'Interviews_Source',
                                        'Readiness_Source',
                                    ], 'list'),
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO2',
                variablename: 'LO2dash',
                desc: 'Identified and helped to change and/or implement organizational processes and structures required to develop capacity for implementation (e.g., human resources, technology)',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        response: [
                            'FocusText',
                            'NextStepLO2',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var FocusText = varDict.FocusText, NextStepLO2 = varDict.NextStepLO2;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'Where do you want to focus your implementation support?',
                                    'What is your next step with the team?',
                                ]
                            },
                            {
                                header: 'NIRN\'s answer',
                                rows: [
                                    'Complete “Guidance for Engaging Critical Perspectives” with the Team',
                                    'At the next team meeting you bring up the question “Are all the right voices at the table to have this discussion?” and have the team reflect on their recent RCA and their selection criteria.',
                                ]
                            },
                            {
                                header: 'Answer provided',
                                rows: processTemplateVarHtml([
                                    FocusText,
                                    NextStepLO2,
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO4',
                variablename: 'LO4dash',
                desc: 'Promoted collaboration and partnerships as a way to build and expand capacities (e.g. through the use of community-academic partnerships, learning collaboratives, etc.)',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    icon: 'text',
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        response: [
                            'LO4a',
                            'LO4b',
                            'LO4c',
                            'LO4d',
                            'LO4e',
                            'LO4f',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var LO4a = varDict.LO4a, LO4b = varDict.LO4b, LO4c = varDict.LO4c, LO4d = varDict.LO4d, LO4e = varDict.LO4e, LO4f = varDict.LO4f;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'Who drafted the Give/Get Document?',
                                    'What are the benefits of collecting feedback from an executive sponsor before taking it to a team?',
                                    'What do you see about this process and tool that connects to building capacity?',
                                    'What did you notice about the questions the Implementation Support Practitioner asked the executive sponsor?',
                                    'How were the concerns of the executive sponsor addressed?',
                                    'Why were some items tabled for the team? How does this relate to building capacity?',
                                ]
                            },
                            {
                                header: 'NIRN\'s answer',
                                rows: [
                                    'The development and creation of a give/get document is co-designed between the support agency and the organization receiving services.',
                                    'The benefits of collecting feedback from the executive sponsor first include creating collaborative consensus, cultivating leadership, and ensuring all items meet the agency\'s needs.',
                                    'By co-designing the expectations and supports in the give/get document with the executive sponsor and the executive or implementation team, general capacity is being built for a common understanding of the work.',
                                    'The questions focused on building an understanding of the give/get document and allowed time for processing the information.',
                                    'The Implementation Support Practitioner lifted the concerns and encouraged the executive sponsor to discuss the items with the implementation team.',
                                    'By tabling the items for the team meeting, it ensures all members understand the expectations and supports in place and allows them the opportunity to provide feedback.',
                                ]
                            },
                            {
                                header: 'Answer provided',
                                rows: processTemplateVarHtml([
                                    LO4a,
                                    LO4b,
                                    LO4c,
                                    LO4d,
                                    LO4e,
                                    LO4f,
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
        ],
        specialGroupVarDictFns: {},
    }
};
