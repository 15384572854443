export var polyfills = {
    init: function (cb) {
        var isIe = this.checkIe();
        if (isIe) {
            console.log('isIe:', isIe);
            this.loadPolyfillScript(cb);
        }
        else {
            cb();
        }
    },
    checkIe: function () {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }
        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }
        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }
        // other browser
        return false;
    },
    loadPolyfillScript: function (cb) {
        var id = 'polyfills';
        var src = "https://polyfill.io/v3/polyfill.min.js?callback=polyfillLoaded&features=es2015";
        if (document.getElementById(id)) {
            return;
        }
        var s = document.createElement('script');
        s.src = src;
        s.id = id;
        s.type = "text/javascript";
        s.async = false;
        document.body.appendChild(s);
        s.onload = function (e) {
            console.log(id + ' loaded:', src);
            if (cb) {
                cb();
            }
        };
    },
};
