export var xapiIds = {
    verbMap: {
        "scored": "http://adlnet.gov/expapi/verbs/scored",
        "answered": "http://adlnet.gov/expapi/verbs/answered",
        "completed": "http://adlnet.gov/expapi/verbs/completed",
        "passed": "http://adlnet.gov/expapi/verbs/passed",
        "failed": "http://adlnet.gov/expapi/verbs/failed",
        "watched": "http://activitystrea.ms/schema/1.0/watch",
        "paused": "http://id.tincanapi.com/verb/paused",
        "played": "http://activitystrea.ms/schema/1.0/play",
        "rated": "http://id.tincanapi.com/verb/rated",
        "viewed": "http://id.tincanapi.com/verb/viewed",
        "downloaded": "http://id.tincanapi.com/verb/downloaded",
        "attended": "http://adlnet.gov/expapi/verbs/attended",
        "attempted": "http://adlnet.gov/expapi/verbs/attempted",
        "interacted": "http://adlnet.gov/expapi/verbs/interacted",
        "imported": "http://adlnet.gov/expapi/verbs/imported",
        "created": "http://adlnet.gov/expapi/verbs/created",
        "shared": "http://adlnet.gov/expapi/verbs/shared",
        "voided": "http://adlnet.gov/expapi/verbs/voided",
        "asked": "http://adlnet.gov/expapi/verbs/asked",
        "commented": "http://adlnet.gov/expapi/verbs/commented",
        "exited": "http://adlnet.gov/expapi/verbs/exited",
        "initialized": "http://adlnet.gov/expapi/verbs/initialized",
        "launched": "http://adlnet.gov/expapi/verbs/launched",
        "mastered": "http://adlnet.gov/expapi/verbs/mastered",
        "progressed": "http://adlnet.gov/expapi/verbs/progressed",
        "registered": "http://adlnet.gov/expapi/verbs/registered",
        "preferred": "http://adlnet.gov/expapi/verbs/preferred",
        "responded": "http://adlnet.gov/expapi/verbs/responded",
        "resumed": "http://adlnet.gov/expapi/verbs/resumed",
        "suspended": "http://adlnet.gov/expapi/verbs/suspended",
        "terminated": "http://adlnet.gov/expapi/verbs/terminated",
        "experienced": "http://adlnet.gov/expapi/verbs/experienced",
    },
    activityTypeMap: {
        "note": "http://activitystrea.ms/schema/1.0/note",
        "assessment": "http://adlnet.gov/expapi/activities/assessment",
        "question": "http://adlnet.gov/expapi/activities/question",
        "video": "http://activitystrea.ms/schema/1.0/video",
        "document": "http://id.tincanapi.com/activitytype/document",
        "scenario": "http://id.tincanapi.com/activitytype/scenario",
        "course": "http://adlnet.gov/expapi/activities/course",
        "module": "http://adlnet.gov/expapi/activities/module",
        "interaction": "http://adlnet.gov/expapi/activities/interaction",
        "meeting": "http://adlnet.gov/expapi/activities/meeting",
        "objective": "http://adlnet.gov/expapi/activities/objective",
        "simulation": "http://adlnet.gov/expapi/activities/simulation",
        "performance": "http://adlnet.gov/expapi/activities/performance"
    }
};
