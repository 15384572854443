export var tableStructTestData = [
    {
        title: 'Create Lesson Plan',
        blocks: [
            {
                title: 'Individual',
                tables: [
                    {
                        title: 'Create',
                        columns: [
                            {
                                header: 'Element',
                                rows: [
                                    'Get student attention',
                                    'Present a clear opportunity',
                                    'Use varied instructions',
                                ],
                            },
                            {
                                header: 'Score',
                                rows: [
                                    '(variables here)',
                                    '(variables here)',
                                ],
                            },
                            {
                                header: 'Choice made',
                                rows: [
                                    '(variables here)',
                                    '(variables here)',
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        title: 'Implement Lesson Plan',
        blocks: [
            {
                title: 'Report',
                tables: [
                    {
                        title: 'Create an Opportunity',
                        columns: [
                            {
                                header: 'Components',
                                rows: [
                                    'Get student attention',
                                    'Presents clear opportunities',
                                    'Uses varied instructions',
                                ],
                            },
                            {
                                header: 'Score (0 or 1)',
                                rows: [
                                    '(variables here)',
                                    '(variables here)',
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
// =============================================
export var tableStructTest = [
    {
        title: 'Table Section Title',
        blocks: [
            {
                title: 'Table Block Title',
                tables: [
                    {
                        title: 'Table Title',
                        columns: [
                            {
                                header: 'Table Header 1',
                                rows: [
                                    'Table Data 1a',
                                    'Table Data 1b',
                                ],
                            },
                            {
                                header: 'Table Header 2',
                                rows: [
                                    'Table Data 2a',
                                    'Table Data 2b',
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
