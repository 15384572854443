import { DrilldownTable } from "../../../../../shared/interfaces-classes/index";
import { reportUtils } from "../../../../report-utils";
var processTemplateVarHtml = reportUtils.processTemplateVarHtmlList, getGenericGroupMsg = reportUtils.getGenericGroupMsg;
var genericGroupMsg = getGenericGroupMsg();
export var inventory = {
    'http://SISEPInventory': {
        columns: [
            {
                parent: 'LO1_1',
                variablename: 'LO1_1',
                desc: 'Gather appropriate information',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 50
                    },
                    yellow: {
                        min: 51,
                        max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                            stringifyVarPrefix: 'var',
                        },
                        success: [
                            'option1chosen',
                            'option2chosen',
                            'option3chosen',
                            'option4chosen',
                            'option5chosen',
                            'option6chosen',
                        ],
                        response: [
                            '0213text',
                            '0214text',
                            'insufficientincorrect',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var varoption1chosen = varDict.varoption1chosen, varoption2chosen = varDict.varoption2chosen, varoption3chosen = varDict.varoption3chosen, varoption4chosen = varDict.varoption4chosen, varoption5chosen = varDict.varoption5chosen, varoption6chosen = varDict.varoption6chosen, var0213text = varDict.var0213text, var0214text = varDict.var0214text, varinsufficientincorrect = varDict.varinsufficientincorrect;
                        var columns01 = [
                            {
                                header: 'Question',
                                rows: [
                                    "Option 1",
                                    "Option 2",
                                    "Option 3",
                                    "Option 4",
                                    "Option 5",
                                    "Option 6",
                                ]
                            },
                            {
                                header: "Select the non-examples of initiatives to include on Strong County\u2019s inventory.<br>\n                <em>Select all that apply.</em>",
                                rows: [
                                    'East Elementary School identifies a math-related role.',
                                    'West Elementary identifies a STEM-related funding source',
                                    'North Elementary has no math/disabilities practice',
                                    'South Elementary identifies a practice focused on increasing students reasoning skills about relationships and connections in mathematics',
                                    'West Elementary identifies a program that uses visual representations of math quantities and relationships.',
                                    'North Elementary identifies a curriculum using metacognitive strategies for math problem solving skills.',
                                ]
                            },
                            {
                                header: 'Correct?',
                                // type: 'checkmark',
                                rows: [
                                    'Correct',
                                    'Correct',
                                    'Correct',
                                    'Incorrect',
                                    'Incorrect',
                                    'Incorrect',
                                ]
                            },
                            {
                                header: 'Chosen?',
                                type: 'checkmark',
                                rows: processTemplateVarHtml([
                                    varoption1chosen.toString(),
                                    varoption2chosen.toString(),
                                    varoption3chosen.toString(),
                                    varoption4chosen.toString(),
                                    varoption5chosen.toString(),
                                    varoption6chosen.toString(),
                                ])
                            },
                        ];
                        // ===========================
                        var columns02 = [
                            {
                                header: 'Question',
                                rows: [
                                    "<p>\u201CWe have an instructional lead in each building with .50 FTE time allocated to supporting math instruction.\u201D</p>\n                  <p>What is your next best step?</p>\n                  ",
                                    "<p>\u201CEach teacher individually identifies their resources.\u201D</p>\n                  <p>What is your next best step?</p>\n                  ",
                                ]
                            },
                            {
                                header: 'Correct answer',
                                rows: [
                                    'Call to follow up',
                                    'Remove this school from the inventory',
                                ]
                            },
                            {
                                header: 'Answer provided',
                                rows: [
                                    var0213text,
                                    var0214text,
                                ]
                            },
                        ];
                        // ===========================
                        var columns03 = [
                            {
                                header: 'Number of areas of insufficient data',
                                rows: [
                                    "<p>Total of 6 areas of insufficient data:</p>\n                  <ul>\n                    <li>Blank: 2</li>\n                    <li>\u201Cunknown\u201D: (2)</li>\n                    <li>\u201Cnone\u201D: (1)</li>\n                    <li>\u201Csome progress for some students\u201D: (1)</li>\n                  </ul>\n                  "
                                ]
                            },
                            {
                                header: 'Number identified',
                                rows: [
                                    varinsufficientincorrect,
                                ]
                            },
                        ];
                        // ===========================
                        var drilldownTableOpts01 = { varDict: varDict, columns: columns01 };
                        var drilldownTableOpts02 = { varDict: varDict, columns: columns02 };
                        var drilldownTableOpts03 = { varDict: varDict, columns: columns03 };
                        var html01 = new DrilldownTable(drilldownTableOpts01).html;
                        var html02 = new DrilldownTable(drilldownTableOpts02).html;
                        var html03 = new DrilldownTable(drilldownTableOpts03).html;
                        var html = "\n              <div class=\"drilldown-section\">\n                <h3>LO1_1a</h3>\n                <div class=\"drilldown-section-subtitle\"><em>Identifies nonexamples</em></div>\n                <div>" + html01 + "</div>\n              </div>\n\n              <div class=\"drilldown-section\">\n                <h3>LO1_1b</h3>\n                <div class=\"drilldown-section-subtitle\"><em>Correctly follows up with data provided</em></div>\n                <div>" + html02 + "</div>\n              </div>\n\n              <div class=\"drilldown-section\">\n                <h3>LO1_1c</h3>\n                <div class=\"drilldown-section-subtitle\"><em>Identifies areas of insufficient data</em></div>\n                <div>" + html03 + "</div>\n              </div>\n            ";
                        return html;
                    }
                }
            },
            {
                parent: 'LO1_2',
                variablename: 'LO1_2',
                desc: 'Identify inventory components',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 50
                    },
                    yellow: {
                        min: 51,
                        max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                            stringifyVarPrefix: 'var',
                        },
                        response: [
                            'understandcomponents',
                            '0211text',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var varunderstandcomponents = varDict.varunderstandcomponents, var0211text = varDict.var0211text;
                        var columns01 = [
                            {
                                header: 'Number of data points to match with Initiative Inventory headings',
                                rows: [
                                    "<p>Total of 5 Initiative Inventory headings:</p>\n                  <ul>\n                    <li>Expected Outcome</li>\n                    <li>Target Population and Scale of Intended Use</li>\n                    <li>Measures of Outcomes</li>\n                    <li>Evidence of Outcomes (What has happened thus far?)</li>\n                    <li>Measures of Implementation</li>\n                  </ul>\n                  "
                                ]
                            },
                            {
                                header: 'Number identified',
                                rows: processTemplateVarHtml([
                                    varunderstandcomponents,
                                ])
                            }
                        ];
                        // ======================
                        var columns02 = [
                            {
                                header: 'Question',
                                rows: [
                                    "<p>Which of these summaries is the best to include here?</p>\n                  <em>(Evidence of Outcomes/What has happened thus far?)</em>\n                  ",
                                ]
                            },
                            {
                                header: 'Correct answer',
                                rows: [
                                    "<p><strong>32%</strong> of our students demonstrated growth from fall to spring. <strong>40%</strong> of AA students demonstrated growth, <strong>25%</strong> of our ELL demonstrated growth, and <strong>10%</strong> of our SWD demonstrated growth.</p> \n                  <p>Overall <strong>62%</strong> of all students met spring goal.</p>",
                                ]
                            },
                            {
                                header: 'Answer provided',
                                rows: processTemplateVarHtml([
                                    var0211text
                                ])
                            }
                        ];
                        // ======================
                        var drilldownTableOpts01 = { varDict: varDict, columns: columns01 };
                        var drilldownTableOpts02 = { varDict: varDict, columns: columns02 };
                        var html01 = new DrilldownTable(drilldownTableOpts01).html;
                        var html02 = new DrilldownTable(drilldownTableOpts02).html;
                        var html = "\n              <div class=\"drilldown-section\">\n                <h3>LO1_2a</h3>\n                <div class=\"drilldown-section-subtitle\"><em>Identifies nonexamples</em></div>\n                <div>" + html01 + "</div>\n              </div>\n\n              <div class=\"drilldown-section\">\n                <h3>LO1_2b</h3>\n                <div class=\"drilldown-section-subtitle\"><em>Provides appropriate summary</em></div>\n                <div>" + html02 + "</div>\n              </div>\n\n            ";
                        return html;
                    }
                }
            },
            {
                parent: 'LO1_3',
                variablename: 'LO1_3',
                desc: 'Provides appropriate summary information',
                drilldown: {
                    icon: 'text',
                    vars: {
                        opts: {
                            prefix: false
                        },
                        response: [
                            'writtensummary',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var writtensummary = varDict.writtensummary;
                        var columns = [
                            {
                                header: 'When asked to summarize this information…',
                                rows: [
                                    "\n                  <ul>\n                    <li><strong>FTE:</strong> 80 full time teachers ($10, 000,000)</li>\n                    <li><strong>FTE:</strong> 4 Principals ($700,000)</li>\n                    <li><strong>FTE:</strong> 4 instructional supports at .25 FTE each</li>\n                    <li><strong>Building costs:</strong> $3 million</li>\n                    <li><strong>Transportation:</strong> $1.5 million</li>\n                    <li><strong>Materials:</strong> $30,000 in curriculum resources</li>\n                    <li><strong>Training Costs:</strong> $15,000</li>\n                  </ul>\n                  \n      \t\t\t\t\t\t",
                                ]
                            },
                            {
                                header: '…constructed this summary:',
                                rows: processTemplateVarHtml([
                                    writtensummary,
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO2',
                variablename: 'LO2',
                desc: 'Conduct appropriate data analysis',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 50
                    },
                    yellow: {
                        min: 51,
                        max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        response: [
                            'analysiscorrect',
                            'analysisincorrect',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var analysiscorrect = varDict.analysiscorrect, analysisincorrect = varDict.analysisincorrect;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    "<p>Now, how should you analyze this data?</p>\n                  <em>Select all that apply. </em>\n                  "
                                ]
                            },
                            {
                                header: 'Answers (all are correct)',
                                rows: [
                                    "<ul>\n                    <li>Review data in team discussion</li>\n                    <li>Use guiding questions (e.g., notice & wonder)</li>                 \n                    <li>Determine decision-making process (e.g., consensus, veto power)</li>\n                  </ul>\n                  "
                                ]
                            },
                            {
                                header: 'All answers were correctly chosen?',
                                rows: processTemplateVarHtml([
                                    analysiscorrect,
                                ])
                            },
                            {
                                header: 'Number of tries to get correct answer',
                                rows: processTemplateVarHtml([
                                    analysisincorrect,
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO3',
                variablename: 'LO3',
                desc: 'Use results of data analysis for decision-making and action planning',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 50
                    },
                    yellow: {
                        min: 51,
                        max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        response: [
                            'action',
                            'planstep1',
                            'planstep2',
                            'planstep3',
                            'actionreason',
                            'planstep1reason',
                            'planstep2reason',
                            'planstep3reason',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var action = varDict.action, planstep1 = varDict.planstep1, planstep2 = varDict.planstep2, planstep3 = varDict.planstep3, actionreason = varDict.actionreason, planstep1reason = varDict.planstep1reason, planstep2reason = varDict.planstep2reason, planstep3reason = varDict.planstep3reason;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'Of all these necessary next steps, which should be first?',
                                    'Of all these necessary next steps, which should be first?',
                                    'What is the best way to align?',
                                    'What activities are needed to deselect?',
                                ]
                            },
                            {
                                header: 'Correct answer',
                                rows: [
                                    'Selection',
                                    'Identify and review any additional data sources to confirm impact of the program and evidence of implementation (e.g., what fidelity measure can be used?)',
                                    'Analyze each practice’s core components or essential functions for similarities, differences, overlap, duplications, or conflict.',
                                    'Meet with sample of principals, teachers, and other staff to gain feedback and thoughts on the implementation and implications of de-selection and de-implementing the practice.',
                                ]
                            },
                            {
                                header: 'Answer chosen',
                                rows: processTemplateVarHtml([
                                    action,
                                    planstep1,
                                    planstep2,
                                    planstep3,
                                ])
                            },
                            {
                                header: 'Reason provided',
                                rows: processTemplateVarHtml([
                                    actionreason,
                                    planstep1reason,
                                    planstep2reason,
                                    planstep3reason,
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
        ],
        specialGroupVarDictFns: {},
    }
};
