import { DrilldownTable, defaultTrafficLights, } from "../../../../../shared/interfaces-classes/index";
import { choiceTextMap } from "../processing/power-choice-map";
import { reportUtils } from "../../../../report-utils";
var getHtmlFromBoolVars = reportUtils.getHtmlFromBoolVars, processTemplateVarHtml = reportUtils.processTemplateVarHtmlList, getGenericGroupMsg = reportUtils.getGenericGroupMsg;
var genericGroupMsg = getGenericGroupMsg();
export var power = {
    'http://SISEPPower': {
        columns: [
            {
                parent: 'LO1',
                variablename: 'LO1dash',
                desc: 'Manage your own power as an ISP',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    icon: 'text',
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        response: [
                            'PowerID',
                            'PowerImpact',
                            'PowerShare',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var PowerID = varDict.PowerID, PowerImpact = varDict.PowerImpact, PowerShare = varDict.PowerShare;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'What privilege and power do I bring to this work? What biases do I bring?',
                                    'How might my position, authority, privilege, and power impact the group dynamic and my responsibilities in this work?',
                                    'How can I share authority and decision-making with the team I am supporting? How will I know this is happening as intended?',
                                ]
                            },
                            {
                                header: 'Answer provided',
                                rows: processTemplateVarHtml([
                                    PowerID,
                                    PowerImpact,
                                    PowerShare,
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO2',
                variablename: 'LO2dash',
                desc: 'Identify informal leaders & their influence on team dynamics, decision-making processes',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        response: [
                            'LO2TextQ1',
                            'LO2TextQ2',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var LO2TextQ1 = varDict.LO2TextQ1, LO2TextQ2 = varDict.LO2TextQ2;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'What is the best approach to identifying the informal leaders within this group?',
                                    'What is the best next step to continue identifying the informal leaders among caregivers within this team?',
                                ]
                            },
                            {
                                header: 'Correct answer',
                                rows: [
                                    'Ask to shadow Ms. Cortez in the next teacher PLC meeting',
                                    'Survey the team to share their connections among places or events where caregivers are likely to be present and active. Use the survey data to begin a power map.',
                                ]
                            },
                            {
                                header: 'Answer provided',
                                rows: processTemplateVarHtml([
                                    LO2TextQ1,
                                    LO2TextQ2,
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO3',
                variablename: 'LO3dash',
                desc: 'Achieve collaborative consensus from (formal & informal) leaders to include other critical perspectives',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        response: [
                            'LO3Q1a',
                            'LO3Q1b',
                            'LO3Q1c',
                            'LO3TextQ2',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var LO3TextQ2 = varDict.LO3TextQ2;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'How do you support Ms. Cortez and respond to Mr. Jorgeson?',
                                    'How do you respond to the email (indicating that Mr. Jorgenson wants to ignore the team decision because it will take too long and instead move ahead with training)?',
                                ]
                            },
                            // <ol type="A">
                            {
                                header: 'Correct answer',
                                rows: [
                                    "<p>All:</p>\n\n                    <ul>\n                      <li>" + choiceTextMap.LO3Q1a + "</li>\n                      <li>" + choiceTextMap.LO3Q1b + "</li>\n                      <li>" + choiceTextMap.LO3Q1c + "</li>\n                    </ol>\n                  ",
                                    'Meet with Mr Sullivan, the DIT Leader, to develop a plan for a meeting with Mr. Jorgenson, Ms. Li, and Dr. Richardson. During this meeting, you plan to express your concerns about undermining the decision of the team, risking future disengagement because team members do not feel empowered to make authentic decisions.',
                                ]
                            },
                            {
                                header: 'Answer provided',
                                rows: processTemplateVarHtml([
                                    getHtmlFromBoolVars(varDict, choiceTextMap, [
                                        'LO3Q1a',
                                        'LO3Q1b',
                                        'LO3Q1c',
                                    ], 'list'),
                                    LO3TextQ2,
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO4',
                variablename: 'LO4dash',
                desc: 'Facilitate to allow all voices & protect disenfranchised perspectives in order to build a collective view',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        response: [
                            'LO4TextQ1',
                            'LO4TextQ2',
                            'LO4TextQ3',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var LO4TextQ1 = varDict.LO4TextQ1, LO4TextQ2 = varDict.LO4TextQ2, LO4TextQ3 = varDict.LO4TextQ3;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'What should you do first to facilitate shared power in decision making?',
                                    'How do you facilitate the interactions so that more team members are able to share their perspectives?',
                                    'Which protocol or process would you use with this group?'
                                ]
                            },
                            {
                                header: 'Correct answer',
                                rows: [
                                    'Ask people to pair up and share the first book they remember in which  the main character did NOT look like them or share their identity.',
                                    'During a break, you remind Mr. Sullivan that it would be a good idea to determine if any norms should be added now that we have new members.',
                                    'You decide to use a nominal group technique. Using this structured method for group brainstorming, you hope that it would encourage contributions from everyone and facilitate quick agreement on a solution.',
                                ]
                            },
                            {
                                header: 'Answer provided',
                                rows: processTemplateVarHtml([
                                    LO4TextQ1,
                                    LO4TextQ2,
                                    LO4TextQ3,
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
        ],
        specialGroupVarDictFns: {},
    }
};
