var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { utils } from '../shared/utils';
import { StandardTableRow } from '../shared/interfaces-classes/index';
import { colSettings } from './standard-reports-columns';
export var standardReports = {
    addedColumns: [],
    removedColumns: [],
    colHeaders: colSettings.map(function (col) { return col.header; }),
    colWidths: colSettings.map(function (col) { return col.width; }),
    processData: function (stmts) {
        var _this = this;
        var processed = stmts.filter(function (stmt) {
            return stmt.verb.id !== 'http://adlnet.gov/expapi/verbs/experienced';
        }).map(function (stmt) {
            var actor = stmt.actor, verb = stmt.verb, target = stmt.target, _a = stmt.result, result = _a === void 0 ? {} : _a, _b = stmt.context, context = _b === void 0 ? {} : _b;
            var _c = target.definition || {}, name = _c.name, description = _c.description, type = _c.type, activityExtensions = _c.extensions;
            var _d = result || {}, response = _d.response, score = _d.score, duration = _d.duration, success = _d.success, completion = _d.completion;
            var row = new StandardTableRow();
            var userInfo = _this.utils.getUserInfo(actor);
            row.name = userInfo.name;
            row.id = userInfo.id;
            row.verb = _this.utils.getDisplayText(verb, 'verb') || '';
            row.activityName = name ? _this.utils.getDisplayText(name) : '';
            row.activityDesc = description ? _this.utils.getDisplayText(description) : '';
            row.response = utils.asString(response);
            row.score = score ? score.raw : '';
            row.maxscore = score ? score.max : '';
            row.success = utils.asString(success);
            row.completion = utils.asString(completion);
            // row.variable = utils.getVariableNameExtVal(context);
            row.variable = utils.getVariableNameExtVal(context.extensions, activityExtensions);
            if (type === 'http://activitystrea.ms/schema/1.0/video') {
                var seconds = utils.convertDuration(duration || 0);
                row.percentageViewed = seconds && activityExtensions ? _this.utils.getPercentageViewed(seconds, activityExtensions) : '';
                row.duration = seconds ? utils.durationInMinutes(seconds) : '';
            }
            else {
                row.duration = '';
            }
            var dateFormat = 'MMM DD YYYY h:mm:ss A';
            row.date = moment(stmt.timestamp).format(dateFormat);
            if (_this.removedColumns && _this.removedColumns.length) {
                _this.removeRowDataFromRemovedColumns(row);
            }
            if (_this.addedColumns && _this.addedColumns.length) {
                _this.addRowDataFromAddedColumns(row, stmt);
            }
            return row;
        });
        return Promise.resolve(processed);
    },
    findValInStmt: function (stmt, colData) {
        var _a = colData.data, data = _a === void 0 ? {} : _a;
        var type = data.type, lookInProp = data.lookInProp, textInProp = data.textInProp, returnProp = data.returnProp;
        var valIsInContext = type === 'context';
        var outputValue = '';
        if (valIsInContext) {
            var ctxtAct_1 = stmt.context ? stmt.context.contextActivities : null;
            if (!ctxtAct_1) {
                return '';
            }
            ['parent', 'grouping', 'category', 'other'].forEach(function (ctxtArray) {
                var thisActArray = ctxtAct_1[ctxtArray] || [];
                if (!thisActArray.length) {
                    return;
                }
                var foundMatches = thisActArray.filter(function (activity) {
                    if (lookInProp === 'id') {
                        return activity.id.includes(textInProp);
                    }
                });
                var found = foundMatches[0];
                if (!found) {
                    return;
                }
                var outputIsInDef = returnProp === 'name';
                if (outputIsInDef) {
                    var name_1 = (found.definition || {}).name;
                    for (var lang in name_1) {
                        var isEn = lang.toLowerCase().includes('en');
                        var isUnd = lang.toLowerCase().includes('und');
                        outputValue = (isEn || isUnd) ? name_1[lang] : '';
                    }
                }
            });
        }
        return outputValue;
    },
    addRowDataFromAddedColumns: function (row, stmt) {
        var _this = this;
        this.addedColumns.forEach(function (colData) {
            var _a = colData.propName, propName = _a === void 0 ? '(--)' : _a;
            var foundVal = _this.findValInStmt(stmt, colData);
            row[propName] = foundVal || '';
        });
    },
    removeRowDataFromRemovedColumns: function (row) {
        this.removedColumns.forEach(function (columnDataName) {
            delete row[columnDataName];
        });
    },
    createColumnStructure: function (action) {
        // const columnMap = new StandardTableRow();
        // const columnArray = Object.keys(columnMap);
        // const newColumnArray = [...columnArray];
        var isMore = action === 'more';
        var newColumnArray = colSettings.map(function (col) { return col.prop; });
        var newHeaderArray = __spreadArray([], __read(standardReports.colHeaders));
        !isMore && this.removedColumns.forEach(function (colName) {
            var colIndex = newColumnArray.indexOf(colName);
            // const colIndex = newHeaderArray.indexOf(colName);
            newColumnArray.splice(colIndex, 1);
            newHeaderArray.splice(colIndex, 1);
        });
        !isMore && this.addedColumns.forEach(function (colData) {
            var index = colData.index, propName = colData.propName, header = colData.header;
            newColumnArray.splice(index, 0, propName);
            newHeaderArray.splice(index, 0, header);
        });
        this.colHeaders = newHeaderArray;
        return newColumnArray.map(function (key) {
            return { data: key };
        });
    },
    createColumnWidths: function () {
        var newColumnWidths = __spreadArray([], __read(this.colWidths));
        this.addedColumns.forEach(function (colData) {
            var index = colData.index, width = colData.width;
            newColumnWidths.splice(index, 0, width);
        });
        return newColumnWidths;
    },
    utils: {
        getUserInfo: function (actor) {
            var name = actor.name, mbox = actor.mbox, account = actor.account;
            var userInfo = {
                name: name || '',
                id: '',
            };
            if (mbox) {
                userInfo.id = mbox.replace("mailto:", '');
            }
            if (account) {
                userInfo.name = name || account.name;
                userInfo.id = account.name;
            }
            return userInfo;
        },
        getDisplayText: function (value, type) {
            // const langFormats = ['en-us', 'en-US', 'id-ID', 'und'];
            if (!value) {
                return '';
            }
            var isVerb = type === 'verb';
            if (isVerb) {
                return value.display ? Object.values(value.display)[0] : value.id;
            }
            else {
                return Object.values(value)[0] || '';
            }
            // $.each(langFormats, function (i, lang) {
            // 	if (type === "verb" && value.display && value.display.hasOwnProperty(lang)) {
            // 		thisOutput = value.display[lang];
            // 	} else if (type === "verb" && !value.display) {
            // 		thisOutput = value.id; //mostly for Storyline
            // 	} else if (type === "activityname" && objDef && objDef.name && objDef.name.hasOwnProperty(lang)) {
            // 		thisOutput = objDef.name[lang];
            // 	} else if (type === "activitydesc" && objDef && objDef.description && objDef.description.hasOwnProperty(lang)) {
            // 		thisOutput = objDef.description[lang];
            // 	}
            // });
            // return thisOutput;
        },
        getPercentageViewed: function (seconds, extensions) {
            var totalDurationRaw = extensions["http://id.tincanapi.com/extension/duration"] || 0;
            var totalDuration = Math.round(totalDurationRaw);
            if (!totalDuration) {
                return '';
            }
            return Math.round((seconds / totalDuration) * 100);
        },
    }
};
