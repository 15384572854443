import { DrilldownTable, defaultTrafficLights, } from "../../../../../shared/interfaces-classes/index";
import { reportUtils } from '../../../../report-utils';
var getBoolFromBoolishVar = reportUtils.getBoolFromBoolishVar, getCheckOrXOrNoDataFromBoolishVar = reportUtils.getCheckOrXOrNoDataFromBoolishVar, processSingleVarHtml = reportUtils.processSingleVarHtml, getGenericGroupMsg = reportUtils.getGenericGroupMsg;
var genericGroupMsg = getGenericGroupMsg();
export var tailoring = {
    'http://SISEPTailoringSupport': {
        columns: [
            {
                parent: 'LO1',
                variablename: 'LO1',
                desc: 'Assess capacity needs',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    icon: 'text',
                    vars: {
                        response: [
                            'AdaptiveLO1',
                            'TechnicalLO1'
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var AdaptiveLO1 = varDict.AdaptiveLO1, TechnicalLO1 = varDict.TechnicalLO1;
                        var AdaptiveLO1BoolOrNoData = getBoolFromBoolishVar(AdaptiveLO1);
                        var AdaptiveLO1Html = "\n              <p><strong>Adaptive concerns:</strong></p>\n\n              <ul>\n                <li>Cultivating leadership</li>\n                <li>Addressing power differentials</li>\n                <li>Grow & sustain relationships</li>\n              </ul>\n            ";
                        var TechnicalLO1BoolOrNoData = getBoolFromBoolishVar(TechnicalLO1);
                        var TechnicalLO1Html = "\n              <p><strong>Technical concerns:</strong></p>\n\n              <ul>\n                <li>General capacity: Modify organizational processes and procedures</li>\n                <li>Innovation-specific capacity:</li>\n                <li>\n                  <ul>\n                    <li>Improve math content knowledge</li>\n                    <li>Improved instructional practices</li>\n                  </ul>\n                </li>\n              </ul>\n            ";
                        var adaptiveLo1IsBool = typeof AdaptiveLO1BoolOrNoData === 'boolean';
                        var technicalLo1IsBool = typeof TechnicalLO1BoolOrNoData === 'boolean';
                        var answerRow = '';
                        if (adaptiveLo1IsBool && AdaptiveLO1BoolOrNoData) {
                            answerRow += AdaptiveLO1Html;
                        }
                        if (technicalLo1IsBool && TechnicalLO1BoolOrNoData) {
                            answerRow += TechnicalLO1Html;
                        }
                        if (!adaptiveLo1IsBool && !technicalLo1IsBool) {
                            answerRow = undefined; // no data
                        }
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'What are the needs of the district team?'
                                ],
                            },
                            {
                                header: 'Answer chosen',
                                rows: [
                                    processSingleVarHtml(answerRow),
                                ],
                            },
                            {
                                header: 'NIRN’s answer',
                                rows: [
                                    "\n                    <p><strong>Both adaptive and technical concerns:</strong></p>\n                    \n                    " + AdaptiveLO1Html + "\n\n                    " + TechnicalLO1Html + "\n                  "
                                ],
                            }
                        ];
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: columns,
                            title: '',
                            subtitle: '',
                        };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
            {
                parent: 'LO2',
                variablename: 'LO2dash',
                desc: 'Construct implementation support strategies',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'AdaptiveLO1',
                            'TechnicalLO1',
                            'LO2',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var AdaptiveLO1 = varDict.AdaptiveLO1, TechnicalLO1 = varDict.TechnicalLO1, LO2 = varDict.LO2;
                        var AdaptiveLO1BoolOrNoData = getBoolFromBoolishVar(AdaptiveLO1);
                        var TechnicalLO1BoolOrNoData = getBoolFromBoolishVar(TechnicalLO1);
                        var adaptiveLo1IsBool = typeof AdaptiveLO1BoolOrNoData === 'boolean';
                        var technicalLo1IsBool = typeof TechnicalLO1BoolOrNoData === 'boolean';
                        var adaptiveLo1IsTrue = adaptiveLo1IsBool && AdaptiveLO1BoolOrNoData;
                        var technicalLo1IsTrue = technicalLo1IsBool && TechnicalLO1BoolOrNoData;
                        // const lo2Bool = getBoolFromBoolishVar(LO2);
                        // const lo2IsBool = typeof lo2Bool === 'boolean';
                        // const lo2IsTrue = lo2IsBool && lo2Bool;
                        // const lo2IsFalse = lo2IsBool && !lo2Bool;
                        // const lo2Html = lo2IsTrue
                        //   ? true // checkmark
                        //   : (lo2IsFalse
                        //     ? false // red X
                        //     : lo2Bool); // no data
                        var lo2Html = getCheckOrXOrNoDataFromBoolishVar(LO2);
                        var adaptiveQuestion = 'Which strategies should you use to support the district’s adaptive concerns?';
                        var technicalQuestion = 'Which strategies should you use to support the district’s technical concerns?';
                        var adaptiveCorrectAnswer = "\n              <ul>\n                <li>Relationship building</li>\n                <li>Cultivating leadership</li>\n                <li>Strategic facilitation</li>\n              </ul>\n            ";
                        var technicalCorrectAnswer = "\n              <ul>\n                <li>Creation of a training plan</li>\n                <li>Hexagon Tool</li>\n                <li>Initiative Inventory</li>\n              </ul>\n            ";
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    adaptiveLo1IsTrue ? adaptiveQuestion : null,
                                    technicalLo1IsTrue ? technicalQuestion : null,
                                ].filter(Boolean),
                            },
                            {
                                header: 'Correct Answer',
                                rows: [
                                    adaptiveLo1IsTrue ? adaptiveCorrectAnswer : null,
                                    technicalLo1IsTrue ? technicalCorrectAnswer : null,
                                ].filter(Boolean),
                            },
                            {
                                header: 'Correct answer chosen?',
                                type: 'checkmark',
                                allowEmpty: true,
                                rows: [
                                    adaptiveLo1IsTrue ? lo2Html : null,
                                    technicalLo1IsTrue ? lo2Html : null,
                                ].filter(function (val) { return val !== null; }),
                            }
                        ];
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: columns,
                            title: '',
                            subtitle: '',
                        };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
            {
                parent: 'LO3',
                variablename: 'LO3dash',
                desc: 'Identify data plan related to capacity building & strategy success',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'AdaptiveLO1',
                            'TechnicalLO1',
                            'LO3',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var AdaptiveLO1 = varDict.AdaptiveLO1, TechnicalLO1 = varDict.TechnicalLO1, LO3 = varDict.LO3;
                        var adaptiveQuestion = 'What data should you collect to monitor and improve the district’s adaptive concerns?';
                        var technicalQuestion = 'What data should you collect to monitor and improve the district’s technical concerns?';
                        var adaptiveCorrectAnswer = "\n              <ul>\n                <li>Observational data of team functioning</li>\n              </ul>\n            ";
                        var technicalCorrectAnswer = "\n              <ul>\n                <li>Student outcome data</li>\n                <li>Capacity assessment data</li>\n                <li>Data collected by the district</li>\n              </ul>\n            ";
                        var AdaptiveLO1BoolOrNoData = getBoolFromBoolishVar(AdaptiveLO1);
                        var TechnicalLO1BoolOrNoData = getBoolFromBoolishVar(TechnicalLO1);
                        var adaptiveLo1IsBool = typeof AdaptiveLO1BoolOrNoData === 'boolean';
                        var technicalLo1IsBool = typeof TechnicalLO1BoolOrNoData === 'boolean';
                        var adaptiveLo1IsTrue = adaptiveLo1IsBool && AdaptiveLO1BoolOrNoData;
                        var technicalLo1IsTrue = technicalLo1IsBool && TechnicalLO1BoolOrNoData;
                        // const lo3Bool = getBoolFromBoolishVar(LO3);
                        // const lo3IsBool = typeof lo3Bool === 'boolean';
                        // const lo3IsTrue = lo3IsBool && lo3Bool;
                        // const lo3IsFalse = lo3IsBool && !lo3Bool;
                        // const lo3Html = lo3IsTrue
                        //   ? true // checkmark
                        //   : (lo3IsFalse
                        //     ? false // red X
                        //     : lo3Bool); // no data
                        var lo3Html = getCheckOrXOrNoDataFromBoolishVar(LO3);
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    adaptiveLo1IsTrue ? adaptiveQuestion : null,
                                    technicalLo1IsTrue ? technicalQuestion : null,
                                ].filter(Boolean),
                            },
                            {
                                header: 'Correct Answer',
                                rows: [
                                    adaptiveLo1IsTrue ? adaptiveCorrectAnswer : null,
                                    technicalLo1IsTrue ? technicalCorrectAnswer : null,
                                ].filter(Boolean),
                            },
                            {
                                header: 'Correct answer chosen?',
                                type: 'checkmark',
                                allowEmpty: true,
                                rows: [
                                    adaptiveLo1IsTrue ? lo3Html : null,
                                    technicalLo1IsTrue ? lo3Html : null,
                                ].filter(function (val) { return val !== null; }),
                            }
                        ];
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: columns,
                            title: '',
                            subtitle: '',
                        };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
            {
                parent: 'LO4a',
                variablename: 'LO4adash',
                desc: 'Tailor support strategies based on data',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'LO4a'
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var LO4a = varDict.LO4a;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'In light of these results, how should you tailor your support?'
                                ],
                            },
                            {
                                header: 'Correct Answer',
                                rows: [
                                    'Address technical concerns in addition to adaptive concerns'
                                ],
                            },
                            {
                                header: 'Correct answer chosen?',
                                type: 'checkmark',
                                allowEmpty: true,
                                rows: [
                                    // getBoolFromBoolishVar(LO4a) || ''
                                    getCheckOrXOrNoDataFromBoolishVar(LO4a),
                                ],
                            }
                        ];
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: columns,
                            title: '',
                            subtitle: '',
                        };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
            {
                parent: 'LO4b',
                variablename: 'LO4bdash',
                desc: 'Adjust implementation plan based on data',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'LO4b'
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var LO4b = varDict.LO4b;
                        var lo4bBoolOrNoData = getBoolFromBoolishVar(LO4b);
                        var lo4IsBool = typeof lo4bBoolOrNoData === 'boolean';
                        var lo4IsTrue = lo4IsBool && lo4bBoolOrNoData;
                        var lo4IsFalse = lo4IsBool && !lo4bBoolOrNoData;
                        var lo4bCorrectHtml = "\n              <p>Focus implementation practice learning for the team on installation stage activities of training and coaching; advise district to co-design both with teachers</p>\n            ";
                        var lo4bHtml = lo4IsTrue
                            ? lo4bCorrectHtml
                            : lo4IsFalse
                                ? 'Give it more time; results won’t be immediate. Continue taking data.'
                                : lo4bBoolOrNoData;
                        if (typeof lo4bBoolOrNoData !== 'boolean') {
                            lo4bHtml = lo4bBoolOrNoData; // no data
                        }
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'What should you do next in response to the data?'
                                ],
                            },
                            {
                                header: 'Correct Answer',
                                rows: [
                                    lo4bCorrectHtml
                                ],
                            },
                            {
                                header: 'Answer chosen',
                                rows: [
                                    lo4bHtml
                                ],
                            }
                        ];
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: columns,
                            title: '',
                            subtitle: '',
                        };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
        ],
        specialGroupVarDictFns: {},
    }
};
