export var coach = {
    'http://MiMTSSCoach': {
        columns: [
            {
                parent: 'LO1',
                variablename: 'LO1dash',
                desc: 'Identify coaching opportunity',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 75
                    },
                    yellow: {
                    // min: 51,
                    // max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false
                        },
                        response: [
                            'Focus1',
                            'Focus2',
                            'Focus3',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return '<p>Select individual results for more details, or view <strong>Analytics</strong> report.</p>';
                        }
                        var Focus1 = varDict.Focus1, Focus2 = varDict.Focus2, Focus3 = varDict.Focus3;
                        // console.log('varDict (focus):', varDict);
                        return "\n\t\t\t\t\t\t\t<table class=\"drilldown-table\">\n\t\t\t\t\t\t\t\t<thead>\n\t\t\t\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t\t\t\t<th>#</th>\n\t\t\t\t\t\t\t\t\t\t<th>Correct</th>\n\t\t\t\t\t\t\t\t\t\t<th>Selected</th>\n\t\t\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t\t\t</thead>\n\t\t\t\t\t\t\t\t<tbody>\n\t\t\t\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t\t\t\t<td>1</td>\n\t\t\t\t\t\t\t\t\t\t<td>Focus on SLT</td>\n\t\t\t\t\t\t\t\t\t\t<td>" + Focus1 + "</td>\n\t\t\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t\t\t\t<td>2</td>\n\t\t\t\t\t\t\t\t\t\t<td>Focus on SLT</td>\n\t\t\t\t\t\t\t\t\t\t<td>" + Focus2 + "</td>\n\t\t\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t\t\t\t<td>3</td>\n\t\t\t\t\t\t\t\t\t\t<td>Focus on Staff Buy-in</td>\n\t\t\t\t\t\t\t\t\t\t<td>" + Focus3 + "</td>\n\t\t\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t\t\t</tbody>\n\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t</table>\n\t\t\t\t\t\t";
                    }
                }
            },
            {
                parent: 'LO2',
                variablename: 'LO2dash',
                desc: 'Apply coaching foundational skills',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 75
                    },
                    yellow: {
                    // min: 51,
                    // max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false
                        },
                        response: [
                            'Skills1',
                            'Skills2',
                            'Skills3',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return '<p>Select individual results for more details, or view <strong>Analytics</strong> report.</p>';
                        }
                        var Skills1 = varDict.Skills1, Skills2 = varDict.Skills2, Skills3 = varDict.Skills3;
                        // console.log('varDict (skills):', varDict);
                        return "\n\t\t\t\t\t\t\t<table class=\"drilldown-table\">\n\t\t\t\t\t\t\t\t<thead>\n\t\t\t\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t\t\t\t<th>#</th>\n\t\t\t\t\t\t\t\t\t\t<th>Correct</th>\n\t\t\t\t\t\t\t\t\t\t<th>Selected</th>\n\t\t\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t\t\t</thead>\n\t\t\t\t\t\t\t\t<tbody>\n\t\t\t\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t\t\t\t<td>1</td>\n\t\t\t\t\t\t\t\t\t\t<td>Develop talking points to explain why the administrators presence and active engagement is critical for the success of the team.</td>\n\t\t\t\t\t\t\t\t\t\t<td>" + Skills1 + "</td>\n\t\t\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t\t\t\t<td>2</td>\n\t\t\t\t\t\t\t\t\t\t<td>Plan to raise the raise the concern of limited engagement from team members during your regularly scheduled meeting with the administrator.</td>\n\t\t\t\t\t\t\t\t\t\t<td>" + Skills2 + "</td>\n\t\t\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t\t\t\t<td>3</td>\n\t\t\t\t\t\t\t\t\t\t<td>Add this topic to the agenda for the next SLT meeting.</td>\n\t\t\t\t\t\t\t\t\t\t<td>" + Skills3 + "</td>\n\t\t\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t\t\t</tbody>\n\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t</table>\n\t\t\t\t\t\t";
                    }
                }
            },
        ]
    }
};
