import { xapiUtils } from "../../../../../xapi/xapi-utils";
import { reportUtils } from '../../../../report-utils';
var getGenericGroupMsg = reportUtils.getGenericGroupMsg;
var genericGroupMsg = getGenericGroupMsg();
import { clDrilldown } from './cl-drilldown';
export var cl = {
    'http://SISEPCL': {
        columns: [
            {
                parent: 'LO1',
                variablename: 'LO1dash',
                desc: 'Explanation of the importance of implementation leaders and champions',
                drilldown: {
                    icon: 'text',
                    vars: {
                        opts: {
                            prefix: false
                        },
                        response: [
                            'ChampionImportance',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var ChampionImportance = varDict.ChampionImportance;
                        var responseHtml = ChampionImportance ? "<p class=\"user-text-response\">\"" + ChampionImportance + "\"</p>" : '<em>None</em>';
                        return "\n\t\t\t\t\t\t\t<h4 style=\"margin: 0\">User Response:</h4>\n\t\t\t\t\t\t\t" + responseHtml + "\n\t\t\t\t\t\t";
                    },
                }
            },
            {
                parent: 'LO2',
                variablename: 'LO2dash',
                desc: 'Identified key champion activities',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 50
                    },
                    yellow: {
                        min: 51,
                        max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                            successMetric: 'scoreRaw'
                        },
                        // scoreRaw: [
                        // 	'LO4count'
                        // ],
                        successList: [
                            {
                                question: "Does the champion advocate for the practice / program? <em>(Influence)</em>",
                                variablename: 'LO2a',
                                correctAnswer: true,
                            },
                            {
                                question: "Does the champion have existing positive relationships with stakeholders? <em>(Influence)</em>",
                                variablename: 'LO2b',
                                correctAnswer: false,
                            },
                            {
                                question: "Can the champion build relationships with stakeholders? <em>(Participative Leadership)</em>",
                                variablename: 'LO2c',
                                correctAnswer: true,
                            },
                            {
                                question: "Is the champion identified as being involved with the practice or program? <em>(Ownership)</em>",
                                variablename: 'LO2d',
                                correctAnswer: true,
                            },
                            {
                                question: "Does the champion engage in planning activities around implementation? <em>(Physical Presence)</em>",
                                variablename: 'LO2e',
                                correctAnswer: true,
                            },
                            {
                                question: "Does the champion model and participate in the reflection? <em>(Physical Presence)</em>",
                                variablename: 'LO2f',
                                correctAnswer: false,
                            },
                            {
                                question: "Can the champion use data to troubleshoot problems? <em>(Persistence)</em>",
                                variablename: 'LO2g',
                                correctAnswer: false,
                            },
                            {
                                question: "Does the champion motivate the staff involved with the practice/program? <em>(Persuasive/Positivity)</em>",
                                variablename: 'LO2h',
                                correctAnswer: true,
                            },
                            {
                                question: "Can the champion persuade staff on the merits of the new practice/program? <em>(Persuasive/Positivity)</em>",
                                variablename: 'LO2i',
                                correctAnswer: false,
                            },
                            {
                                question: "Does the champion keep the team positive about change efforts? <em>(Persuasive/Positivity)</em>",
                                variablename: 'LO2j',
                                correctAnswer: true,
                            },
                        ]
                    },
                    template: function (varDict) {
                        var type = varDict.type;
                        var isGroup = type === 'group';
                        // if (isGroup) {
                        // 	return genericGroupMsg;
                        // }
                        var drilldownDesc = "\n\t\t\t\t\t\t\t<div class=\"drilldown-desc\">\n\t\t\t\t\t\t\t\t<h3>EC District Coordinator:</h3>\n\t\t\t\t\t\t\t\t<p>The EC District Coordinator begins to meet and discuss the project with the high school principal and the High School Team to ensure understanding of the practice selected. She also has been working on collecting data and analyzing it to troubleshoot challenges the team is facing.</p>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t";
                        // console.log('varDict:', varDict);
                        var learnerAnswersHeader = isGroup ? 'Total Answers' : 'Learner Answers';
                        var _a = varDict.totalUsers, totalUsers = _a === void 0 ? '?' : _a;
                        var tableHtml = "\n\t\t\t\t\t\t\t<div class=\"drilldown-table-container\">\n\t\t\t\t\t\t\t\t<table>\n\t\t\t\t\t\t\t\t\t<thead>\n\t\t\t\t\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t\t\t\t\t<th colspan=\"2\">Activity (Attribute)</th>\n\t\t\t\t\t\t\t\t\t\t\t<th>Correct Answers</th>\n\t\t\t\t\t\t\t\t\t\t\t<th>" + learnerAnswersHeader + "</th>\n\t\t\t\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t\t\t\t</thead>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<tbody>\n\t\t\t\t\t\t\t";
                        var rowIndex = 0;
                        var totalCorrectScore = 0;
                        var totalUserScore = 0;
                        var checkIconEl = '<ion-icon class="neutral" name="checkmark-circle-outline"></ion-icon>';
                        for (var vbl in varDict) {
                            if (vbl === 'type' || vbl === 'totalUsers') {
                                continue;
                            }
                            rowIndex = rowIndex + 1;
                            var item = varDict[vbl];
                            var question = item.question, isCorrect = item.isCorrect, userScore = item.userScore, correctAnswer = item.correctAnswer;
                            totalCorrectScore += correctAnswer ? 1 : 0;
                            var learnerAnswerVal = isCorrect ? 1 : 0;
                            totalUserScore = totalUserScore + (userScore || 0);
                            var rowClass = !!learnerAnswerVal === !!correctAnswer ? 'correct' : 'incorrect';
                            if (isGroup) {
                                var allUsersTrue = userScore === totalUsers;
                                var allCorrect = allUsersTrue && !!correctAnswer;
                                var allIncorrect = allUsersTrue && !correctAnswer;
                                rowClass = allCorrect ? 'group-correct' : allIncorrect ? 'incorrect' : 'correct';
                            }
                            if (isGroup) {
                                var prefix = '';
                                if (userScore) {
                                    prefix = checkIconEl;
                                }
                                learnerAnswerVal = "\n\t\t\t\t\t\t\t\t\t<div class=\"cell-flex\">\n\t\t\t\t\t\t\t\t\t\t" + prefix + " \n\t\t\t\t\t\t\t\t\t\t<span style=\"margin-left: 0.4em\">" + userScore + " / " + totalUsers + "</span>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t";
                            }
                            else {
                                learnerAnswerVal = learnerAnswerVal ? checkIconEl : '';
                            }
                            var correctAnswerVal = correctAnswer ? checkIconEl : '';
                            if (userScore === null) {
                                rowClass = 'unanswered';
                                learnerAnswerVal = '--';
                            }
                            tableHtml += "\n\t\t\t\t\t\t\t\t<tr class=\"table-row-" + rowClass + "\">\n\t\t\t\t\t\t\t\t\t<td>" + rowIndex + "</td>\n\t\t\t\t\t\t\t\t\t<td>" + question + "</td>\n\t\t\t\t\t\t\t\t\t<td class=\"checkmark-cell\">" + correctAnswerVal + "</td>\n\t\t\t\t\t\t\t\t\t<td class=\"checkmark-cell " + (isGroup ? 'total' : '') + "\">" + learnerAnswerVal + "</td>\n\t\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t\t";
                        }
                        if (isGroup) {
                            totalUserScore = +(totalUserScore / totalUsers).toFixed(2);
                        }
                        tableHtml += "\n\t\t\t\t\t\t\t<tr class=\"table-row-total\">\n\t\t\t\t\t\t\t\t<td colspan=\"2\">\n\t\t\t\t\t\t\t\t\t<div class=\"table-row-total-header\">\n\t\t\t\t\t\t\t\t\t\t<span>TOTAL </span>\n\t\t\t\t\t\t\t\t\t\t<span class=\"table-row-total-icon\">" + checkIconEl + "</span>\n\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t</td>\n\t\t\t\t\t\t\t\t<td style=\"text-align: center;\">" + totalCorrectScore + "</td>\n\t\t\t\t\t\t\t\t<td style=\"text-align: center;\">" + (isGroup ? totalUserScore + ' <span style="font-weight: normal;">(avg)</span>' : totalUserScore) + "</td>\n\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t";
                        tableHtml += '</tbody></table></div>';
                        return drilldownDesc + tableHtml;
                    },
                }
            },
            {
                parent: 'LO3',
                variablename: 'LO3dash',
                desc: 'Used the Champion Engagement Tool to cultivate and grow leaders & champions',
                drilldown: {
                    icon: 'text',
                    vars: {
                        opts: {
                            prefix: false
                        },
                        response: [
                            'StartDoing', 'StopDoing',
                            'DoMore', 'DoLess', 'ContinueDoing',
                            'StartDoing2', 'StopDoing2',
                            'DoMore2', 'DoLess2', 'ContinueDoing2'
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var camelCaseReverse = xapiUtils.camelCaseReverse;
                        var _a = clDrilldown.nirn, startDoing1 = _a.startDoing1, stopDoing1 = _a.stopDoing1, doMore1 = _a.doMore1, doLess1 = _a.doLess1, continueDoing1 = _a.continueDoing1, startDoing2 = _a.startDoing2, stopDoing2 = _a.stopDoing2, doMore2 = _a.doMore2, doLess2 = _a.doLess2, continueDoing2 = _a.continueDoing2;
                        var StartDoing = varDict.StartDoing, StopDoing = varDict.StopDoing, DoMore = varDict.DoMore, DoLess = varDict.DoLess, ContinueDoing = varDict.ContinueDoing, StartDoing2 = varDict.StartDoing2, StopDoing2 = varDict.StopDoing2, DoMore2 = varDict.DoMore2, DoLess2 = varDict.DoLess2, ContinueDoing2 = varDict.ContinueDoing2;
                        var varMap = new Map();
                        for (var varName in varDict) {
                            var value = varDict[varName];
                            varMap.set(varName, value);
                        }
                        function createSingleRowTables(columnObj) {
                            var columnList = [];
                            for (var varName in columnObj) {
                                columnList.push({
                                    title: camelCaseReverse(varName, true),
                                    value: columnObj[varName]
                                });
                            }
                            return "\n\t\t\t\t\t\t\t\t<div class=\"drilldown-table-2-col-wrapper\">\n\t\t\t\t\t\t\t\t\t<table>\n\t\t\t\t\t\t\t\t\t\t<thead>\n\t\t\t\t\t\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t\t\t\t\t\t" + columnList.map(function (col) { return '<th>' + col.title + '</th>'; }).join('') + "\n\t\t\t\t\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t\t\t\t\t</thead>\n\t\t\t\t\t\t\t\t\t\t<tbody>\n\t\t\t\t\t\t\t\t\t\t\t" + columnList.map(function (col) { return '<td>' + (col.value || '--') + '</td>'; }).join('') + "\n\t\t\t\t\t\t\t\t\t\t</tbody>\n\t\t\t\t\t\t\t\t\t</table>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t";
                        }
                        var section1table1learner = createSingleRowTables({ StartDoing: StartDoing, StopDoing: StopDoing });
                        var section1table2learner = createSingleRowTables({ DoMore: DoMore, DoLess: DoLess });
                        var section1table3learner = createSingleRowTables({ ContinueDoing: ContinueDoing });
                        var section2table1learner = createSingleRowTables({ StartDoing2: StartDoing2, StopDoing2: StopDoing2 });
                        var section2table2learner = createSingleRowTables({ DoMore2: DoMore2, DoLess2: DoLess2 });
                        var section2table3learner = createSingleRowTables({ ContinueDoing2: ContinueDoing2 });
                        var section1table1nirn = createSingleRowTables({ startDoing1: startDoing1, stopDoing1: stopDoing1 });
                        var section1table2nirn = createSingleRowTables({ doMore1: doMore1, doLess1: doLess1 });
                        var section1table3nirn = createSingleRowTables({ continueDoing1: continueDoing1 });
                        var section2table1nirn = createSingleRowTables({ startDoing2: startDoing2, stopDoing2: stopDoing2 });
                        var section2table2nirn = createSingleRowTables({ doMore2: doMore2, doLess2: doLess2 });
                        var section2table3nirn = createSingleRowTables({ continueDoing2: continueDoing2 });
                        return "\t\t\t\t\t\t\n\t\t\t\t\t\t\t<div class=\"drilldown-desc\">\n\t\t\t\t\t\t\t\t<h3>Middle School Teacher:</h3>\n\t\t\t\t\t\t\t\t<p>With the support from his principal, the middle school teacher begins to meet and discuss the project with the high school principal and the High School Team to ensure understanding of the practice selected. He also has been working on collecting data and analyzing it to troubleshoot challenges the team is facing.</p>\n\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t<section class=\"drilldown-content-section\">\n\t\t\t\t\t\t\t\t<h3>1. What does the identified champion need to do to be effective in their role?</h3>\n\t\t\t\t\t\t\t\t<div class=\"drilldown-multi-table\">\n\t\t\t\t\t\t\t\t\t<h4>Learner Answers:</h4>\n\t\t\t\t\t\t\t\t\t" + section1table1learner + "\n\t\t\t\t\t\t\t\t\t" + section1table2learner + "\n\t\t\t\t\t\t\t\t\t" + section1table3learner + "\n\t\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t\t<div class=\"drilldown-multi-table\">\n\t\t\t\t\t\t\t\t\t<h4>NIRN Answers:</h4>\n\t\t\t\t\t\t\t\t\t" + section1table1nirn + "\n\t\t\t\t\t\t\t\t\t" + section1table2nirn + "\n\t\t\t\t\t\t\t\t\t" + section1table3nirn + "\n\t\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t</section>\n\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t<section class=\"drilldown-content-section\">\n\t\t\t\t\t\t\t\t<h3>2. What do I need to do to cultivate/engage the identified champions?</h3>\n\t\t\t\t\t\t\t\t<div class=\"drilldown-multi-table\">\n\t\t\t\t\t\t\t\t\t<h4>Learner Answers:</h4>\n\t\t\t\t\t\t\t\t\t" + section2table1learner + "\n\t\t\t\t\t\t\t\t\t" + section2table2learner + "\n\t\t\t\t\t\t\t\t\t" + section2table3learner + "\n\t\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t\t<div class=\"drilldown-multi-table\">\n\t\t\t\t\t\t\t\t\t<h4>NIRN Answers:</h4>\n\t\t\t\t\t\t\t\t\t" + section2table1nirn + "\n\t\t\t\t\t\t\t\t\t" + section2table2nirn + "\n\t\t\t\t\t\t\t\t\t" + section2table3nirn + "\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</section>\n\n\t\t\t\t\t\t";
                    }
                }
            },
        ],
        specialGroupVarDictFns: {
            LO2: function (column) {
                var parent = column.parent, varDictUserList = column.varDictUserList;
                // console.log('%s varDictUserList:', parent, varDictUserList);
                var varDictGroup = {
                    type: 'group',
                    totalUsers: varDictUserList.length
                };
                varDictUserList.forEach(function (varDictUser) {
                    // LO2a-j
                    for (var vbl in varDictUser) {
                        if (!vbl.includes('LO2')) {
                            return;
                        }
                        var userData = varDictUser[vbl];
                        var question = userData.question, correctAnswer = userData.correctAnswer, userScore = userData.userScore;
                        varDictGroup[vbl] = varDictGroup[vbl] || {
                            question: question,
                            correctAnswer: correctAnswer,
                            userScore: 0, // total answers
                            // isCorrect: true,
                        };
                        varDictGroup[vbl].userScore += userScore;
                    }
                });
                // console.log('varDictGroup:', varDictGroup);
                /*
                LO2a: {question: "Does the champion advocate for the practice / program? <em>(Influence)</em>", isCorrect: 1, userScore: 1, correctAnswer: true}
                LO2b: {question: "Does the champion have existing positive relationships with stakeholders? <em>(Influence)</em>", isCorrect: 1, userScore: 1, correctAnswer: false}
                LO2c: {question: "Can the champion build relationships with stakeholders? <em>(Participative Leadership)</em>", isCorrect: 1, userScore: 1, correctAnswer: true}
                LO2d: {question: "Is the champion identified as being involved with the practice or program? <em>(Ownership)</em>", isCorrect: 1, userScore: 1, correctAnswer: true}
                LO2e: {question: "Does the champion engage in planning activities around implementation? <em>(Physical Presence)</em>", isCorrect: 1, userScore: 1, correctAnswer: true}
                LO2f: {question: "Does the champion model and participate in the reflection? <em>(Physical Presence)</em>", isCorrect: 1, userScore: 1, correctAnswer: false}
                LO2g: {question: "Can the champion use data to troubleshoot problems? <em>(Persistence)</em>", isCorrect: 1, userScore: 1, correctAnswer: false}
                LO2h: {question: "Does the champion motivate the staff involved with…ractice/program? <em>(Persuasive/Positivity)</em>", isCorrect: 1, userScore: 1, correctAnswer: true}
                LO2i: {question: "Can the champion persuade staff on the merits of t…ractice/program? <em>(Persuasive/Positivity)</em>", isCorrect: 1, userScore: 1, correctAnswer: false}
                LO2j: {question: "Does the champion keep the team positive about change efforts? <em>(Persuasive/Positivity)</em>", isCorrect: 1, userScore: 1, correctAnswer: true}
                
                */
                return varDictGroup;
                // let LO4countTotal = 0;
                // varDictUserList.forEach((varDictUser: any) => {
                // 	for (let vbl in varDictUser) {
                // 		if (vbl === 'LO4count') {
                // 			LO4countTotal += (varDictUser[vbl] || 0);
                // 		} else {
                // 			const item = Object.assign({}, varDictUser[vbl]);
                // 			varDictGroup[vbl] = varDictGroup[vbl] || item;
                // 			let totalIncorrect = varDictGroup[vbl].totalIncorrect || 0;
                // 			totalIncorrect += item.isCorrect ? 0 : 1;
                // 			varDictGroup[vbl].totalIncorrect = totalIncorrect;
                // 		}
                // 	}
                // });
                // varDictGroup.LO4count = (LO4countTotal / varDictUserList.length).toFixed(2) + ' (Average)';
                // console.log('%s varDictGroup:', parent, varDictGroup);
                // return varDictGroup;
            }
        },
    },
};
