import { DrilldownTable } from "../../../../../shared/interfaces-classes/index";
import { reportUtils } from "../../../../report-utils";
var processTemplateVarHtml = reportUtils.processTemplateVarHtmlList, getGenericGroupMsg = reportUtils.getGenericGroupMsg;
var genericGroupMsg = getGenericGroupMsg();
export var hex = {
    'http://SISEPHex': {
        columns: [
            {
                parent: 'LO1',
                variablename: 'LO1dash',
                desc: 'Prepare to use Hexagon Tool',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 50
                    },
                    yellow: {
                        min: 51,
                        max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                            stringifyVarPrefix: 'var',
                        },
                        response: [
                            '0301text',
                            '0302text',
                            '0303text',
                            '0304text',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var var0301text = varDict.var0301text, var0302text = varDict.var0302text, var0303text = varDict.var0303text, var0304text = varDict.var0304text;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    '<strong>What do you do first?</strong>',
                                    '<strong>What do you do next?</strong>',
                                    'Now that you’ve gathered your team and have community focus group feedback, <strong>what is your best next step?</strong>',
                                    '<strong>What should you do now</strong> (after root cause analysis)?',
                                ]
                            },
                            {
                                header: 'Correct Answer',
                                rows: [
                                    'Gather a team of stakeholders with diverse perspectives',
                                    'Gather information on the community’s perception of the need and potential causes',
                                    'Conduct a Root Cause Analysis <em>(e.g. Five Whys, Fishbone Analysis)</em>',
                                    'Conduct a review of clearinghouses that catalog evidence-based programs or practices <br><br>OR<br><br>Complete a scan or inventory of current programs or practices to address the need',
                                ]
                            },
                            {
                                header: 'Answer Provided',
                                rows: processTemplateVarHtml([
                                    var0301text,
                                    var0302text,
                                    var0303text,
                                    var0304text,
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO2',
                variablename: 'LO2dash',
                desc: 'Use Hexagon Tool',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 75
                    },
                    yellow: {
                    // min: 51,
                    // max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                            stringifyVarPrefix: 'var',
                        },
                        scoreRaw: [
                            '0414number',
                            '0403number',
                            '0405number',
                            '0412number',
                            '0411number',
                            '0410number',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var var0414number = varDict.var0414number, var0403number = varDict.var0403number, var0405number = varDict.var0405number, var0412number = varDict.var0412number, var0411number = varDict.var0411number, var0410number = varDict.var0410number;
                        var columns = [
                            {
                                header: 'Hexagon Area',
                                rows: [
                                    "Supports",
                                    "Need",
                                    "Evidence",
                                    "Capacity",
                                    "Usability",
                                    "Fit",
                                ]
                            },
                            {
                                header: 'Correct Score',
                                rows: [
                                    "4",
                                    "3",
                                    "4",
                                    "2",
                                    "3",
                                    "4"
                                ]
                            },
                            {
                                header: 'Score Chosen',
                                rows: processTemplateVarHtml([
                                    var0414number.toString(),
                                    var0403number.toString(),
                                    var0405number.toString(),
                                    var0412number.toString(),
                                    var0411number.toString(),
                                    var0410number.toString(),
                                ])
                            }
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO3',
                variablename: 'LO3dash',
                desc: 'Make recommendations with Hexagon data',
                drilldown: {
                    icon: 'text',
                    vars: {
                        opts: {
                            prefix: false
                        },
                        response: [
                            'recommendation',
                        ],
                        scoreRaw: [
                            'competencyside',
                            'organizationside',
                            'leadershipside',
                        ]
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var recommendation = varDict.recommendation, competencyside = varDict.competencyside, organizationside = varDict.organizationside, leadershipside = varDict.leadershipside;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    "<ul>\n\t\t\t\t\t\t\t\t\t\t\t<li>What would be your recommendation to the team regarding selection?</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>What are the key take-aways from the analysis?</li>\n\t\t\t\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t\t\t\t",
                                    "<p>What do these results tell you about where to focus your agency\u2019s resources and efforts in planning for implementation and during implementation of this program?</p>\n\t\t\t\t\t\t\t\t\t<p>Indicate the percent of your agency effort that will be required for each driver.</p>\n\t\t\t\t\t\t\t\t\t"
                                ]
                            },
                            {
                                header: 'NIRN\'s Answer',
                                rows: [
                                    "<h3><strong>Recommendation:</strong></h3>\n\t\t\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t\t\tSelect the program but ensure that your implementation is focused on strengthening the capacity of the local implementing site and the usability of the program. <strong>Remember the team makes decision based on the collective results of the analysis.</strong> A total score is NOT generated and used to make the decision. \n\t\t\t\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t\t\t\t<h3><strong>Takeaways:</strong></h3>\n\n\t\t\t\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t\t\t\t<li>Program addresses our local site\u2019s <strong>need</strong> to improve students staying in school by addressing the root cause of lack of connection with adults at school. In addition, the program addresses risk indicators for not graduating including behavior incidents, academic performance, and connecting students and families to services when needed.</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>The program has strong <strong>evidence</strong>.</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>The program <strong>fits</strong> with our current strategic plan and initiatives as well as community initiatives and state priority.</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>The program is <strong>usable</strong> given its well defined underlying principles and core components, however, it is missing a fidelity measure.</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>We have some <strong>capacity</strong> to use the program. Work is needed to expand the qualified workforce, secure funding, adjust procedures and schedules, and make adjustments to the technology and data systems.</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>The program has available <strong>supports</strong> from the national developer as well from our state agency. We need assistance with a fidelity measure and our data system.</li> \n\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t\t\t\t",
                                    "<div><strong>Competency Drivers:</strong> 40%</div>\n\t\t\t\t\t\t\t\t\t\t<div><strong>Organization Drivers:</strong> 40%</div>\n\t\t\t\t\t\t\t\t\t\t<div><strong>Leadership:</strong> 20%</div>\n\t\t\t\t\t\t\t\t\t"
                                ]
                            },
                            {
                                header: 'Answer Provided',
                                rows: processTemplateVarHtml([
                                    recommendation,
                                    "<div><strong>Competency Drivers:</strong> " + competencyside + "%</div>\n\t\t\t\t\t\t\t\t\t<div><strong>Organization Drivers:</strong> " + organizationside + "%</div>\n\t\t\t\t\t\t\t\t\t<div><strong>Leadership:</strong> " + leadershipside + "%</div>\n\t\t\t\t\t\t\t\t"
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
        ],
        specialGroupVarDictFns: {},
    }
};
