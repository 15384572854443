import { mod1 } from './mod1';
import { mod2 } from './mod2';
import { mod3 } from './mod3';
import { mod4 } from './mod4';
import { mod5 } from './mod5';
import { mod6 } from './mod6';
import { mod7 } from './mod7';
import { mod8 } from './mod8';
import { mod9 } from './mod9';
import { mod9_2 } from './mod9-2';
import { mod9_3 } from './mod9-3';
export var structures = Object.assign({}, mod1, mod2, mod3, mod4, mod5, mod6, mod7, mod8, mod9, mod9_2, mod9_3);
