var HorizontalBarValues = /** @class */ (function () {
    function HorizontalBarValues() {
        this.true = 0;
        this.total = 0;
    }
    return HorizontalBarValues;
}());
export { HorizontalBarValues };
export var fbaBip = {
    'http://MiMTSSFbaBip': {
        title: 'Histogram of frequency of T values',
        hasData: false,
        columns: [
            'Category',
            'Description',
            'Related Guidance Documents',
            'Frequency of T Values',
        ],
        categoryList: [
            {
                name: 'Tier 1',
                variables: [
                    {
                        name: 'Tier1_Importance',
                        desc: 'Importance of Tier 1',
                        relatedText: '',
                        relatedLink: '',
                        values: new HorizontalBarValues()
                    },
                    {
                        name: 'Tier1_Assess',
                        desc: 'How to Assess Tier 1 needs',
                        relatedText: '',
                        relatedLink: '',
                        values: new HorizontalBarValues()
                    },
                    {
                        name: 'Tier1_Implement',
                        desc: 'How to Implement Tier 1',
                        relatedText: '',
                        relatedLink: '',
                        values: new HorizontalBarValues()
                    },
                ]
            },
            {
                name: 'FBA',
                variables: [
                    {
                        name: 'FBA_Team',
                        desc: 'Team approach to FBA',
                        relatedText: '',
                        relatedLink: '',
                        values: new HorizontalBarValues()
                    },
                    {
                        name: 'FBA_Def',
                        desc: 'Operational definition of behaviors',
                        relatedText: '',
                        relatedLink: '',
                        values: new HorizontalBarValues()
                    },
                    {
                        name: 'FBA_Data',
                        desc: 'Collecting data',
                        relatedText: '',
                        relatedLink: '',
                        values: new HorizontalBarValues()
                    },
                    {
                        name: 'FBA_Hypo',
                        desc: 'Synthesizing data into a hypothesis statement',
                        relatedText: '',
                        relatedLink: '',
                        values: new HorizontalBarValues()
                    },
                ]
            },
            {
                name: 'BIP',
                variables: [
                    {
                        name: 'BIP_Categories',
                        desc: 'BIP Components',
                        relatedText: '',
                        relatedLink: '',
                        values: new HorizontalBarValues()
                    },
                    {
                        name: 'BIP_FBA',
                        desc: 'Alignment with FBA',
                        relatedText: '',
                        relatedLink: '',
                        values: new HorizontalBarValues()
                    },
                    {
                        name: 'BIP_EBP',
                        desc: 'Using Evidence-based and least-to-most restrictive strategies',
                        relatedText: '',
                        relatedLink: '',
                        values: new HorizontalBarValues()
                    },
                    {
                        name: 'BIP_Measure',
                        desc: 'Measurable and implementable strategies',
                        relatedText: '',
                        relatedLink: '',
                        values: new HorizontalBarValues()
                    },
                    {
                        name: 'BIP_Eval',
                        desc: 'Action and evaluation plans',
                        relatedText: '',
                        relatedLink: '',
                        values: new HorizontalBarValues()
                    },
                ]
            },
        ],
    }
};
