/**
 *
 */
var CustomReportLightsActivity = /** @class */ (function () {
    function CustomReportLightsActivity() {
    }
    return CustomReportLightsActivity;
}());
/**
 * Custom report lights for each section, comprised of 1 or more activities
 */
var CustomReportLightsSection = /** @class */ (function () {
    function CustomReportLightsSection() {
    }
    return CustomReportLightsSection;
}());
export { CustomReportLightsSection };
/**
 * Custom report lights for individual emails
 */
var CustomReportLights = /** @class */ (function () {
    function CustomReportLights() {
    }
    return CustomReportLights;
}());
export { CustomReportLights };
/**
 * Default values for Group Traffic Lights:
 * - red: 0-50
 * - yellow: 51-75
 * - green: 76-100
 */
var TrafficLightGroupLogic = /** @class */ (function () {
    function TrafficLightGroupLogic(opts) {
        this.red = {
            min: 0,
            max: 50
        };
        this.yellow = {
            min: 51,
            max: 75
        };
        this.green = {
            min: 76,
            max: 100
        };
        var _a = opts || {}, red = _a.red, yellow = _a.yellow, green = _a.green;
        this.red = red || this.red;
        this.yellow = yellow || this.yellow;
        this.green = green || this.green;
    }
    return TrafficLightGroupLogic;
}());
export { TrafficLightGroupLogic };
/**
 * Custom Report default `varDict` object
 */
var CustomReportGroupVarDict = /** @class */ (function () {
    function CustomReportGroupVarDict() {
        this.type = 'group';
    }
    return CustomReportGroupVarDict;
}());
export { CustomReportGroupVarDict };
// ===========================
// Sample empty  CustomReportGridColumn
/*
{
    parent: '',
    variablename: '',
    desc: '',
    trafficLight: '',

    trafficLightGroupLogic: {
        red: {
            min: 1,
            max: 1
        },
        yellow: {
            min: 1,
            max: 1
        },
        green: {
            min: 1,
            max: 1
        }
    },

    drilldown: {
        vars: {
            opts: {
                prefix: false
            },
        },

        template(varDict: any) {

            return '';
        }
    }
}
*/ 
