export var choiceTextMap = {
    // Individual > Create > Choice made =================================
    var_3aTexta: 'Show the box with figures in it',
    var_3aTextb: 'Say “How many?”',
    var_3aTextc: 'Say “Count these.”',
    var_3aTextd: 'Say “Clap your hands.”',
    var_4aTexta: 'Ask Gabriel to “Put them in the box.”',
    var_4aTextb: 'Ask Gabriel what color something is. For example, “What color is Woody’s hat?”',
    var_4aTextc: 'Ask Gabriel to show you a character. For example, “Show me Woody.”',
    var_4aTextd: 'Ask Gabriel to count five characters.',
    var_5aTexta: 'Bubbles',
    var_5aTextb: 'Fidget toy',
    var_5aTextc: 'Books',
    var_5aTextd: 'Toy Story characters',
    var_5aTexte: 'Goldfish',
    var_6aTexta: 'Choose between characters (for example, Buzz or Woody)',
    var_6aTextb: 'Choose between counting 3 or 1 objects',
    var_6aTextc: 'Choice of books or playing with Toy Story figurines at end of the activity',
    var_6aTextd: 'Choice of math lesson or reading books',
    var_6aTexte: 'Choice of counting items/characters in a book or Toy Story characters',
    // Individual > Respond > Choice made =================================
    var_7aTexta: 'Appropriate student behavior',
    var_7aTextb: 'Reasonable attempt',
    var_7aTextc: 'Inappropriate student behavior',
    var_7aTextd: 'Incorrect student behavior',
    var_8part1aTexta: 'Model counting objects',
    var_8part1aTextb: 'Gesture to the correct number icon',
    var_8part1aTextc: 'Say, “Count these,” repeatedly without other supports to increase independence',
    var_8part1aTextd: 'Use a verbal prompt, “Give me X number.”',
    var_8part1aTexte: 'Use hand over hand to count objects while saying numbers after Gabriel does not respond to other prompts',
    var_8part1aTextf: 'Repeatedly say Gabriel’s name to make sure he is focused.',
    var_8part2aTexta: 'Direct reward',
    var_8part2aTextb: 'Reward attempt',
    var_8part2aTextc: 'No reward/prompt (inappropriate behavior)',
    var_8part2aTextd: 'No reward/prompt (incorrect behavior)',
    // Individual > Turns > Choice made =================================
    var_9aTexta: 'Model pointing and counting items presented',
    var_9aTextb: 'Model \'my turn\'',
    var_9aTextc: 'Model appropriately playing with toys (making sounds to represent each character and moving them around)',
    var_9aTextd: 'Have students take turns playing with the figurines',
    var_9aTexte: 'Put the Toy Story characters away and model how to play with blocks',
    // Group > Create > Choice made =================================
    var_2bTexta: 'Say “Show me your coins.”',
    var_2bTextb: 'Remove the coins when the students get answers correct.',
    var_2bTextc: 'Place the dice and fake coins in front of the students and say “OK, you may begin.”',
    var_2bTextd: 'When students complete a problem, say “Now make the word problem.”',
    var_3bTexta: 'Point to the coins',
    var_3bTextb: 'Open students’ bag of coins, and put them in front of each student',
    var_3bTextc: 'Ask students multiplication problems between money related problems',
    var_4bTexta: 'Ask students to name coin values',
    var_4bTextb: 'Ask students to take turns to roll the dice',
    var_4bTextc: 'Ask students to count to ten',
    var_4bTextd: 'Ask students to find the area of a squares',
    var_5bTexta: 'Incorporate Star Wars characters',
    var_5bTextb: 'Students like the game-based learning with the money dice',
    var_5bTextc: 'Students like to write with smelly markers',
    var_5bTextd: 'Complete the lesson outside, since the students like to be outside',
    // Group > Respond > Choice made =================================
    var_7bTexta: 'Appropriate student behavior',
    var_7bTextb: 'All responses will be either factually correct or incorrect (not applicable)',
    var_7bTextc: 'Inappropriate student behavior',
    var_7bTextd: 'Incorrect student behavior',
    var_8part1bTexta: 'Gesture to the number the student needs to focus on (rework)',
    var_8part1bTextb: 'Model the word problem',
    var_8part1bTextc: 'Use a verbal prompt to provide specific corrections and steps to work out the problem',
    var_8part1bTextd: 'Give students the correct answer.',
    var_8part1bTexte: 'Tell the students they are incorrect but allow them to find the correct answer on their own.',
    var_8part2bTexta: 'Direct reward',
    var_8part2bTextc: 'No reward/prompt (inappropriate behavior)',
    var_8part2bTextd: 'No reward/prompt (incorrect behavior)',
    // Group > Turns > Choice made =================================
    var_9bTexta: 'Take turns selecting the activity to be completed',
    var_9bTextb: 'Take turns rolling dice',
    var_9bTextc: 'Take turns reading the problem to one another',
    var_9bTextd: 'The students struggle with turn-taking so give each student their own set of dice to complete the activity independently',
};
