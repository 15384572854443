var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
// import { reportUtils } from "../report-utils";
import { messaging, utils, googleChart } from '../../../shared/exports';
import { xapiUtils } from '../../../xapi/xapi-utils';
import { Search } from "../../../xapi/xapi-classes";
import { MiMTSSChartActivity, UserStmts } from "../../../shared/interfaces-classes/index";
import { drilldownGrid } from '../../../shared/custom-report/drilldown-grid';
import { structures } from '../mimtss/reports/structures/exports';
import { reportUtils } from '../../report-utils';
var addedColumns = [
    {
        index: 2,
        width: 130,
        header: 'District Name',
        propName: 'districtName',
        propType: 'string',
        data: {
            type: 'context',
            lookInProp: 'id',
            textInProp: '/district-name/',
            returnProp: 'name',
        },
    },
    {
        index: 3,
        width: 100,
        header: 'District Code',
        propName: 'districtCode',
        propType: 'string',
        data: {
            type: 'context',
            lookInProp: 'id',
            textInProp: '/district-code/',
            returnProp: 'name',
        },
    },
    {
        index: 4,
        width: 100,
        header: 'District Level',
        propName: 'districtLevel',
        propType: 'string',
        data: {
            type: 'context',
            lookInProp: 'id',
            textInProp: '/districtLevel/',
            returnProp: 'name',
        },
    },
    {
        index: 5,
        width: 130,
        header: 'Facility Name',
        propName: 'facilityName',
        propType: 'string',
        data: {
            type: 'context',
            lookInProp: 'id',
            textInProp: '/facility-name/',
            returnProp: 'name',
        },
    },
    {
        index: 6,
        width: 100,
        header: 'Facility Code',
        propName: 'facilityCode',
        propType: 'string',
        data: {
            type: 'context',
            lookInProp: 'id',
            textInProp: '/facility-code/',
            returnProp: 'name',
        },
    },
    {
        index: 7,
        width: 130,
        header: 'Facility City',
        propName: 'facilityCity',
        propType: 'string',
        data: {
            type: 'context',
            lookInProp: 'id',
            textInProp: '/facility-city/',
            returnProp: 'name',
        },
    },
    {
        index: 8,
        width: 100,
        header: 'On SLT',
        propName: 'slt',
        propType: 'string',
        data: {
            type: 'context',
            lookInProp: 'id',
            textInProp: '/slt/',
            returnProp: 'name',
        },
    },
];
var removedColumns = [
    'percentageViewed',
    'duration',
];
var ctxtPropList = [
    'scoreRaw',
    'districtCode', 'districtName', 'districtlevel',
    'facilityCode', 'facilityName', 'facilityCity', 'facilitySlt'
];
export var mimtss = {
    type: '',
    report: {},
    originalSubtype: '',
    structures: structures,
    addedColumns: addedColumns,
    removedColumns: removedColumns,
    activityListForCharts: [],
    horizontalBarData: {},
    filterData: [],
    formData: null,
    search: null,
    postSearchFilter: null,
    uniqueUsers: null,
    completeUsers: null,
    onSubmitCallback: function () { },
    init: function (stmts) {
        var _this = this;
        var type = this.type;
        return new Promise(function (resolve, reject) {
            var invalid = !_this[type];
            if (invalid) {
                alert('Custom report not available yet; showing standard report.');
                reject('invalid');
            }
            else {
                // this[type].init(stmts, mimtss);
                _this[type].init(stmts);
                resolve(true);
            }
        });
    },
    setCustomParameters: function (projectData, settings) {
    },
    // for custom reports
    // setCustomParameters(reportConfig: any, query: any) {
    setCustomParameters__BACKUP: function (projectData, settings) {
        var districtCode = (settings || {}).districtCode;
        // Custom Cafeteria direct deep link info, manually set from config
        if (districtCode) {
            var _a = (projectData || {}).courseList, courseList = _a === void 0 ? [] : _a;
            // let currentCourse = <ConfigPropsCourse>{};
            var currentReport_1 = {};
            courseList.forEach(function (crs) {
                var matchingReport = crs.reports.find(function (report) { report.id === 'cafeteria'; });
                if (matchingReport) {
                    currentReport_1 = matchingReport;
                    // currentCourse = crs;
                }
            });
            // const instanceConfig: ConfigPropsReport = reports.find((report) => {
            // 	return report.settings?.course === 'cafeteria';
            // });
            var _b = currentReport_1 || {}, name_1 = _b.name, id = _b.id;
            $('.page-title').html(name_1);
            mimtss.report = {
                id: id,
                shortId: id,
                name: name_1,
                type: 'summary',
            };
        }
        /*
            const { reports = [] } = reportConfig || {};
            const { districtCode } = query || {};
    
            // Custom Cafeteria direct deep link info, manually set from config
            if (districtCode) {
                const instanceConfig = reports.find((report: any) => {
                    // return report.slug === 'cafeteria';
                    return report.slug === 'cafeteria';
                }) || {};
    
                const { id, title } = instanceConfig;
    
                $('.page-title').html(title);
    
                mimtss.report.id = id;
                mimtss.report.title = title;
                mimtss.type = 'summary';
            }
        */
    },
    getxAPISearchValues: function (search) {
        // console.log({ search, custom: mimtss.search });
        var finalSearch = Object.assign({}, (mimtss.search || search));
        finalSearch.limit = 0;
        // const isCompletion = mimtss.report.subtype === 'completions';
        // FIX: Temp for West Branch
        // get all verbs and filter
        // if (!isCompletion) {
        // 	finalSearch.verb = finalSearch.verb || 'http://adlnet.gov/expapi/verbs/responded';
        // 	if (window.location.href.includes('sandbox')) {
        // 		finalSearch.verb = finalSearch.verb || 'http://adlnet.gov/expapi/verbs/answered';
        // 	}
        // }
        // const sinceDate = '2021-02-28';
        // finalSearch.since = utils.getISOString(sinceDate);
        // console.log('finalSearch:', finalSearch);
        return finalSearch;
    },
    summary: {
        init: function (stmts) {
            // if (!stmts || !stmts.length) {
            // 	mimtss.ui.displayNoData();
            // 	return;
            // }
            utils.sortStmtArrayByTimestamp(stmts);
            var processedData = this.processRawTincanStmts(stmts);
            this.build(processedData);
        },
        processRawTincanStmts: function (stmts) {
            var standardStmts = xapiUtils.getStandardStmtsFromTincan(stmts);
            var filteredStmts = this.applySecondaryFilters(standardStmts);
            var exts = ['variableName', 'variablename']; // camel case to cover both types
            var simplifiedStmts = xapiUtils.getSimplifiedStmtsFromStandard(mimtss.report.id, filteredStmts, exts);
            return simplifiedStmts;
        },
        /** Filter LRS results post-search
         * @param data Array of statements
         */
        applySecondaryFilters: function (stmts) {
            return stmts.filter(function (stmt) {
                var postSearchFilter = mimtss.postSearchFilter;
                var activity = (postSearchFilter || {}).activity;
                if (!activity) {
                    return true;
                }
                var _a = (stmt.context || {}).contextActivities, contextActivities = _a === void 0 ? {} : _a;
                var _b = contextActivities.grouping, grouping = _b === void 0 ? [] : _b;
                var matched = grouping.find(function (ctxtGroup) {
                    return ctxtGroup.id === activity;
                });
                return matched;
            });
        },
        build: function (data) {
            console.log('data:', data);
            var subtype = mimtss.report.subtype;
            var isCharts = subtype === 'charts';
            var isHorizontalBar = subtype === 'horizontalBar';
            var isCompletions = subtype === 'completions';
            var ui = mimtss.ui;
            // if (!data.length) {
            // 	ui.displayNoData();
            // 	return;
            // }
            if (isCompletions) {
                $('.chart-container').addClass('hidden');
                $('.completion-list-container').removeClass('hidden');
                // NOTE: currently filtering data since completed verbs aren't in course
                var userCompletionMap = this.getUserCompletionMap(data);
                ui.completionList.init(userCompletionMap);
            }
            else {
                var activityListHasData = false;
                if (isCharts) {
                    mimtss.activityListForCharts = this.createActivityListForCharts(data);
                    console.log('mimtss.activityListForCharts:', mimtss.activityListForCharts);
                    activityListHasData = !!mimtss.activityListForCharts.length;
                }
                if (isHorizontalBar) {
                    mimtss.horizontalBarData = this.getHorizontalBarData(data);
                    activityListHasData = mimtss.horizontalBarData.hasData;
                }
                if (activityListHasData) {
                    ui.charts.init(subtype);
                }
                else {
                    console.log('No chart data');
                    mimtss.ui.displayNoData();
                    return;
                }
            }
        },
        createSimpleDataRecordFromStmt: function (stmt) {
            var record = {};
            ctxtPropList.forEach(function (prop) { return record[prop] = stmt[prop]; });
            return record;
        },
        getPrePostDataProp: function (stmt) {
            var _a = stmt.variablename, variablename = _a === void 0 ? '' : _a;
            var isPre = variablename.includes('pre');
            var isPost = variablename.includes('post');
            return isPre ? 'preTest' : isPost ? 'postTest' : null;
        },
        // OLD way, from when there was a Parent ctxtAct defined in xapi.ly:
        // getPrePostDataProp(stmt: SimplifiedStatement): string {
        // 	let isPre = false;
        // 	let isPost = false;
        // 	for (let prop in stmt) {
        // 		isPre = prop === 'preTest';
        // 		isPost = prop === 'postTest';
        // 		if (isPre || isPost) {
        // 			break;
        // 		}
        // 	}
        // 	return isPre ? 'preTest' : isPost ? 'postTest' : null;
        // },
        createUserMap: function (data) {
            var self = mimtss.summary;
            var userMap = new Map();
            data.forEach(function (stmt) {
                var userId = stmt.userId, _a = stmt.variablename, variablename = _a === void 0 ? '' : _a, scoreRaw = stmt.scoreRaw, verb = stmt.verb, activityName = stmt.activityName, activityDescription = stmt.activityDescription;
                // FIX TEMP?
                if ((verb !== 'answered') && (verb !== 'responded')) {
                    return;
                }
                var thisUser = userMap.get(userId) || new UserStmts();
                // TODO
                // • determine pre or post, to get first or last behavior
                // • if PRE, skip if it exists
                // • if POST, overwrite if it exists
                // FIX TEMP for West Branch 
                var preOrPostProp = self.getPrePostDataProp(stmt);
                var isPos = variablename && variablename.includes('positive');
                var isPun = variablename && variablename.includes('punitive');
                var isNoCtrl = variablename && variablename.includes('nocontrol');
                var varKey = isPos ? 'controlpositive' : isPun ? 'controlpunitive' : isNoCtrl ? 'nocontrol' : null;
                if (!varKey) {
                    if (variablename === 'responsedifference') {
                        thisUser.activityInfo.responsedifference.name = activityName;
                        thisUser.activityInfo.responsedifference.description = activityDescription;
                        if (thisUser.scores.responsedifference === null) {
                            thisUser.scores.responsedifference = scoreRaw;
                        }
                        thisUser.diffRecords.push(stmt);
                        userMap.set(userId, thisUser);
                        return;
                    }
                    console.warn('No variable key:', stmt);
                    return;
                }
                if (preOrPostProp) {
                    // correct way
                    var scoreKey = preOrPostProp === 'preTest' ? 'pre' : 'post';
                    var curVal = thisUser.scores[scoreKey][varKey];
                    // if ((scoreKey === 'pre' && !curVal) || scoreKey === 'post') {
                    if (curVal == null) {
                        thisUser.scores[scoreKey][varKey] = scoreRaw;
                    }
                }
                else {
                    // incorrect old way, so fix it
                    thisUser.hasOldScores = true;
                    var scoreKey = stmt.preTest ? 'pre' : 'post';
                    var num = variablename.replace(/\D/g, '');
                    var curVal = thisUser.oldScores[scoreKey][varKey][num];
                    if (curVal == null) {
                        thisUser.oldScores[scoreKey][varKey][num] = scoreRaw;
                    }
                }
                thisUser.records.push(stmt);
                userMap.set(userId, thisUser);
            });
            mimtss.uniqueUsers = userMap.size;
            mimtss.completeUsers = userMap.size;
            // FIX TEMP for West Branch
            // Process oldScores
            userMap.forEach(function (user) {
                var hasOldScores = user.hasOldScores, oldScores = user.oldScores, scores = user.scores;
                if (!hasOldScores) {
                    return;
                }
                var ordinals = ['pre', 'post'];
                var varNames = ['controlpositive', 'controlpunitive', 'nocontrol'];
                ordinals.forEach(function (ordinal) {
                    varNames.forEach(function (varName) {
                        var thisVar = oldScores[ordinal][varName];
                        if ((thisVar[1] !== null) && (thisVar[2] !== null)) {
                            user.scores[ordinal][varName] = (thisVar[1] + thisVar[2]) / 2;
                        }
                    });
                });
                if (user.scores.post.controlpositive && user.scores.pre.controlpositive) {
                    user.scores.responsedifference = user.scores.pre.controlpositive - user.scores.post.controlpositive;
                }
            });
            // Flag incomplete or out of range users
            userMap.forEach(function (user) {
                var scores = user.scores;
                if (scores.responsedifference == null) {
                    mimtss.completeUsers -= 1;
                }
                for (var preOrPostOrRd in scores) {
                    if (preOrPostOrRd === 'responsedifference') {
                        break;
                    }
                    for (var variableStem in scores[preOrPostOrRd]) {
                        var scoreRaw = scores[preOrPostOrRd][variableStem];
                        if (scoreRaw == null) {
                            user.isComplete = false;
                            break;
                        }
                        if (scoreRaw > 6) {
                            user.outOfRange = true;
                        }
                        if (scoreRaw == 0) {
                            user.partialResults = true;
                        }
                    }
                }
            });
            return userMap;
        },
        /**
         * Create sorted list with each activity's info and records
         * @param data Filtered simplified statement array
         * @returns Activity List array
         */
        createActivityListForCharts: function (data) {
            var self = mimtss.summary;
            var userMap = self.createUserMap(data);
            console.log('userMap:', userMap);
            var activityMap = new Map();
            userMap.forEach(function (user) {
                var activityInfo = user.activityInfo, scores = user.scores, isComplete = user.isComplete, outOfRange = user.outOfRange, partialResults = user.partialResults;
                if (outOfRange) {
                    console.warn('user out of range:', user);
                    return;
                }
                if (partialResults) {
                    console.error('user has 0 in results:', user);
                    return;
                }
                if (!isComplete) {
                    console.warn('user is not complete:', user);
                    return;
                }
                for (var preOrPostOrRd in scores) {
                    if (preOrPostOrRd === 'responsedifference') {
                        var variablename = preOrPostOrRd;
                        var thisActivity = activityMap.get(variablename) || new MiMTSSChartActivity(variablename);
                        var _a = activityInfo[variablename], name_2 = _a.name, description = _a.description;
                        thisActivity.name = name_2 || thisActivity.name;
                        thisActivity.description = description || thisActivity.description;
                        var scoreRaw = scores[variablename];
                        if (scoreRaw == null) {
                            break;
                        }
                        var tally = thisActivity.frequencyMap.get('tally') || {
                            total: 0,
                            records: 0,
                        };
                        tally.total += scoreRaw;
                        tally.records += 1;
                        thisActivity.frequencyMap.set('tally', tally);
                        activityMap.set(variablename, thisActivity);
                    }
                    else {
                        for (var variableStem in scores[preOrPostOrRd]) {
                            var variablename = variableStem + preOrPostOrRd;
                            var scoreRaw = scores[preOrPostOrRd][variableStem];
                            var thisActivity = activityMap.get(variablename) || new MiMTSSChartActivity(variablename);
                            var frequencyRank = thisActivity.frequencyMap.get(scoreRaw) || 0;
                            frequencyRank += 1;
                            thisActivity.frequencyMap.set(scoreRaw, frequencyRank);
                            activityMap.set(variablename, thisActivity);
                        }
                    }
                }
            });
            var activityListForCharts = __spreadArray([], __read(activityMap.values())).map(function (activity) {
                if (activity.variablename === 'responsedifference') {
                    return activity;
                }
                var frequencyMap = activity.frequencyMap;
                // format:
                // [rank, preval, postval]
                var sortedMap = utils.sortMap(frequencyMap);
                sortedMap.forEach(function (freqs, rank) {
                    var freqDataRow = [rank, freqs];
                    activity.frequencyList.push(freqDataRow);
                });
                activity.frequencyList.sort();
                return activity;
            });
            return activityListForCharts;
        },
        getHorizontalBarData: function (data) {
            var structures = mimtss.structures;
            var structure = structures[mimtss.report.id];
            var testMode = true; // fake data for testing
            var variablesWithData = 0;
            structure.categoryList.forEach(function (category) {
                category.variables.forEach(function (variable) {
                    var filteredData = data.filter(function (stmt) { return stmt.variablename === variable.name; });
                    variable.values.total = filteredData.length;
                    if (filteredData.length) {
                        variablesWithData += 1;
                    }
                    filteredData.forEach(function (stmt) {
                        var response = stmt.response;
                        var isTrue = response === 'true' || response === true;
                        if (isTrue) {
                            variable.values.true += 1;
                        }
                    });
                    if (testMode) {
                        var testValues = mimtss.utils.getRandomInt(30, 100);
                        variable.values.true = testValues.min;
                        variable.values.total = testValues.max;
                        variablesWithData = 3;
                    }
                });
            });
            structure.hasData = variablesWithData > 0;
            console.log('horizontal bar structure:', structure);
            return structure;
        },
        getUserCompletionMap: function (data) {
            console.log('data.length:', data.length);
            var userMap = new Map();
            function defaultUserOpts() {
                this.name = '';
                this.email = '';
                this.completedAt = null;
                this.districtName = '';
                this.facilityCity = '';
                this.facilityName = '';
                this.stmtList = [];
                // TEMP for West Branch
                this.hasOldScores = false;
            }
            ;
            data.forEach(function (stmtRow) {
                var userId = stmtRow.userId, verb = stmtRow.verb, variablename = stmtRow.variablename;
                // const email = mbox.replace('mailto:', '');
                if ((verb !== 'answered') && (verb !== 'responded')) {
                    return;
                }
                var userOpts = new defaultUserOpts();
                var user = userMap.get(userId) || userOpts;
                user.name = stmtRow.name || '';
                user.email = userId;
                user.districtName = stmtRow.districtName || '';
                user.facilityCity = stmtRow.facilityCity || '';
                user.facilityName = stmtRow.facilityName || '';
                if (!user.hasOldScores && variablename) {
                    var hasOldScores = stmtRow.variablename.replace(/\D/g, ''); // get number in name
                    user.hasOldScores = !!hasOldScores;
                }
                user.stmtList.push(stmtRow);
                userMap.set(userId, user);
            });
            function sortMapByDate(a, b) {
                var aDate = new Date(a.date);
                var bDate = new Date(b.date);
                if (aDate < bDate) {
                    return 1;
                }
                if (aDate > bDate) {
                    return -1;
                }
                return 0;
            }
            function sortMapByCompleted(a, b) {
                var aDate = a[1].completedAt;
                var bDate = b[1].completedAt;
                if (aDate < bDate) {
                    return 1;
                }
                if (aDate > bDate) {
                    return -1;
                }
                return 0;
            }
            // const TOTAL_STATEMENTS_NEW = 12;
            var TOTAL_STATEMENTS_NEW = 6; // MK - new is now back to 6 like the old
            var TOTAL_STATEMENTS_OLD = 12; // TEMP West Branch fix
            var TOTAL_STATEMENTS = null;
            // data reworked around this time,
            //  so totals are different before/after this date
            var oldTotalThresholdDate = new Date('2021-02-28');
            userMap.forEach(function (user, userId) {
                user.stmtList.sort(sortMapByDate);
                var lastStmtCompleteDate = new Date(user.stmtList[0].date);
                var isOldFormat = user.hasOldScores || (lastStmtCompleteDate < oldTotalThresholdDate);
                TOTAL_STATEMENTS = isOldFormat ? TOTAL_STATEMENTS_OLD : TOTAL_STATEMENTS_NEW;
                var fakeCompletion = user.stmtList.length >= TOTAL_STATEMENTS;
                if (fakeCompletion) {
                    user.completedAt = lastStmtCompleteDate;
                }
                else {
                    userMap.delete(userId);
                }
            });
            var outputMap = new Map(__spreadArray([], __read(userMap)).sort(sortMapByCompleted));
            return outputMap;
        },
    },
    grid: drilldownGrid,
    ui: {
        init: function (appSettings) {
            var _this = this;
            // const { type, districtCode, subtype } = urlQuery || {};
            var _a = appSettings || {}, districtCode = _a.districtCode, course = _a.course, report = _a.report;
            var _b = report || {}, type = _b.type, subtype = _b.subtype;
            mimtss.report.subtype = subtype;
            // to toggle back to original after completions
            mimtss.originalSubtype = subtype;
            if (type === 'summary') {
                if (subtype === 'charts') {
                    googleChart.init();
                }
                var hideFilters = !!districtCode;
                this.filters.setup(hideFilters, function () {
                    if (districtCode) {
                        _this.loadDistrictOnly(districtCode);
                    }
                    else {
                        _this.bindSummaryEvents();
                    }
                });
            }
            else {
                reportUtils.bindActorFormEvents(mimtss, drilldownGrid);
            }
        },
        bindSummaryEvents: function () {
            var radioDistrictLevelSel = 'input.radio-btn[name="filter-district-level"]';
            var radioSltSel = 'input.radio-btn[name="filter-facility-slt"]';
            $(radioDistrictLevelSel).on('change', this.filters.onDistrictLevelChange.bind(this.filters));
            $(radioSltSel).on('change', this.filters.onSltChange.bind(this.filters));
            $('#filter-form').on('submit', this.onSubmitForm);
        },
        loadDistrictOnly: function (districtCode) {
            var thisDistrict = mimtss.filterData.find(function (district) {
                return district.id === districtCode.toString();
            });
            var _a = thisDistrict || {}, id = _a.id, text = _a.text;
            if (!id) {
                messaging.show('alert', 'District Code not found.');
                console.error('Cannot find district ID in list:', id);
                return;
            }
            $('.all-chart-container').prepend("<h1 class=\"direct-report-header\">" + text + "</h1>");
            // TODO: set form elements 
            console.log('thisDistrict:', thisDistrict);
            var $districtSelect = $('select#district-list');
            $districtSelect.append("<option value=\"" + id + "\">" + text + "</option>");
            $districtSelect.val(id).trigger('change');
            this.processFormSubmit();
        },
        displayNoData: function () {
            this.charts.hideChartArea();
            mimtss.ui.completionList.loading.hide();
            mimtss.ui.charts.hideLoading();
            messaging.show('alert', 'No data found');
        },
        onSubmitForm: function (e) {
            var _a;
            e.preventDefault();
            var _b = (((_a = e.originalEvent) === null || _a === void 0 ? void 0 : _a.submitter) || {}).id, buttonId = _b === void 0 ? '' : _b;
            if (buttonId === 'filter-button--completions') {
                mimtss.report.subtype = 'completions';
            }
            else {
                mimtss.report.subtype = mimtss.originalSubtype;
            }
            mimtss.ui.processFormSubmit();
        },
        processFormSubmit: function () {
            $('.completion-list-container, .all-chart-container, .horizontal-bar-container').addClass('hidden');
            var shortId = mimtss.report.shortId;
            mimtss.postSearchFilter = null;
            var rawFormData = $('#filter-form').serializeArray();
            if (shortId === 'fba-bip') {
                this.processFacilityTypeName(rawFormData);
            }
            else {
                this.processDistrictFacility(rawFormData);
            }
        },
        postDataProcess: function (search) {
            var subtype = mimtss.report.subtype;
            // NOTE: not getting completions from course, so manually filtering
            if (subtype === 'completions') {
                console.log('skipping completed verb search filter (dec 2020)');
                // search.verb = 'http://adlnet.gov/expapi/verbs/completed';
                mimtss.ui.completionList.loading.show();
            }
            else {
                mimtss.ui.charts.showLoading(subtype);
            }
            mimtss.search = search;
            // NOTE: app.actions.getStmtsStart() which then hits this report's init()
            mimtss.onSubmitCallback();
        },
        processDistrictFacility: function (rawFormData) {
            var createFormDataObj = mimtss.utils.createFormDataObj;
            var formData = {};
            var activityNameMap = {
                districtCode: 'district-code',
                // facilityCode: 'facility-code',
                facilityId: 'facility-id',
                facilitySlt: 'mtss',
            };
            rawFormData.forEach(function (field) {
                var name = field.name, value = field.value;
                if (name === 'filter-district-level' || name === 'filter-facility-slt') {
                    if (value === 'all') {
                        return;
                    }
                }
                var newName = name.replace('filter-', '');
                var newNameSplit = newName.split('-');
                var updatedFieldName = xapiUtils.camelCasify(newNameSplit);
                formData[updatedFieldName] = createFormDataObj(activityNameMap, updatedFieldName, value);
            });
            var districtCode = formData.districtCode, districtLevel = formData.districtLevel, facilityId = formData.facilityId, facilitySlt = formData.facilitySlt;
            console.log('formData:', formData);
            console.log('rawFormData:', rawFormData);
            var filterData = mimtss.filterData;
            var search = new Search();
            search.related_activities = true;
            if ((districtCode === null || districtCode === void 0 ? void 0 : districtCode.value) === 'all') {
                search.activity = mimtss.report.id;
                if (districtLevel === null || districtLevel === void 0 ? void 0 : districtLevel.value) {
                    search.activity = districtLevel === null || districtLevel === void 0 ? void 0 : districtLevel.activity;
                    if (facilitySlt === null || facilitySlt === void 0 ? void 0 : facilitySlt.value) {
                        search.activity = facilitySlt === null || facilitySlt === void 0 ? void 0 : facilitySlt.activity;
                    }
                }
                if (facilitySlt === null || facilitySlt === void 0 ? void 0 : facilitySlt.value) {
                    search.activity = facilitySlt === null || facilitySlt === void 0 ? void 0 : facilitySlt.activity;
                }
            }
            else {
                search.activity = districtCode === null || districtCode === void 0 ? void 0 : districtCode.activity;
                if (districtLevel === null || districtLevel === void 0 ? void 0 : districtLevel.value) {
                    mimtss.postSearchFilter = districtLevel;
                    if (facilitySlt === null || facilitySlt === void 0 ? void 0 : facilitySlt.value) {
                        mimtss.postSearchFilter = facilitySlt;
                    }
                }
                var thisDistrict = filterData.find(function (districtObj) {
                    return districtObj.id === (districtCode === null || districtCode === void 0 ? void 0 : districtCode.value);
                });
                formData.districtName = createFormDataObj(activityNameMap, 'district-name', thisDistrict.text);
                if (facilityId.value === 'all') {
                    // district has been selected, so use that
                    // then we can filter on SLT with the results
                }
                else {
                    search.activity = facilityId.activity;
                    var _a = (thisDistrict || {}).schools, schools = _a === void 0 ? [] : _a;
                    var thisFacility = schools.find(function (school) {
                        return school.id === facilityId.value;
                    });
                    formData.facilityName = createFormDataObj(activityNameMap, 'facility-name', thisFacility.text);
                    formData.facilityCity = createFormDataObj(activityNameMap, 'facility-city', thisFacility.city);
                    mimtss.postSearchFilter = (facilitySlt === null || facilitySlt === void 0 ? void 0 : facilitySlt.value) ? facilitySlt : null;
                }
            }
            mimtss.ui.postDataProcess(search);
        },
        processFacilityTypeName: function (rawFormData) {
            var createFormDataObj = mimtss.utils.createFormDataObj;
            console.log('rawFormData:', rawFormData);
            var formData = {};
            var activityNameMap = {
                facilityType: 'facility-type',
                facilityId: 'facility-id',
            };
            rawFormData.forEach(function (field) {
                var name = field.name, value = field.value;
                var fieldName = name.replace('filter-facility-', '');
                if (fieldName === 'id') {
                    var idSplit = value === null || value === void 0 ? void 0 : value.split('|');
                    var fieldMap = {
                        name: idSplit === null || idSplit === void 0 ? void 0 : idSplit[0],
                        code: idSplit === null || idSplit === void 0 ? void 0 : idSplit[1],
                    };
                    for (var thisField in fieldMap) {
                        var thisValue = fieldMap[thisField];
                        if (!thisValue) {
                            continue;
                        }
                        formData[thisField] = createFormDataObj(activityNameMap, thisField, thisValue);
                    }
                }
                else {
                    formData[fieldName] = createFormDataObj(activityNameMap, fieldName, value);
                }
            });
            var type = formData.type, name = formData.name, code = formData.code;
            var search = new Search();
            search.related_activities = true;
            console.log('type:', type);
            console.log('name:', name);
            console.log('code:', code);
            var getAllFacilityTypes = (type === null || type === void 0 ? void 0 : type.value) === 'all';
            var getAllFacilities = (name === null || name === void 0 ? void 0 : name.value) === 'all';
            if (getAllFacilityTypes) {
                search.activity = mimtss.report.id;
            }
            else {
                if (getAllFacilities) {
                    search.activity = type === null || type === void 0 ? void 0 : type.activity;
                }
                else {
                    search.activity = name === null || name === void 0 ? void 0 : name.activity;
                    // This is a secondary filter to ensure the name/code combo is correct (there are a few duplicates in the source data)
                    mimtss.postSearchFilter = code;
                }
            }
            console.log('search:', search);
            console.log('mimtss.postSearchFilter:', mimtss.postSearchFilter);
            mimtss.ui.postDataProcess(search);
        },
        filters: {
            defaultOpts: {
                selectOnClose: true,
            },
            setup: function (hideFilters, cb) {
                var _this = this;
                var shortId = mimtss.report.shortId;
                var isFbaBip = shortId === 'fba-bip';
                this.getData(function (filterData) {
                    if (filterData) {
                        filterData.sort(function (a, b) {
                            if (a.text > b.text) {
                                return 1;
                            }
                            if (a.text < b.text) {
                                return -1;
                            }
                            if (a.text === b.text) {
                                return 0;
                            }
                        });
                    }
                    if (isFbaBip) {
                        // For Select2 options - same as login
                        filterData.forEach(function (facilityType) {
                            facilityType.id = facilityType.text;
                            facilityType.facilities.forEach(function (facility) {
                                facility.id = facility.text + "|" + facility.code;
                            });
                        });
                    }
                    console.log('filterData:', filterData);
                    var selectAllOption = {
                        text: isFbaBip ? 'All Facility Types' : 'All Districts',
                        id: 'all',
                        selected: true,
                    };
                    filterData.unshift(selectAllOption);
                    mimtss.filterData = filterData;
                    if (!hideFilters) {
                        if (isFbaBip) {
                            $('#filter-district').addClass('hidden');
                            $('#filter-facility-type').removeClass('hidden');
                            $('.filter-facility-slt').addClass('hidden');
                        }
                        _this.createDistrictDropdown(isFbaBip);
                        _this.show();
                    }
                    cb();
                });
            },
            getData: function (cb) {
                var filename = 'districts-facilities.json';
                if (mimtss.report.shortId === 'fba-bip') {
                    filename = 'facility-type-name.json';
                }
                // TODO: get JSON
                $.getJSON(filename, cb)
                    .fail(function (jqxhr, textStatus, error) {
                    console.error('json error:', textStatus, error);
                });
            },
            createDistrictDropdown: function (isFbaBip) {
                var _this = this;
                var options = Object.assign({}, this.defaultOpts);
                // https://stackoverflow.com/a/57468425/5323112
                // This "fake ajax pagination" gives better performance
                var timer = null;
                options.ajax = {
                    delay: 100,
                    transport: function (params, success, failure) {
                        var pageSize = 10;
                        var term = (params.data.term || '').toLowerCase();
                        var page = (params.data.page || 1);
                        if (timer) {
                            clearTimeout(timer);
                        }
                        function setSelect2Options() {
                            timer = null;
                            var results = [];
                            if (isFbaBip) {
                                results = mimtss.filterData
                                    .filter(function (facilityType) { return facilityType.text.toLowerCase().includes(term); });
                            }
                            else {
                                results = mimtss.filterData
                                    .filter(function (district) { return district.text.toLowerCase().includes(term); });
                            }
                            var paged = results.slice((page - 1) * pageSize, page * pageSize);
                            var options = {
                                results: paged,
                                pagination: {
                                    more: results.length >= page * pageSize
                                }
                            };
                            success(options);
                        }
                        timer = setTimeout(setSelect2Options, params.delay);
                    }
                };
                if (isFbaBip) {
                    $('.select2-facility-type')
                        .select2(options)
                        .on('change.select2', function (e) {
                        _this.onFacilityTypeChange(e.target.value);
                    });
                }
                else {
                    $('.select2-district')
                        .select2(options)
                        .on('change.select2', function (e) {
                        _this.onDistrictChange(e.target.value);
                    });
                }
                this.updateFacilities([]);
            },
            deselectRadiosByName: function (name) {
                $('input[name="' + name + '"]').prop('checked', false);
            },
            getFacilityListFromFacilityTypeValue: function (facilityTypeValue) {
                var selectedDistrict = mimtss.filterData
                    .find(function (facilityType) { return facilityType.text == facilityTypeValue; });
                return selectedDistrict === null || selectedDistrict === void 0 ? void 0 : selectedDistrict.facilities;
            },
            getFacilityListFromDistrictCode: function (districtCode) {
                var selectedDistrict = mimtss.filterData
                    .find(function (district) { return district.id == districtCode; });
                return selectedDistrict === null || selectedDistrict === void 0 ? void 0 : selectedDistrict.schools;
            },
            onFacilityTypeChange: function (facilityTypeValue) {
                console.log('facilityTypeValue - update facility list:', facilityTypeValue);
                if (facilityTypeValue === 'all') {
                    this.updateFacilities([]);
                    return;
                }
                var facilityList = this.getFacilityListFromFacilityTypeValue(facilityTypeValue);
                this.updateFacilities(facilityList);
            },
            onDistrictChange: function (districtCode) {
                if (districtCode === 'all') {
                    this.updateFacilities([]);
                    return;
                }
                var facilityList = this.getFacilityListFromDistrictCode(districtCode);
                this.updateFacilities(facilityList);
            },
            onDistrictLevelChange: function (e) {
                var value = e.currentTarget.value;
                if (value === 'all') {
                    var facilitySltAllEl = document.querySelector('#filter-facility-slt-all');
                    facilitySltAllEl.checked = true;
                }
                var selectedValue = value === 'all' ? 'false' : value;
                var showFacilityList = selectedValue == 'false';
                this.changeBlockVisibility('filter-facility-wrapper', showFacilityList);
                if (showFacilityList) {
                    var districtCode = $('#district-list').val();
                    var facilityCode = $('#facility-list').val();
                    var doUpdateFacilities = districtCode !== 'all' && facilityCode === 'all';
                    if (doUpdateFacilities) {
                        var facilityList = this.getFacilityListFromDistrictCode(districtCode);
                        this.updateFacilities(facilityList);
                    }
                }
                else {
                    this.updateFacilities([]);
                    var allSltSel = document.getElementById('filter-facility-slt-all') || {};
                    allSltSel.checked = true;
                }
            },
            updateFacilities: function (facilityList) {
                var _this = this;
                if (facilityList === void 0) { facilityList = []; }
                var options = Object.assign({}, this.defaultOpts);
                var selectAllOption = {
                    text: 'All Facilities',
                    id: 'all',
                    selected: true,
                };
                facilityList.unshift(selectAllOption);
                options.data = facilityList;
                var $selectEl = $('#facility-list');
                var isInitialized = $selectEl.hasClass("select2-hidden-accessible");
                if (isInitialized) {
                    $selectEl.select2('destroy');
                    $selectEl.val(null).trigger('change');
                    $selectEl.empty();
                    $selectEl.append('<option value="all">' + selectAllOption.text + '</option>');
                }
                $selectEl
                    .select2(options)
                    .on('change.select2', function (e) {
                    _this.onFacilityBlockChange();
                });
            },
            onSltChange: function (e) {
                this.onFacilityBlockChange();
            },
            onFacilityBlockChange: function () {
                $('#filter-district-level-false').trigger('click');
            },
            changeBlockVisibility: function (blockClass, doShow) {
                var el = document.querySelector('.' + blockClass);
                doShow ? el.classList.remove('hidden') : el.classList.add('hidden');
            },
            show: function () {
                $('.dynamic.filters').removeClass('hidden');
            }
        },
        charts: {
            init: function (subtype) {
                if (subtype === 'charts') {
                    googleChart.loadChartApi('bar', this.drawCharts.bind(this));
                }
                if (subtype === 'horizontalBar') {
                    this.drawHorizontalBarCharts();
                }
            },
            showLoading: function (subtype) {
                $('.chart-body').addClass('hidden invisible');
                $('.chart-loading-container').removeClass('hidden');
                if (subtype === 'horizontalBar') {
                    $('.horizontal-bar-container').removeClass('hidden');
                }
            },
            hideLoading: function () {
                $('.chart-loading-container').addClass('hidden');
            },
            showChartArea: function () {
                $('.text.chart-container, .all-chart-container').removeClass('hidden');
            },
            hideChartArea: function () {
                $('.text.chart-container, .all-chart-container').addClass('hidden');
            },
            showHorizontalBarArea: function () {
                $('.horizontal-bar.chart-body').removeClass('hidden invisible');
                this.hideLoading();
            },
            hideHorizontalBarArea: function () {
            },
            drawCharts: function () {
                var _this = this;
                this.showChartArea();
                var activityListForCharts = mimtss.activityListForCharts;
                if (!activityListForCharts.length) {
                    return;
                }
                var responsedifferenceIndex = null;
                activityListForCharts.forEach(function (activity, index) {
                    // this.createChartHeaders(activity);
                    if (activity.variablename === 'responsedifference') {
                        responsedifferenceIndex = index;
                    }
                });
                var responsedifferenceActivity = activityListForCharts.splice(responsedifferenceIndex, 1)[0];
                this.createResponseDifferenceDisplay(responsedifferenceActivity, activityListForCharts);
                activityListForCharts.forEach(function (activity) {
                    _this.drawBarChart(activity);
                });
                /////////////////////////// Reference
                // const data = google.visualization.arrayToDataTable(chartData);
                // const options = {
                // 	// title: activity.name,
                // 	width: 600,
                // 	height: 360,
                // 	legend: { position: "none" },
                // 	hAxis: {
                // 		title: 'Frequency',
                // 		minValue: 0,
                // 		gridlines: {
                // 			minSpacing: 100,
                // 		},
                // 	},
                // 	vAxis: {
                // 		title: 'Average Range'
                // 	}
                // 	// orientation: 'horizontal',
                // };
                // Instantiate and draw our chart, passing in some options.
                // const chart = new google.visualization.Histogram(containerEl);
                // const chart = new google.visualization.BarChart(containerEl);
                // const containerEl = document.querySelector('.custom-report-container');
            },
            createSimpleHorizontalBarChart: function (values) {
                var textValue = values.true + " / " + values.total;
                var trueValue = values.true, total = values.total;
                var percent = Math.round((trueValue / total) * 100);
                // <div class=horizontal-bar-chart-value-text>${textValue}</div>
                return "\n\t\t\t\t\t<div class=\"horizontal-bar-chart-single\">\n\t\t\t\t\t\t<div class=\"horizontal-bar-chart-total\">\n\t\t\t\t\t\t\t<div class=\"horizontal-bar-chart-value\" style=\"width: " + percent + "%\">\n\t\t\t\t\t\t\t\t<div class=horizontal-bar-chart-value-text>" + textValue + "</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t";
            },
            drawHorizontalBarCharts: function () {
                console.log('mimtss.horizontalBarData:', mimtss.horizontalBarData);
                var _a = mimtss.horizontalBarData, title = _a.title, columns = _a.columns, categoryList = _a.categoryList;
                var titleEl = document.querySelector('.horizontal-bar-title');
                titleEl.innerHTML = title;
                var tableEl = document.querySelector('.horizontal-bar-table');
                var headerCells = columns.map(function (column) { return "<th>" + column + "</th>"; }).join('');
                var tableHtml = "<thead><tr>" + headerCells + "</tr></thead>";
                tableHtml += '<tbody>';
                categoryList.forEach(function (category) {
                    var categoryName = category.name, variables = category.variables;
                    variables.forEach(function (variable, vblIdx) {
                        var variableName = variable.name, desc = variable.desc, relatedText = variable.relatedText, relatedLink = variable.relatedLink, values = variable.values;
                        var firstVblRowInCategory = vblIdx === 0;
                        var rowspan = firstVblRowInCategory ? "rowspan=\"" + variables.length + "\"" : '';
                        var rowClass = firstVblRowInCategory ? 'first-row' : '';
                        var categoryNameCell = firstVblRowInCategory
                            ? "\n\t\t\t\t\t\t\t\t<td " + rowspan + " class=\"horizontal-bar-category\">" + categoryName + "</td>\n\t\t\t\t\t\t\t\t"
                            : '';
                        var barChart = mimtss.ui.charts.createSimpleHorizontalBarChart(values);
                        var variableRowHtml = "\n\t\t\t\t\t\t\t<tr data-variable=\"" + variableName + "\" class=\"" + rowClass + "\">\n\t\t\t\t\t\t\t\t" + categoryNameCell + "\n\n\t\t\t\t\t\t\t\t<td>" + desc + "</td>\n\n\t\t\t\t\t\t\t\t<td>\n\t\t\t\t\t\t\t\t\t<a href=\"" + relatedLink + "\" target=\"_blank\">" + (relatedText || 'Link') + "</a>\n\t\t\t\t\t\t\t\t</td>\n\n\t\t\t\t\t\t\t\t<td>" + barChart + "</td>\n\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t";
                        tableHtml += variableRowHtml;
                    });
                });
                tableHtml += '</tbody>';
                tableEl.innerHTML = tableHtml;
                this.showHorizontalBarArea();
            },
            createResponseDifferenceDisplay: function (activity, activityListForCharts) {
                // frequencyMap
                var _a = activity || {}, variablename = _a.variablename, name = _a.name, description = _a.description;
                var preActivity = activityListForCharts.find(function (act) {
                    return act.id === 'controlpositivepre';
                });
                var postActivity = activityListForCharts.find(function (act) {
                    return act.id === 'controlpositivepost';
                });
                var preFreqMap = (preActivity || {}).frequencyMap;
                var postFreqMap = (postActivity || {}).frequencyMap;
                function _sortMapAsList(sourceMap) {
                    return __spreadArray([], __read(sourceMap.entries())).sort(function (a, b) { return b[1] - a[1]; });
                }
                // const topPre = (preFreqMap && [...preFreqMap.entries()][0][1]) || 0;
                // const topPost = (postFreqMap && [...postFreqMap.entries()][0][1]) || 0;
                var topPre = (preFreqMap && _sortMapAsList(preFreqMap)[0][1]) || 0;
                var topPost = (postFreqMap && _sortMapAsList(postFreqMap)[0][1]) || 0;
                console.log('preFreqMap, postFreqMap:', preFreqMap, postFreqMap);
                console.log('topPre, topPost:', topPre, topPost);
                var diff = topPre ? ((topPost - topPre) / topPre) : 0;
                // OLD way (< 2022)
                // ------------------------------------
                // let total = 0;
                // let records = 0;
                // frequencyList.forEach((item: any) => {
                // 	const value = item[0];
                // 	const freq = item[1];
                // 	const occurences = value * freq;
                // 	total += occurences;
                // 	records += freq;
                // });
                // OLD way (< March 2022)
                // ------------------------------------
                // const tally = frequencyMap.get('tally');
                // const { total, records } = tally;
                // const average = (total / (records || 1)).toFixed(2);
                var percent = Math.round(diff * 100);
                var increase = percent + "%";
                $('.responsedifference-average .average-number').html(increase);
                $('.responsedifference-average .rd-users').html(mimtss.completeUsers);
                var incompleteUsers = mimtss.uniqueUsers - mimtss.completeUsers;
                // currently hidden
                $('.responsedifference-average .incomplete-users').html(incompleteUsers);
                var $chartBody = $('.chart-body', "." + variablename);
                $chartBody.removeClass('invisible');
                // $('.title', $chartBody).html(name);
                // $('.subtitle', $chartBody).html(description);
                $('.chart-loading-container', "." + variablename).addClass('hidden');
            },
            drawBarChart: function (activity) {
                var variablename = activity.variablename, frequencyList = activity.frequencyList;
                if (!variablename) {
                    return;
                }
                // const labelList = ['Rank', 'Pre', 'Post']; // old way
                var labelList = ['Rank', 'Total'];
                frequencyList.unshift(labelList);
                /*
                const chartDataList = frequencyList.map((row: any) => {
                    // If 1st column is string, no vertical gridlines (non-continuous)
                    // But it renders properly
                    // row[0] = row[0].toString();
                    return row;
                });
                */
                // const chartData = [];
                var chartData = google.visualization.arrayToDataTable(frequencyList);
                // https://jsfiddle.net/684moLq3/233/
                var commonOptions = {
                    // width: 800,
                    // height: 340,
                    height: 800,
                    // colors: ['#01aec5', '#db4437'],
                    // colors: ['#01aec5', '#147986'], // old way
                    colors: ['#01aec5'],
                    // Only works in Classic chart
                    // https://github.com/google/google-visualization-issues/issues/2143
                    // legend: {
                    // 	position: 'top',
                    // 	alignment: 'start',
                    // },
                    // legend: { position: "none" },
                    bars: 'horizontal',
                    hAxis: {
                        title: 'Frequency',
                        minValue: 0,
                    },
                    vAxis: {
                        gridlines: {
                            count: -1,
                        },
                        viewWindow: {
                            min: 0,
                            max: 6.5
                        }
                    },
                    bar: {
                        groupWidth: '100%',
                        // groupWidth: '80',
                    },
                    // NOTE: series/axes messes up the proper scale...don't know why, a bug?
                    // series: {
                    // 	0: { axis: 'frequency' }
                    // },
                    // axes: {
                    // 	y: {
                    // 		frequency: { label: 'Frequency' }, // Left y-axis.
                    // 	}
                    // },
                };
                // const containerEl = document.querySelector(`#${ variablename }.single.chart - wrapper`);
                // const containerEl = document.querySelector(`.${variablename}.single.chart - wrapper`);
                var containerEl = document.querySelector("." + variablename + " .single.chart-wrapper");
                if (!containerEl) {
                    console.warn('container not avail for:', variablename);
                    return;
                }
                var MATERIAL_CHARTS = true;
                var chart = null;
                var options = commonOptions;
                if (MATERIAL_CHARTS) {
                    commonOptions.legend = { position: "none" };
                    options = google.charts.Bar.convertOptions(commonOptions);
                    chart = new google.charts.Bar(containerEl);
                }
                else {
                    options.legend = {
                        position: 'top',
                        alignment: 'start',
                    };
                    chart = new google.visualization.ColumnChart(containerEl);
                }
                $('.chart-body').removeClass('hidden');
                google.visualization.events.addListener(chart, 'ready', function () {
                    $('.chart-body', "." + variablename).removeClass('invisible');
                    $('.chart-loading-container', "." + variablename).addClass('hidden');
                });
                chart.draw(chartData, options);
            },
            createChartHeaders: function (activity) {
                var name = activity.name, description = activity.description, variablename = activity.variablename;
                var $titleEl = $("." + variablename + ".title");
                var $subtitleEl = $("." + variablename + ".subtitle");
                if (variablename === 'responsedifference') {
                    var subtitle = 'Number indicates the percentage of increase in the frequency of learners prioritizing positive responses within staff control (or PBIS-friendly) when faced with behavioral issues. Specifically, this number indicates the increase in frequency of learners placing PBIS-friendly responses as their #1 and #2 options.';
                    $subtitleEl.html(subtitle);
                }
                else {
                    $subtitleEl.html(description);
                }
                $titleEl.html(name);
            },
        },
        completionList: {
            loading: {
                $el: $('.completion-loading-container'),
                show: function () {
                    this.$el.removeClass('hidden');
                },
                hide: function () {
                    this.$el.addClass('hidden');
                }
            },
            init: function (userCompletionMap) {
                console.log('userCompletionMap:', userCompletionMap);
                var $container = $('.completion-list-container');
                $container.removeClass('hidden');
                var html = "\n\t\t\t\t< div class= \"completions--totals\" > <strong>TOTAL: </strong> " + userCompletionMap.size + "</div >\n\n\t\t\t\t<table>\n\t\t\t\t<thead>\n\t\t\t\t<tr class=\"completions--header-row\" >\n\t\t\t\t<th class=\"completions--header-username\" > Name < /th>\n\t\t\t\t< th class= \"completions--header-email\" > Email < /th>\n\t\t\t\t< th class= \"completions--header-completed-at-date\" > Completed Date < /th>\n\t\t\t\t< th class= \"completions--header-completed-at-time\" > Completed Time < /th>\n\t\t\t\t< th class= \"completions--header-district\" > District < /th>\n\t\t\t\t< th class= \"completions--header-city\" > City < /th>\n\t\t\t\t< th class= \"completions--header-facility\" > Facility < /th>\n\t\t\t\t< /tr>\n\t\t\t\t< /thead>\n\t\t\t\t\t<tbody>\n\t\t\t\t\t";
                userCompletionMap.forEach(function (user) {
                    var userRowHtml = "\n\t\t\t\t< tr class= \"completions--user-row\" >\n\t\t\t\t<td class=\"completions-username\" > " + user.name + " < /td>\n\t\t\t\t< td class= \"completions-email\" > " + user.email + " < /td>\n\t\t\t\t< td class= \"completions-completed-at-date\" > " + new Date(user.completedAt).toLocaleDateString() + " < /td>\n\t\t\t\t< td class= \"completions-completed-at-time\" > " + new Date(user.completedAt).toLocaleTimeString() + " < /td>\n\t\t\t\t< td class= \"completions-district\" > " + user.districtName + " < /td>\n\t\t\t\t< td class= \"completions-city\" > " + user.facilityCity + " < /td>\n\t\t\t\t< td class= \"completions-facility\" > " + user.facilityName + " < /td>\n\n\t\t\t\t< /tr>\n\t\t\t\t\t";
                    html += userRowHtml;
                });
                html += "\n\t\t\t\t< /tbody>\n\t\t\t\t< /table>\n\t\t\t\t\t";
                $container.html(html);
                mimtss.ui.completionList.loading.hide();
            }
        }
    },
    utils: {
        encodeID: function (text) {
            text = text || "";
            return encodeURIComponent(text.replace(/\s/g, '-').toLowerCase()).replace(/[!'()*]/g, "");
        },
        createFormDataObj: function (activityNameMap, propName, value) {
            return {
                value: value,
                activity: mimtss.utils.createActivityId(activityNameMap, propName, value),
            };
        },
        createActivityId: function (activityNameMap, fieldName, value) {
            var type = activityNameMap[fieldName] || fieldName;
            var adjustedValue = value === 'true'
                ? 'yes'
                : value === 'false'
                    ? 'no'
                    : value;
            var encodedValue = mimtss.utils.encodeID(adjustedValue);
            return [mimtss.report.id, type, encodedValue].join('/');
        },
        getRandomInt: function (min, max) {
            var maxOutput = Math.floor(Math.random() * (max - min + 1)) + min;
            return {
                min: Math.floor(Math.random() * (maxOutput - min + 1)) + min,
                max: maxOutput
            };
        },
    }
};
