import { DrilldownTable, TrafficLightGroupLogic } from "../../../../../shared/interfaces-classes/index";
import { reportUtils } from '../../../../report-utils';
var processSingleVarHtml = reportUtils.processSingleVarHtml, processTemplateVarHtmlList = reportUtils.processTemplateVarHtmlList, getGenericGroupMsg = reportUtils.getGenericGroupMsg;
var genericGroupMsg = getGenericGroupMsg();
export var brokering = {
    'http://SISEPBrokering': {
        columns: [
            {
                parent: 'LO1 | LO2',
                variablename: '',
                desc: 'Provide an example of brokering | Explain the importance of brokering relationships',
                trafficLight: '',
                trafficLightGroupLogic: new TrafficLightGroupLogic(),
                drilldown: {
                    icon: 'text',
                    vars: {
                        response: [
                            'BrokerReflect',
                            'BrokerReason',
                            'BrokerStrategy',
                            'BrokerSuccess',
                            'SuccessReason',
                            'different',
                            'BrokerDifferent',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var BrokerReflect = varDict.BrokerReflect, BrokerReason = varDict.BrokerReason, BrokerStrategy = varDict.BrokerStrategy, BrokerSuccess = varDict.BrokerSuccess, SuccessReason = varDict.SuccessReason, different = varDict.different, BrokerDifferent = varDict.BrokerDifferent;
                        function getSuccessfulStrategy() {
                            if (!BrokerSuccess && !SuccessReason) {
                                return processSingleVarHtml('');
                            }
                            return "\n                <div>\n                  <strong>From 1 to 4:</strong> " + processSingleVarHtml(BrokerSuccess) + "\n                </div>\n                <div>\n                  <strong>Reason:</strong> " + processSingleVarHtml(SuccessReason) + "\n                </div>\n              ";
                        }
                        function getDifferent() {
                            var noDataStr = processSingleVarHtml('');
                            if (!different && !BrokerDifferent) {
                                return noDataStr;
                            }
                            var differentDisplay = different;
                            if (different) {
                                differentDisplay = different.toLowerCase() == 'true' ? 'Yes' : 'No';
                            }
                            else {
                                differentDisplay = noDataStr;
                            }
                            var brokerDiffDisplay = BrokerDifferent || noDataStr;
                            return differentDisplay + " - " + brokerDiffDisplay;
                        }
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: [
                                {
                                    header: 'Question',
                                    rows: [
                                        'Describe a time that you used brokering in your work',
                                        'Why did you decide to engage in brokering?',
                                        'What brokering strategy did you use?',
                                        'How successful was this strategy and why?',
                                        'Would you do anything differently next time?',
                                    ],
                                },
                                {
                                    header: 'Answer provided',
                                    rows: processTemplateVarHtmlList([
                                        BrokerReflect,
                                        BrokerReason,
                                        BrokerStrategy,
                                        getSuccessfulStrategy(),
                                        getDifferent(),
                                    ]),
                                },
                            ]
                        };
                        var drilldownTable = new DrilldownTable(drilldownTableOpts);
                        return drilldownTable.html;
                    }
                }
            },
            {
                parent: 'LO3',
                variablename: 'LO3dash',
                desc: 'Utilize brokering strategies',
                trafficLight: '',
                trafficLightGroupLogic: new TrafficLightGroupLogic(),
                drilldown: {
                    vars: {
                        response: [
                            'relationships',
                            'data',
                            'communication',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var relationships = varDict.relationships, data = varDict.data, communication = varDict.communication;
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: [
                                {
                                    type: 'checkmark',
                                    header: 'Establishing mutually beneficial relationships',
                                    rows: processTemplateVarHtmlList([
                                        relationships
                                    ]),
                                },
                                {
                                    type: 'checkmark',
                                    header: 'Sharing & using data',
                                    rows: processTemplateVarHtmlList([
                                        data
                                    ]),
                                },
                                {
                                    type: 'checkmark',
                                    header: 'Using communication protocols',
                                    rows: processTemplateVarHtmlList([
                                        communication
                                    ]),
                                },
                            ]
                        };
                        var drilldownTable = new DrilldownTable(drilldownTableOpts);
                        return drilldownTable.html;
                    }
                }
            },
        ],
        specialGroupVarDictFns: {},
    }
};
