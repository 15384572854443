var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { xapiIds } from './xapi-id-maps';
// import { xapiConfig } from './xapi-config';
import { LrsConfig, Agent, Search } from './xapi-classes';
import { messaging } from '../shared/exports';
var filteredVerbs = [
    'http://adlnet.gov/expapi/verbs/experienced',
    'http://activitystrea.ms/schema/1.0/leave',
    'http://adlnet.gov/expapi/verbs/progressed',
    // 'http://adlnet.gov/expapi/verbs/attempted',
];
export var xapi = {
    queryParams: {
        prod: false,
        sandbox: false,
    },
    init: function (lrsInfo, query) {
        if (!lrsInfo.endpoint) {
            return;
        }
        this.queryParams = query;
        this.connection.setup(lrsInfo);
    },
    lrs: null,
    search: new Search(),
    moreUrl: '',
    ids: xapiIds,
    statements: [],
    skippedCounter: 0,
    connection: {
        config: null,
        setup: function (lrsInfo) {
            this.config = new LrsConfig(lrsInfo);
            try {
                console.log('LRS endpoint:', this.config.endpoint);
                xapi.lrs = new TinCan.LRS(this.config);
            }
            catch (ex) {
                console.error("Failed to setup LRS object: ", ex);
            }
        },
        isSandbox: function (query) {
            var params = query || xapi.queryParams;
            var localRouteList = ['127.0.0.1', 'localhost'];
            var isLocal = localRouteList.includes(window.location.hostname);
            if (params && params.prod) {
                return false;
            }
            return (params && params.sandbox) || window.location.pathname.includes('sandbox/') || isLocal;
        },
    },
    create: {
        agent: function (type, mboxOrAccountName, homePage, name) {
            var agent = new Agent();
            if (name) {
                agent.name = name;
            }
            if (type === 'mbox') {
                agent.mbox = mboxOrAccountName;
            }
            else if (type === 'account') {
                agent.account = {
                    name: mboxOrAccountName,
                    homePage: homePage || 'http://fpg.unc.edu',
                };
            }
            else {
                console.log('mboxOrAccountName has no type identified:', mboxOrAccountName);
            }
            return agent;
        },
    },
    get: {
        statements: function (moreUrl) {
            var search = xapi.search;
            if (search.agent) {
                search.agent = new TinCan.Agent(search.agent);
            }
            if (!search.activity) {
                delete search.activity;
                delete search.related_activities;
            }
            return new Promise(function (resolve, reject) {
                var config = {
                    callback: function (err, response) {
                        if (err) {
                            reject(err);
                        }
                        xapi.moreUrl = response.more || '';
                        var stmts = response.statements;
                        if (stmts && stmts.length) {
                            stmts = stmts.filter(function (stmt) {
                                var filterTriggered = filteredVerbs.includes(stmt.verb.id);
                                if (filterTriggered) {
                                    xapi.skippedCounter += 1;
                                }
                                return !filterTriggered;
                            });
                        }
                        resolve(stmts);
                    }
                };
                if (moreUrl) {
                    config.url = xapi.moreUrl;
                    xapi.lrs.moreStatements(config);
                }
                else {
                    config.params = search;
                    xapi.lrs.queryStatements(config);
                }
            });
        },
        recursive: function () {
            return __awaiter(this, void 0, void 0, function () {
                // let counter = 0;
                function checkForMore() {
                    return __awaiter(this, void 0, void 0, function () {
                        var stmts;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!xapi.moreUrl) return [3 /*break*/, 2];
                                    console.log('xapi.moreUrl:', xapi.moreUrl);
                                    return [4 /*yield*/, xapi.get.statements(xapi.moreUrl)];
                                case 1:
                                    stmts = _a.sent();
                                    xapi.statements = xapi.statements.concat(stmts);
                                    console.log('xapi.statements.length:', xapi.statements.length);
                                    messaging.show('info', "Records found: <b>" + xapi.statements.length + "</b>");
                                    return [2 /*return*/, checkForMore()];
                                case 2:
                                    messaging.hide('info');
                                    return [2 /*return*/];
                            }
                        });
                    });
                }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, checkForMore()];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        },
        // scormCloudProfile(profileId: string, mbox: string): Promise<any> {
        // 	const isSandbox = xapi.connection.isSandbox();
        // 	const type = isSandbox ? 'sandbox' : 'prod';
        // 	const lrsInfo = scormCloudConfig[type];
        // 	const tempTinCan = new TinCan.LRS(lrsInfo);
        // 	return new Promise((resolve, reject) => {
        // 		const cfg = {
        // 			agent: new TinCan.Agent({ mbox: 'mailto:' + mbox }),
        // 			callback(err: any, response: any) {
        // 				if (err || !response) {
        // 					reject(false);
        // 				}
        // 				resolve(lrsInfo);
        // 			},
        // 		};
        // 		tempTinCan.retrieveAgentProfile(profileId, cfg)
        // 	});
        // },
    },
};
