import { utils } from "../../../../../shared/utils";
import { reportUtils } from '../../../../report-utils';
var getGenericGroupMsg = reportUtils.getGenericGroupMsg;
var genericGroupMsg = getGenericGroupMsg();
export var ca = {
    'http://SISEP-CA': {
        columns: [
            {
                parent: 'LO1',
                variablename: 'LO1dash',
                desc: 'Identifies relevant assessments',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 75
                    },
                    yellow: {},
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false
                        },
                        response: [
                            'fieldofwork', 'k12description', 'nonk12description',
                        ],
                        success: [
                            'DBPAadmin', 'DBPApartic',
                            'DCAadmin', 'DCApartic',
                            'RCAadmin', 'RCApartic',
                            'SCAadmin', 'SCApartic',
                            'ACAadmin', 'ACApartic',
                            'assesscorrect',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var fieldofwork = varDict.fieldofwork, k12description = varDict.k12description, nonk12description = varDict.nonk12description, DBPAadmin = varDict.DBPAadmin, DBPApartic = varDict.DBPApartic, DCAadmin = varDict.DCAadmin, DCApartic = varDict.DCApartic, RCAadmin = varDict.RCAadmin, RCApartic = varDict.RCApartic, SCAadmin = varDict.SCAadmin, SCApartic = varDict.SCApartic, ACAadmin = varDict.ACAadmin, ACApartic = varDict.ACApartic, assesscorrect = varDict.assesscorrect;
                        var assessments = {
                            worksInEd: {
                                DBPA: {
                                    admin: DBPAadmin,
                                    partic: DBPApartic
                                },
                                DCA: {
                                    admin: DCAadmin,
                                    partic: DCApartic
                                },
                                RCA: {
                                    admin: RCAadmin,
                                    partic: RCApartic
                                },
                                SCA: {
                                    admin: SCAadmin,
                                    partic: SCApartic
                                },
                            },
                            noWorksInEd: {
                                DBPA: {
                                    admin: DBPAadmin,
                                    partic: DBPApartic
                                },
                                ACA: {
                                    admin: ACAadmin,
                                    partic: ACApartic
                                },
                            }
                        };
                        var assessmentHtmlLists = {
                            worksInEd: [],
                            noWorksInEd: [],
                        };
                        for (var field in assessments) {
                            var thisAssess = assessments[field];
                            for (var assessName in thisAssess) {
                                var varVals = thisAssess[assessName];
                                var admin = varVals.admin, partic = varVals.partic;
                                // <span class="checkmark-neutral ${admin}"></span>
                                // <span class="checkmark-neutral ${partic}"></span>
                                var iconTypeAdmin = admin ? 'checkmark-circle-outline' : '';
                                var iconTypePartic = partic ? 'checkmark-circle-outline' : '';
                                var html = "\n\t\t\t\t\t\t\t\t\t<li>\n\t\t\t\t\t\t\t\t\t\t<label style=\"font-size: 1.15em;\">" + assessName + "</label>\n\t\t\t\t\t\t\t\t\t\t<ul style=\"list-style: none; padding-left: 0.5em;\">\n\t\t\t\t\t\t\t\t\t\t\t<li>\n\t\t\t\t\t\t\t\t\t\t\t\t<ion-icon class=\"neutral\" name=\"" + iconTypeAdmin + "\"></ion-icon>\n\t\t\t\t\t\t\t\t\t\t\t\t<label class=\"label-neutral " + admin + "\">Administer</label>\n\t\t\t\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>\n\t\t\t\t\t\t\t\t\t\t\t\t<ion-icon class=\"neutral\" name=\"" + iconTypePartic + "\"></ion-icon>\n\t\t\t\t\t\t\t\t\t\t\t\t<label class=\"label-neutral " + partic + "\">Participate</label>\n\t\t\t\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t\t\t\t</ul>\t\t\t\t\n\t\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t";
                                assessmentHtmlLists[field].push(html);
                            }
                        }
                        var worksInEdHtml = assessmentHtmlLists.worksInEd.join('');
                        var noWorksInEdHtml = assessmentHtmlLists.noWorksInEd.join('');
                        var worksInEd = fieldofwork && fieldofwork.includes('works in education field');
                        var assessmentHtml = worksInEd ? worksInEdHtml : noWorksInEdHtml;
                        // <span class="checkmark ${assesscorrect || false}"></span>
                        // const iconType = assesscorrect ? 'checkmark-circle' : 'close';
                        // const iconClass = assesscorrect ? 'correct' : 'incorrect';
                        // <ion-icon class="${iconClass}" name="${iconType}"></ion-icon>
                        var iconHtml = utils.createCheckmarkIconHtml(assesscorrect);
                        return "\n\t\t\t\t\t\t\t<div class=\"drilldown-row\">\n\t\t\t\t\t\t\t\t<label>Field of work:</label>\n\t\t\t\t\t\t\t\t<span>" + fieldofwork + "</span>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t<div class=\"drilldown-row\">\n\t\t\t\t\t\t\t\t<label>Level of position:</label>\n\t\t\t\t\t\t\t\t<span>" + (k12description || nonk12description || '') + "</span>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t<div class=\"drilldown-row\">\n\t\t\t\t\t\t\t\t<label>Assessment(s) chosen:</label>\n\t\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t\t<ul class=\"sub-list-container\">" + assessmentHtml + "</ul>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t<div class=\"drilldown-row with-checkmarks\">\n\t\t\t\t\t\t\t\t<label>Assessment Chosen Correctly?</label>\n\t\t\t\t\t\t\t\t" + iconHtml + "\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t";
                    },
                }
            },
            {
                parent: 'LO2',
                variablename: 'LO2dash',
                desc: 'Defines key purposes of a capacity assessment generally',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 75
                    },
                    yellow: {},
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    vars: {
                        opts: {
                            prefix: true
                        },
                        scoreRaw: [
                            'LO2incorrectcount',
                        ],
                        grouping: {
                            'answer': {
                                textArray: [
                                    'Administer a capacity assessment',
                                    'Hire a consultant to do an evaluation or audit',
                                    'Keep moving forward and doing what they are doing',
                                    'Abandon the selected practice',
                                ],
                                correctIndex: 0
                            }
                        }
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var _a = varDict.groupinganswer, groupinganswer = _a === void 0 ? [] : _a, scoreRawLO2incorrectcount = varDict.scoreRawLO2incorrectcount;
                        var chosenListHtml = groupinganswer.map(reportUtils.createScoredListItem).join('');
                        return "\n\t\t\t\t\t\t\t<div class=\"drilldown-row half-margin\">\n\t\t\t\t\t\t\t\t<label>Question:</label>\n\t\t\t\t\t\t\t\t<span>What would help both the Stars Agency and the Comet Agency move forward?</span>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t<div class=\"drilldown-row\">\n\t\t\t\t\t\t\t\t<label>Answers:</label>\n\t\t\t\t\t\t\t\t<em>(marks by all chosen)</em>\n\t\t\t\t\t\t\t\t<ul class=\"checkmarks-for-chosen\">\n\t\t\t\t\t\t\t\t\t" + chosenListHtml + "\n\t\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t<div class=\"drilldown-row\">\n\t\t\t\t\t\t\t\t<label>Incorrect attempts:</label>\n\t\t\t\t\t\t\t\t<span>" + scoreRawLO2incorrectcount + "</span>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t";
                    },
                }
            },
            {
                parent: 'LO3',
                variablename: 'LO3dash',
                desc: 'Identifies key constructs underlying a capacity assessment',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 50
                    },
                    yellow: {
                        min: 51,
                        max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    vars: {
                        opts: {
                            prefix: true
                        },
                        scoreRaw: [
                            '53incorrectcount',
                            '55incorrectcount',
                        ],
                        grouping: {
                            '53answer': {
                                textArray: [
                                    'Selection',
                                    'Training',
                                    'Coaching',
                                    'Fidelity',
                                    'Decision Support Data System',
                                    'Facilitative Administration',
                                    'Systems Intervention',
                                ],
                                correctIndex: 5
                            },
                            '55answer': {
                                textArray: [
                                    'Selection',
                                    'Training',
                                    'Coaching',
                                    'Fidelity',
                                    'Decision Support Data System',
                                    'Facilitative Administration',
                                    'Systems Intervention',
                                ],
                                correctIndex: 0
                            }
                        }
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var _a = varDict.grouping53answer, grouping53answer = _a === void 0 ? [] : _a, _b = varDict.grouping55answer, grouping55answer = _b === void 0 ? [] : _b, scoreRaw53incorrectcount = varDict.scoreRaw53incorrectcount, scoreRaw55incorrectcount = varDict.scoreRaw55incorrectcount;
                        var chosenList53Html = grouping53answer.map(reportUtils.createScoredListItem).join('');
                        var chosenList55Html = grouping55answer.map(reportUtils.createScoredListItem).join('');
                        return "\n\t\t\t\t\t\t\t<div class=\"drilldown-row half-margin\">\n\t\t\t\t\t\t\t\t<label>Question:</label>\n\t\t\t\t\t\t\t\t<span>Which driver is most relevant to Natasha\u2019s experience?</span>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t<div class=\"drilldown-row\">\n\t\t\t\t\t\t\t\t<label>Answers:</label>\n\t\t\t\t\t\t\t\t<em>(marks by all chosen)</em>\n\t\t\t\t\t\t\t\t<ul class=\"checkmarks-for-chosen\">\n\t\t\t\t\t\t\t\t\t" + chosenList53Html + "\n\t\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t<div class=\"drilldown-row\">\n\t\t\t\t\t\t\t\t<label>Incorrect attempts:</label>\n\t\t\t\t\t\t\t\t<span>" + scoreRaw53incorrectcount + "</span>\n\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t<p><hr></p>\n\n\t\t\t\t\t\t\t<div class=\"drilldown-row half-margin\">\n\t\t\t\t\t\t\t\t<label>Question:</label>\n\t\t\t\t\t\t\t\t<span>Which driver is most relevant to Ana\u2019s experience?</span>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t<div class=\"drilldown-row\">\n\t\t\t\t\t\t\t\t<label>Answers:</label>\n\t\t\t\t\t\t\t\t<em>(marks by all chosen)</em>\n\t\t\t\t\t\t\t\t<ul class=\"checkmarks-for-chosen\">\n\t\t\t\t\t\t\t\t\t" + chosenList55Html + "\n\t\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t<div class=\"drilldown-row\">\n\t\t\t\t\t\t\t\t<label>Incorrect attempts:</label>\n\t\t\t\t\t\t\t\t<span>" + scoreRaw55incorrectcount + "</span>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t";
                    },
                }
            },
            {
                parent: 'LO4',
                variablename: 'LO4dash',
                desc: 'Administers a capacity assessment',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 50
                    },
                    yellow: {
                        min: 51,
                        max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                            successMetric: 'successFlipped'
                        },
                        scoreRaw: [
                            'LO4count'
                        ],
                        successList: [
                            {
                                question: "\"Who all is needed for the assessment?\"",
                                variablename: 'slide61incorrect'
                            },
                            {
                                question: "\"Do I need to have the top leader of my agency at the table for this?\"",
                                variablename: 'slide62incorrect'
                            },
                            {
                                question: "\"How much time do you think we’ll need? <br><br>I’ve scheduled one hour.\"",
                                variablename: 'slide63incorrect'
                            },
                            {
                                question: "You are now just about to begin facilitating the Assessment.  Look around the room.<br><br>What is missing?",
                                variablename: 'slide64incorrect'
                            },
                            {
                                question: "What key constructs are covered in your introduction?",
                                variablename: 'slide65incorrect'
                            },
                            {
                                question: "\"I have a question. I am new to the organization and my position. I don’t know enough to vote. Can I not vote for this one question?\"",
                                variablename: 'slide66incorrect'
                            },
                            {
                                question: "\"Before we continue, can we get clarification on what is meant by 'training effectiveness data'?\"",
                                variablename: 'slide67incorrect'
                            },
                            {
                                question: "You have just read this DBPA Item to the team:<br><em>There is someone accountable for the coaching of relevant staff for the programs or practices.</em><br><br>You then say \"Ready, set, vote\".<br><em>Voting results: Three \"2s\" and two \"1s\"</em><br><br>What is your best response?",
                                variablename: 'slide69incorrect'
                            },
                            {
                                question: "Team member 1 describes the source of the information and how she became aware of the lack of time for coaching staff. The team members continue to discuss why they didn’t know about the time constraints for the coach.<br><br>What should you do next?",
                                variablename: 'slide610incorrect'
                            },
                            {
                                question: "You have just read the item: Agency staff follow a protocol for fidelity assessments. Keep in mind that the terms 'consistently' and 'regularly' mean 'usually.'<br><br>Before you say \"Ok, Ready, Set, Vote,\" two team members begin discussing the item.<br><br>What should you do next?",
                                variablename: 'slide611incorrect'
                            },
                            {
                                question: "Before calling for a vote, you read this item to the team:<br> <em>Leadership engages in regular communication with all staff and service users regarding the program or practice.</em><br><br>Does it have to be the administrators gathering all the feedback? We have different agency staff and parent advocates doing some of this.<br><br>What is your best response?",
                                variablename: 'slide612incorrect'
                            },
                            {
                                question: "You have just read an item about the use of a fidelity measure. To assist teams with answering this item, you should:",
                                variablename: 'slide613incorrect'
                            },
                            {
                                question: "Select the best response (to graph of results):",
                                variablename: 'slide614incorrect'
                            },
                            {
                                question: "Team member:<br> \"In looking at these data and thinking about our work, I recommend that we start with some action planning around our selection practices.\"<br><br>Other team members have mixed responses.<br><br>What should you do next?",
                                variablename: 'slide615incorrect'
                            },
                        ]
                    },
                    template: function (varDict) {
                        var correctTotal = varDict.LO4count, type = varDict.type;
                        var inCorrectHeader = type === 'group' ? 'Total Incorrect' : 'Incorrect';
                        var tableHtml = "\n\t\t\t\t\t\t\t<div class=\"drilldown-row\">\n\t\t\t\t\t\t\t<label>Total correct: </label>\n\t\t\t\t\t\t\t\t<span>" + (correctTotal || '') + "</span>\n\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t<div class=\"drilldown-table-container\">\n\t\t\t\t\t\t\t\t<table>\n\t\t\t\t\t\t\t\t\t<thead>\n\t\t\t\t\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t\t\t\t\t<th colspan=\"2\">Question</th>\n\t\t\t\t\t\t\t\t\t\t\t<th>" + inCorrectHeader + "</th>\n\t\t\t\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t\t\t\t</thead>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<tbody>\n\t\t\t\t\t\t\t";
                        var rowIndex = 0;
                        for (var vbl in varDict) {
                            if (vbl === 'LO4count' || vbl === 'type') {
                                continue;
                            }
                            rowIndex = rowIndex + 1;
                            var item = varDict[vbl];
                            var quantityIncorrect = item.isCorrect ? 0 : 1;
                            if (item.totalIncorrect) {
                                quantityIncorrect = item.totalIncorrect;
                            }
                            var rowClass = quantityIncorrect ? 'incorrect' : 'correct';
                            if (type !== 'group') {
                                quantityIncorrect = quantityIncorrect ? 'True' : 'False';
                            }
                            tableHtml += "\n\t\t\t\t\t\t\t\t<tr class=\"table-row-" + rowClass + "\">\n\t\t\t\t\t\t\t\t\t<td>" + rowIndex + "</td>\n\t\t\t\t\t\t\t\t\t<td>" + item.question + "</td>\n\t\t\t\t\t\t\t\t\t<td>" + quantityIncorrect + "</td>\n\t\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t\t";
                        }
                        tableHtml += '</tbody></table></div>';
                        return tableHtml;
                    },
                }
            },
        ],
        specialGroupVarDictFns: {
            LO4: function (column) {
                var parent = column.parent, varDictUserList = column.varDictUserList;
                console.log('%s varDictUserList:', parent, varDictUserList);
                var varDictGroup = {
                    type: 'group',
                };
                var LO4countTotal = 0;
                varDictUserList.forEach(function (varDictUser) {
                    for (var vbl in varDictUser) {
                        if (vbl === 'LO4count') {
                            LO4countTotal += (varDictUser[vbl] || 0);
                        }
                        else {
                            var item = Object.assign({}, varDictUser[vbl]);
                            varDictGroup[vbl] = varDictGroup[vbl] || item;
                            var totalIncorrect = varDictGroup[vbl].totalIncorrect || 0;
                            totalIncorrect += item.isCorrect ? 0 : 1;
                            varDictGroup[vbl].totalIncorrect = totalIncorrect;
                        }
                    }
                });
                varDictGroup.LO4count = (LO4countTotal / varDictUserList.length).toFixed(2) + ' (Average)';
                console.log('%s varDictGroup:', parent, varDictGroup);
                return varDictGroup;
            }
        },
    },
};
