import { table } from './table';
export var fileExport = {
    init: function (project) {
        this.exportData(project);
        // const data = this.getTableData();
        // console.log('data:', data);
        // fileExport.openSaveDialog(project, data);
    },
    exportData: function (project) {
        var exportPlugin = table.instance ? table.instance.getPlugin('exportFile') : null;
        if (!exportPlugin) {
            console.error('no table.instance to export file?', table.instance);
            return '';
        }
        var dateFormat = 'DDMMMYY_h.mma';
        var dateTime = moment().format(dateFormat);
        var filename = "UNC_" + project + "_" + dateTime;
        // `UNC-Data-Report_${project}_[YYYY]-[MM]-[DD]`,
        exportPlugin.downloadFile('csv', {
            bom: false,
            columnDelimiter: ',',
            columnHeaders: true,
            exportHiddenColumns: true,
            exportHiddenRows: true,
            fileExtension: 'csv',
            filename: filename,
            mimeType: 'text/csv',
            rowDelimiter: '\r\n',
            rowHeaders: true
        });
    },
    // getTableData() {
    // 	// const thisTable = document.querySelector('#results table.htCore');
    // 	const thisTable = table.instance.table || null;
    // 	console.log('table.instance.table:', table.instance.table);
    // 	return thisTable ? this.tableToCSV(thisTable) : '';
    // },
    // tableToCSV(table: HTMLTableElement) {
    // 	const slice = Array.prototype.slice;
    // 	return slice.call(table.rows).map((row: HTMLTableRowElement) => {
    // 		const rowArray = slice.call(row.cells);
    // 		rowArray.shift(); // remove first column (numbering)
    // 		return rowArray.map((cell: HTMLTableCellElement) => {
    // 			return '"t"'.replace("t", cell.textContent);
    // 		}).join(",");
    // 	}).join("\r\n");
    // },
    // openSaveDialog(project: string, data: any) {
    // 	const dateFormat = 'DDMMMYY_h.mma';
    // 	const dateTime = moment().format(dateFormat);
    // 	const filename = `UNC_${project}_${dateTime}.csv`;
    // 	let url = '';
    // 	if (window.Blob) {
    // 		const blObject = new Blob([data], {
    // 			type: "text/csv, charset=utf-8"
    // 		});
    // 		if (window.navigator.msSaveOrOpenBlob) {
    // 			window.navigator.msSaveOrOpenBlob(blObject, filename);
    // 			return;
    // 		} else {
    // 			url = URL.createObjectURL(blObject);
    // 		}
    // 	} else {
    // 		const dataPrefix = 'data:text/csv;charset=utf-8,';
    // 		url = encodeURI(dataPrefix + data);
    // 	}
    // 	var link = document.getElementById('export');
    // 	link.setAttribute("href", url);
    // 	link.setAttribute("download", filename);
    // 	link.click();
    // }
};
