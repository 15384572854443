export var xapiUtils = {
    langs: ['und', 'en', 'en-US'],
    /**
     * Get English lang value from a language object using available languages
     * @param obj Object with language properties
     * @returns Value of language property
     */
    getValueFromLangObj: function (obj) {
        if (!obj) {
            return;
        }
        var output = null;
        this.langs.forEach(function (lang) {
            var outputVal = obj[lang];
            if (!outputVal) {
                return;
            }
            output = outputVal;
        });
        return output;
    },
    /**
     * Converts TinCan statements into standard (using `originalJSON` property)
     * @param stmts Array of TinCan Library statements
     * @returns Array of standard statements
     */
    getStandardStmtsFromTincan: function (stmts) {
        return stmts.map(function (stmt) { return JSON.parse(stmt.originalJSON); });
    },
    // getSimplifiedResult(result: any): any {
    // 	const { response, score, success, completion } = result || {};
    // 	const { raw, min, max, scaled } = score || {};
    // 	const output = {
    // 		response, success, completion
    // 	};
    // 	return output;
    // },
    /**
     * Returns simplified activity object
     * @param activity Original statement activity object
     * @returns Simplified activity object
     */
    getSimplifiedActivity: function (activity) {
        var _this = this;
        var id = activity.id, definition = activity.definition;
        var output = { id: id };
        ['name', 'description'].forEach(function (prop) {
            var langObj = definition && definition[prop];
            var propValue = langObj && _this.getValueFromLangObj(langObj);
            if (propValue) {
                output[prop] = propValue;
            }
        });
        return output;
    },
    /**
     * Simplfies ContextActivity object array contents
     * @param reportId Main course ID
     * @param ctxt Statement's `context.contextActivities` object
     * @returns Simplified ContextActivity object
     */
    getSimplifiedContext: function (reportId, ctxt) {
        var _this = this;
        var output = {};
        for (var ctxtType in ctxt) {
            var ctxtArray = ctxt[ctxtType] || [];
            var simplifiedCtxtArray = ctxtArray.map(function (activity) {
                var simpleAct = _this.getSimplifiedActivity(activity);
                if (simpleAct.id === reportId) {
                    return null;
                }
                return simpleAct;
            }).filter(function (activity) { return activity; });
            output[ctxtType] = simplifiedCtxtArray;
        }
        return output;
    },
    /**
     * Flatten a 2-depth object
     * @param mainPropName
     * @param nestedObj 2-depth object
     * @example ```
     * {
     * 	score: {
     * 		raw: 1,
     * 		max: 5
     * 	}
     * }
     * ```
     * @returns single-level object, for example `{ scoreRaw: 1, scoreMax: 5 }`
     */
    flatten2dSimpleObject: function (nestedObj) {
        var output = {};
        for (var firstProp in nestedObj) {
            var firstValue = nestedObj[firstProp];
            if (typeof firstValue === 'object') {
                for (var secondProp in firstValue) {
                    var propName = this.camelCasify(firstProp, secondProp);
                    output[propName] = firstValue[secondProp];
                }
            }
            else {
                output[firstProp] = firstValue;
            }
        }
        return output;
    },
    /**
     * Flatten simplified `context.contextactivities` object
     * `mainProp` name that is returned camelCasifies the first 2 words of the activity name
     * @param reportId Main course ID
     * @param nestedCtxtObj Simplified `context.contextactivities` object
     * @param nameOnly (optional) Return `name` property only, as final string value
     * @returns Flattened ContextActivity object (regardless of type)
     */
    flattenContext: function (reportId, nestedCtxtObj, nameOnly) {
        var _this = this;
        var output = {};
        for (var ctxtType in nestedCtxtObj) {
            var ctxtArray = nestedCtxtObj[ctxtType] || [];
            ctxtArray.forEach(function (activity) {
                var id = activity.id, name = activity.name, description = activity.description;
                var truncatedId = id.replace(reportId, '');
                var mainPropRaw = truncatedId.split('/').filter(function (part) { return part; })[0];
                if (!mainPropRaw) {
                    return;
                }
                var mainPropSplit = mainPropRaw.split('-');
                var mainProp = _this.camelCasify(mainPropSplit[0], mainPropSplit[1]);
                // temp correction
                if (mainProp === 'mtss') {
                    mainProp = 'facilitySlt';
                }
                if (nameOnly) {
                    output[mainProp] = name;
                }
                else {
                    output[mainProp] = { name: name, description: description };
                }
            });
        }
        return output;
    },
    /**
     * Return simple extension value
     * @param stmt Original Statement
     * @param location "context", "activity" or "result"
     * @param id Extension ID
     * @returns Extension value
     */
    getExtensionValueById: function (stmt, location, id) {
        var locationRoot = location === 'activity' ? stmt.object.definition : (stmt[location] || {});
        var extensions = locationRoot.extensions;
        if (!extensions) {
            return;
        }
        return extensions[id] || '';
    },
    /**
     * Create a simplified object for each statement
     * @param reportId Main course ID
     * @param stmts Statement array
     * @param exts Extension key array
     * @returns Simplified statement object
     */
    getSimplifiedStmtsFromStandard: function (reportId, stmts, exts) {
        if (exts === void 0) { exts = []; }
        return stmts.map(function (stmt) {
            var timestamp = stmt.timestamp, id = stmt.id, actor = stmt.actor, verb = stmt.verb, result = stmt.result, context = stmt.context;
            var platform = context.platform;
            var origActivity = stmt.object;
            var _a = actor.mbox, mbox = _a === void 0 ? '' : _a, _b = actor.name, name = _b === void 0 ? '' : _b, _c = actor.account, _d = _c === void 0 ? {} : _c, _e = _d.name, accountName = _e === void 0 ? '' : _e;
            var userId = (mbox || accountName).replace('mailto:', '');
            var userName = name || accountName;
            var verbName = xapiUtils.getValueFromLangObj(verb.display);
            var activity = xapiUtils.getSimplifiedActivity(origActivity);
            var output = {
                date: new Date(timestamp),
                id: id,
                name: userName,
                userId: userId,
                verb: verbName,
            };
            /* =============================== */
            // Flatten Activity
            var flattenedActivity = xapiUtils.flatten2dSimpleObject({ activity: activity });
            var isXapily = platform && (platform.includes('xapily') || platform.includes('xapi.ly'));
            var subDir = isXapily ? '' : 'extensions'; // trigger builder adds this text
            exts.forEach(function (ext) {
                var extProp = ext;
                var extPropLower = ext.toLowerCase();
                var extId = [reportId, subDir, ext].filter(function (path) { return path; }).join('/');
                var extValOriginal = xapiUtils.getExtensionValueById(stmt, 'context', extId);
                var extValOriginalActivity = xapiUtils.getExtensionValueById(stmt, 'activity', extId);
                var extIdLowerCase = [reportId, subDir, extPropLower].filter(function (path) { return path; }).join('/');
                var extValLowerCase = xapiUtils.getExtensionValueById(stmt, 'context', extIdLowerCase);
                var extValLowerCaseActivity = xapiUtils.getExtensionValueById(stmt, 'activity', extIdLowerCase);
                var extVal = extValOriginal || extValOriginalActivity || extValLowerCase || extValLowerCaseActivity;
                // console.log(stmt);
                // console.table({
                // 	platform,
                // 	ext,
                // 	extId,
                // 	extValOriginal,
                // 	extVal
                // });
                if (extPropLower === 'variablename') {
                    // extVal = extVal || activity.id;
                    extProp = extPropLower;
                }
                if (extVal) {
                    // console.log('updating val', extProp, extVal, '\n\n');
                    flattenedActivity[extProp] = extVal;
                }
            });
            output = Object.assign(output, flattenedActivity);
            /* =============================== */
            // Flatten Result
            var flattenedResult = xapiUtils.flatten2dSimpleObject(result);
            output = Object.assign(output, flattenedResult);
            /* =============================== */
            // Flatten Context
            var ctxtAct = context && context.contextActivities;
            var simplifiedContextObj = ctxtAct && xapiUtils.getSimplifiedContext(reportId, ctxtAct);
            if (simplifiedContextObj) {
                var flattenedCtxt = xapiUtils.flattenContext(reportId, simplifiedContextObj, true);
                output = Object.assign(output, flattenedCtxt);
            }
            return output;
        });
    },
    getUserMapFromSimplifiedStmts: function (stmts) {
        var userMap = new Map();
        stmts.forEach(function (stmt) {
            var _a = stmt.userId, userId = _a === void 0 ? '' : _a, _b = stmt.name, name = _b === void 0 ? '' : _b;
            // const email = mbox ? mbox.replace('mailto:', '') : accountName;
            var email = userId;
            var userName = name;
            var user = userMap.get(email) || {
                email: email,
                name: userName,
                statements: []
            };
            user.statements.push(stmt);
            userMap.set(email, user);
        });
        return userMap;
    },
    /**
     * Reverse a camel cased string
     * @param text Text to reverse
     * @param removeNumbers (optional) Remove any numbers
     * @returns
     */
    camelCaseReverse: function (text, removeNumbers) {
        var result = text.replace(/([A-Z])/g, " $1");
        if (removeNumbers) {
            result = result.replace(/([0-9])/g, '');
        }
        return (result.charAt(0).toUpperCase() + result.slice(1)).trim();
    },
    camelCasify: function (first, second) {
        var firstParam = first;
        var secondParam = second;
        if (!second && first && Array.isArray(first)) {
            firstParam = first[0];
            secondParam = first[1];
        }
        var firstStr = (firstParam && firstParam.toString()) || '';
        var secondStr = (secondParam && secondParam.toString()) || '';
        if (!firstStr) {
            return secondStr.toLowerCase();
        }
        var cappedSecond = secondStr.charAt(0).toUpperCase() + (secondStr.substring(1) || '').toLowerCase();
        return firstStr.toLowerCase() + cappedSecond;
    },
};
