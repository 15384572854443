import { GenericTableSection } from "../../../../../shared/interfaces-classes/generic-table";
// TESTING
import { tableStructTestData } from "./table-struct-test";
export var cprtGeneric = {
    reportStructure: {},
    mainProject: {},
    userMap: new Map(),
    listType: '',
    groupList: [],
    // TESTING
    init: function (stmts, mainProject) {
        console.log('process table here');
        $('.generic-loading-container').addClass('hidden');
        var allHtml = tableStructTestData.map(function (tableSection) { return new GenericTableSection(tableSection).html; }).join('');
        $('.generic-container').html(allHtml);
    },
    // TODO: show user table first (with "show report" buttons)
    // Then each user's report has a "back" to 
    // init(stmts: any[], mainProject: any) {
    // 	this.mainProject = Object.assign({}, mainProject);
    // 	console.log('cprtGeneric init with statements');
    // 	utils.sortStmtArrayByTimestamp(stmts);
    // 	this.reportStructure = reportUtils.getReportStructure(this.mainProject);
    // 	reportUtils.processStmtData(stmts, this);
    // },
};
