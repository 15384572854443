import { DrilldownTable, defaultTrafficLights, } from "../../../../../shared/interfaces-classes/index";
import { reportUtils } from "../../../../report-utils";
var getGenericGroupMsg = reportUtils.getGenericGroupMsg;
var genericGroupMsg = getGenericGroupMsg();
export var codesign = {
    'http://SISEPCoDesign': {
        columns: [
            {
                parent: 'LO1',
                variablename: 'LO1',
                desc: 'Articulate importance of using co-design methods and strategies within implementation',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    icon: 'text',
                    vars: {
                        response: [
                            'Reasons'
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var Reasons = varDict.Reasons;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'How do you explain the differences between these two implementation outcomes?'
                                ]
                            },
                            {
                                header: 'Answer Provided',
                                rows: [
                                    Reasons
                                ]
                            },
                            {
                                header: 'NIRN’s Answer',
                                rows: [
                                    "<p>East Township School District did not collect additional data to gain insight into the user experience. Therefore, the number of strategies created for increasing caregiver engagement was limited. Without having the user experience to guide the creation of  solutions, or inform the design of solutions or how solutions could be implemented, the overall success or effectiveness was impacted and limited.</p>\n\n                  <p>West Township started engaging with end users (teachers) and the focus population (families/caregivers) from the beginning. The district engaged in exercises to understand the barriers or challenges to authentic engagement with the different school activities. This understanding helped to guide selection of solutions to address the challenges to engagement. The district continued to engage families throughout the iterative  process of developing and testing solutions such as the home visit protocol.  West Township modeled how to engage in each step of implementation with their community.</p>"
                                ]
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO2',
                variablename: 'LO2dash',
                desc: 'Identify co-design techniques to support the creation of an implementation plan or blueprint',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'LO2text1',
                            'LO2text2',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var LO2text1 = varDict.LO2text1, LO2text2 = varDict.LO2text2;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'Which of these co-design techniques would you choose first?',
                                    'Which of these co-design techniques would you choose next?',
                                ]
                            },
                            {
                                header: 'Answer',
                                rows: [
                                    LO2text1,
                                    LO2text2,
                                ]
                            },
                            {
                                header: 'Correct Answer',
                                rows: [
                                    'Work to Transform Power',
                                    'Top Five'
                                ]
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO3',
                variablename: 'LO3dash',
                desc: 'Facilitate the use of co-design techniques to develop tools, process, etc. to support the plan of action',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'LO3text1',
                            'LO3text2',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var LO3text1 = varDict.LO3text1, LO3text2 = varDict.LO3text2;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'Which co-design technique should they use first as they design their home visiting protocol? ',
                                    'Which of these co-design techniques would you choose next?',
                                ]
                            },
                            {
                                header: 'Answer',
                                rows: [
                                    LO3text1,
                                    LO3text2,
                                ]
                            },
                            {
                                header: 'Correct Answer',
                                rows: [
                                    'Top Five',
                                    'Story Board',
                                ]
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO4',
                variablename: 'LO4dash',
                desc: 'Facilitate the use of co-design techniques to adapt and align strategies to address barriers and leverage facilitators during the implementation process',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'LO4text',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var LO4text = varDict.LO4text;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'Which co-design technique should the team use to make adjustments to their training and home visiting protocol?',
                                ]
                            },
                            {
                                header: 'Answer',
                                rows: [
                                    LO4text,
                                ]
                            },
                            {
                                header: 'Correct Answer',
                                rows: [
                                    "\n                    <p>Try</p>\n                    <p><em>OR</em></p>\n                    <p>What, So What, Now What</p>\n                  \n                  "
                                ]
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
        ],
        specialGroupVarDictFns: {},
    }
};
