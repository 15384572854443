var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
export var utils = {
    parseQuery: function (fullQuery) {
        fullQuery = fullQuery || window.location.search || window.location.href || '';
        if (!fullQuery) {
            return;
        }
        var query = {};
        var queryString = fullQuery.split('?')[1];
        if (!queryString) {
            return query;
        }
        ;
        var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    },
    decodeUri: function (val) {
        return val ? decodeURIComponent(val) : '';
    },
    getISOString: function (date, time) {
        if (!date) {
            return new Date().toISOString();
        }
        time = time || '00:00';
        return new Date(date + "T" + time).toISOString();
    },
    convertDuration: function (duration) {
        var momentDur = moment.duration(duration, moment.ISO_8601);
        return momentDur.asSeconds();
        // if (!duration) { return ''; }
        // const array = duration.match(/(\d+)(?=[MHS])/ig) || [];
        // return array.map((item) => {
        // 	return item.length < 2 ? `0${item}` : item;
        // }).join(':');
    },
    durationInMinutes: function (totalSeconds) {
        // Hours, minutes and seconds
        var hrs = ~~(totalSeconds / 3600); // bitwise NOT | http://rocha.la/JavaScript-bitwise-operators-in-practice
        var mins = ~~((totalSeconds % 3600) / 60);
        var secs = totalSeconds % 60;
        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = '';
        if (hrs > 0) {
            ret += hrs + ":" + (mins < 10 ? '0' : '');
        }
        ret += mins + ":" + (secs < 10 ? '0' : '');
        ret += secs;
        return ret;
    },
    asString: function (value) {
        return (value != undefined || value != null) ? value.toString() : '';
    },
    sortStmtArrayByTimestamp: function (stmts, direction) {
        var isAsc = (direction || 'asc') === 'asc';
        stmts.sort(function (a, b) {
            var aTime = a.timestamp || a.date || '';
            var bTime = b.timestamp || b.date || '';
            if (aTime > bTime) {
                return isAsc ? 1 : -1;
            }
            if (aTime < bTime) {
                return isAsc ? -1 : 1;
            }
            return 0;
        });
    },
    mapOrSetToSortedArray: function (type, mapOrSet, sortProp, direction) {
        var spreadValue = type === 'map' ? mapOrSet.entries() : mapOrSet;
        var isAsc = (direction || 'asc') === 'asc';
        if (sortProp) {
            var isDate_1 = sortProp.toLowerCase().includes('date');
            return __spreadArray([], __read(spreadValue)).sort(function (a, b) {
                var aProp = a[1][sortProp];
                var bProp = b[1][sortProp];
                if (isDate_1) {
                    aProp = new Date(aProp);
                    bProp = new Date(bProp);
                }
                if (aProp > bProp) {
                    return isAsc ? 1 : -1;
                }
                if (aProp < bProp) {
                    return isAsc ? -1 : 1;
                }
                return 0;
            });
        }
        return __spreadArray([], __read(spreadValue)).sort();
    },
    sortMap: function (unsortedMap, sortProp, direction) {
        return new Map(this.mapOrSetToSortedArray('map', unsortedMap, sortProp, direction));
    },
    sortSet: function (unsortedSet) {
        var sortedArray = this.mapOrSetToSortedArray('set', unsortedSet);
        return new Set(sortedArray);
    },
    mapToArrayByValue: function (originalMap) {
        return __spreadArray([], __read(originalMap.entries())).map(function (entry) { return entry[1]; });
    },
    getFirstPropertyValue: function (obj) {
        if (typeof obj !== 'object') {
            return '';
        }
        return Object.values(obj || {})[0] || '';
    },
    getDictFromSerializedForm: function (rawFormData) {
        var formData = {};
        rawFormData.forEach(function (field) {
            var name = field.name, value = field.value;
            formData[name] = typeof value === 'string' ? value.trim() : value;
        });
        return formData;
    },
    calculateStrQtyFromList: function (list) {
        var output = {};
        list.forEach(function (item) {
            var outputItem = output[item] || 0;
            outputItem = outputItem + 1;
            output[item] = outputItem;
        });
        return output;
    },
    getBooleanFromString: function (str) {
        if (str === void 0) { str = ''; }
        var val = str.toString().toLowerCase();
        return val.includes('true');
    },
    createCheckmarkIconHtml: function (correct) {
        var iconType = correct ? 'checkmark-circle' : 'close';
        var iconClass = correct ? 'correct' : 'incorrect';
        return "<ion-icon class=\"" + iconClass + "\" name=\"" + iconType + "\"></ion-icon>";
    },
    slugify: function (text) {
        return text
            .toString()
            .normalize('NFD')
            .replace(/https|http/g, '')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .trim()
            .replace(/\s+/g, '-')
            .replace(/[^\w-]+/g, '')
            .replace(/--+/g, '-');
    },
    getGroupListFromTextarea: function (list) {
        var listRaw = list.replace(/\t/ig, '').split(/\r?\n/);
        return listRaw.map(function (email) {
            return email.trim();
        }).filter(function (val) { return val; });
    },
    // getVariableNameExtVal(context: any) {
    // 	let variableName = '';
    // 	const { extensions } = context || {};
    // 	if (!extensions) {
    // 		return '';
    // 	}
    // 	for (let prop in extensions) {
    // 		if (prop.toLowerCase().includes('variablename')) {
    // 			variableName = extensions[prop];
    // 		}
    // 	}
    // 	return variableName;
    // },
    getVariableNameExtVal: function (contextExt, activityExt) {
        var variableName = '';
        // const { extensions } = context || {};
        if (!contextExt && !activityExt) {
            return '';
        }
        [contextExt, activityExt].forEach(function (extLocation) {
            for (var prop in extLocation) {
                if (prop.toLowerCase().includes('variablename')) {
                    variableName = extLocation[prop];
                }
            }
        });
        // for (let prop in contextExt) {
        // 	if (prop.toLowerCase().includes('variablename')) {
        // 		variableName = contextExt[prop];
        // 	}
        // }
        return variableName;
    },
    updateUiForPrint: function (timing, source) {
        var header = document.querySelector('header');
        var main = document.querySelector('main');
        var action = timing === 'before' ? 'add' : 'remove';
        var classes = ['no-print'];
        if (source === 'external') {
            classes.push('hidden');
        }
        [header, main].forEach(function (el) {
            var _a;
            return el && (_a = el.classList)[action].apply(_a, __spreadArray([], __read(classes)));
        });
    },
};
