import { DrilldownTable, defaultTrafficLights, createCheckmarkCellHtml, } from "../../../../../shared/interfaces-classes/index";
import { reportUtils } from '../../../../report-utils';
var getBoolFromBoolishVar = reportUtils.getBoolFromBoolishVar, processSingleVarHtml = reportUtils.processSingleVarHtml, processTemplateVarHtmlList = reportUtils.processTemplateVarHtmlList, createDrilldownTableGroup = reportUtils.createDrilldownTableGroup, getGenericGroupMsg = reportUtils.getGenericGroupMsg;
var genericGroupMsg = getGenericGroupMsg();
export var mod9_2 = {
    'http://ICTP9micro2': {
        columns: [
            {
                parent: 'LO1',
                variablename: 'LO1dash',
                desc: 'Facilitate the usage of fidelity data for process improvement',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'LO1a',
                            'LO1b',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var LO1a = varDict.LO1a, LO1b = varDict.LO1b;
                        function getLO1aCellHtml(str) {
                            if (!str)
                                return processSingleVarHtml(str);
                            return createCheckmarkCellHtml(str);
                        }
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'How should Andrea and John share the community-wide Triple P fidelity report with the Community Leadership Team?',
                                    'Order these facilitation steps to provide the most support for the interpretation and use of the fidelity data.',
                                ],
                            },
                            {
                                header: 'Correct answers',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t<p><strong>Both answers offered are correct:</strong></p>\n\n\t\t\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t\t\t<li>Email the report out to Community Leadership Team members with the agenda, a week in advance</li>\n\t\t\t\t\t\t\t\t\t\t<li>Share the report at the meeting</li>\n\t\t\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t\t\t\t",
                                    "\n\t\t\t\t\t\t\t\t\t<p><strong>Correct order:</strong></p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<ol>\n\t\t\t\t\t\t\t\t\t\t<li>Remind the Community Leadership Team of the questions they wanted answered regarding Triple P fidelity across Grand County.</li>\n\t\t\t\t\t\t\t\t\t\t<li>Review each fidelity assessment resource, how it connects to the original questions asked by the Community Leadership Team,  and how the data from each assessment might be interpreted (e.g., ranges of scores, what might be considered higher or lower scores).</li>\n\t\t\t\t\t\t\t\t\t\t<li>Ask the Community Leadership Team questions such as, \u201CWhat do you see when you look through this data,\u201D \u201CWhat are a couple areas of Triple P program fidelity in which we\u2019re doing well,\u201D and \u201CWhat is at least one are that you see we might need to make some improvement?\u201D</li>\n\t\t\t\t\t\t\t\t\t\t<li>Probe Community Leadership Team feedback about the data in the report using curious questions, such as \u201Cwhy might that be,\u201D and \u201Cwhat do you think might be happening there?\u201D</li>\n\t\t\t\t\t\t\t\t\t\t<li>Facilitate a consensus building activity around a one or two areas of improvement that the Community Leadership Team might focus on in the upcoming quarter.</li>\n\t\t\t\t\t\t\t\t\t\t<li>Facilitate a brainstorming session on strategies the community might put in place to improve fidelity in areas of focus in the upcoming quarter.</li>\n\t\t\t\t\t\t\t\t\t</ol>\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                            {
                                header: 'Learner answer information',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t\t<p>Learner answer correct?</p>\n\n\t\t\t\t\t\t\t\t\t\t<div style=\"width: 20px;\">" + getLO1aCellHtml(LO1a) + "</div>\n\t\t\t\t\t\t\t\t\t",
                                    "\n\t\t\t\t\t\t\t\t\t\t<p>Number of attempts to order these steps:</p>\n\n\t\t\t\t\t\t\t\t\t\t" + processSingleVarHtml(LO1b) + "\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                        ];
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: columns,
                        };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
            {
                parent: 'LO2',
                variablename: 'LO2dash',
                desc: 'Select appropriate process improvement strategies ',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'LO2'
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var LO2 = varDict.LO2;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'Which of these strategies should John and Andrea encourage?'
                                ],
                            },
                            {
                                header: 'Correct answers',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t<p><strong>Appropriate:</strong></p>\n\n\t\t\t\t\t\t\t\t\t<ol>\n\t\t\t\t\t\t\t\t\t\t<li>Pull together coaching groups for practitioners delivering Level 3 Triple P Programs.</li>\n\t\t\t\t\t\t\t\t\t\t<li>Host a series of brief webinars, facilitated by a Triple P trainer, to review Triple P's core program components: Assessment, Co-Creating Homework, and Self-Regulation.</li>\n\t\t\t\t\t\t\t\t\t\t<li>Partner with local Triple P practitioners to develop video demonstrations and tip sheets related to \"Assessment, \" \"Co-Creating Homework,\" and \"Self-Regulation\" practices.</li>\n\t\t\t\t\t\t\t\t\t\t<li>Partner with community Triple P practitioners to develop a targeted communications campaign that reinforces parents' engagement with Level 3 Triple P programs.</li>\n\t\t\t\t\t\t\t\t\t</ol>\n\n\t\t\t\t\t\t\t\t\t<p><strong>Inappropriate:</strong></p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<ol>\n\t\t\t\t\t\t\t\t\t\t<li>Offer re-training in Level 3 Triple P programs to practitioners whose fidelity data showed the most need for improvement.</li>\n\t\t\t\t\t\t\t\t\t\t<li>Identify Triple P practitioners lower on fidelity to \"Assessment,\" \"Co-Creating Homework,\" and \"Self-Regulation\" to their coaches for more intensive support.</li>\n\t\t\t\t\t\t\t\t\t</ol>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                            {
                                header: 'Out of 6 strategies, number correctly identified as appropriate or not',
                                rows: [
                                    processSingleVarHtml(LO2)
                                ],
                            },
                        ];
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: columns,
                        };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
            {
                parent: 'LO3',
                variablename: 'LO3dash',
                desc: 'Appropriately address challenges around collecting fidelity data',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'LO3'
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var LO3 = varDict.LO3;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t<p>Before the end of the Community Leadership Team meeting, Andrea and John want to take a few minutes to recognize that not all community Triple P practitioners completed their fidelity assessments as expected in the Community-wide Triple P Fidelity Assessment Plan. In fact, a third of practitioners expressed challenges participating in at least some of the fidelity assessment practices in the plan. </p>\n\n\t\t\t\t\t\t\t\t\t<p>How might Andrea and John approach this issue with the Community Leadership Team in the last part of their meeting?</p>\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                            {
                                header: 'Correct answers',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t<p><strong>Appropriate:</strong></p>\n\n\t\t\t\t\t\t\t\t\t<ol>\n\t\t\t\t\t\t\t\t\t\t<li>Ask the Community Leadership Team about practitioners' challenges with fidelity assessment and elicit strategies to address them.</li>\n\t\t\t\t\t\t\t\t\t\t<li>Ask a couple Community Leadership Team members to help pull together a practitioner focus group to identify challenges and strategies.</li>\n\t\t\t\t\t\t\t\t\t</ol>\n\n\t\t\t\t\t\t\t\t\t<p><strong>Inappropriate:</strong></p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<ol>\n\t\t\t\t\t\t\t\t\t\t<li>Identify practitioners who are not meeting fidelity assessment expectations to their agency leaders. Ask leaders to meet with them to reinforce expectations.</li>\n\t\t\t\t\t\t\t\t\t\t<li>Tell the Community Leadership Team about practitioner feedback and suggest helpful changes.</li>\n\t\t\t\t\t\t\t\t\t</ol>\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                            {
                                header: 'Out of 4 responses, number correctly identified as appropriate or not',
                                rows: [
                                    processSingleVarHtml(LO3)
                                ],
                            },
                        ];
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: columns,
                        };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
        ],
        printouts: [
        // {
        // 	name: 'Customized Learner Summary',
        // 	profileId: 'ictp-mod-9_2',
        // project: 'ictp',
        // }
        ],
    },
};
