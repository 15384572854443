import { reportUtils } from "../reports/report-utils";
import { DynamicTableOptions } from './interfaces-classes/index';
import { utils } from "./exports";
var dynamicStandardHeaders = [
    'UID',
    'Completed Date',
    'Completed Time',
];
var DynamicUserData = /** @class */ (function () {
    function DynamicUserData() {
        this.uid = '';
        this.completedAtDate = '';
        this.completedAtTime = '';
        this.columnMap = new Map();
    }
    return DynamicUserData;
}());
export var dynamicTable = {
    options: new DynamicTableOptions(),
    userMap: new Map(),
    tableColumnSet: new Set(),
    init: function (stmts, options) {
        this.reset();
        this.options = options;
        stmts.forEach(this.addToUserMap.bind(this));
        try {
            this.sortColumnData();
            var tableData = this.getTableDataFromUserMap();
            var colDataStructure = this.getColDataStructureFromUserSample(tableData);
            return {
                colHeaders: this.getColHeadersFromSet(),
                colDataStructure: colDataStructure,
                tableData: tableData,
            };
        }
        catch (error) {
            console.error('error:', error);
        }
        return {};
    },
    reset: function () {
        this.options = new DynamicTableOptions();
        this.userMap = new Map();
        this.tableColumnSet = new Set();
    },
    addToUserMap: function (stmt) {
        var _a = this.options, showScoresOnly = _a.showScoresOnly, showCompletion = _a.showCompletion, useNameAndDescHeaders = _a.useNameAndDescHeaders;
        var actor = stmt.actor, verb = stmt.verb, result = stmt.result, target = stmt.target, timestamp = stmt.timestamp;
        var isScored = verb.id.includes('scored');
        var isComplete = verb.id.includes('completed');
        var notScoredWithOptionSet = showScoresOnly && !isScored;
        var notCompletionWithOptionSet = showCompletion && !isComplete;
        var skipBasedOnOptions = notScoredWithOptionSet && notCompletionWithOptionSet;
        if (skipBasedOnOptions) {
            return;
        }
        var _b = result || {}, score = _b.score, response = _b.response;
        var definition = (target || {}).definition;
        var _c = definition || {}, name = _c.name, description = _c.description;
        var user = reportUtils.getUser(actor);
        var uid = user.id;
        var userData = new DynamicUserData();
        var existingUser = this.userMap.get(uid);
        if (existingUser) {
            userData = existingUser;
        }
        else {
            userData.uid = uid;
        }
        if (showCompletion && isComplete) {
            var jsDate = new Date(timestamp);
            userData.completedAtDate = jsDate.toLocaleDateString();
            userData.completedAtTime = jsDate.toLocaleTimeString();
        }
        else {
            var displayValue = '';
            if (isScored) {
                displayValue = score ? score.raw : 0;
            }
            else {
                if (!showScoresOnly && response) {
                    displayValue = response;
                }
            }
            var headerText = utils.getFirstPropertyValue(name);
            this.tableColumnSet.add(JSON.stringify({
                header: headerText,
                nested: useNameAndDescHeaders ? utils.getFirstPropertyValue(description) : '',
            }));
            userData.columnMap.set(headerText, displayValue);
        }
        this.userMap.set(uid, userData);
    },
    sortColumnData: function () {
        var _this = this;
        var sortedTableColumns = utils.sortSet(this.tableColumnSet);
        this.tableColumnSet = sortedTableColumns;
        this.userMap.forEach(function (user) {
            _this.tableColumnSet.forEach(function (headerObjString) {
                var headerObj = JSON.parse(headerObjString);
                var header = headerObj.header;
                var existingHeader = user.columnMap.get(header);
                if (!existingHeader) {
                    user.columnMap.set(header, '');
                }
            });
            var sortedUserColumns = utils.sortMap(user.columnMap);
            user.columnMap = sortedUserColumns;
        });
    },
    getColHeadersFromSet: function () {
        var useNameAndDescHeaders = this.options.useNameAndDescHeaders;
        var colHeaders = [];
        var arrayFromSet = utils.mapOrSetToSortedArray('set', this.tableColumnSet);
        var headersArray = dynamicStandardHeaders.slice();
        var nestedArray = dynamicStandardHeaders.slice().map(function (std) { return ''; });
        arrayFromSet.forEach(function (headerObjString) {
            var headerObj = JSON.parse(headerObjString);
            var header = headerObj.header, nested = headerObj.nested;
            headersArray.push(header);
            nestedArray.push(nested);
        });
        if (useNameAndDescHeaders) {
            colHeaders = [headersArray, nestedArray];
        }
        else {
            colHeaders = headersArray;
        }
        return colHeaders;
    },
    getColDataStructureFromUserSample: function (tableData) {
        return tableData[0] || {};
    },
    getTableDataFromUserMap: function () {
        var userMap = new Map(this.userMap);
        userMap.forEach(function (user) {
            var columnMap = user.columnMap;
            columnMap.forEach(function (value, key) {
                user[key] = value;
            });
            delete user.columnMap;
        });
        return utils.mapToArrayByValue(userMap);
    },
};
