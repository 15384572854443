import { DrilldownTable, 
// DrilldownTableRowCell,
defaultTrafficLights, } from "../../../../../shared/interfaces-classes/index";
import { reportUtils } from '../../../../report-utils';
var getBoolFromBoolishVar = reportUtils.getBoolFromBoolishVar, processSingleVarHtml = reportUtils.processSingleVarHtml, 
// processTemplateVarHtmlList,
// createDrilldownTableGroup,
getGenericGroupMsg = reportUtils.getGenericGroupMsg;
var genericGroupMsg = getGenericGroupMsg();
function getTrueIfGreen(val) {
    return (val || '').toLowerCase().includes('green');
}
export var mod9 = {
    'http://ICTPMod9': {
        columns: [
            {
                parent: 'LO2a',
                variablename: 'LO2a',
                desc: 'Describe the importance of program fidelity',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    icon: 'text',
                    vars: {
                        response: [
                            'TextEntry1fidelityImportance'
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var TextEntry1fidelityImportance = varDict.TextEntry1fidelityImportance;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'John attempts to describe the importance of assessing fidelity to Triple P’s core program components across their community. What should he say?'
                                ],
                            },
                            {
                                header: 'Learner Answer',
                                rows: [
                                    TextEntry1fidelityImportance
                                ],
                            },
                            {
                                header: 'ICTP’s answer',
                                rows: [
                                    "<p>Assessing fidelity to Triple P core program components and related content is important because we know the more our community Triple P practitioners are delivering Triple P with fidelity, the more likely we are to get to our intended child and family outcomes across Grand County.</p> \n\n\t\t\t\t\t\t\t\t\t<p>With sufficient Triple P fidelity data, we\u2019ll better be able to determine why we are - or perhaps are not - seeing the program outcomes we want to see across our community. </p> \n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>If our community Triple P practitioners are delivering Triple P programs with low fidelity to core program components, we can use the data to plan improvements to our Grand County implementation practices. We can also use the data to improve our community-wide Triple P coaching system for our practitioners and learn what coaching supports are needed to improve Triple P program delivery. Overall, fidelity data helps us better support our practitioners across Grand County.</p>\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                        ];
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: columns,
                        };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
            {
                parent: 'LO2bi',
                variablename: 'LO2bi',
                desc: 'Create a community-wide Triple P fidelity assessment plan: WHY',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'why1correct',
                            'correct417',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var why1correct = varDict.why1correct, correct417 = varDict.correct417;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'Remember, there are four elements of fidelity assessment: program adherence, quality of delivery, program dose, and engagement of participants during program delivery. Which of these questions might be answered through fidelity assessment practices?',
                                    'Match each question with the element of fidelity assessment to which it corresponds.'
                                ],
                            },
                            {
                                header: 'Correct answers',
                                rows: [
                                    "<strong>Out of eight possible answers:</strong>\n\n\t\t\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t\t\t<li>Are core Triple P components, and related content, being delivered to Grand County caregivers and parents in the right sequence and format?</li>\n\t\t\t\t\t\t\t\t\t\t<li>Are participating caregivers and families reasonably engaged with core Triple P components and activities during program delivery?</li>\n\t\t\t\t\t\t\t\t\t\t<li>Are we delivering core Triple P components with high quality?</li>\n\t\t\t\t\t\t\t\t\t\t<li>Are participating caregivers and families receiving enough of Triple P\u2019s core program components to be able to benefit?</li>\n\t\t\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t\t\t\t",
                                    "<p>\n\t\t\t\t\t\t\t\t\t\t<div><strong>Program adherence:</strong></div>\n\t\t\t\t\t\t\t\t\t\tAre core Triple P components, and related content, being delivered to Grand County caregivers and parents in the right sequence and format?\n\t\t\t\t\t\t\t\t\t</p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>\n\t\t\t\t\t\t\t\t\t\t<div><strong>Quality of delivery:</strong></div>\n\t\t\t\t\t\t\t\t\t\tAre we delivering core Triple P components with high quality?\n\t\t\t\t\t\t\t\t\t</p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>\n\t\t\t\t\t\t\t\t\t\t<div><strong>Program dose:</strong></div>\n\t\t\t\t\t\t\t\t\t\tAre participating caregivers and families receiving enough of Triple P\u2019s core program components to be able to benefit?\n\t\t\t\t\t\t\t\t\t</p>\n\n\t\t\t\t\t\t\t\t\t<p>\n\t\t\t\t\t\t\t\t\t\t<div><strong>Engagement of participants during program delivery:</strong></div>\n\t\t\t\t\t\t\t\t\t\tAre participating caregivers and families reasonably engaged with core Triple P components and activities during program delivery?\n\t\t\t\t\t\t\t\t\t</p>\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                            {
                                header: 'Learner answer correct?',
                                type: 'checkmark',
                                // allowEmpty: true,
                                rows: [
                                    getBoolFromBoolishVar(why1correct) || '',
                                    getBoolFromBoolishVar(correct417) || '',
                                ],
                            },
                        ];
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: columns,
                        };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
            {
                parent: 'LO2bii',
                variablename: 'LO2bii',
                desc: 'Create a community-wide Triple P fidelity assessment plan: DATA SOURCE',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'LO2bii'
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var LO2bii = varDict.LO2bii;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    '<div>Which Triple P fidelity assessment resources and strategies can be used to measure each element of fidelity?</div> <em>(Matching)</em>'
                                ],
                            },
                            {
                                header: 'Correct answers',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t<p>\n\t\t\t\t\t\t\t\t\t\t<div><strong>Program adherence:</strong></div>\n\t\t\t\t\t\t\t\t\t\tTriple P Strengths Checklist - Content\n\t\t\t\t\t\t\t\t\t</p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>\n\t\t\t\t\t\t\t\t\t\t<div><strong>Quality of delivery:</strong></div>\n\t\t\t\t\t\t\t\t\t\tTriple P Strengths Checklist - Competence\n\t\t\t\t\t\t\t\t\t</p>\n\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>\n\t\t\t\t\t\t\t\t\t\t<div><strong>Program dose:</strong></div>\n\t\t\t\t\t\t\t\t\t\tPercentage of program sessions completed by participants\n\t\t\t\t\t\t\t\t\t</p>\n\n\t\t\t\t\t\t\t\t\t<p>\n\t\t\t\t\t\t\t\t\t\t<div><strong>Engagement of participants during program delivery:</strong></div>\n\t\t\t\t\t\t\t\t\t\tAssessing participants\u2019 engagement during Triple P program delivery.\n\t\t\t\t\t\t\t\t\t</p>\n\t\t\t\t\t\t\t\t"
                                ],
                            },
                            {
                                header: 'Learner answer correct?',
                                type: 'checkmark',
                                rows: [
                                    LO2bii && getTrueIfGreen(LO2bii)
                                ],
                            },
                        ];
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: columns,
                        };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
            {
                parent: 'LO2biii',
                variablename: 'LO2biii',
                desc: 'Create a community-wide Triple P fidelity assessment plan: WHO',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'LO2biii'
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var LO2biii = varDict.LO2biii;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    '<div>Who could be involved in collecting data from each Triple P fidelity assessment resource or strategy?</div> <em>(Checkboxes by Practitioners and Observers)</em>'
                                ],
                            },
                            {
                                header: 'Correct answers',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t<p>\n\t\t\t\t\t\t\t\t\t\t<div><strong>Triple P Session Checklists:</strong></div>\n\t\t\t\t\t\t\t\t\t\tTriple P Practitioners <strong>AND/OR</strong> Observers\n\t\t\t\t\t\t\t\t\t</p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>\n\t\t\t\t\t\t\t\t\t\t<div><strong>Triple P Strengths Checklist (Content & Competence):</strong></div>\n\t\t\t\t\t\t\t\t\t\tTriple P Practitioners <strong>AND/OR</strong> Observers\n\t\t\t\t\t\t\t\t\t</p>\n\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>\n\t\t\t\t\t\t\t\t\t\t<div><strong>Number of program sessions completed by participants:</strong></div>\n\t\t\t\t\t\t\t\t\t\tTriple P Practitioners <strong>BUT NOT</strong> Observers\n\t\t\t\t\t\t\t\t\t</p>\n\n\t\t\t\t\t\t\t\t\t<p>\n\t\t\t\t\t\t\t\t\t\t<div><strong>Assessing participants\u2019 engagement during Triple P program delivery: Triple P</strong></div>\n\t\t\t\t\t\t\t\t\t\tPractitioners <strong>AND/OR</strong> Observers\n\t\t\t\t\t\t\t\t\t</p>\n\t\t\t\t\t\t\t\t"
                                ],
                            },
                            {
                                header: 'Learner answer correct?',
                                type: 'checkmark',
                                rows: [
                                    LO2biii && getTrueIfGreen(LO2biii)
                                ],
                            },
                        ];
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: columns,
                        };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
            {
                parent: 'LO2biv',
                variablename: 'LO2biv',
                desc: 'Create a community-wide Triple P fidelity assessment plan: WHEN',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'when',
                            'WhenNumber6_2',
                            'WhenNumber6_8',
                            'WhenNumber6_4',
                            'WhenNumber6_5',
                            'WhenNumber6_10',
                            'WhenNumber6_3',
                            'WhenNumber6_7',
                            'WhenNumber6_6',
                            'WhenNumber6_11',
                            'WhenNumber6_12',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var when = varDict.when, WhenNumber6_2 = varDict.WhenNumber6_2, WhenNumber6_8 = varDict.WhenNumber6_8, WhenNumber6_4 = varDict.WhenNumber6_4, WhenNumber6_5 = varDict.WhenNumber6_5, WhenNumber6_10 = varDict.WhenNumber6_10, WhenNumber6_3 = varDict.WhenNumber6_3, WhenNumber6_7 = varDict.WhenNumber6_7, WhenNumber6_6 = varDict.WhenNumber6_6, WhenNumber6_11 = varDict.WhenNumber6_11, WhenNumber6_12 = varDict.WhenNumber6_12;
                        var columns = [
                            {
                                header: 'Resource',
                                rowspanList: [
                                    3, 3, 1, 3
                                ],
                                rows: [
                                    'Triple P Session Checklist Data',
                                    '',
                                    '',
                                    'Triple P Strengths Checklist (Content & Competence)',
                                    '',
                                    '',
                                    'Number of program sessions completed by participants',
                                    'Triple P Engagement Items',
                                    '',
                                    '',
                                ],
                            },
                            {
                                header: 'Question',
                                rows: [
                                    'How often might practitioners complete the session checklist to describe their own work?',
                                    'How often might observers complete the session checklist to describe the work of practitioners?',
                                    'What might be a helpful schedule for practitioners to report to the Community Implementation Team given they are being completed two-to-four times per month?',
                                    'How often might practitioners complete the strengths checklist to describe their own work?',
                                    'How often might observers complete the strengths checklist to describe the work of practitioners?',
                                    'What might be a helpful schedule for practitioners to report to the Community Implementation Team given they are being completed monthly?',
                                    'Which of these options are most feasible and useful?',
                                    'How often might practitioners rate participants’ engagement during Triple P program delivery?',
                                    'How often might observers rate participants’ engagement during Triple P program delivery?',
                                    'What might be a helpful schedule for practitioners to report to the Community Implementation Team given they are being completed two-to-four times per month?',
                                ],
                            },
                            {
                                header: 'Correct answer',
                                rows: [
                                    'Two-to-four sessions monthly, randomly selected by the practitioner',
                                    'One session quarterly',
                                    'Monthly',
                                    'Monthly',
                                    'Quarterly',
                                    'Monthly',
                                    "\n\t\t\t\t\t\t\t\t\t\t<strong>After the end of participation for each Triple P program participant:</strong>\n\n\t\t\t\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t\t\t\t<li>Practitioners calculate the number of sessions completed.</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Determine whether or not the completion rate is sufficient.</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Monthly, Agency Implementation Team members report the data to Community Implementation Team members.</li>\n\t\t\t\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t\t\t\t",
                                    'Two-to-four sessions monthly, alongside completion of Triple P Session Checklists',
                                    'One session quarterly',
                                    'Monthly',
                                ],
                            },
                            {
                                header: 'Number of tries to answer question',
                                rows: [
                                    WhenNumber6_2,
                                    WhenNumber6_8,
                                    WhenNumber6_4,
                                    WhenNumber6_5,
                                    WhenNumber6_10,
                                    WhenNumber6_3,
                                    WhenNumber6_7,
                                    WhenNumber6_6,
                                    WhenNumber6_11,
                                    WhenNumber6_12,
                                ],
                            },
                        ];
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: columns,
                            title: "Number of correct answers out of 10 questions: " + processSingleVarHtml(when),
                        };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
            {
                parent: 'LO2bv',
                variablename: 'LO2bv',
                desc: 'Create a community-wide Triple P fidelity assessment plan: HOW',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'LO2bv'
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var LO2bv = varDict.LO2bv;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    '<div>Which of these choices are most likely to encourage practitioners to complete Triple P fidelity assessments? </div> <em>(Select all that apply)</em>'
                                ],
                            },
                            {
                                header: 'Correct answers',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t<p><em>Eight choices offered</em></p>\n\n\t\t\t\t\t\t\t\t\t<p>\n\t\t\t\t\t\t\t\t\t\t<div><strong>Incorrect:</strong></div>\n\t\t\t\t\t\t\t\t\t\tPractitioners will attend Triple P program training, provided by Triple P America.\n\t\t\t\t\t\t\t\t\t</p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>\n\t\t\t\t\t\t\t\t\t\t<div><strong>Correct:</strong></div>\n\n\t\t\t\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t\t\t\t<li>Practitioners are provided an orientation to expectations in the Community-wide Triple P Fidelity Assessment Plan.</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Practitioners\u2019 service delivery agencies integrate Triple P fidelity assessment resources into practitioners\u2019 typical service documentation routine.</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Practitioners and coaches use recent fidelity data during routine Triple P coaching activities.</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Practitioners see the use of community-wide fidelity data as a part of the Triple P coalition\u2019s routine implementation improvement activities.</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Practitioners receive a verbal or written \u201Cthank you\u201D from their service agency leadership and Community Implementation Team members for turning in fidelity data each month.</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Practitioners are recognized at community Triple P coalition meetings for having turned in fidelity data.</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Each quarter, the practitioners that met all community Triple P fidelity assessment expectations get to draw from a prize bowl.</li>\n\t\t\t\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t\t\t\t</p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                            {
                                header: 'Learner answer correct?',
                                type: 'checkmark',
                                rows: [
                                    LO2bv && getTrueIfGreen(LO2bv)
                                ],
                            },
                        ];
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: columns,
                        };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
        ]
    }
};
// import { CustomReportLights } from '../../../../../shared/interfaces-classes/index';
// export const mod9 = {
// 	'http://ICTPMod9': <CustomReportLights>{
// 		// title: '',
// 		profileId: 'ictp-mod-9',
// 		hasPrintout: false,
// 		sections: [
// 			{
// 				title: 'Describe the importance of program fidelity:',
// 				standalone: true,
// 				activityList: [
// 					{
// 						activityName: 'LO2a',
// 						variable: 'LO2a',
// 						response: '',
// 					}
// 				]
// 			},
// 			// { separator: true },
// 			{
// 				title: 'Create a community-wide Triple P fidelity assessment plan:',
// 				groupedSection: [
// 					{
// 						title: 'WHY',
// 						activityList: [
// 							{
// 								activityName: 'plan-why',
// 								// variable: 'LO2bi',
// 								color: '',
// 							}
// 						]
// 					},
// 					{
// 						title: 'DATA SOURCE',
// 						activityList: [
// 							{
// 								activityName: 'plan-data-source',
// 								// variable: 'LO2bii',
// 								color: '',
// 							}
// 						]
// 					},
// 					{
// 						title: 'WHO',
// 						activityList: [
// 							{
// 								activityName: 'plan-who',
// 								// variable: 'LO2biii',
// 								color: '',
// 							}
// 						]
// 					},
// 					{
// 						title: 'WHEN',
// 						activityList: [
// 							{
// 								activityName: 'plan-when',
// 								// variable: 'LO2biv',
// 								color: '',
// 							}
// 						]
// 					},
// 					{
// 						title: 'HOW',
// 						activityList: [
// 							{
// 								activityName: 'plan-how',
// 								// variable: 'How',
// 								color: '',
// 							}
// 						]
// 					}
// 				]
// 			}
// 		],
// 	}
// };
