import { DrilldownTable } from "../../../../../shared/interfaces-classes/index";
import { reportUtils } from "../../../../report-utils";
var processTemplateVarHtml = reportUtils.processTemplateVarHtmlList, getGenericGroupMsg = reportUtils.getGenericGroupMsg;
var genericGroupMsg = getGenericGroupMsg();
export var growSustain = {
    'http://SISEPGrowSustain': {
        columns: [
            {
                parent: 'LO1',
                // variablename: 'LO1',
                desc: 'Know & trust yourself',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 50
                    },
                    yellow: {
                        min: 51,
                        max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    icon: 'text',
                    vars: {
                        opts: {
                            prefix: false,
                            // stringifyVarPrefix: 'var',
                        },
                        // success: [
                        // ],
                        response: [
                            'LO1a',
                            'LO1b',
                            'LO1c',
                            'LO1d',
                            'LO1e',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var LO1a = varDict.LO1a, LO1b = varDict.LO1b, LO1c = varDict.LO1c, LO1d = varDict.LO1d, LO1e = varDict.LO1e;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'When you provide implementation support, what are the skills and/or knowledge bases that you <strong>most effectively utilize</strong>? ',
                                    'What skills and knowledge do you <strong>want to improve</strong> in your interactions with your implementation partner(s)?',
                                    'What are your salient personal and social identities?',
                                    'How might these impact your relationship with your implementation partner(s)?',
                                    'How do your implicit biases affect your implementation support?',
                                ]
                            },
                            {
                                header: 'Answer chosen',
                                rows: processTemplateVarHtml([
                                    LO1a,
                                    LO1b,
                                    LO1c,
                                    LO1d,
                                    LO1e,
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO2',
                variablename: 'LO2dash',
                desc: 'Build Interpersonal trust: Support the relationship between yourself and key stakeholders involved in implementation',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 50
                    },
                    yellow: {
                        min: 51,
                        max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        response: [
                            'text1LO2',
                            'text2LO2',
                            'text3LO2',
                            'text4LO2',
                            'text5LO2',
                            'text6LO2',
                        ],
                        scoreRaw: [
                            'tries1LO2',
                            'tries2LO2',
                            'tries3LO2',
                            'tries4LO2',
                            'tries5LO2',
                            'tries6LO2',
                        ]
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var text1LO2 = varDict.text1LO2, text2LO2 = varDict.text2LO2, text3LO2 = varDict.text3LO2, text4LO2 = varDict.text4LO2, text5LO2 = varDict.text5LO2, text6LO2 = varDict.text6LO2, tries1LO2 = varDict.tries1LO2, tries2LO2 = varDict.tries2LO2, tries3LO2 = varDict.tries3LO2, tries4LO2 = varDict.tries4LO2, tries5LO2 = varDict.tries5LO2, tries6LO2 = varDict.tries6LO2;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    "<p><strong>Scenario:</strong></p>\n\n                  <p>You are a new math coach at Happy Grove High School. The school\u2019s math scores have been declining over the past few years and you have been tasked with providing coaching support to the teachers. You have met the teachers at staff meetings and are trying to develop positive working relationships with each of them. You have let each of them know that you will be conducting math fidelity/integrity observations to provide feedback on how to help all students be successful in math. </p>\n                  \n                  <p>Today is your first day doing the classroom observations using the math fidelity/integrity measure designed by the district and curriculum publisher. You are going to observe Mr. Lopez\u2019s 9th grade math class. He has been teaching high school for 10 years and expressed at a staff meeting that he did not think that he needed support from a math coach. You observe his math lesson and are now meeting with him during his planning time to discuss your observation. You thank him for meeting with you and allowing you to observe his math lesson.</p> \n                  \n                  <p>What do you do next?</p>\n                  ",
                                    "<p>Mr. Lopez says that he thinks the lesson went just as he expected. He says he followed the lesson plan as it is outlined in the teacher\u2019s manual.</p>\n\n                  <p>How do you respond?</p>\n                  ",
                                    "<p>Mr. Lopez thinks for a moment and says that he noticed that several of the students were not engaged.</p> \n\n                  <p>What do you tell him?</p>\n                  ",
                                    "<p>Mr. Lopez explains that some of the students were glancing at their phones, some were looking out the window, while others just seemed to have a bored look on their faces. To increase engagement, he is thinking about having the students do some collaborative small group work.</p>\n\n                  <p>What is your best option?</p>\n                  ",
                                    "<p>You and Mr. Lopez continue the discussion about the math lesson and list out next steps that he is going to use to address student engagement as well as a few lesson specific items you noted in the observation. There are still about 10 minutes left in your scheduled meeting time.</p>\n\n                  <p>What do you do?</p>\n                  ",
                                    "<p>Mr. Lopez says meeting individually and asking him questions was helpful because it made him stop and think about the lesson and his next steps. He then tells you about ways in which professional development provided to math teachers can be improved. </p>\n\n                  <p>How do you respond?</p>\n                  ",
                                ]
                            },
                            {
                                header: 'Correct answer',
                                rows: [
                                    'Ask him how he thinks the math lesson went',
                                    'Mention that you also noticed that he used the manual during the lesson and ask him what he thought about the students’ response to the lesson ',
                                    'Tell him that is an interesting observation and ask him to tell you more about what he observed with student engagement and his thoughts about next steps',
                                    'Agree that his idea of collaborative group work ties in with the math curriculum. Using the fidelity/integrity measure, you begin discussing areas of strength in his math lesson and then moving onto areas not checked on the list. You try to make connections between his suggestions and those areas on the checklist and ask how he would address those items. ',
                                    'Thank him for his time and ask him what was helpful and what you could do differently in the coaching session to tailor your support to meet the needs of the teachers.',
                                    'Thank him and let him know you appreciate his feedback. You take notes and use paraphrasing to gain an understanding of his views. You also ask clarifying questions to get specific information on what he found helpful and what can be improved with the coaching support and professional development. ',
                                ]
                            },
                            {
                                header: 'First answer provided',
                                rows: processTemplateVarHtml([
                                    text1LO2,
                                    text2LO2,
                                    text3LO2,
                                    text4LO2,
                                    text5LO2,
                                    text6LO2,
                                ])
                            },
                            {
                                header: '# of tries to get correct answer',
                                rows: processTemplateVarHtml([
                                    tries1LO2,
                                    tries2LO2,
                                    tries3LO2,
                                    tries4LO2,
                                    tries5LO2,
                                    tries6LO2,
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO3_1',
                // variablename: 'LO3_1',
                desc: 'Support the relationships among the systems and community stakeholders, including consumers, who have a stake in the implementation effort',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 50
                    },
                    yellow: {
                        min: 51,
                        max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    icon: 'text',
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        response: [
                            'LO3a',
                            'LO3b',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var LO3a = varDict.LO3a, LO3b = varDict.LO3b;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'How do you currently promote interdepartmental collaboration?',
                                    'Based on the information shared in this lesson, what are one or two steps you can take to facilitate a cross-silo culture in your implementation work?',
                                ]
                            },
                            {
                                header: 'Answer chosen',
                                rows: processTemplateVarHtml([
                                    LO3a,
                                    LO3b,
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO3_2',
                variablename: 'LO3dash',
                desc: 'Support the relationships among the systems and community stakeholders, including consumers, who have a stake in the implementation effort.',
                trafficLight: '',
                trafficLightGroupLogic: {
                    red: {
                        min: 0,
                        max: 50
                    },
                    yellow: {
                        min: 51,
                        max: 75
                    },
                    green: {
                        min: 76,
                        max: 100
                    },
                },
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        response: [
                            'text1LO3',
                            'text2LO3',
                            'text3LO3',
                            'text4LO3',
                            'text5LO3',
                            'text6LO3',
                            'text7LO3',
                        ],
                        scoreRaw: [
                            'tries1LO3',
                            'tries2LO3',
                            'tries3LO3',
                            'tries4LO3',
                            'tries5LO3',
                            'tries6LO3',
                            'tries7LO3',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var text1LO3 = varDict.text1LO3, text2LO3 = varDict.text2LO3, text3LO3 = varDict.text3LO3, text4LO3 = varDict.text4LO3, text5LO3 = varDict.text5LO3, text6LO3 = varDict.text6LO3, text7LO3 = varDict.text7LO3, tries1LO3 = varDict.tries1LO3, tries2LO3 = varDict.tries2LO3, tries3LO3 = varDict.tries3LO3, tries4LO3 = varDict.tries4LO3, tries5LO3 = varDict.tries5LO3, tries6LO3 = varDict.tries6LO3, tries7LO3 = varDict.tries7LO3;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    "<p>The State has announced that due to stagnant 3rd grade reading test scores, they are introducing a new reading initiative in grades K-2 in all public schools starting next school year. All school districts are being required to start using the prescribed reading program by next year allowing them one year of implementation planning.</p> \n                  \n                  <p>You are the District Literacy Director for The Happyville school district. It is considered one of the largest and top performing districts in the state, although not all of its schools are meeting the state standards in reading. Happyville has not always followed state recommendations but has always complied with state mandates. You are leading the district\u2019s implementation team for this new reading initiative. The implementation team for the reading initiative consists of you and two district literacy coaches.</p> \n                  \n                  <p>Dr. Patel, the principal at Merry Elementary in your district, calls you to tell you that she disagrees with the state mandated reading initiative and will not be using the program because she doesn't think it is the best choice for her teachers and students. </p>\n\n                  <p>How do you respond?</p>\n                  ",
                                    "<p>At the next implementation team meeting, the literacy coaches mention that they have also heard concerns from several principals and a few teachers about having to adopt a new reading program.</p>\n\n                  <p>What do you do next?</p>\n                  ",
                                    "<p>When the survey results are analyzed, the team notices that the school principals note similar concerns (e.g., they already have a reading program that their teachers prefer so they don\u2019t want to ask them to change; their teachers are already overwhelmed so they don\u2019t need to be asked to do one more thing such as attend additional training; they are unsure of how this reading program will make a difference).</p>\n\n                  <p>What should you do?</p>\n                  ",
                                    "<p>At the principals meeting, the school principals bring up concerns about the implementation plan created by the district and state. They are upset that they did not have input in the decisions about the professional development schedule and resource allocation. They point out that the schedule developed by the implementation team conflicts with their school level plans.</p>\n\n                  <p>How do you proceed?</p>\n                  ",
                                    "<p>The updated implementation team, including state, district, and school leadership representation, reviews and updates the implementation plan to address several of the concerns mentioned at the last principals meeting.</p> \n\n                  <p>What\u2019s your best next step?</p>\n                  ",
                                    "<p>You receive feedback from principals that there are some areas not being addressed in the implementation plan - specifically students with special needs and students that are English language learners. </p>\n\n                  <p>How should you respond?</p>\n                  ",
                                    "<p>The implementation plan is finalized, and the district team is ready to move forward with implementation of the reading initiative. The principals mention that they feel heard and can support the implementation of the new reading program.</p>\n\n                  <p>What do you do now?</p>\n                  ",
                                ]
                            },
                            {
                                header: 'Correct answer',
                                rows: [
                                    "Acknowledge her feelings and ask her about her specific concerns. Let her know that you will share her concerns with the implementation team and you will follow up with her about next steps.",
                                    "Send a message to principals asking them to complete a short survey to let the team know their concerns about the new reading initiative.",
                                    "Work with the state literacy consultant to plan the next principals meeting to address the concerns noted in the survey. You invite the state literacy consultant to join the implementation team and attend the next principals meeting to help explain the next steps of the implementation plan",
                                    "Ask for principal representatives to join the implementation team to help determine next steps",
                                    "Share a copy of the implementation plan with the principals with information about the next steps and encourage them to let you know of any additional concerns. Let them know that the reading initiative will be a standing item on the monthly principal meetings. ",
                                    "Invite additional perspectives to join the implementation team to ensure that the needs of all students are being considered and addressed in the implementation plan - including the directors of SPED, ESL, Family & Community Connections, and Equity & Inclusion.",
                                    "Create a communication plan that includes sharing implementation information and updates with the state, district staff, school level staff, families, and students and develop methods for them to give input",
                                ]
                            },
                            {
                                header: 'First answer provided',
                                rows: processTemplateVarHtml([
                                    text1LO3,
                                    text2LO3,
                                    text3LO3,
                                    text4LO3,
                                    text5LO3,
                                    text6LO3,
                                    text7LO3,
                                ])
                            },
                            {
                                header: '# of tries to get correct answer',
                                rows: processTemplateVarHtml([
                                    tries1LO3,
                                    tries2LO3,
                                    tries3LO3,
                                    tries4LO3,
                                    tries5LO3,
                                    tries6LO3,
                                    tries7LO3,
                                ])
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
        ],
        specialGroupVarDictFns: {},
    }
};
