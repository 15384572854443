import { DrilldownTable, defaultTrafficLights, } from "../../../../../shared/interfaces-classes/index";
import { reportUtils } from "../../../../report-utils";
var getGenericGroupMsg = reportUtils.getGenericGroupMsg, processSingleVarHtml = reportUtils.processSingleVarHtml, getCorrectIncorrectByValueComparison = reportUtils.getCorrectIncorrectByValueComparison;
var genericGroupMsg = getGenericGroupMsg();
export var mod7 = {
    'http://ICTPMod7': {
        columns: [
            {
                parent: 'LO1a',
                variablename: 'LO1a',
                desc: 'Distinguish coaching from peer support and supervision',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        scoreRaw: [
                            'conflate',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var conflate = varDict.conflate;
                        var columns = [
                            {
                                header: "Statement after each video<br /><em>(agree/disagree required)</em>:",
                                rows: [
                                    'This activity will meet the function of coaching',
                                ],
                            },
                            {
                                header: "Highest possible number of correct choices",
                                rows: [
                                    '4',
                                ],
                            },
                            {
                                header: "Number of correct choices made",
                                rows: [
                                    conflate,
                                ],
                            }
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        var title = "\n\t\t\t\t\t\t\t<p><em>(Provided 4 example videos of coaching to evaluate)</em></p>\n\t\t\t\t\t\t";
                        return "\n\t\t\t\t\t\t\t" + title + "\n\n\t\t\t\t\t\t\t" + html + "\n\t\t\t\t\t\t";
                    }
                },
            },
            {
                parent: 'LO1b',
                variablename: 'LO1b',
                desc: 'Identify coaching best practices at SDAs',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        scoreRaw: [
                            'bestpractices',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var bestpractices = varDict.bestpractices;
                        var columns = [
                            {
                                header: "Coaching practices to identify:",
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t\t<ol>\n\t\t\t\t\t\t\t\t\t\t\t<li>Utilized observational data</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Utilized other forms of data</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Behaviorally specific feedback</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Role modeling/role playing</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Focus on knowledge/skills application needed to deliver Triple P</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Reflection and self-regulation</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Practitioner judgment for flexible delivery of Triple P</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Connection to a specific goal</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Positive reinforcement for use of new skills</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>Coaching activities, goals, and action plans are documented and shared with participants</li>\n\t\t\t\t\t\t\t\t\t\t</ol>\n\t\t\t\t\t\t\t\t\t"
                                ],
                            },
                            {
                                header: "Highest possible number of correct choices",
                                rows: [
                                    '40',
                                ],
                            },
                            {
                                header: "Number of correct choices made",
                                rows: [
                                    bestpractices,
                                ],
                            }
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        var title = "\n\t\t\t\t\t\t\t<p><em>(Provided 4 example videos of coaching to evaluate)</em></p>\n\t\t\t\t\t\t";
                        return "\n\t\t\t\t\t\t\t" + title + "\n\n\t\t\t\t\t\t\t" + html + "\n\t\t\t\t\t\t";
                    }
                },
            },
            {
                parent: 'LO1c',
                variablename: 'LO1c',
                desc: 'Identify the importance of coaching',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    icon: 'text',
                    vars: {
                        response: [
                            'coachingexplain',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var coachingexplain = varDict.coachingexplain;
                        var columns = [
                            {
                                header: "Question asked",
                                rows: [
                                    'What might you say to agency leaders to help them understand what coaching is and why it matters?',
                                ],
                            },
                            {
                                header: "Answer provided",
                                rows: [
                                    coachingexplain,
                                ],
                            },
                            {
                                header: "ICTP\u2019s answer",
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t\t<p>Grand County is creating a community coaching system to provide support to practitioners who are trained and accredited in Triple P. Coaching will increase practitioners\u2019 competence and confidence to use and deliver Triple P as intended in varied situations and settings.</p>\n\n\t\t\t\t\t\t\t\t\t\t<p>Coaching is program specific, grounded in supporting fidelity, and is a collaborative effort to support a practitioner in delivering Triple P. The use of the coaching best practices, observational data, and other data sources will support these goals.</p>\n\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t<p>Coaching will support practitioners starting out with Triple P and may increase their delivery of Triple P to families.</p>\n\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t<p>All of this leads to higher rates of family satisfaction with Triple P. Combining fidelity monitoring with high-quality practitioner coaching has also been shown to reduce practitioner turnover in community service providers. Our plan to support coaching for practitioners across Grand County will allow us to enjoy these results all across the county.</p>\n\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t",
                                ],
                            }
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
        ],
        printouts: [
            {
                name: 'Customized Learner Summary',
                profileId: 'ictp-mod-7',
                project: 'ictp',
            }
        ],
    },
};
// export const mod7 = {
// 	'http://ICTPMod7': {
// 		type: 2,
// 		profileId: 'ictp-mod-7',
// 		hasPrintout: true,
// 		title: 'Evaluate coaching best practices at the Service Delivery Agencies in your community coalition:',
// 		sections: [
// 			{
// 				title: 'Distinguish coaching from peer support and supervision',
// 				section: 'Evaluate coaching best practices',
// 				activityList: [
// 					{
// 						activityName: 'Distinguish coaching from peer support and supervision',
// 						color: '',
// 						variable: 'LO1a',
// 					},
// 				],
// 			},
// 			{
// 				title: 'Identify coaching best practices at SDAs',
// 				section: 'Evaluate coaching best practices',
// 				activityList: [
// 					{
// 						activityName: 'Identify coaching best practices at SDAs',
// 						color: '',
// 						variable: 'LO1b',
// 					},
// 				],
// 			},
// 			{
// 				title: 'Explain to agency leaders why coaching matters',
// 				section: 'Evaluate coaching best practices',
// 				activityList: [
// 					{
// 						activityName: 'Explain to agency leaders why coaching matters',
// 						response: '',
// 						variable: 'coachingexplain',
// 					},
// 				],
// 			},
// 		],
// 	},
// };
