import { GenericTableSection } from "../../../../../shared/interfaces-classes/index";
import { choiceTextMap } from "../processing/cprt-interactive-choice-map";
import { reportUtils } from "../../../../report-utils";
var getHtmlFromBoolVars = reportUtils.getHtmlFromBoolVars, processTemplateVarHtml = reportUtils.processTemplateVarHtmlList, getGenericGroupMsg = reportUtils.getGenericGroupMsg;
var genericGroupMsg = getGenericGroupMsg();
// import { indivCreateChoices3a, indivCreateChoices4a, indivCreateChoices5a, indivCreateChoices6a } from "../processing/cprt-interactive-fns";
export var cprtInteractive = {
    'http://CPRTinteractive': {
        columns: [
            {
                parent: 'Coach Report',
                desc: '',
                opts: {
                    singleColumn: true,
                },
                drilldown: {
                    icon: 'text',
                    vars: {
                        opts: {
                            prefix: false,
                            stringifyVarPrefix: 'var_',
                        },
                        scoreRaw: [
                            // Individual > Create
                            'attention1a',
                            'opportunity2a',
                            'instructions3a',
                            'easy4a',
                            'useMaterials5a',
                            'choices6a',
                            // Individual > Respond
                            'studentbehavior7a',
                            'prompts8part1a',
                            'othercontingent8part2a',
                            // Individual > Turns
                            'turns9a',
                            // Group > Create
                            'attention1b',
                            'opportunity2b',
                            'instructions3b',
                            'easy4b',
                            'UseMaterials5b',
                            'choices6b',
                            // Group > Respond
                            'studentbehavior7b',
                            'prompts8part1b',
                            'othercontingent8part2b',
                            // Group > Turns
                            'turns9b',
                            // Implement Report > Create
                            'attention1IMP',
                            'opportunity2IMP',
                            'instructions3IMP',
                            'easy4IMP',
                            'UseMaterials5IMP',
                            'choices6IMP',
                            'studentinterest7IMP',
                            'prompts8part1IMP',
                            // Implement Report > Respond
                            'othercontingent8part2aIMP',
                            'othercontingent8part2dIMP',
                            // Implement Report > Turns
                            'turns9IMP',
                        ],
                        // success: [
                        // ],
                        response: [
                            // Individual > Create
                            '1aText',
                            '2aText',
                            '3aTexta',
                            '3aTextb',
                            '3aTextc',
                            '3aTextd',
                            '4aTexta',
                            '4aTextb',
                            '4aTextc',
                            '4aTextd',
                            '5aTexta',
                            '5aTextb',
                            '5aTextc',
                            '5aTextd',
                            '5aTexte',
                            '6aTexta',
                            '6aTextb',
                            '6aTextc',
                            '6aTextd',
                            '6aTexte',
                            // Individual > Respond
                            '7aTexta',
                            '7aTextb',
                            '7aTextc',
                            '7aTextd',
                            '8part1aTexta',
                            '8part1aTextb',
                            '8part1aTextc',
                            '8part1aTextd',
                            '8part1aTexte',
                            '8part1aTextf',
                            // '8part1aTextg',
                            '8part2aTexta',
                            '8part2aTextb',
                            '8part2aTextc',
                            '8part2aTextd',
                            // Individual > Turns
                            '9aTexta',
                            '9aTextb',
                            '9aTextc',
                            '9aTextd',
                            '9aTexte',
                            // Group > Create
                            '1bText',
                            // '2bText',
                            '2bTexta',
                            '2bTextb',
                            '2bTextc',
                            '2bTextd',
                            '3bTexta',
                            '3bTextb',
                            '3bTextc',
                            '4bTexta',
                            '4bTextb',
                            '4bTextc',
                            '4bTextd',
                            '5bTexta',
                            '5bTextb',
                            '5bTextc',
                            '5bTextd',
                            '6bText',
                            // Group > Respond
                            '7bTexta',
                            '7bTextb',
                            '7bTextc',
                            '8part1bTexta',
                            '8part1bTextb',
                            '8part1bTextc',
                            '8part1bTextd',
                            '8part1bTexte',
                            '8part2bTexta',
                            '8part2bTextc',
                            '8part2bTextd',
                            // Group > Turns
                            '9bTexta',
                            '9bTextb',
                            '9bTextc',
                            '9bTextd',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var 
                        // SCORES ================
                        // Individual > Create
                        var_attention1a = varDict.var_attention1a, var_opportunity2a = varDict.var_opportunity2a, var_instructions3a = varDict.var_instructions3a, var_easy4a = varDict.var_easy4a, var_useMaterials5a = varDict.var_useMaterials5a, var_choices6a = varDict.var_choices6a, 
                        // Individual > Respond
                        var_studentbehavior7a = varDict.var_studentbehavior7a, var_prompts8part1a = varDict.var_prompts8part1a, var_othercontingent8part2a = varDict.var_othercontingent8part2a, 
                        // Individual > Turns
                        var_turns9a = varDict.var_turns9a, 
                        // Group > Create
                        var_attention1b = varDict.var_attention1b, var_opportunity2b = varDict.var_opportunity2b, var_instructions3b = varDict.var_instructions3b, var_easy4b = varDict.var_easy4b, var_UseMaterials5b = varDict.var_UseMaterials5b, var_choices6b = varDict.var_choices6b, 
                        // Group > Respond
                        var_studentbehavior7b = varDict.var_studentbehavior7b, var_prompts8part1b = varDict.var_prompts8part1b, var_othercontingent8part2b = varDict.var_othercontingent8part2b, 
                        // Group > Turns
                        var_turns9b = varDict.var_turns9b, 
                        // Implement Report > Create
                        var_attention1IMP = varDict.var_attention1IMP, var_opportunity2IMP = varDict.var_opportunity2IMP, var_instructions3IMP = varDict.var_instructions3IMP, var_easy4IMP = varDict.var_easy4IMP, var_UseMaterials5IMP = varDict.var_UseMaterials5IMP, var_choices6IMP = varDict.var_choices6IMP, var_studentinterest7IMP = varDict.var_studentinterest7IMP, var_prompts8part1IMP = varDict.var_prompts8part1IMP, 
                        // Implement Report > Respond
                        var_othercontingent8part2aIMP = varDict.var_othercontingent8part2aIMP, var_othercontingent8part2dIMP = varDict.var_othercontingent8part2dIMP, 
                        // Implement Report > Turns
                        var_turns9IMP = varDict.var_turns9IMP, 
                        // RESPONSE ===================================
                        // Individual > Create
                        var_1aText = varDict.var_1aText, var_2aText = varDict.var_2aText, 
                        // Group > Create
                        var_1bText = varDict.var_1bText, 
                        // var_2bText,
                        var_6bText = varDict.var_6bText;
                        // function getHtmlFromBoolVars(...varList: string[]) {
                        //   return varList
                        //     .map(varName => {
                        //       const varValString = varDict[varName] ? varDict[varName].toString() : '';
                        //       if (varValString == 'true') {
                        //         return choiceTextMap[varName];
                        //       }
                        //     })
                        //     .filter(val => val)
                        //     .join('<br>');
                        // }
                        var genericTableData = [
                            {
                                title: 'Create Lesson Plan',
                                blocks: [
                                    {
                                        title: 'Individual',
                                        tables: [
                                            {
                                                title: 'Create an opportunity',
                                                columns: [
                                                    {
                                                        header: 'Element',
                                                        rows: [
                                                            'Get student attention',
                                                            'Present a clear opportunity',
                                                            'Use varied instructions',
                                                            'Include some easy tasks',
                                                            'Use preferred materials (and vary them)',
                                                            'Give choices',
                                                        ],
                                                    },
                                                    {
                                                        header: 'Score',
                                                        rows: processTemplateVarHtml([
                                                            var_attention1a,
                                                            var_opportunity2a,
                                                            var_instructions3a,
                                                            var_easy4a,
                                                            var_useMaterials5a,
                                                            var_choices6a,
                                                        ]),
                                                    },
                                                    {
                                                        header: 'Choice made',
                                                        rows: processTemplateVarHtml([
                                                            var_1aText,
                                                            var_2aText,
                                                            getHtmlFromBoolVars(varDict, choiceTextMap, [
                                                                'var_3aTexta',
                                                                'var_3aTextb',
                                                                'var_3aTextc',
                                                                'var_3aTextd',
                                                            ]),
                                                            getHtmlFromBoolVars(varDict, choiceTextMap, [
                                                                'var_4aTexta',
                                                                'var_4aTextb',
                                                                'var_4aTextc',
                                                                'var_4aTextd',
                                                            ]),
                                                            getHtmlFromBoolVars(varDict, choiceTextMap, [
                                                                'var_5aTexta',
                                                                'var_5aTextb',
                                                                'var_5aTextc',
                                                                'var_5aTextd',
                                                                'var_5aTexte',
                                                            ]),
                                                            getHtmlFromBoolVars(varDict, choiceTextMap, [
                                                                'var_6aTexta',
                                                                'var_6aTextb',
                                                                'var_6aTextc',
                                                                'var_6aTextd',
                                                                'var_6aTexte',
                                                            ]),
                                                        ]),
                                                    },
                                                ],
                                            },
                                            {
                                                title: 'Respond to student’s behavior',
                                                columns: [
                                                    {
                                                        header: 'Element',
                                                        rows: [
                                                            'Identify type of student behavior',
                                                            'Provide prompts as necessary',
                                                            'Provide other contingent responses',
                                                        ],
                                                    },
                                                    {
                                                        header: 'Score',
                                                        rows: [
                                                            var_studentbehavior7a,
                                                            var_prompts8part1a,
                                                            var_othercontingent8part2a,
                                                        ],
                                                    },
                                                    {
                                                        header: 'Choice made',
                                                        rows: processTemplateVarHtml([
                                                            {
                                                                type: 'staticAndDynamic',
                                                                title: 'Correctly identified these behaviors:',
                                                                text: getHtmlFromBoolVars(varDict, choiceTextMap, [
                                                                    'var_7aTexta',
                                                                    'var_7aTextb',
                                                                    'var_7aTextc',
                                                                    'var_7aTextd',
                                                                ]),
                                                            },
                                                            getHtmlFromBoolVars(varDict, choiceTextMap, [
                                                                'var_8part1aTexta',
                                                                'var_8part1aTextb',
                                                                'var_8part1aTextc',
                                                                'var_8part1aTextd',
                                                                'var_8part1aTexte',
                                                                'var_8part1aTextf',
                                                                // 'var_8part1aTextg',
                                                            ]),
                                                            {
                                                                type: 'staticAndDynamic',
                                                                title: 'Correctly provided these contingent behaviors:',
                                                                text: getHtmlFromBoolVars(varDict, choiceTextMap, [
                                                                    'var_8part2aTexta',
                                                                    'var_8part2aTextb',
                                                                    'var_8part2aTextc',
                                                                    'var_8part2aTextd',
                                                                ]),
                                                            },
                                                        ]),
                                                    },
                                                ],
                                            },
                                            {
                                                title: 'Turns',
                                                columns: [
                                                    {
                                                        header: 'Element',
                                                        rows: [
                                                            'Take turns',
                                                        ],
                                                    },
                                                    {
                                                        header: 'Score',
                                                        rows: [
                                                            var_turns9a,
                                                        ],
                                                    },
                                                    {
                                                        header: 'Choice made',
                                                        rows: processTemplateVarHtml([
                                                            getHtmlFromBoolVars(varDict, choiceTextMap, [
                                                                'var_9aTexta',
                                                                'var_9aTextb',
                                                                'var_9aTextc',
                                                                'var_9aTextd',
                                                                'var_9aTexte',
                                                            ])
                                                        ]),
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        title: 'Group',
                                        tables: [
                                            {
                                                title: 'Create an opportunity',
                                                columns: [
                                                    {
                                                        header: 'Element',
                                                        rows: [
                                                            'Get student attention',
                                                            'Present a clear opportunity',
                                                            'Use varied instructions',
                                                            'Include some easy tasks',
                                                            'Use preferred materials (and vary them)',
                                                            'Give choices',
                                                        ],
                                                    },
                                                    {
                                                        header: 'Score',
                                                        rows: processTemplateVarHtml([
                                                            var_attention1b,
                                                            var_opportunity2b,
                                                            var_instructions3b,
                                                            var_easy4b,
                                                            var_UseMaterials5b,
                                                            var_choices6b,
                                                        ]),
                                                    },
                                                    {
                                                        header: 'Choice made',
                                                        rows: processTemplateVarHtml([
                                                            var_1bText,
                                                            // var_2bText,
                                                            getHtmlFromBoolVars(varDict, choiceTextMap, [
                                                                'var_2bTexta',
                                                                'var_2bTextb',
                                                                'var_2bTextc',
                                                                'var_2bTextd',
                                                            ]),
                                                            getHtmlFromBoolVars(varDict, choiceTextMap, [
                                                                'var_3bTexta',
                                                                'var_3bTextb',
                                                                'var_3bTextc',
                                                            ]),
                                                            getHtmlFromBoolVars(varDict, choiceTextMap, [
                                                                'var_4bTexta',
                                                                'var_4bTextb',
                                                                'var_4bTextc',
                                                                'var_4bTextd',
                                                            ]),
                                                            getHtmlFromBoolVars(varDict, choiceTextMap, [
                                                                'var_5bTexta',
                                                                'var_5bTextb',
                                                                'var_5bTextc',
                                                                'var_5bTextd',
                                                            ]),
                                                            var_6bText,
                                                        ]),
                                                    },
                                                ],
                                            },
                                            {
                                                title: 'Respond to student’s behavior',
                                                columns: [
                                                    {
                                                        header: 'Element',
                                                        rows: [
                                                            'Identify type of student behavior',
                                                            'Provide prompts as necessary',
                                                            'Provide other contingent responses <br><em>(Note that reasonable attempts are not possible within this lesson as all attempts will be factually correct or incorrect)</em>',
                                                        ],
                                                    },
                                                    {
                                                        header: 'Score',
                                                        rows: processTemplateVarHtml([
                                                            var_studentbehavior7b,
                                                            var_prompts8part1b,
                                                            var_othercontingent8part2b,
                                                        ]),
                                                    },
                                                    {
                                                        header: 'Choice made',
                                                        rows: processTemplateVarHtml([
                                                            {
                                                                type: 'staticAndDynamic',
                                                                title: 'Correctly identified these behaviors:',
                                                                text: getHtmlFromBoolVars(varDict, choiceTextMap, [
                                                                    'var_7bTexta',
                                                                    'var_7bTextb',
                                                                    'var_7bTextc',
                                                                    'var_7bTextd',
                                                                ]),
                                                            },
                                                            getHtmlFromBoolVars(varDict, choiceTextMap, [
                                                                'var_8part1bTexta',
                                                                'var_8part1bTextb',
                                                                'var_8part1bTextc',
                                                                'var_8part1bTextd',
                                                                'var_8part1bTexte',
                                                            ]),
                                                            {
                                                                type: 'staticAndDynamic',
                                                                title: 'Correctly provided these contingent behaviors:',
                                                                text: getHtmlFromBoolVars(varDict, choiceTextMap, [
                                                                    'var_8part2bTexta',
                                                                    'var_8part2bTextc',
                                                                    'var_8part2bTextd',
                                                                ]),
                                                            },
                                                        ]),
                                                    },
                                                ],
                                            },
                                            {
                                                title: 'Turns',
                                                columns: [
                                                    {
                                                        header: 'Element',
                                                        rows: [
                                                            'Take turns',
                                                        ],
                                                    },
                                                    {
                                                        header: 'Score',
                                                        rows: processTemplateVarHtml([
                                                            var_turns9b,
                                                        ]),
                                                    },
                                                    {
                                                        header: 'Choice made',
                                                        rows: processTemplateVarHtml([
                                                            getHtmlFromBoolVars(varDict, choiceTextMap, [
                                                                'var_9bTexta',
                                                                'var_9bTextb',
                                                                'var_9bTextc',
                                                                'var_9bTextd',
                                                            ]),
                                                        ]),
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                title: 'Implement Lesson Plan',
                                blocks: [
                                    {
                                        title: 'Report',
                                        tables: [
                                            {
                                                title: 'Create an Opportunity',
                                                columns: [
                                                    {
                                                        header: 'Components',
                                                        rows: [
                                                            'Gets student attention',
                                                            'Presents clear opportunities',
                                                            'Uses varied instructions',
                                                            'Includes some easy tasks',
                                                            'Uses preferred materials (and varies them)',
                                                            'Gives choices',
                                                            'Responds to student interest',
                                                            'Waits 5-10 seconds for child to respond <strong>(Pause)</strong>',
                                                        ],
                                                    },
                                                    {
                                                        header: 'Score (0 or 1)',
                                                        rows: processTemplateVarHtml([
                                                            var_attention1IMP,
                                                            var_opportunity2IMP,
                                                            var_instructions3IMP,
                                                            var_easy4IMP,
                                                            var_UseMaterials5IMP,
                                                            var_choices6IMP,
                                                            var_studentinterest7IMP,
                                                            var_prompts8part1IMP,
                                                        ]),
                                                    },
                                                ],
                                            },
                                            {
                                                title: 'Respond to student’s behavior',
                                                columns: [
                                                    {
                                                        header: 'Components',
                                                        rows: [
                                                            'Provides direct reward and appropriate contingent response to appropriate student behavior',
                                                            'Provides reward and appropriate contingent response to reasonable student attempt',
                                                            'Provides no reward and appropriate contingent response (including prompt) to inappropriate student behavior',
                                                            'Provides no reward and appropriate contingent response (including prompt) to incorrect student behavior',
                                                        ],
                                                    },
                                                    {
                                                        header: 'Score (0 or 1)',
                                                        rows: processTemplateVarHtml([
                                                            var_othercontingent8part2aIMP,
                                                            'N/A',
                                                            'N/A',
                                                            var_othercontingent8part2dIMP,
                                                        ]),
                                                    },
                                                ],
                                            },
                                            {
                                                title: 'Take Turns',
                                                columns: [
                                                    {
                                                        header: 'Components',
                                                        rows: [
                                                            'Takes turns with student',
                                                            'Facilitates turns between students (for group lessons only)',
                                                        ],
                                                    },
                                                    {
                                                        header: 'Score (0 or 1)',
                                                        rows: processTemplateVarHtml([
                                                            var_turns9IMP,
                                                            'N/A',
                                                        ]),
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            },
                        ];
                        var html = genericTableData.map(function (tableSection) { return new GenericTableSection(tableSection).html; }).join('');
                        return html;
                    },
                }
            },
        ]
    },
};
