import { DrilldownTable, defaultTrafficLights, } from "../../../../../shared/interfaces-classes/index";
import { reportUtils } from "../../../../report-utils";
var processTemplateVarHtml = reportUtils.processTemplateVarHtmlList, getGenericGroupMsg = reportUtils.getGenericGroupMsg, processSingleVarHtml = reportUtils.processSingleVarHtml, getYesNoFromBoolishVar = reportUtils.getYesNoFromBoolishVar;
var genericGroupMsg = getGenericGroupMsg();
export var mod1 = {
    'http://NCICMod1': {
        columns: [
            {
                parent: 'LO7',
                variablename: 'LO7',
                desc: 'Recall types of (5-6) necessary groups of partners and their roles',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        response: [
                            'CocreationPartnersCorrect',
                            // 'CocreationPartnersAttempts',
                        ],
                        scoreRaw: [
                            'CocreationPartnersAttempts',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var CocreationPartnersCorrect = varDict.CocreationPartnersCorrect, CocreationPartnersAttempts = varDict.CocreationPartnersAttempts;
                        var columns = [
                            {
                                header: 'Co-creation partners',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t\t\t<li>Agency leaders & staff</li>\n\t\t\t\t\t\t\t\t\t\t<li>Regional funder</li>\n\t\t\t\t\t\t\t\t\t\t<li>Researchers/evaluators</li>\n\t\t\t\t\t\t\t\t\t\t<li>Community members</li>\n\t\t\t\t\t\t\t\t\t\t<li>Policymakers</li>\n\t\t\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t\t\t\t"
                                ]
                            },
                            {
                                header: 'Correct answer (choose all)?',
                                rows: [
                                    getYesNoFromBoolishVar(CocreationPartnersCorrect),
                                ]
                            },
                            {
                                header: 'Number of attempts',
                                rows: [
                                    processSingleVarHtml(CocreationPartnersAttempts)
                                ]
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO9a',
                variablename: 'LO9',
                desc: 'Utilize successful strategies to engage specific, community-relevant Triple P co-creation partners',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        response: [
                            'text42',
                            'text43',
                            'text44',
                            'text45',
                            'text412',
                            'text46',
                            'text47',
                            'text48',
                            'text411',
                            'text49',
                            'text410',
                            'text414',
                            'text415',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var text42 = varDict.text42, text43 = varDict.text43, text44 = varDict.text44, text45 = varDict.text45, text412 = varDict.text412, text46 = varDict.text46, text47 = varDict.text47, text48 = varDict.text48, text411 = varDict.text411, text49 = varDict.text49, text410 = varDict.text410, text414 = varDict.text414, text415 = varDict.text415;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    "<p><strong>Agency rep:</strong></p>\n\t\t\t\t\t\t\t\t\tHow is this going to fit in with everything else we have going on?",
                                    "<p><strong>Parent:</strong></p>\n\t\t\t\t\t\t\t\t\tThese new community initiatives are always done <em>to</em> us, not <em>with</em> us. How can we be sure that we\u2019re going to have meaningful voice and involvement this time?",
                                    "<p><strong>Agency rep:</strong></p>\n\t\t\t\t\t\t\t\t\tHow will this be funded?",
                                    "<p><strong>Funder:</strong></p>\n\t\t\t\t\t\t\t\t\tGiven how large this initiative may become over time, how do you plan to manage the sizable funding that may be necessary?",
                                    "<p><strong>Funder:</strong></p>\n\t\t\t\t\t\t\t\t\tHow do you plan to sustain this program financially over time at such a large scale within the community?",
                                    "<p><strong>Funder:</strong></p>\n\t\t\t\t\t\t\t\t\tI\u2019m sorry to rattle off so many questions in a row, but I have one more. Typically, program funding results in limited success for short periods of time. In this case, how might we increase the likelihood of a stronger return on investment?",
                                    "<p><strong>Evaluator:</strong></p>\n\t\t\t\t\t\t\t\t\tHow will you know if you are successful?",
                                    "<p><strong>Evaluator:</strong></p>\n\t\t\t\t\t\t\t\t\tWhat do you need to know along the way to increase likelihood of success?",
                                    "<p><strong>Intermediatory organization ISP:</strong></p>\n\t\t\t\t\t\t\t\t\tWho is going to be responsible for ensuring that local capacity to implement Triple P is developed and that performance is strong?",
                                    "<p><strong>Agency rep:</strong></p>\n\t\t\t\t\t\t\t\t\tWhat kind of time and effort will be required from agency staff?",
                                    "<p><strong>Agency rep:</strong></p>\n\t\t\t\t\t\t\t\t\tGiven all that we already have to do, why should local agencies participate in this?",
                                    "<p><strong>Parent:</strong></p>\n\t\t\t\t\t\t\t\t\tWhat do you know about the culture of our families in our community? How do we know that this program will fit with our cultural experiences, needs, and preferences?",
                                    "<p><strong>Intermediatory organization ISP:</strong></p>\n\t\t\t\t\t\t\t\t\tWhat external supports might be needed on an ongoing basis?",
                                ]
                            },
                            {
                                header: "Correct answer",
                                rows: [
                                    "By embedding effective implementation strategies and best practices, agencies will have an opportunity to better align programs internally and across the community.",
                                    "We will identify local parents who would be willing to co-lead the Triple P initiative and participate in program evaluation and practitioners\u2019 professional development activities.",
                                    "While we hope to secure some initial resources from a funder, the process of identifying adequate funding streams will be ongoing work.",
                                    "This initiative is likely to need a blended funding model over time.",
                                    "Over time, we will identify existing funding streams that can be repurposed and seek to develop new funding streams.",
                                    "In many initiatives, funding is only allocated for program training and materials, but for this initiative, we will purposefully invest in the local implementation capacity needed to support success and sustainability.",
                                    "We will be successful if we are able to reach the majority of local families with some form of evidence-based parenting support.",
                                    "Working with a local evaluator, we intend to identify and monitor multiple benchmarks for implementation success and previously identified community well-being indicators.",
                                    "It will take a coalition of agencies working together, each with identified leaders and implementation team members, to ensure a system of support and feedback about Triple P implementation across the community.",
                                    "Practitioners will need time to deliver, train, coach, and collect data. Also ongoing leadership and daily management support for Triple P implementation will be required.",
                                    "The collection of implementation resources and abilities we\u2019ll develop across all agencies involved in the coalition is likely to benefit not only Triple P delivery, but other agency programs as well.",
                                    "We will actively partner with parents and Triple P developers as co-leaders in the implementation process to ensure that Triple P is meeting the needs and preferences of our families, while retaining the active ingredients that lead to individual and community wellbeing benefits.\n\t\t\t\t\t\t\t\t\t",
                                    "Bringing together this initial group of co-creation partners is a start. Over time, we\u2019ll be asking each of you, and others in your role, to be key support partners.",
                                ]
                            },
                            {
                                header: 'Answer provided',
                                rows: processTemplateVarHtml([
                                    text42,
                                    text43,
                                    text44,
                                    text45,
                                    text412,
                                    text46,
                                    text47,
                                    text48,
                                    text411,
                                    text49,
                                    text410,
                                    text414,
                                    text415,
                                ]),
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO9b',
                variablename: 'LO9b',
                desc: 'Construct statements to gain buy-in from co-creation partners',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    icon: 'text',
                    vars: {
                        opts: {
                            prefix: false,
                        },
                        response: [
                            'identifiedneeds',
                            'recommendedstrategy',
                            'whatittakestoscaleup',
                            'clearrequest',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var identifiedneeds = varDict.identifiedneeds, recommendedstrategy = varDict.recommendedstrategy, whatittakestoscaleup = varDict.whatittakestoscaleup, clearrequest = varDict.clearrequest;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    "<p>Describe the identified needs in Grand County</p> \n\t\t\t\t\t\t\t\t\t<em>(Remember, try to appeal to your partners' own interests: what the needs mean to them)</em>",
                                    "<p>Describe the recommended strategy to address local needs</p>\n\t\t\t\t\t\t\t\t\t <em>(This may include clear rationale for the local fit and expected outcomes of Triple P)</em>",
                                    "<p>Describe what it will take to scale-up Triple P</p> \n\t\t\t\t\t\t\t\t\t<em>(Be clear about current capacities and additional capacities that will have to be created)</em>",
                                    "<p>Finally, make a clear request of your co-creation partners</p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<em>(What is it your team needs from them to move forward?)</em>",
                                ]
                            },
                            {
                                header: "Correct answer",
                                rows: [
                                    "<p>Thank you for your support as we begin to explore child and family well-being needs in Grand County. The community needs assessment you funded showed that our children aren\u2019t all starting kindergarten with the social and emotional skills to get off on the right foot. We\u2019ve also seen child abuse and neglect cases rising over the past few years in our community. When we listened to local parents and families, a fair number said they would like to make use of parenting support for things like teaching their kids needed routines, promoting healthy interests, and managing behavior.</p> \n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>But even with existing community services, parents were unsure where to turn for the level of help they wanted. Many also felt that reaching out for even basic parenting advice might be judged or lead to false concerns about their parenting.</p> ",
                                    "<p>When we looked around for parenting programs that might address some of these needs and preferences, the Triple P - Positive Parenting Program system of interventions gained our interest. Triple P has an evidence-base that includes good child social and emotional development outcomes from birth through age 8, has demonstrated the ability to reduce child abuse and neglect across whole communities, and includes media and other community-wide strategies to normalize the need for parenting advice and increase the reach of parenting support.</p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>We also believe that Triple P could align well with our other current community well-being initiatives, such as our new health campaign around child physical activity and health foods.</p>",
                                    "<p>We\u2019ve had an initial conversation with Triple P America, and to scale-up Triple P with good effect, it\u2019s best to have collective effort from a group of committed local service agencies and practitioners. To this end, we already have several local child and family service agencies from across the service spectrum that have expressed interest in collective actions towards shared community outcomes not unlike kindergarten readiness or child safety.</p> \n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>Some of these agencies participated in our recent child reading initiative, and even used data to show their effectiveness and improve outcomes. Our exploration team has interest in coordinating and supporting the effort. We also think our role is feasible with funding support and clear commitments from other community agencies and partners.</p>",
                                    "<p>We\u2019ve partnered with each of you because you bring unique abilities, resources, and perspectives to the table. While our exploration team doesn\u2019t pretend to have all the answers yet, we believe that with your partnership, the initial data we have at hand, and the fit that Triple P appears to provide for our community, we\u2019re on the right track to improve the lives of families in Grand County. What we\u2019re asking for now is to reaffirm our partnerships and take the next steps together.</p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>We believe that we\u2019re ready to recruit an initial coalition of community partners around our community needs, collective goals, and joint actions using Triple P. And we know that we will need to get the necessary resources to scale Triple P to reach all Grand County families. Will you join us in these next steps?</p>",
                                ]
                            },
                            {
                                header: 'Answer provided',
                                rows: processTemplateVarHtml([
                                    identifiedneeds,
                                    recommendedstrategy,
                                    whatittakestoscaleup,
                                    clearrequest,
                                ]),
                            },
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
        ],
        printouts: [
            {
                name: 'Customized Learner Summary',
                profileId: 'ictp-mod-1',
                project: 'ictp',
            }
        ]
    },
};
