// import { IGenericFlatMap } from "./index";
var GenericTable = /** @class */ (function () {
    function GenericTable(table) {
        this.html = '';
        var tableTitle = table.title, columns = table.columns;
        var tableTitleHtml = "<h4 class=\"generic-table-title\">" + tableTitle + "</h4>";
        var tHeadHtml = '';
        var tBodyHtml = '';
        var tBodyRowMap = new Map();
        columns.forEach(function (_a) {
            var header = _a.header, rows = _a.rows;
            tHeadHtml += "<th>" + header + "</th>";
            rows.forEach(function (cell, cellIdx) {
                var cellValue = cell;
                var tbodyRow = tBodyRowMap.get(cellIdx) || [];
                tbodyRow.push("\n\t\t\t\t\t<td class=\"column-text\">\n\t\t\t\t\t\t<p>" + cellValue + "</p>\n\t\t\t\t\t</td>\n\t\t\t\t");
                tBodyRowMap.set(cellIdx, tbodyRow);
            });
        });
        tBodyRowMap.forEach(function (rowList) {
            tBodyHtml += "<tr>" + rowList.join('') + "</tr>";
        });
        this.html = "\n      <div class=\"generic-table-wrapper\">\n\n        " + tableTitleHtml + "\n\n        <table class=\"generic-table\">\n          <thead>\n            <tr>" + tHeadHtml + "</tr>\n          </thead>\n          <tbody>" + tBodyHtml + "</tbody>\n        </table>\n\n      </div>\n\t\t";
    }
    return GenericTable;
}());
var GenericTableBlock = /** @class */ (function () {
    function GenericTableBlock(block) {
        this.html = '';
        var blockTitle = block.title, tables = block.tables;
        var blockTitleHtml = "<h3 class=\"generic-table-block-title\">" + blockTitle + "</h3>";
        var blockHtml = tables.map(function (table) { return new GenericTable(table).html; }).join('');
        this.html = "\n      <div class=\"generic-table-block\">\n        " + blockTitleHtml + "\n        " + blockHtml + "\n      </div>\n    ";
    }
    return GenericTableBlock;
}());
/**
 * Generic Table
 * @property {string} html - HTML table
 */
var GenericTableSection = /** @class */ (function () {
    function GenericTableSection(genericTableSection) {
        this.html = '';
        var sectionTitle = genericTableSection.title, blocks = genericTableSection.blocks;
        var sectionTitleHtml = "<h2 class=\"generic-table-section-title\">" + sectionTitle + "</h2>";
        var sectionHtml = blocks.map(function (block) { return new GenericTableBlock(block).html; }).join('');
        this.html = "\n      <div class=\"generic-table-section\">\n        " + sectionTitleHtml + "\n        " + sectionHtml + "\n      </div>\n    ";
    }
    return GenericTableSection;
}());
export { GenericTableSection };
