import { DrilldownTable, defaultTrafficLights, } from "../../../../../shared/interfaces-classes/index";
import { reportUtils } from "../../../../report-utils";
var getGenericGroupMsg = reportUtils.getGenericGroupMsg, processSingleVarHtml = reportUtils.processSingleVarHtml, getCorrectIncorrectByValueComparison = reportUtils.getCorrectIncorrectByValueComparison, getBoolFromBoolishVar = reportUtils.getBoolFromBoolishVar;
var genericGroupMsg = getGenericGroupMsg();
export var mod8 = {
    'http://ICTPMod8': {
        columns: [
            {
                parent: 'LO1',
                variablename: 'plandash',
                desc: 'Identify appropriate coaching plan elements',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'plan35',
                            'plan36',
                            'plan37',
                            'plan38',
                            'plan39',
                            'plan310',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var plan35 = varDict.plan35, plan36 = varDict.plan36, plan37 = varDict.plan37, plan38 = varDict.plan38, plan39 = varDict.plan39, plan310 = varDict.plan310;
                        var columns = [
                            {
                                header: 'Structure in coaching plan',
                                rows: [
                                    'Frequency of coaching',
                                    'Coaching frequency data',
                                    'Available fluent coaches',
                                    'Coaching data to inform feedback',
                                    'Coaching outcome data',
                                    'Adherence data',
                                ],
                            },
                            {
                                header: 'Correct answers?',
                                type: 'checkmark',
                                rows: [
                                    plan35,
                                    plan36,
                                    plan37,
                                    plan38,
                                    plan39,
                                    plan310,
                                ],
                            }
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
            {
                parent: 'LO2',
                variablename: 'LO2',
                desc: 'Identify next steps regarding coaching system adherence ',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    icon: 'text',
                    vars: {
                        response: [
                            'ResponseToGCMiddle',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var ResponseToGCMiddle = varDict.ResponseToGCMiddle;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'What coaching might you provide to Grand County Middle School about their adherence to the Triple P Coaching System?',
                                ],
                            },
                            {
                                header: 'Learner answer',
                                rows: [
                                    ResponseToGCMiddle
                                ],
                            },
                            {
                                header: 'ICTP answer',
                                rows: [
                                    "\n\t\t\t\t\t\t\t\t\t<p>Consider using a strengths-based approach, including self-regulation principles, and create an action plan.</p>\n\n\t\t\t\t\t\t\t\t\t<p>\u201CYou have done a great job setting up coaching!  Your coach attended the coaching peer supports and turned in data related to coaching.</p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>Tell us about what you see as your successes and barriers to having your practitioners participate in coaching at the expected frequency.</p>\n\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t<p>Let\u2019s create a plan to increase the frequency of coaching over the next quarter.\u201D</p>\n\t\t\t\t\t\t\t\t\t"
                                ],
                            }
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
            {
                parent: 'LO3',
                variablename: 'LO3dash',
                desc: 'Identify system changes necessary',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'changes',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var changes = varDict.changes;
                        var learnerAnswer = '';
                        if (changes === undefined) {
                            learnerAnswer = processSingleVarHtml(changes).toString();
                        }
                        else {
                            learnerAnswer = getBoolFromBoolishVar(changes) ?
                                "\n\t\t\t\t\t\t\t\t<p><strong>CORRECT answer chosen:</strong></p>\n\t\t\t\t\t\t\t\t<p>Provide needed supports to the agencies to action plan for adherence to the coaching plan. Then, do another adherence check in three months.</p>\n\t\t\t\t\t\t\t"
                                :
                                    "\n\t\t\t\t\t\t\t\t<p><strong>INCORRECT answer chosen:</strong></p>\n\t\t\t\t\t\t\t\t<p>Change the frequency for practitioners to participate in coaching.</p>\n\t\t\t\t\t\t\t\t<p><em>OR</em></p>\n\t\t\t\t\t\t\t\t<p>Add more coaches into the Triple P Community Coaching System and require coaching trainings for everyone.</p>\n\t\t\t\t\t\t\t\t</p>\n\t\t\t\t\t\t\t";
                        }
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'What changes might improve the Grand County coaching plan?',
                                ],
                            },
                            {
                                header: 'Correct answer',
                                rows: [
                                    'Provide needed supports to the agencies to action plan for adherence to the coaching plan. Then, do another adherence check in three months.'
                                ],
                            },
                            {
                                header: 'Learner answer',
                                rows: [
                                    learnerAnswer
                                ],
                            }
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
        ],
        printouts: [
        // {
        // 	name: 'Customized Learner Summary',
        // 	profileId: 'ictp-mod-8',
        // project: 'ictp',
        // }
        ],
    },
};
// export const mod8 = {
// 	'http://ICTPMod8': {
// 		type: 2,
// 		profileId: 'ictp-mod-8',
// 		hasPrintout: true,
// 		title: '',
// 		sections: [
// 			{
// 				title: 'Coaching Plan Elements overall',
// 				titleSize: 'large',
// 				activityList: [
// 					{
// 						activityName: 'Dashboard variable',
// 						variable: 'plandash',
// 						color: '',
// 					},
// 				],
// 			},
// 			{ separator: true, },
// 			{ subtitle: 'Coaching structure provided:' },
// 			{
// 				title: 'Coaching data and frequency:',
// 				groupedSection: [
// 					{
// 						title: 'Frequency of coaching',
// 						activityList: [
// 							{
// 								activityName: 'Frequency of coaching',
// 								variable: 'plan35',
// 								// dataProp: 'result.success',
// 								responseType: 'boolean',
// 								color: '',
// 							},
// 						],
// 					},
// 					{
// 						title: 'Coaching frequency data',
// 						activityList: [
// 							{
// 								activityName: 'Coaching frequency data',
// 								variable: 'plan36',
// 								// dataProp: 'result.success',
// 								responseType: 'boolean',
// 								color: '',
// 							},
// 						],
// 					},
// 				],
// 				// groupedSection: [
// 				// 	{
// 				// 		activityName: 'Frequency of coaching',
// 				// 		variable: 'plan35',
// 				// 		// dataProp: 'result.success',
// 				// 		responseType: 'boolean',
// 				// 		color: '',
// 				// 	},
// 				// 	{
// 				// 		activityName: 'Coaching frequency data',
// 				// 		variable: 'plan36',
// 				// 		// dataProp: 'result.success',
// 				// 		responseType: 'boolean',
// 				// 		color: '',
// 				// 	},
// 				// ],
// 				// grouped: {
// 				// 	// title: '',
// 				// 	activityList: [
// 				// 		{
// 				// 			activityName: 'Frequency of coaching',
// 				// 			variable: 'plan35',
// 				// 			// dataProp: 'result.success',
// 				// 			responseType: 'boolean',
// 				// 			color: '',
// 				// 		},
// 				// 		{
// 				// 			activityName: 'Coaching frequency data',
// 				// 			variable: 'plan36',
// 				// 			// dataProp: 'result.success',
// 				// 			responseType: 'boolean',
// 				// 			color: '',
// 				// 		},
// 				// 	],						
// 				// }
// 			},
// 			/* 
// 							{
// 								title: 'Frequency of coaching',
// 								section: 'Frequency of coaching',
// 								activityList: [
// 									{
// 										activityName: 'Frequency of coaching',
// 										variable: 'plan35',
// 										// dataProp: 'result.success',
// 										responseType: 'boolean',
// 										color: '',
// 									},
// 								],
// 							},
// 							{
// 								title: 'Coaching frequency data',
// 								section: 'Coaching frequency data',
// 								activityList: [
// 									{
// 										activityName: 'Coaching frequency data',
// 										variable: 'plan36',
// 										// dataProp: 'result.success',
// 										responseType: 'boolean',
// 										color: '',
// 									},
// 								],
// 							}, 
// 							*/
// 			{
// 				title: 'Available fluent coaches',
// 				section: 'Available fluent coaches',
// 				activityList: [
// 					{
// 						activityName: 'Available fluent coaches',
// 						variable: 'plan37',
// 						// dataProp: 'result.success',
// 						responseType: 'boolean',
// 						color: '',
// 					},
// 				],
// 			},
// 			{
// 				title: 'Coaching data to inform feedback',
// 				section: 'Coaching data to inform feedback',
// 				activityList: [
// 					{
// 						activityName: 'Coaching data to inform feedback',
// 						variable: 'plan38',
// 						// dataProp: 'result.success',
// 						responseType: 'boolean',
// 						color: '',
// 					},
// 				],
// 			},
// 			{
// 				title: 'Coaching outcome data',
// 				section: 'Coaching outcome data',
// 				activityList: [
// 					{
// 						activityName: 'Coaching outcome data',
// 						variable: 'plan39',
// 						// dataProp: 'result.success',
// 						responseType: 'boolean',
// 						color: '',
// 					},
// 				],
// 			},
// 			{
// 				title: 'Adherence data',
// 				section: 'Adherence data',
// 				activityList: [
// 					{
// 						activityName: 'Adherence data',
// 						variable: 'plan310',
// 						// dataProp: 'result.success',
// 						responseType: 'boolean',
// 						color: '',
// 					},
// 				],
// 			},
// 			{ separator: true, },
// 			{
// 				title: 'Next Steps:',
// 				titleSize: 'large',
// 				note: '<strong>Note:</strong> should include: identification of strengths, use of self-regulation principles, action planning',
// 				activityList: [
// 					{
// 						title: 'Coaching provided:',
// 						activityName: 'Coaching provided',
// 						variable: 'ResponseToGCMiddle',
// 						response: '',
// 					},
// 				],
// 			},
// 			{ separator: true, },
// 			{
// 				title: 'Changes necessary',
// 				titleSize: 'large',
// 				activityList: [
// 					{
// 						activityName: 'Changes necessary',
// 						variable: 'changes',
// 						// dataProp: 'result.success',
// 						responseType: 'boolean',
// 						color: '',
// 					},
// 				],
// 			},
// 		],
// 	},
// };
