import { structures } from './reports/structures/index';
import { embeds } from '../../embeds';
import { messaging } from '../../../shared/exports';
import { reportUtils } from '../../report-utils';
import { cprtGeneric } from './reports/processing/cprt-generic';
import { drilldownGrid } from '../../../shared/custom-report/drilldown-grid';
import { xapi } from '../../../xapi/xapi-base';
// list available in `standard-reports.ts`
var removedColumns = [
    'percentageViewed',
    'duration',
];
export var cprt = {
    type: '',
    report: {},
    structures: structures,
    removedColumns: removedColumns,
    // gets statements using search params
    // will run init() when report button clicked (after statements retrieved)
    onSubmitCallback: function () { },
    init: function (stmts) {
        var _this = this;
        var type = this.type;
        return new Promise(function (resolve, reject) {
            var invalid = !_this[type];
            if (invalid) {
                alert('Custom report not available yet; showing standard report.');
                reject('invalid');
            }
            else {
                if (!stmts || !stmts.length) {
                    messaging.show('alert', 'No data found');
                    $("." + cprt.type + "-loading-container").addClass('hidden');
                    return;
                }
                _this[type].init(stmts, cprt);
                resolve(null);
            }
        });
    },
    searchUserId: '',
    searchUserType: '',
    getxAPISearchValues: function (search) {
        var type = cprt.type, searchUserId = cprt.searchUserId, searchUserType = cprt.searchUserType;
        var isGrid = type === 'grid';
        if (isGrid) {
            if (searchUserId) {
                search.agent = xapi.create.agent(searchUserType, searchUserId);
            }
        }
        return search;
    },
    grid: drilldownGrid,
    generic: cprtGeneric,
    // getFullReportId() {
    //   const { report } = cprt;
    //   const { subtype } = report;
    //   return subtype ? `${report.id}/${subtype}` : report.id;
    // },
    ui: {
        init: function () {
            // const { type, structures } = cprt;
            // const reportId = cprt.getFullReportId();
            // const thisReport = structures[reportId];
            // const { hasPrintout } = thisReport || {};
            // if (hasPrintout) {
            // 	$('.print-cert')
            // 		.removeClass('hidden')
            // 		.on('click', printout.onGetPrintout);
            // }
            // if (type === 'printout') {
            // 	$('.get-stmts').addClass('hidden');
            // }
            this.updateFilters();
            this.bindEvents();
            this.customInit();
        },
        updateFilters: function () {
            $('.standard.filters').addClass('hidden');
            $('.custom.filters').removeClass('hidden');
            $('.user-filter').removeClass('hidden');
            $('.user-filter-email').removeClass('hidden');
        },
        bindEvents: function () {
            var reportProcessor = cprt.type === 'generic' ? cprtGeneric : drilldownGrid;
            reportUtils.bindActorFormEvents(cprt, reportProcessor);
        },
        customInit: function () {
            if (cprt.type === 'embeds') {
                embeds.init(cprt.report);
            }
        }
    },
};
