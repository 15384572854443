import { xapi } from '../xapi/xapi-base';
export var embeds = {
    rootUrl: "https://veracity.fpg.unc.edu/integrations/embedableCharts/?id=",
    mode: 'production',
    iFrameHtml: {},
    init: function (report) {
        $('.custom.filters').addClass('hidden');
        var isSandbox = xapi.connection.isSandbox();
        this.mode = isSandbox ? 'sandbox' : 'production';
        this.loadDataToHtml(report);
        this.bindEvents();
    },
    loadDataToHtml: function (report) {
        var _this = this;
        console.log('report:', report);
        var html = '';
        var embeds = report.embeds;
        embeds.forEach(function (section) {
            html += _this.getSectionHtml(section);
        });
        $('.embed-container').html(html);
    },
    bindEvents: function () {
        function toggleSectionPanel(e) {
            var $this = $(this);
            var $sectionContainer = $this.parent('.section-container');
            var isOpen = $sectionContainer.hasClass('open');
            var isLoaded = $sectionContainer.hasClass('loaded');
            var $iFrameContainer = $sectionContainer.find('.section-charts-container');
            if (isOpen) {
                $sectionContainer.removeClass('open');
            }
            else if (isLoaded) {
                $sectionContainer.addClass('open');
            }
            else {
                var section = $sectionContainer[0].dataset.section;
                var iFrameHtml = embeds.iFrameHtml[section];
                $iFrameContainer.append(iFrameHtml);
                var $frames = $iFrameContainer.find('iframe');
                embeds.bindIframeEvents($frames);
                $sectionContainer.addClass('open loaded');
            }
        }
        $('.section-title').on('click', toggleSectionPanel);
    },
    bindIframeEvents: function ($frames) {
        // const frames = document.querySelectorAll('iframe');
        function hideLoading() {
            var $frameLoading = $("#" + this.id + "-container .frame-loading-container");
            $frameLoading.addClass('hidden');
        }
        $frames.each(function () {
            this.onload = hideLoading;
        });
        // frames.forEach((frame) => {
        // 	frame.onload = hideLoading;
        // });
    },
    getSectionHtml: function (section) {
        var _this = this;
        var sectionName = section.name, desc = section.desc, charts = section.charts;
        var iFrameHtml = charts.map(function (chart, idx) {
            var name = chart.name, desc = chart.desc, id = chart.id;
            var title = "<strong>" + name + "</strong> | " + desc;
            var thisId = id[_this.mode];
            var url = _this.rootUrl + thisId;
            if (!thisId || thisId.includes('xxxxx')) { // skip WIP charts
                return "\n\t\t\t\t\t<hr>\n\t\t\t\t\t<div>" + desc + "</div>\n\t\t\t\t\t<em>(in progress)</em>\n\t\t\t\t";
            }
            var iframeId = sectionName + "-" + idx;
            return _this.createIFrame(title, url, iframeId);
        }).join('');
        // })[0] || 'TBD'; // TEST one frame each section
        embeds.iFrameHtml[sectionName] = iFrameHtml;
        return "\n\t\t\t<div class=\"section-container\" data-section=\"" + sectionName + "\">\n\t\t\t\t<div class=\"section-title\">\n\t\t\t\t\t<div>\n\t\t\t\t\t\t" + sectionName + " <span class=\"section-title-sub\">| " + desc + "</span>\n\t\t\t\t\t</div>\n\t\t\t\t\t<div class=\"section-icon\"></div>\n\t\t\t\t</div>\n\t\t\t\t<div class=\"section-charts-container hidden\">\n\t\t\t\t\t<hr>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t";
        // ${iFrameHtml}
    },
    /*
    <div class="frame-loading-container">
        <img class="loading" width="70" height="70" src="../../../../../img/loading.png">
    </div>
    */
    createIFrame: function (title, url, iframeId) {
        return "\n\t\t\t<div class=\"chart-frame-container\" id=\"" + iframeId + "-container\">\n\t\t\t\t<p>" + title + "</p>\n\t\t\t\t<div class=\"chart-frame\">\n\t\t\t\t\t<div class=\"frame-loading-container\">\n\t\t\t\t\t\t<img class=\"loading\" width=\"70\" height=\"70\" src=\"../../../../../img/loading.png\">\n\t\t\t\t\t</div>\n\t\t\t\t\t<iframe id=\"" + iframeId + "\" src=\"" + url + "\" style=\"width:100%; height:360px; border: none;\"></iframe>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t";
    },
};
