import { DrilldownTable, defaultTrafficLights, } from "../../../../../shared/interfaces-classes/index";
import { reportUtils } from '../../../../report-utils';
var getGenericGroupMsg = reportUtils.getGenericGroupMsg;
var genericGroupMsg = getGenericGroupMsg();
export var assetsNeeds = {
    'http://SISEPAssetsNeeds': {
        columns: [
            {
                parent: 'LO1a & LO2a',
                variablename: 'LO12adash',
                desc: 'Determine how to assess system-level and organizational-level assets and needs',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'TextLO12a',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var TextLO12a = varDict.TextLO12a;
                        // Table 1: LO12a ==============================
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'Which of these system-level assessment tools would you use first during exploration?',
                                ],
                            },
                            {
                                header: 'Answer',
                                rows: [
                                    TextLO12a,
                                ],
                            },
                            {
                                header: 'Correct Answer',
                                rows: [
                                    'Root Cause Analysis',
                                ],
                            },
                        ];
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: columns
                        };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
            {
                parent: 'LO1b & LO2b',
                variablename: 'LO12bdash',
                desc: 'Use asset and need assessments to guide action planning',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'TextLO12b',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var TextLO12b = varDict.TextLO12b;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'What is your next best step as your team enters the installation phase?',
                                ],
                            },
                            {
                                header: 'Answer',
                                rows: [
                                    TextLO12b,
                                ],
                            },
                            {
                                header: 'Correct Answer',
                                rows: [
                                    'Readiness Thinking Tool',
                                ],
                            },
                        ];
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: columns
                        };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                },
            },
        ]
    }
};
