export var clDrilldown = {
    nirn: {
        startDoing1: "\n\t\t\t<ul>\n\t\t\t\t<li>Co-planning & facilitating implementation team meetings</li>\n\t\t\t\t<li>Build a relationship with the Curriculum Director & Early Childhood Director</li>\n\t\t\t\t<li>Push for more reflection time and questioning at implementation team meetings.</li>\n\t\t\t\t<li>Ensure that the team remains positive and keep the team moving forward with barrier busting</li>\n\t\t\t</ul>\n\t\t",
        stopDoing1: "\n\t\t\t<ul>\n\t\t\t\t<li>Meeting with staff without pre-planning from the implementation team.</li>\n\t\t\t\t<li>Looking at data in isolation. It should be prepared in a way for the team to review.</li>\n\t\t\t</ul>\n\t\t",
        doMore1: "\n\t\t\t<ul>\n\t\t\t\t<li><strong>Influence</strong>: focus on developing relationships with the team and stakeholders before jumping into the nitty-gritty\n\t\t\t\t\tof the work.</li>\n\t\t\t\t<li><strong>Physical Presence</strong>: Be more vocal in meetings until co-planning begins.</li>\n\t\t\t\t<li><strong>Persuasive/Positivity</strong>: While he has worked on persuading HS staff members to be involved, focusing on the motivation as well.</li>\n\t\t\t</ul>\n\t\t",
        doLess1: "\n\t\t\t<ul>\n\t\t\t\t<li><strong>Ownership:</strong> Meeting with High School staff without team discussions & analyzing data on their own.\n\t\t\t\t</li>\n\t\t\t</ul>\n\t\t",
        continueDoing1: "\n\t\t\t\t<ul>\n\t\t\t\t\t<li>Advocate for the program with the High School staff (once a plan is developed by the team).</li>\n\t\t\t\t\t<li>Use data to problem-solve </li>\n\t\t\t\t</ul>\n\t\t",
        startDoing2: "\n\t\t\t\t<ul>\n\t\t\t\t\t<li>Provide learning around leadership roles at the team meeting.</li>\n\t\t\t\t\t<li>Meet with the Superintendent and Curriculum Director to discuss the activities of a champion and how they can\n\t\t\t\t\t\tsupport them.</li>\n\t\t\t\t</ul>\n\t\t",
        stopDoing2: "\n\t\t\t<ul>\n\t\t\t\t<li>Not advocating for reflection time at meetings.</li>\n\t\t\t</ul>\n\t\t",
        doMore2: "\n\t\t\t<ul>\n\t\t\t\t<li>Discuss the role of the champion at meetings.</li>\n\t\t\t\t<li>Assist the team with defining roles in the Terms of Reference and make it a regular agenda item.</li>\n\t\t\t</ul>\n\t\t",
        doLess2: '',
        continueDoing2: "\n\t\t\t\t<ul>\n\t\t\t\t\t<li>Co-planning implementation team meetings with the Curriculum Director, but bring in the Middle School Teacher</li>\n\t\t\t\t</ul>\n\t\t",
    },
};
