var ColumnData = /** @class */ (function () {
    function ColumnData() {
        this.total = 0;
        this.dataMap = {};
    }
    return ColumnData;
}());
export { ColumnData };
var DynamicTableOptions = /** @class */ (function () {
    function DynamicTableOptions() {
    }
    return DynamicTableOptions;
}());
export { DynamicTableOptions };
var StandardTableRow = /** @class */ (function () {
    function StandardTableRow() {
        this.name = '';
        this.id = '';
        this.verb = '';
        this.activityName = '';
        this.activityDesc = '';
        this.response = '';
        this.score = '';
        this.maxscore = '';
        // success: boolean = false;
        // completion: boolean = false;
        this.success = '';
        this.completion = '';
        this.percentageViewed = '';
        this.duration = '';
        this.variable = '';
        this.date = '';
        // browser: browser,
        // system: system,
        // json: rawJsonBtn
    }
    return StandardTableRow;
}());
export { StandardTableRow };
/**
 * Custom columns to be added to standard reports
 */
var CustomColumn = /** @class */ (function () {
    function CustomColumn() {
    }
    return CustomColumn;
}());
export { CustomColumn };
