var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { customReports } from "../reports/custom-reports";
import { standardReports } from "../reports/standard-reports";
export var table = {
    project: '',
    report: {},
    isCustom: false,
    instance: null,
    columns: [],
    columnWidths: [],
    useNameAndDescHeaders: false,
    nestedHeaders: [],
    init: function (tableData, action) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, isCustom, project, customProject, tableExists;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this, isCustom = _a.isCustom, project = _a.project;
                        customProject = customReports[project];
                        if (isCustom) {
                            if (customProject) {
                                this.columns = customReports[project].createColumnStructure(action);
                            }
                            else {
                                console.error('this.project not available?', project);
                                return [2 /*return*/];
                            }
                        }
                        else {
                            tableExists = table.instance || action === 'more';
                            if (!tableExists) {
                                this.columns = standardReports.createColumnStructure(action);
                                this.columnWidths = standardReports.createColumnWidths();
                            }
                        }
                        return [4 /*yield*/, this.create(tableData)];
                    case 1: 
                    // console.log('this.columns:', this.columns);
                    // this.register.incorrectValueFn();
                    return [2 /*return*/, _b.sent()];
                }
            });
        });
    },
    getCustomHeaders: function () {
        var _a = this, project = _a.project, useNameAndDescHeaders = _a.useNameAndDescHeaders;
        var colHeaders = [];
        // const customHeaders = customReports[project].colHeaders[report.id][report.type];
        // const customHeaders = customReports[project].createColumnHeaders(standardReports.colHeaders);
        var customHeaders = customReports[project].createColumnHeaders();
        if (!customHeaders) {
            return [];
        }
        if (useNameAndDescHeaders) {
            colHeaders = true;
            this.nestedHeaders = customHeaders;
        }
        else {
            colHeaders = customHeaders;
            this.nestedHeaders = [];
        }
        return colHeaders;
    },
    create: function (tableData) {
        var _a = this, isCustom = _a.isCustom, columns = _a.columns, columnWidths = _a.columnWidths;
        var colHeaders = isCustom ? this.getCustomHeaders() : standardReports.colHeaders;
        // const standardWidths = [120, 130, 80, 200, 250, 250, 50, 50, 110, 150, 110, 150, 75, 110, 50];
        var colWidths = isCustom ? 190 : columnWidths;
        var nestedHeaders = Array.isArray(this.nestedHeaders) && this.nestedHeaders.length ? this.nestedHeaders : false;
        var tableSettings = {
            data: tableData,
            // https://handsontable.com/docs/7.1.1/demo-filtering.html
            filters: true,
            // dropdownMenu: true,
            dropdownMenu: ['filter_by_condition', 'filter_by_value', 'filter_action_bar'],
            afterGetColHeader: this.filters.addInput,
            beforeOnCellMouseDown: this.doNotSelectColumn,
            // persistentState: true,
            search: true,
            stretchH: 'all',
            // stretchH: 'none',
            rowHeaders: true,
            columnSorting: true,
            currentRowClassName: 'currentRow',
            colHeaders: colHeaders,
            nestedHeaders: nestedHeaders,
            columns: columns,
            // colWidths: [120, 130, 80, 200, 250, 250, 50, 50, 110, 150, 110, 150, 75, 110, 50],
            // colWidths: ["auto"],
            // colWidths: 190,
            manualColumnResize: true,
            manualRowResize: true,
            colWidths: colWidths,
            // autoColumnSize: {
            // 	useHeaders: true,
            // 	// syncLimit: 300,
            // },
            // autoColumnSize: true,
            contextMenu: false,
            readOnly: true,
            fixedRowsTop: 0,
            fixedColumnsLeft: 1,
            // width: 'auto',
            // https://handsontable.com/docs/7.2.2/Options.html#columnSummary
            // columnSummary: [
            // 	{
            // 		destinationRow: 0,
            // 		destinationColumn: 3,
            // 		type: 'average',
            // 		forceNumeric: true,
            // 		reversedRowCoords: true,
            // 	},
            // 	{
            // 		destinationRow: 0,
            // 		destinationColumn: 4,
            // 		type: 'average',
            // 		forceNumeric: true,
            // 		reversedRowCoords: true,
            // 	}
            // ],
            // cells: (row: number, col: number) => { // prop: string
            // 	const cellProperties: any = {};
            // 	if (!isCustom && col === 6) { // itemscore
            // 		cellProperties.renderer = "incorrectValueRenderer";
            // 	}
            // 	// if (col === 5) { // itemresponse
            // 	//   cellProperties.renderer = "skippedValueRenderer";
            // 	// }
            // 	return cellProperties;
            // },
            licenseKey: 'non-commercial-and-evaluation',
        };
        var container = document.getElementById('results');
        this.instance = new Handsontable(container, tableSettings);
        this.addTextSearch();
        return Promise.resolve();
    },
    addTextSearch: function () {
        var searchField = document.getElementById('search_field');
        Handsontable.dom.addEvent(searchField, 'keyup', function () {
            var search = table.instance.getPlugin('search');
            search.query(this.value);
            table.instance.render();
        });
    },
    filters: {
        addEventListeners: function (input, colIndex) {
            var debounceFn = Handsontable.helper.debounce(function (colIndex, event) {
                var filtersPlugin = table.instance.getPlugin('filters');
                filtersPlugin.removeConditions(colIndex);
                filtersPlugin.addCondition(colIndex, 'contains', [event.realTarget.value]);
                filtersPlugin.filter();
            }, 200);
            input.addEventListener('keydown', function (event) {
                debounceFn(colIndex, event);
            });
        },
        // Build elements which will be displayed in header.
        getInitializedElements: function (colIndex) {
            var div = document.createElement('div');
            var input = document.createElement('input');
            input.placeholder = 'Filter';
            div.className = 'filterHeader';
            this.addEventListeners(input, colIndex);
            div.appendChild(input);
            return div;
        },
        addInput: function (col, TH) {
            // Hooks can return value other than number (for example `columnSorting` plugin use this).
            if (typeof col !== 'number') {
                return col;
            }
            if (table.nestedHeaders.length) {
                var parentRow = $(TH).parent('tr')[0];
                var isFirst = parentRow ? !(parentRow.previousElementSibling || parentRow.previousSibling) : false;
                if (isFirst) {
                    return;
                }
            }
            if (col >= 0 && TH.childElementCount < 2) {
                TH.appendChild(table.filters.getInitializedElements(col));
            }
        },
    },
    doNotSelectColumn: function (event, coords) {
        if (coords.row === -1 && event.realTarget.nodeName === 'INPUT') {
            event.stopImmediatePropagation();
            this.deselectCell();
        }
    },
    register: {
        incorrectValueFn: function () {
            function incorrectValueRenderer(instance, td, row, col) {
                Handsontable.renderers.TextRenderer.apply(this, arguments);
                var thisRowData = instance.getData()[row][col];
                var thisVerb = instance.getData()[row][2];
                var MAX_SCORE_DATA = instance.getData()[row][col + 1];
                var verbIsScored = thisVerb === "http://adlnet.gov/expapi/verbs/scored";
                if (thisRowData < MAX_SCORE_DATA || !thisRowData && verbIsScored) {
                    $(td).addClass('incorrectCell');
                    $(td).parent().addClass('incorrectRow');
                }
            }
            Handsontable.renderers.registerRenderer('incorrectValueRenderer', incorrectValueRenderer);
        }
    }
};
