/**
 * xAPI LRS Config
 */
var LrsConfig = /** @class */ (function () {
    function LrsConfig(lrsInfo) {
        this.allowFail = false; // TinCan	
        this.endpoint = lrsInfo.endpoint;
        this.auth = lrsInfo.auth;
    }
    return LrsConfig;
}());
export { LrsConfig };
/**
 * xAPI Actor Account
 */
var Account = /** @class */ (function () {
    function Account() {
    }
    return Account;
}());
export { Account };
/**
 * xAPI Actor
 */
var Agent = /** @class */ (function () {
    function Agent() {
    }
    return Agent;
}());
export { Agent };
/**
 * xAPI Search query
 */
var Search = /** @class */ (function () {
    function Search() {
        this.limit = 0;
        this.related_activities = true;
    }
    return Search;
}());
export { Search };
;
