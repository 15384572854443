import { DrilldownTable, defaultTrafficLights, } from "../../../../../shared/interfaces-classes/index";
import { reportUtils } from '../../../../report-utils';
var getBoolFromBoolishVar = reportUtils.getBoolFromBoolishVar, processSingleVarHtml = reportUtils.processSingleVarHtml, processTemplateVarHtmlList = reportUtils.processTemplateVarHtmlList, createDrilldownTableGroup = reportUtils.createDrilldownTableGroup, getGenericGroupMsg = reportUtils.getGenericGroupMsg;
var genericGroupMsg = getGenericGroupMsg();
export var bt = {
    'http://SISEPTeams': {
        columns: [
            // TODO: LO1 is header row above all?
            {
                parent: 'LOintro',
                variablename: 'LOintro',
                desc: 'Identify the need for critical perspectives',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    icon: 'text',
                    vars: {
                        response: [
                            'NeedText',
                            'GoalText',
                            'EngagementText',
                        ]
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var NeedText = varDict.NeedText, GoalText = varDict.GoalText, EngagementText = varDict.EngagementText;
                        var columns = [
                            {
                                header: 'Question',
                                rows: [
                                    'To start this process, you ask the team: what is your identified need for change?',
                                    'Next you facilitate the discussion around the team purpose and forming a goal.',
                                    'Why is engagement from additional partners important to your initiative?',
                                ]
                            },
                            {
                                header: "NIRN's answer",
                                rows: [
                                    'Fourth grade Black boys and students with disabilities are disproportionately failing the state literacy assessment.',
                                    "<p>The purpose of the team is to complete a root cause analysis for the literacy need and select a new literacy curriculum to address the issue/s.</p>\n\t\t\t\t\t\t\t\t\t\t<p>The goal is: By the end of the school year, the team will have selected the new literacy curriculum and gotten approval from the school board in order to begin planning for implementation by summer.</p>",
                                    'The team is "switching gears" from attendance to literacy. We want to make sure the team represents the perspectives of those with experience in literacy, special education and/or fourth grade. It would also be important to have teachers and/or students of color help the team to understand their lived experiences in schools.',
                                ]
                            },
                            {
                                header: "Learner's answer",
                                rows: [
                                    NeedText,
                                    GoalText,
                                    EngagementText,
                                ]
                            }
                        ];
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        return html;
                    }
                }
            },
            {
                parent: 'LO1pre',
                variablename: 'LO1predash',
                desc: 'Accurately utilize guidance for engaging critical perspectives',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'RelevanceMap1',
                            'RelevanceMap2',
                            'RelevanceMap3',
                            'RelevanceMap4',
                            'RelevanceMap5',
                            'RelevanceMap6',
                        ]
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: [
                                {
                                    header: 'Team Member',
                                    rows: [
                                        'Family/Community Engagement Liaison',
                                        'Teacher',
                                        'Administrator Representative ',
                                        'District literacy coach',
                                        'Community Partner',
                                        'Student Support Services Coordinator',
                                    ],
                                },
                                {
                                    header: 'Correct Answer',
                                    rows: [
                                        'Currently lives in focus area',
                                        'Directly works with focus population',
                                        'Similar to focus population',
                                        'Personal lived experience with issue',
                                        {
                                            type: 'list',
                                            listItems: [
                                                'No direct life experience with issue',
                                                'Does not work with focus population',
                                                'Not from historically disenfranchised group, area, or community',
                                            ]
                                        },
                                        {
                                            type: 'list',
                                            listItems: [
                                                'No direct life experience with issue',
                                                'Does not work with focus population',
                                                'Not from historically disenfranchised group, area, or community',
                                            ]
                                        },
                                    ],
                                },
                                {
                                    header: 'Answer Provided',
                                    get rows() {
                                        return processTemplateVarHtmlList(Array.from({ length: 6 }).map(function (_, idx) {
                                            return {
                                                type: 'variable',
                                                variable: "RelevanceMap" + (idx + 1)
                                            };
                                        }));
                                    },
                                }
                            ]
                        };
                        var drilldownTable = new DrilldownTable(drilldownTableOpts);
                        return drilldownTable.html;
                    }
                }
            },
            {
                parent: 'LO1a1',
                variablename: 'LO1a1dash',
                desc: 'Form team with critical perspectives',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        // TODO: check final variable types (success booleans?)
                        success: [
                            'SPED',
                            'IHE',
                            'Family',
                        ],
                        scoreRaw: [
                            'NewTeam',
                        ]
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var NewTeam = varDict.NewTeam, SPED = varDict.SPED, IHE = varDict.IHE, Family = varDict.Family;
                        var image = "\n\t\t\t\t\t\t\t<div>\n\t\t\t\t\t\t\t\t<img src=\"../img/sisep/bt/lo1a1-image.png\" />\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t";
                        var intro = "\n\t\t\t\t\t\t\t<p>Given the lack of experience of two team members, <strong>" + (NewTeam || 0) + "</strong> out of <strong>3</strong> possible new, more experienced members were added to the team:</p>\n\t\t\t\t\t\t";
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: [
                                {
                                    header: 'Potential team member',
                                    rows: [
                                        '<strong>Special Education Coordinator</strong>',
                                        'A subject matter expert from an <strong>institute of higher education</strong>',
                                        'A <strong>family member</strong>',
                                    ]
                                },
                                {
                                    header: 'Correct',
                                    type: 'checkmark',
                                    rows: processTemplateVarHtmlList([
                                        SPED,
                                        IHE,
                                        Family,
                                    ])
                                },
                            ]
                        };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        var drilldownHtml = [
                            image,
                            intro,
                            html
                        ].join('');
                        return drilldownHtml;
                    }
                }
            },
            {
                parent: 'LO1a2',
                variablename: 'LO1a2dash',
                desc: 'Form team with additional critical perspectives',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        success: [
                            'SPEDTeach',
                            'Tutor',
                            'Students',
                            'SPED',
                            'IHE',
                            'Family',
                        ],
                        scoreRaw: [
                            'stakeholders',
                            'NewTeam',
                        ]
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var _a = varDict.stakeholders, stakeholders = _a === void 0 ? 0 : _a, _b = varDict.NewTeam, NewTeam = _b === void 0 ? 0 : _b, SPEDTeach = varDict.SPEDTeach, Tutor = varDict.Tutor, Students = varDict.Students, SPED = varDict.SPED, IHE = varDict.IHE, Family = varDict.Family;
                        // const newTeamVal = +NewTeam;
                        var hasNewTeam = +NewTeam > 0;
                        var intro = '';
                        var columns = [];
                        var header = 'Potential stakeholders';
                        if (hasNewTeam) {
                            intro = "\n\t\t\t\t\t\t\t\t<p><strong>" + stakeholders + " out of 3</strong> possible additional critical perspectives were chosen to work with the team:</p>\n\t\t\t\t\t\t\t";
                            columns = [
                                {
                                    header: header,
                                    rows: [
                                        '<strong>Special Education Teacher</strong>',
                                        '<strong>After School Tutoring Program Coordinator Tutor</strong>',
                                        '<strong>Students</strong> from 3rd, 4th, and 5th grades',
                                    ]
                                },
                                {
                                    header: 'Correct',
                                    type: 'checkmark',
                                    rows: processTemplateVarHtmlList([
                                        SPEDTeach,
                                        Tutor,
                                        Students,
                                    ])
                                },
                            ];
                        }
                        else {
                            intro = "\n\t\t\t\t\t\t\t\t<p><strong>" + stakeholders + " out of 3</strong> of these potential team members were chosen to work with the team as additional critical perspectives:</p>\n\t\t\t\t\t\t\t";
                            columns = [
                                {
                                    header: header,
                                    rows: [
                                        '<strong>Special Education Coordinator</strong>',
                                        'A subject matter expert from an <strong>institute of higher education</strong>',
                                        'A <strong>family member</strong>',
                                    ]
                                },
                                {
                                    header: 'Correct',
                                    type: 'checkmark',
                                    rows: processTemplateVarHtmlList([
                                        SPED,
                                        IHE,
                                        Family,
                                    ])
                                },
                            ];
                        }
                        var drilldownTableOpts = { varDict: varDict, columns: columns };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        var drilldownHtml = [
                            intro,
                            html
                        ].join('');
                        return drilldownHtml;
                    }
                }
            },
            {
                parent: 'LO1b',
                variablename: 'LO1bdash',
                desc: 'Appropriately engage critical perspectives',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            ///////////////////////////////
                            'FacilitatorsSPEDTeach',
                            'FacilitatorsTutor',
                            'FacilitatorsStudents',
                            'BarriersSPEDTeach',
                            'BarriersTutor',
                            'BarriersStudents',
                            ///////////////////////////////
                            'FacilitatorsSPED',
                            'FacilitatorsIHE',
                            'FacilitatorsFamily',
                            'BarriersSPED',
                            'BarriersIHE',
                            'BarriersFamily',
                            ///////////////////////////////
                        ],
                        success: [
                            'SPEDTeach',
                            'Tutor',
                            'Students',
                            'SPED',
                            'IHE',
                            'Family',
                        ],
                        scoreRaw: [
                            // 'stakeholders',
                            'NewTeam',
                            'spectrum1',
                            'spectrum2',
                            'spectrum3',
                        ]
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var 
                        // stakeholders = 0,
                        _a = varDict.NewTeam, 
                        // stakeholders = 0,
                        NewTeam = _a === void 0 ? 0 : _a, FacilitatorsSPEDTeach = varDict.FacilitatorsSPEDTeach, FacilitatorsTutor = varDict.FacilitatorsTutor, FacilitatorsStudents = varDict.FacilitatorsStudents, BarriersSPEDTeach = varDict.BarriersSPEDTeach, BarriersTutor = varDict.BarriersTutor, BarriersStudents = varDict.BarriersStudents, SPEDTeach = varDict.SPEDTeach, Tutor = varDict.Tutor, Students = varDict.Students, FacilitatorsSPED = varDict.FacilitatorsSPED, FacilitatorsIHE = varDict.FacilitatorsIHE, FacilitatorsFamily = varDict.FacilitatorsFamily, BarriersSPED = varDict.BarriersSPED, BarriersIHE = varDict.BarriersIHE, BarriersFamily = varDict.BarriersFamily, SPED = varDict.SPED, IHE = varDict.IHE, Family = varDict.Family, spectrum1 = varDict.spectrum1, spectrum2 = varDict.spectrum2, spectrum3 = varDict.spectrum3;
                        // const newTeamVal = +(NewTeam || '');
                        var hasNewTeam = +NewTeam > 0;
                        var hasNewTeamPerspectiveRowHeaders = [
                            'Special Education Teacher',
                            'After School Tutoring Program Coordinator',
                            'Students from 3rd, 4th, and 5th grades',
                        ];
                        var hasNoNewTeamPerspectiveRowHeaders = [
                            'Special Education Coordinator',
                            'A subject matter expert from an institute of higher education',
                            'A family member',
                        ];
                        var spectrumList = [
                            'Inform',
                            'Consult',
                            'Involve',
                            'Co-create',
                            'Collaborate',
                        ];
                        var table1Title = 'Identified facilitators and barriers';
                        var table2Title = 'Level of engagement chosen with each critical perspective';
                        var table1ColumnHeaders = [
                            'Critical Perspectives',
                            'Facilitators identified by NIRN',
                            'Facilitators identified by learner',
                            'Barriers identified by NIRN',
                            'Barriers identified by learner',
                        ];
                        var table2ColumnHeaders = [
                            'Critical Perspectives',
                            'Most appropriate level of engagement',
                            'Level of engagement chosen',
                        ];
                        // ========================
                        function getEngageSpectrumVal(bool, spectrum) {
                            var boolVal = getBoolFromBoolishVar(bool);
                            var isTrue = boolVal === true;
                            var isFalse = boolVal === false;
                            if (isFalse) {
                                return 'N/A';
                            }
                            if (!isTrue) {
                                return boolVal;
                            } // undefined, no data
                            var spectrumInvalid = Number.isNaN(+spectrum) || (+spectrum < 1);
                            var idx = spectrumInvalid ? 0 : (+spectrum - 1);
                            return spectrumList[idx];
                        }
                        // ========================
                        var table1Columns = [];
                        var table2Columns = [];
                        var drilldown1TableOpts = null;
                        var drilldown2TableOpts = null;
                        var drilldown1Html = '';
                        var drilldown2Html = '';
                        // let drilldownHtml = '';
                        if (hasNewTeam) {
                            table1Columns = [
                                {
                                    header: table1ColumnHeaders[0],
                                    rows: hasNewTeamPerspectiveRowHeaders
                                },
                                {
                                    header: table1ColumnHeaders[1],
                                    rows: [
                                        "<strong>Potential facilitators:</strong> Willingness to support the work and participate in decision making. Wilson Reading trained. Co-teaching in ELA classrooms.",
                                        "<strong>Facilitators:</strong> Good relationships with families and students. Supports literacy development in the after school program.",
                                        "<strong>Facilitators:</strong> The students have valuable insight as to what makes reading difficult and what they would like to be reading.",
                                    ]
                                },
                                {
                                    header: table1ColumnHeaders[2],
                                    rows: [
                                        FacilitatorsSPEDTeach,
                                        FacilitatorsTutor,
                                        FacilitatorsStudents,
                                    ]
                                },
                                {
                                    header: table1ColumnHeaders[3],
                                    rows: [
                                        "<strong>Time</strong> - utilizing planning periods or other time during the day makes it hard for him to keep up with day to day tasks. New teacher and may not feel as willing to speak up and advocate for student needs.",
                                        "<strong>Potential barriers:</strong> Lack of connection or understanding literacy programs during the school day. Time could also be a factor.",
                                        "<strong>Potential barriers:</strong> Time out of class. Questions being framed to the students in such a way as to get answers that can be considered in active decision making.",
                                    ]
                                },
                                {
                                    header: table1ColumnHeaders[4],
                                    rows: [
                                        BarriersSPEDTeach,
                                        BarriersTutor,
                                        BarriersStudents,
                                    ]
                                }
                            ];
                            table2Columns = [
                                {
                                    header: table2ColumnHeaders[0],
                                    rows: hasNewTeamPerspectiveRowHeaders
                                },
                                {
                                    header: table2ColumnHeaders[1],
                                    rows: [
                                        'Collaborate',
                                        'Collaborate',
                                        'Collaborate',
                                    ]
                                },
                                {
                                    header: table2ColumnHeaders[2],
                                    rows: [
                                        getEngageSpectrumVal(SPEDTeach, spectrum1),
                                        getEngageSpectrumVal(Tutor, spectrum2),
                                        getEngageSpectrumVal(Students, spectrum3),
                                    ]
                                },
                            ];
                        }
                        else {
                            table1Columns = [
                                {
                                    header: table1ColumnHeaders[0],
                                    rows: hasNoNewTeamPerspectiveRowHeaders
                                },
                                {
                                    header: table1ColumnHeaders[1],
                                    rows: [
                                        "<strong>Facilitators:</strong> Advocate for students with disabilities, Has the perspective of the whole district.",
                                        "<strong>Facilitators:</strong> Subject matter expert in literacy.",
                                        "<strong>Facilitators:</strong> Great relationships with other families in the community. Lived experience with reading difficulties.",
                                    ]
                                },
                                {
                                    header: table1ColumnHeaders[2],
                                    rows: [
                                        FacilitatorsSPED,
                                        FacilitatorsIHE,
                                        FacilitatorsFamily,
                                    ]
                                },
                                {
                                    header: table1ColumnHeaders[3],
                                    rows: [
                                        "<strong>Potential barriers:</strong> Time. Is comfortable with working in silos to get things done faster and not used to working with general education.",
                                        "<strong>Potential barriers:</strong> Lack of knowledge of the feasibility of the various literacy programs based on the contextual factors in the district.",
                                        "<strong>Potential barriers:</strong> Time due to work schedules.",
                                    ]
                                },
                                {
                                    header: table1ColumnHeaders[4],
                                    rows: [
                                        BarriersSPED,
                                        BarriersIHE,
                                        BarriersFamily,
                                    ]
                                }
                            ];
                            table2Columns = [
                                {
                                    header: table2ColumnHeaders[0],
                                    rows: hasNoNewTeamPerspectiveRowHeaders
                                },
                                {
                                    header: table2ColumnHeaders[1],
                                    rows: [
                                        'Co-create',
                                        'Collaborate',
                                        'Collaborate',
                                    ]
                                },
                                {
                                    header: table2ColumnHeaders[2],
                                    rows: [
                                        getEngageSpectrumVal(SPED, spectrum1),
                                        getEngageSpectrumVal(IHE, spectrum2),
                                        getEngageSpectrumVal(Family, spectrum3),
                                    ]
                                },
                            ];
                        }
                        drilldown1TableOpts = { varDict: varDict, columns: table1Columns };
                        drilldown2TableOpts = { varDict: varDict, columns: table2Columns };
                        var drilldown1Table = new DrilldownTable(drilldown1TableOpts);
                        var drilldown2Table = new DrilldownTable(drilldown2TableOpts);
                        drilldown1Html = drilldown1Table.html || undefined;
                        drilldown2Html = drilldown2Table.html || undefined;
                        var drilldown1 = "<h3>" + table1Title + "</h3>" + processSingleVarHtml(drilldown1Html);
                        var drilldown2 = "<h3>" + table2Title + "</h3>" + processSingleVarHtml(drilldown2Html);
                        return createDrilldownTableGroup([drilldown1, drilldown2]);
                        // drilldownHtml = [
                        // 	`<h3>${table1Title}</h3>`,
                        // 	drilldown1Html,
                        // 	'<hr class="drilldown-table-divider" />',
                        // 	`<h3>${table2Title}</h3>`,
                        // 	drilldown2Html,
                        // ].join('');
                        // return drilldownHtml;
                    }
                }
            },
            {
                parent: 'LO3',
                variablename: 'LO3dash',
                desc: 'Resolve team conflict & building team cohesion through trust while supporting technical functions',
                trafficLight: '',
                trafficLightGroupLogic: defaultTrafficLights,
                drilldown: {
                    vars: {
                        response: [
                            'CohesionChoice1',
                            'CohesionChoice2',
                            'CohesionChoice3',
                        ],
                    },
                    template: function (varDict) {
                        if (varDict.type === 'group') {
                            return genericGroupMsg;
                        }
                        var CohesionChoice1 = varDict.CohesionChoice1, CohesionChoice2 = varDict.CohesionChoice2, CohesionChoice3 = varDict.CohesionChoice3;
                        var drilldownTableOpts = {
                            varDict: varDict,
                            columns: [
                                {
                                    header: 'Scenario',
                                    type: 'text',
                                    rows: [
                                        "\n\t\t\t\t\t\t\t\t\t\t\t<p>The Sunny Side School Implementation Team has met to begin Exploration to address literacy concerns outlined in the data.</p>\n\n\t\t\t\t\t\t\t\t\t\t\t<p>The agenda for the next meeting was developed by the special education teacher based on notes from previous meetings, but she wasn\u2019t sure when or where the meeting was being held.</p> \n\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t<p>She sent the draft agenda to the principal to fill in the missing information and review. He provided the missing information but removed the majority of agenda items the special education teacher had added.</p> \n\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t<p>The principal then sent the agenda to the general education teacher for final review. She changed the date and time of the meeting and emailed the agenda with the location to the remainder of the team. As a result, the team engaged in a long email thread questioning time, date, location, and items for the agenda.</p>\n\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t<p>The special education teacher was left wondering why she volunteered to create the next agenda if her principal was going to change everything anyway. She determined she wouldn\u2019t waste her valuable time doing so for the next meeting. Additionally, she wondered why her general education colleague didn\u2019t come to her with questions or concerns and just changed the date and time.</p>\n\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t<p>As the ISP, what could you do to facilitate a stronger process and reestablish commitment from the special educator who is feeling disenfranchised?</p>",
                                        "\n\t\t\t\t\t\t\t\t\t\t\t<p>The Sunny Side School Implementation Team has completed exploration and determined that they were going to select the \u201CReading is Fun\u201D literacy program for Grades 3-5 to begin in the next school year.</p> \n\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t<p>The district level literacy specialist was part of the team and decision-making process. However, because the district operates with a norm of allowing schools to make independent decisions, the literacy specialist did not see the need to communicate this information with other leaders in the district.</p>\n\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t<p>As the ISP working with the school and the district, you know that the district is made up of highly successful independent offices. You have noticed that the various offices work in silos and do not communicate effectively with each other. You heard from the Director of Special Education, with whom you work closely, that the Superintendent has set a goal for building a coherent model for different offices to work together to align efforts.</p> \n\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t<p>Additionally, when the Special Education Director heard about the program selected, he was very frustrated. Special Education students were not performing well in literacy at Sunny Side School and the program selected was not aligned to the beliefs around literacy put forth by that office. He therefore set up a meeting with you, the district literacy specialist, the principal and the special education teacher who was co-leading the school implementation team to express his concerns and demand that a new or additional program be selected for use by special education teachers from a menu of programs put out by that office.</p> \n\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t<p>As the ISP how might you support a resolution?</p>\n\t\t\t\t\t\t\t\t\t\t",
                                        "\n\t\t\t\t\t\t\t\t\t\t\t<p>The Sunny Side School Implementation Team, in collaboration with their new district level team members and other district resources, developed a plan for training and coaching the \u201CReading is Fun\u201D literacy program.</p> \n\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t<p>They collected data from their first two professional learning sessions. When analyzing the results, it became clear that general education teachers appreciated the training. However, the special education teachers were struggling with the information despite the alignment concerns and modification made by the implementation team and district partners.</p> \n\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t<p>The general education teacher on the team, who co-taught with the two special educators, attempted to elevate the concerns for special education and asked several questions. Some on the team were visibly frustrated with the ongoing questions and challenges and questioned the general education teacher\u2019s interest in front of the other team members.</p>\n\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t<p>Given that the program as well as the training \u2018worked for most\u2019 they wanted to move forward and have the special education team address the problems given their specialized expertise. The general education teacher tried explaining her viewpoint of the results and why that solution was incongruous with the inclusive environment they were trying to build. Not feeling heard, she ultimately left the meeting early due to frustration.</p> \n\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t<p>As the ISP, how might you support a resolution so the team can move forward?</p>\n\t\t\t\t\t\t\t\t\t\t",
                                    ]
                                },
                                {
                                    header: 'Correct answer',
                                    type: 'text',
                                    rows: [
                                        "\n\t\t\t\t\t\t\t\t\t\t\t<p>You schedule planning time with your team leads (the general and special educators) and the principal. During this time, you facilitate a discussion that leads them to name the adaptive challenge/s (e.g., confusion around roles and responsibilities, lack of clear communication, confusion and feeling undervalued).</p> \n\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t<p>Additionally, after providing information on effective team meeting processes as well as a Terms of Reference, you reiterate the challenges and support the co-creation of a plan for the next team meeting to address the concerns. The plan they create outlines a process similar to what you just modeled.</p> \n\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t<p>The principal agrees the general educator and special educator should facilitate the meeting. They wanted to begin the meeting with celebrations of the team to date to acknowledge everyone\u2019s contributions and the challenges they are having. They will lead a brief learning session on team meetings and a Terms of Reference (ToR).</p> \n\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t<p>The team will co-create a ToR that defines roles and responsibilities of team members with specific attention to team meeting processes and norms (i.e., agenda formation with standard items, meeting schedule for the year and communication routines).</p>\n\t\t\t\t\t\t\t\t\t\t",
                                        "\n\t\t\t\t\t\t\t\t\t\t\t<p>You have a strong working relationship with the Director of Special Education. You understand the extremely valuable work he has accomplished for students with disabilities in the district. You are open with him and privately offer a suggestion.</p> \n\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t<p>You ask if rather than calling a small group meeting, he would be open to lifting his concerns to the district level team charged with supporting alignment across offices as well as the school implementation team. He agrees and also agrees to support planning with you and the team leads for both meetings.</p> \n\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t<p>You both meet with the school team wherein you outline an agenda (using the previously established template) that will move the team through problem solving the inclusion of SWDs in the selected program as well as strengthen and revise the communication portions within the ToR to include communication with specific teams and individuals at the district level. Additionally, you decide that the school team will revisit team membership to determine if others from the district are needed.</p> \n\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t<p>When you meet with the district leads for the team charged with alignment, you outline an agenda that supports structuring the team to hold the functions of an implementation team with literacy as an initial focus. You agree to propose putting similar structures in place as those being used by the school team.</p>\n\t\t\t\t\t\t\t\t\t\t",
                                        "\n\t\t\t\t\t\t\t\t\t\t\t<p>As the ISP, you meet with the general and special education lead team members to plan for the next meeting per the responsibilities outlined in the ToR. You co-create a plan leading the team to develop decision making protocols around the data.</p> \n\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t<p>While you will ask the team to generate data analysis norms, you structure the conversation to include such parameters as when you will need to collect more data, what additional data is needed, who analyzes or tells the story of the data, and what the process will be when the data indicates a negative impact on marginalized populations.</p> \n\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t<p>You feel like this will be a good strategy for setting clear goals and tasks when looking at data as well promote openness and transparency. Additionally, you determine that the team should revisit and perhaps redefine norms to include those that define conscientiousness and collegiality with the team at the next meeting.</p>\n\t\t\t\t\t\t\t\t\t\t",
                                    ]
                                },
                                {
                                    header: 'Answer chosen',
                                    type: 'text',
                                    rows: processTemplateVarHtmlList([
                                        CohesionChoice1,
                                        CohesionChoice2,
                                        CohesionChoice3,
                                    ])
                                },
                            ]
                        };
                        var html = new DrilldownTable(drilldownTableOpts).html;
                        var drilldownHtml = [
                            html
                        ].join('');
                        return drilldownHtml;
                    }
                }
            },
        ],
        specialGroupVarDictFns: {},
    }
};
