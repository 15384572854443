var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { reportUtils } from "../../report-utils";
import { messaging, utils } from '../../../shared/exports';
import { xapi } from '../../../xapi/xapi-base';
import { structures } from './reports/structures/exports';
import { drilldownGrid } from "../../../shared/custom-report/drilldown-grid";
import { embeds } from "../../embeds";
var addedColumns = [
    {
        index: 2,
        width: 130,
        header: 'State',
        propName: 'state',
        propType: 'string',
        data: {
            type: 'context',
            lookInProp: 'id',
            textInProp: '/state/',
            returnProp: 'name',
        },
    },
    {
        index: 3,
        width: 130,
        header: 'Country',
        propName: 'country',
        propType: 'string',
        data: {
            type: 'context',
            lookInProp: 'id',
            textInProp: '/country/',
            returnProp: 'name',
        },
    },
];
// list available in `standard-reports.ts`
var removedColumns = [
    // "Video watched (%)",
    // "Video length (min:sec)",
    'percentageViewed',
    'duration',
];
export var ictp = {
    type: '',
    report: {},
    structures: structures,
    customReportTitle: '',
    addedColumns: addedColumns,
    removedColumns: removedColumns,
    // gets statements using search params
    // will run init() when report button clicked (after statements retrieved)
    onSubmitCallback: function () { },
    init: function (stmts) {
        var _this = this;
        var type = this.type;
        return new Promise(function (resolve, reject) {
            var invalid = !_this[type];
            if (invalid) {
                alert('Custom report not available yet; showing standard report.');
                reject('invalid');
            }
            else {
                if (!stmts || !stmts.length) {
                    messaging.show('alert', 'No data found');
                    $('.grid-loading-container').addClass('hidden');
                    return;
                }
                _this[type].init(stmts, ictp);
                resolve(null);
            }
        });
    },
    searchUserId: '',
    searchUserType: '',
    getxAPISearchValues: function (search) {
        var type = ictp.type, searchUserId = ictp.searchUserId, searchUserType = ictp.searchUserType;
        var isGrid = type === 'grid';
        if (isGrid) {
            if (searchUserId) {
                search.agent = xapi.create.agent(searchUserType, searchUserId);
            }
        }
        else {
            var mbox = $('#email').val() || '';
            if (!mbox) {
                alert('Please enter an email address.');
                return false;
            }
            search.agent = xapi.create.agent('mbox', searchUserId);
        }
        search.limit = 0;
        return search;
    },
    summary: {
        init: function (stmts) {
            if (!stmts || !stmts.length) {
                messaging.show('alert', 'No data found');
                return;
            }
            utils.sortStmtArrayByTimestamp(stmts);
            console.log('stmts:', stmts);
            var processedData = this.updateStructureFromData(stmts);
            console.log('processedData:', processedData);
            this.build(processedData);
        },
        findNestedPropValFromString: function (str, obj) {
            if (!str) {
                return '';
            }
            var strSplit = str.split('.');
            var output = obj;
            strSplit.forEach(function (prop) {
                output = output ? output[prop] || '' : '';
            });
            if (output == 'true' || output == 'false') {
                output = JSON.parse(output);
            }
            return output || '';
        },
        updateStructureFromData: function (stmts) {
            // const { report, structure } = ictp;
            var report = ictp.report;
            var subtype = report.subtype;
            var reportId = subtype ? report.id + "/" + subtype : report.id;
            var reportStructure = structures[reportId];
            var _a = reportStructure.sections, sections = _a === void 0 ? [] : _a;
            var reportData = sections.slice();
            var trafficClass = function (resultResponse) {
                if (!resultResponse) {
                    return '';
                }
                var className = '';
                ['green', 'yellow', 'red'].forEach(function (color) {
                    if (resultResponse.toString().toLowerCase().includes(color)) {
                        className = color + '-light';
                    }
                });
                return className;
            };
            function _createAndFindId(activity) {
                var activityName = activity.activityName, dataProp = activity.dataProp, responseType = activity.responseType, variable = activity.variable;
                var id = [report.id, reportUtils.encodeID(activityName)].join('/');
                activity.id = id;
                console.log('looking for id:', id);
                var matchedActivities = stmts.filter(function (stmt) {
                    var thisId = stmt && stmt.target ? stmt.target.id : '';
                    var isMatch = thisId === id;
                    if (!isMatch) {
                        // Look for variable in context extensions
                        var extensionId = report.id + "/extensions/variable";
                        var _a = stmt.context.extensions, extensions = _a === void 0 ? {} : _a;
                        var variableExtVal = extensions ? extensions[extensionId] : '';
                        isMatch = variableExtVal === variable;
                    }
                    return isMatch;
                });
                utils.sortStmtArrayByTimestamp(matchedActivities, 'desc');
                var foundStmt = matchedActivities[0]; // last occurrence
                console.log('foundStmt:', foundStmt);
                if (foundStmt) {
                    var isColor = activity.hasOwnProperty('color');
                    var prop = isColor ? 'color' : 'response';
                    var stmtPropToEval = dataProp || 'result.response';
                    var propVal = ictp.summary.findNestedPropValFromString(stmtPropToEval, foundStmt);
                    if (isColor) {
                        if (dataProp === 'result.success' || responseType === 'boolean') {
                            propVal = propVal ? 'green-light' : 'red-light';
                        }
                        else if (stmtPropToEval === 'result.response') {
                            propVal = trafficClass(propVal);
                        }
                    }
                    activity[prop] = propVal;
                    activity.stmt = foundStmt;
                }
                else {
                    console.warn('Activity not found:', activity);
                }
            }
            reportData.forEach(function (section) {
                var _a = section.activityList, activityList = _a === void 0 ? [] : _a, groupedSection = section.groupedSection;
                if (groupedSection) {
                    groupedSection.forEach(function (group) {
                        group.activityList.forEach(_createAndFindId);
                    });
                }
                else {
                    activityList.forEach(_createAndFindId);
                }
            });
            return reportData;
        },
        build: function (data) {
            var html = '<div class="custom-report">';
            function _createResponseEl(obj) {
                var isTrafficLight = obj.hasOwnProperty('color');
                var responseClass = isTrafficLight ? "traffic-light " + (obj.color.replace(' ', '-') || '') : 'text-response';
                if (isTrafficLight) {
                    return "<span class=\"" + responseClass + "\"></span>";
                }
                else if (obj.response) {
                    return "<span class=\"" + responseClass + "\">" + obj.response + "</span>";
                }
                else {
                    return '';
                }
            }
            function _createNoteEl(note) {
                return note ? "<div class=\"summary-note\">" + note + "</div>" : '';
            }
            function _addLightsAndText(parent, activity) {
                var isTrafficLight = activity.hasOwnProperty('color');
                var notATextResponse = isTrafficLight || activity.hasOwnProperty('activityList');
                var _a = activity.title, title = _a === void 0 ? '' : _a;
                var value = _createResponseEl(activity);
                if (notATextResponse) {
                    parent.trafficLight = { title: title, value: value };
                }
                else {
                    parent.textResponse.push({ title: title, value: value });
                }
            }
            function _getTextResponse(responseArray) {
                if (!responseArray) {
                    return '';
                }
                return responseArray.map(function (response) {
                    var text = response.value || '';
                    return "\n\t\t\t\t\t\t<div class=\"text-response-wrapper\">\n\t\t\t\t\t\t\t<span class=\"text-response-label\">" + (response.title || 'User response:') + "</span>\n\t\t\t\t\t\t\t<span class=\"text-response-body " + (text ? 'populated' : 'empty') + "\">" + (text || 'No response') + "</span>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t";
                }).join('');
            }
            // function _getSubSections(section: any) {
            // 	return section.subSections ? `<div>${section.subSections.join('')}</div>` : '';
            // }
            function createSectionHtml(section) {
                console.log('section:', section);
                var title = section.title, titleSize = section.titleSize, note = section.note, trafficLight = section.trafficLight, textResponse = section.textResponse, standalone = section.standalone;
                var subtitle = trafficLight.title ? "<h3 class=\"section-subtitle\">" + trafficLight.title + "</h3>" : '';
                var isStandaloneText = standalone && textResponse.length;
                return "\n\t\t\t\t\t<div class=\"custom-report-section " + (standalone ? 'standalone' : '') + "\">\n\t\t\t\t\t\t<div class=\"traffic-light-container " + (isStandaloneText ? 'hidden' : '') + "\">\n\t\t\t\t\t\t\t" + (trafficLight.value || '') + " \n\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\n\t\t\t\t\t\t<div class=\"flex-column\">\n\t\t\t\t\t\t\t<div class=\"section-title\">\n\t\t\t\t\t\t\t\t<div class=\"section-title-wrapper\">\n\t\t\t\t\t\t\t\t\t<span class=\"section-title-text " + (titleSize || '') + "\">" + title + "</span>\n\t\t\t\t\t\t\t\t\t" + subtitle + "\n\t\t\t\t\t\t\t\t\t" + _createNoteEl(note) + "\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\n\t\t\t\t\t\t\t<div class=\"custom-report-section-body\">\n\t\t\t\t\t\t\t\t" + _getTextResponse(textResponse) + "\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t";
            }
            // ${_getSubSections(section)}
            // function _addSubSectionHtml(section, activity) {
            // 	section.subSections.push(createSectionHtml(activity));
            // }
            function _createAndAddSectionHtml(parent) {
                var activityList = parent.activityList;
                parent.trafficLight = {
                    title: '',
                    value: ''
                };
                parent.textResponse = [];
                activityList.forEach(function (activity) {
                    _addLightsAndText(parent, activity);
                });
                var sectionHtml = createSectionHtml(parent);
                return sectionHtml;
            }
            var reportAdditionalClasses = new Set();
            data.forEach(function (section) {
                var title = section.title, separator = section.separator, subtitle = section.subtitle, groupedSection = section.groupedSection;
                if (separator) {
                    reportAdditionalClasses.add('with-separators');
                    html += '<div class="custom-report-separator"></div>';
                }
                else {
                    if (groupedSection) {
                        html += "\n\t\t\t\t\t\t\t<div class=\"custom-report-group\">\t\n\t\t\t\t\t\t\t\t<h3 class=\"custom-report-group-title\">" + title + "</h3>\n\t\t\t\t\t\t\t\t<div class=\"custom-report-group-body\">\n\t\t\t\t\t\t";
                        groupedSection.forEach(function (group) {
                            html += _createAndAddSectionHtml(group);
                        });
                        html += '</div></div>';
                    }
                    else if (subtitle) {
                        html += "<h3 class=\"custom-report-subtitle\">" + subtitle + "</h3>";
                    }
                    else {
                        html += _createAndAddSectionHtml(section);
                    }
                    // section.trafficLight = {
                    // 	title: '',
                    // 	value: ''
                    // };
                    // section.textResponse = [];
                    // activityList.forEach((activity: any) => {
                    // 	_addLightsAndText(section, activity);
                    // });
                    // const sectionHtml = createSectionHtml(section);
                    // html += sectionHtml;
                }
            });
            html += '</div>';
            if (ictp.customReportTitle) {
                $('.custom-report-title').html(ictp.customReportTitle);
                $('.custom-report-title').removeClass('hidden');
            }
            $('.custom-report-container').html(html);
            if (reportAdditionalClasses.size > 0) {
                reportAdditionalClasses.forEach(function (cls) {
                    $('.custom-report').addClass(cls);
                });
            }
        },
    },
    grid: drilldownGrid,
    embeds: embeds,
    getFullReportId: function () {
        var report = ictp.report;
        var subtype = report.subtype;
        return subtype ? report.id + "/" + subtype : report.id;
    },
    ui: {
        init: function () {
            var type = ictp.type, printout = ictp.printout;
            var reportId = ictp.getFullReportId();
            var thisReport = structures[reportId];
            var _a = thisReport || {}, title = _a.title, hasPrintout = _a.hasPrintout;
            if (title) {
                ictp.customReportTitle = title;
            }
            if (hasPrintout) {
                $('.print-cert')
                    .removeClass('hidden')
                    .on('click', printout.onGetPrintout);
            }
            if (type === 'printout') {
                $('.get-stmts').addClass('hidden');
            }
            this.updateFilters();
            this.bindEvents();
        },
        updateFilters: function () {
            $('.standard.filters').addClass('hidden');
            $('.custom.filters').removeClass('hidden');
            $('.user-filter').removeClass('hidden');
            $('.user-filter-email').removeClass('hidden');
        },
        bindEvents: function () {
            reportUtils.bindActorFormEvents(ictp, drilldownGrid);
        },
    },
    printout: {
        onGetPrintout: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a, endpoint, auth, mbox, report, printUrl, fullReportId, profileId, printQuery;
                return __generator(this, function (_b) {
                    _a = xapi.connection.config, endpoint = _a.endpoint, auth = _a.auth;
                    mbox = $('#email').val() || '';
                    if (!mbox) {
                        alert('Please enter an email address.');
                        return [2 /*return*/];
                    }
                    report = ictp.report;
                    printUrl = '../printouts/ictp/' + report.id.replace('http://', '') + '/';
                    fullReportId = ictp.getFullReportId();
                    profileId = structures[fullReportId] ? structures[fullReportId].profileId : '';
                    printQuery = '?standalone=true' +
                        '&retrieve=true' +
                        ("&profileId=" + encodeURIComponent(profileId)) +
                        ("&endpoint=" + encodeURIComponent(endpoint)) +
                        ("&auth=" + encodeURIComponent(auth)) +
                        ("&actor={%22mbox%22:%22mailto:" + encodeURIComponent(mbox) + "%22}");
                    console.log('printUrl + printQuery:', printUrl + printQuery);
                    window.open(printUrl + printQuery);
                    return [2 /*return*/];
                });
            });
        },
    },
};
